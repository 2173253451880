// ------------------ CUSTOMER SERVICE ------------------ //
.mfrm-online-pay {
    &__customer-service {
        padding-top: $wmap-default-padding;
        padding-right: $wmap-default-padding;
        border-right: 1px solid $wmap-border-color-nav;
        &-title {
            @include font-content($font-size-xl, 600, 1.39);
            color: $color-text-secondary;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            li {
                list-style: none;
                padding: 4px 0;
            }
            li a {
                @include font-content($font-size-m, normal, 1.63);
                @include vfi-visible($color-text-primary);
                @extend %link-muted;
                color: $color-text-primary;
            }
        }
        &-ads {
            background-color: $color-bg-order-summary;
            margin: 45px 0;
            color: $color-text-primary;
            &-title {
                @include font-content($font-size-l, 700, 1.25);
                padding: 20px 5px;
            }
            p {
                @include font-content($font-size-s, 400, 1.5);
                padding: 0 15px;
            }
            a {
                font-weight: 700;
                padding: 20px 0;
                display: block;
            }
            &-question {
                display: flex;
                justify-content: center;
                width: 100%;
                padding-top: 30px;

                p {
                    @include font-content($font-size-xxl, 700, 1.25);
                    width: 35px;
                    border: 1px solid;
                    border-radius: 100px;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
