$refiner-checkbox-size: 18px;
$refiner-price-range-thumb-size: 16px;
.ms-refine-submenu {
    padding: 15px 15px;
    border-top: 10px solid $color-border-refiner-primary;
    border-radius: 0;
    &:first-child {
        margin-top: 15px;
    }
    &:last-child {
        border-bottom: 10px solid $color-border-refiner-primary;
    }
    &__title {
        @include font-content($font-size-xl, bold, 1.22);
        margin: 0 15px 15px;
        display: none;
        color: $color-text-primary;
        @include breakpoint(md) {
            display: block;
        }
        @include breakpoint(lg) {
            @include font-content($font-size-xxxl, bold, 1.14);
            margin: 0 15px 30px;
        }
    }
    // ------------- Refiner: MENU TITLE ------------- //
    &__toggle_collapsed,
    &__toggle_expanded {
        @include font-content($font-size-l, bold, 1.25);
        @extend %btn-muted;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color-text-primary;
        text-transform: capitalize;
        outline-offset: 4px;
        @include vfi($color-black);
        &::after {
            font-size: ($icon-size-xs - 4);
        }
    }
    &__toggle_collapsed {
        @include add-mfrm-icon($icon-chev-down, after);
    }
    &__toggle_expanded {
        @include add-mfrm-icon($icon-chev-up, after);
        margin-bottom:9px;
    }
    &-item a {
        @extend %link-muted;
    }
    &__list {
        // ------------- Refiner: INNER ITEM ------------- //
        a.ms-refine-submenu-item {
            @include font-content($font-size-l, normal, 1.37);
            @extend %link-muted;
            margin-top: 11px;
            display: flex;
            align-items: center;
            color: $color-link-primary;
            outline-offset: 3px;
            @include vfi($color-black);
            .msc-modal__body & {
                @include font-content($font-size-m, normal, 1.43);
            }
        }
        // ------------- Refiner: CHECKBOX/RADIO ------------- //
        .single-select,
        .single-select-checked,
        .multi-select,
        .multi-select-checked {
            position: relative;
            &::before {
                min-width: $refiner-checkbox-size;
                width: $refiner-checkbox-size;
                min-height: $refiner-checkbox-size;
                height: $refiner-checkbox-size;
                display: inline-block;
                background-color: $color-bg-primary;
                border: 1.5px solid $color-input-border;
                border-radius: 2px;
                content: '';
            }
        }
        .single-select-checked,
        .multi-select-checked {
            &::before {
                background-color: $color-bg-quinary;
                border-color: $color-bg-quinary;
            }
        }
        .single-select,
        .single-select-checked {
            &::before {
                border-radius: 100px;
            }
        }
    }

    .ms-refine-submenu__expand-btn {
        @include font-content($font-size-m, 400, 1.57);
        @include vfi($color-black);
        display: inline-block;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 15px;
        outline-offset: 1px;
        @include breakpoint(md) {
            @include font-content($font-size-l, 400, 1.37);
        }
    }

    // ------------- Refiner: INNER ITEM TEXT ------------- //
    &-item__label {
        position: relative;
        margin-left: 15px;
        display: flex;
        align-items: center;
        // ------------- Refiner: Checkbox Checked ------------- //
        .multi-select-checked & {
            &::before {
                position: absolute;
                left: -27px;
                width: 6px;
                height: 12px;
                margin-top: -4px;
                display: inline-block;
                border-width: 0 2px 2px 0;
                border-style: solid;
                border-color: $color-icon-secondary;
                transform: rotate(45deg);
                content: '';
            }
        }
        // ------------- Refiner: Radio Checked ------------- //
        .single-select-checked & {
            &::before {
                position: absolute;
                left: -28px;
                width: 8px;
                height: 8px;
                display: inline-block;
                background-color: $color-icon-secondary;
                border-radius: 100px;
                content: '';
            }
        }
    }
    // ------------- Refiner: PRICE RANGE ------------- //
    .refine-submenu__item--range {
        margin-top: 20px;
        padding: 0;
        border: 0;
    }
    &__range-refiner {
        position: relative;
        height: 4px;
        display: block;
        background-color: $color-border-refiner-secondary;
        cursor: pointer;
        touch-action: none;
        &.has-disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }
    // ------------- Refiner: PRICE RANGE SLIDER ------------- //
    .slider {
        &__track {
            position: absolute;
            top: -1px;
            height: 100%;
            background-color: $color-bg-indicator-active;
            border: 3px solid $color-bg-indicator-active;
        }
        &__thumb {
            position: absolute;
            top: 50%;
            height: $refiner-price-range-thumb-size;
            width: $refiner-price-range-thumb-size;
            background-color: $color-bg-indicator-active;
            border-radius: 100px;
            outline-offset: 2px;
            transform: translate3d(-50%, -50%, 0);
            @include vfi($color-black);
        }
        &__labels-item {
            cursor: pointer;
            display: inline-block;
            margin-top: 25px;
            padding-bottom: 20px;
            &.start {
                float: left;
            }
            &.end {
                float: right;
            }
        }
    }
}

//TODO
// .slider__thumb:before {
//     bottom: $search-result-container-refiner-slider-thumb-before-bottom;
//     content: "";
//     display: block;
//     left: $search-result-container-refiner-slider-thumb-before-left;
//     outline-offset: calc(0px + 0.0625rem);
//     position: absolute;
//     right: $search-result-container-refiner-slider-thumb-before-right;
//     top: $search-result-container-refiner-slider-thumb-before-top;
//   }

// .slider__thumb:focus {
//     outline: 1px dashed $color-black;
//     border: 1px dashed $color-white;
//     outline-offset: 2px;
//     box-shadow: none;
//   }

//   &__thumb__range:focus {
//     + .slider__thumb {
//       outline: 1px dashed $color-black;
//       border: 1px dashed $color-white;
//       outline-offset: 2px;
//       box-shadow: none;
//     }

//TODO REFINER MODAL
.msc-search-result-modal {
    &.msc-modal__dialog {
        width: 100%;
    }

    .msc-modal {
        &__header {
            padding: 30px 15px;
            display: flex;
            justify-content: space-between;
            text-transform: capitalize;
        }
        &__title {
            @include font-content($font-size-xxl, bold, 1.17);
            color: $color-text-primary;
            text-align: left;
        }
        &__body {
            height: 100%;
            padding: 0;
            overflow-y: auto;
        }
        &__content {
            display: block;
            flex-wrap: wrap;
        }
        &__close-button {
            @include add-mfrm-icon($icon-close-bold);
            width: 30px;
            height: 30px;
            border: 0.5px solid $color-modal-border-close-button;
            border-radius: 100px;
            line-height: 0;
            &::before {
                font-size: $icon-size-xs;
            }
        }
    }

    &__header-container {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px 30px 15px;
        align-items: flex-end;
    }

    .h3 {
        margin-bottom: 0;
    }

    .msc-choice-summary__selection {
        margin: 0;
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        display: none;
    }

    .ms-search-result-container__refiner-section {
        display: block;
        padding-right: 0;
        width: 100%;
    }

    .ms-sort-and-filter-modal__apply-btn {
        @include btn(primary, large);
    }
    .msc-modal__footer {
        position: sticky;
        bottom: 0;
        background: $color-white;
        padding: 30px 0 40px 0;
    }

    .ms-search-result-container__Sort-by-category {
        display: flex;
        cursor: pointer;
    }
}
