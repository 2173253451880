//==============================================================================
// MODAL BASE STYLES
//==============================================================================
$modal-padding-x: 45px;
$modal-padding-y: 40px;
$modal-close-btn-position: 15px;
$modal-zindex:10012;

.modal-open .msc-modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.msc-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  outline: 0;
  text-align: center;
  overflow: hidden;
  z-index: $modal-zindex;

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  &.show .msc-modal__dialog {
    @include animation-transition(fadeIn, $duration: 0.6s);
  }

  &.not-shown .msc-modal__dialog {
    @include animation-transition(slideOutDown, $duration: 1.5s, $delay: 0.25s);
  }

  &.not-shown + .msc-modal__backdrop {
    @include animation-transition(fadedOut, $duration: 1s);
  }

  & .msc-modal__dialog {
    position: relative;
    width: auto;
    margin: auto;
    display: inline-block;
    align-items: center;
    pointer-events: none;
    vertical-align: middle;
  }

  &__close-button {
    @include add-mfrm-icon($icon-close-slim);
    @include vfi($color-black);
    position: absolute;
    right: $modal-close-btn-position;
    top: $modal-close-btn-position;
    padding: 5px;
    color: $color-link-primary;
    font-size: $icon-size-m;
    line-height: 1;
    border: 0;
    background-color: transparent;
    &::before,
    &::after {
      font-weight: 600;
    }
  }
  &__content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $color-text-primary;
    background-color: $color-bg-primary;
    border-radius: 5px;
    box-shadow: 0px -1px 3px transparentize($color: $color-black, $amount: 0.85);
    outline: 0;
    background-clip: padding-box;
    pointer-events: auto;
  }
  &__body {
    text-align: initial;
    padding: $modal-padding-y $modal-padding-x;
  }
  &__title {
    margin: 0;
  }
  @include breakpoint(md){
    z-index: $modal-z-index;
  }
}

.msc-modal__backdrop {
  background-color: rgba($color: #000000, $alpha: 0.4);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ($modal-z-index - 10);

  &.show {
    @include animation-transition(fadedIn, $duration: 2s, $delay: 0.25s);
  }
  &.not-shown {
    @include animation-transition(fadedOut, $duration: 0.75s);
  }
}

@media (prefers-reduced-motion: reduce) {
  .msc-modal.fade .msc-modal__dialog {
    transform: none;
  }
}

//==============================================================================
// FULLVIEW MODAL - DEFAULT
//==============================================================================

.msc-modal .msc-fullview-modal {
  @include breakpoint(md) {
    max-width: 94%;
  }

  .msc-modal {
    &__content {
      padding: 0px;
      background-color: $color-bg-primary;
      background-clip: border-box;
    }

    &__close-button {
      right: 10px;
      top: 15px;
    }
  }

  .msc-fullview-modal-body.msc-modal__body {
    margin-top: 20px;
  }

  .msc-carousel__item {
    .ms-inline-zoom {
      float: none;
    }

    img {
      margin: 0px auto;
      display: block;
      min-height: 772px;

      @include breakpoint(sm, max-width) {
        min-height: 0;
      }
    }
  }
}

//==============================================================================
// Header Nav - Desktop Menu Backdrop
//==============================================================================
$modal-background-color: $color-modal-background;
.ms-header__primary__nav {
  .show {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 70px;
    left: 0;
    background-color: $modal-background-color;
    opacity: 0.4;
    right: 0;
    overflow: hidden;
    z-index: 10;
  }
}

//==============================================================================
// Modal Shopping Intent Styles
//==============================================================================
.modal-shopping {
  .msc-modal__dialog {
    @include breakpoint(lg) {
        max-width: 802px !important;
    }
  }

  &__selection {
    @include breakpoint(lg) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &-aside {
      display: none;

      @include breakpoint(lg) {
        display: block;
        max-width: 100%;
        box-sizing: border-box;
        align-self: stretch;
        flex-basis: 42.6%;

        img {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          height: 100%;
        }
      }
    }

    &-body {
      @include breakpoint(lg) {
        position: relative;
        flex-basis: 57.4%;
      }
    }

    &-content {
      padding: 45px 20px 20px;
      text-align: center;

      @include breakpoint(lg) {
        padding: 50px 30px 20px;
      }
    }

    &-title {
      color: $color-charcoal;
      @include font-content(20px, normal, 1.25);
      margin-bottom: 20px;
      padding: 0 35px;

      @include breakpoint(lg) {
        @include font-content(25px, normal, 1.28);
      }
    }

    &-title-span {
      color: $color-punch;
      font-weight: bold;
    }

    &-subtitle {
      color: $color-charcoal;
      @include font-content(32px, bold, 1.03);
      margin-bottom: 25px;

      @include breakpoint(lg) {
        @include font-content(41px, bold, 1.122);
        margin-bottom: 41px;
      }
    }

    &-button {
      background-color: $color-alabaster;
      border: 1px solid $color-bombay;
      border-radius: 5px;
      color: $color-charcoal;
      @include font-content(16px, normal, 1.25);
      height: 46px;
      margin-bottom: 10px;
      text-align: center;
      width: 100%;

      @include breakpoint(lg) {
        padding-left: 15px;
      }
    }

    &-text {
      color: $color-charcoal;
      @include font-content(10px, normal, 1.6);
      margin-top: 13px;

      @include breakpoint(lg) {
        margin-top: 41px;
        line-height: 1.2;
        text-align: left;
      }
    }
  }

  &__email-subscription {
    @include breakpoint(lg) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &-aside {
      display: none;

      @include breakpoint(lg) {
        display: block;
        max-width: 100%;
        box-sizing: border-box;
        align-self: stretch;
        flex-basis: 42.6%;

        img {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          height: 100%;
        }
      }
    }

    &-body {
      @include breakpoint(lg) {
        position: relative;
        flex-basis: 57.4%;
      }
    }

    &-content {
      padding: 79px 16px 49px;
      text-align: center;

      &-success {
        padding: 39px 9px 33px;
      }

      @include breakpoint(lg) {
        padding: 60px 35px 97px;

        &-success {
          padding: 60px 33px 60px;
          text-align: center;
        }
      }
    }

    &-subtitle {
      color: $color-charcoal;
      @include font-content(32px, bold, 1.03);
      margin-bottom: 10px;

      @include breakpoint(lg) {
        @include font-content(44px, bold, 1.09);
      }
    }

    &-title {
      color: $color-charcoal;
      @include font-content(20px, normal, 1.25);
      margin-bottom: 35px;

      @include breakpoint(lg) {
        @include font-content(25px, normal, 1.28);
        margin-bottom: 45px;
      }
    }

    &-form {
      .field-group {
        margin: 0px !important;
        &:first-child {
          display: none;
        }
      }

      .modal-email-subscription__button {
        @include btn(primary, large);
        background-color: $color-tealish-blue;
        @include font-content(14px, bold, 1.143);
        margin-top: 40px;
        padding: 13px 16px;
        width: 85%;

        &:hover,
        &:active {
          background-color: $color-east-bay !important;
        }

        @include breakpoint(lg) {
          margin-top: 45px;
          width: 70%;
        }
      }
    }
  }

  &__answer {
    &-body {
      padding: 33px 10px 20px;
      text-align: center;

      @include breakpoint(lg) {
        padding: 30px 48px 14px;
      }
    }

    &-title {
      color: $color-charcoal;
      @include font-content(20px, normal, 1.25);

      @include breakpoint(lg) {
        @include font-content(25px, normal, 1.28);
      }
    }

    &-subtitle {
      color: $color-punch;
      @include font-content(32px, bold, 1.031);
      margin-top: 5px;

      @include breakpoint(lg){
        @include font-content(44px, bold, 1.091);
      }
    }

    &-callout-card {
      display: block;
      background-color: none;
      border: none;
      border-radius: 6px;
      margin-top: 20px;
      position: relative;
      overflow: auto;

      @include breakpoint(lg) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background-color: $color-alabaster;
        border: 1px solid $color-bombay;
        border-radius: 5px;
      }

      &-aside {
        &::before {
          content: '';
          background-color: rgba(45, 41, 38, .80);
          height: 100%;
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
        }

        @include breakpoint(lg) {
          display: block;
          max-width: 100%;
          box-sizing: border-box;
          align-self: stretch;
          flex-basis: 42%;
          min-height: 272px;

          &::before {
           display: none;
          }
        }
      }

      &-body {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 7px;
        width: 100%;

        @include breakpoint(xs) {
          padding: 0 20px;
        }

        @include breakpoint(lg) {
          position: relative;
          align-self: center;
          flex-basis: 58%;
          padding: 0 40px;
          top: 0;
          transform: none;
        }
      }

      &-subtitle {
        color: $color-punch;
        @include font-content(12px, bold, 10px);
      }

      &-title {
        color: $color-white;
        @include font-content(20px, bold, 1.12);
        margin-top: 10px;

        @include breakpoint(xs) {
          @include font-content(24px, bold, 1.167);
        }

        @include breakpoint(lg) {
          color: $color-charcoal;
          margin-top: 15px;
        }
      }

      &-description {
        color: $color-white;
        @include font-content(12px, normal, 1.35);
        margin-top: 5px;

        @include breakpoint(xs) {
          @include font-content(14px, normal, 1.429);
        }

        @include breakpoint(lg) {
          color: $color-charcoal;
          margin-top: 10px;
        }
      }

      &-button {
        @include btn(primary, large);
        @include font-content(14px, bold, 10px);
        padding: 10px 35px;
        margin-top: 10px;

        @include breakpoint(xs) {
          @include font-content(16px, bold, 12px);
          padding: 15px 60px;
          margin-top: 30px;
        }
      }
    }

    &-text {
      color: $color-charcoal;
      @include font-content(10px, normal, 1.6);
      margin-top: 15px;

      @include breakpoint(lg){
        margin-top: 29px;
        line-height: 1.2;
      }
    }
  }

  &__shop-now {
    &-body {
      padding: 33px 15px 20px;
      text-align: center;

      @include breakpoint(lg) {
        padding: 30px 125px 14px;
      }

      .modal-shopping__answer-text {
        margin-top: 10px;

        @include breakpoint(lg) {
          margin-top: 15px;
        }
      }
    }

    &-description {
      display: none;

      @include breakpoint(lg) {
        display: block;
        color: $color-charcoal;
        @include font-content(18px, normal, 1.167);
        margin-top: 20px;
      }
    }

    &-container {
      width: 100%;
      @include custom-scrollbar-webkit();
      display: inline-flex;
      margin-top: 17px;
      overflow-x: auto;
      padding-bottom: 20px;

      @include breakpoint(lg) {
        margin-top: 20px;
        padding-bottom: 0px;
      }
    }

    &-card {
      height: 212px;
      margin-right: 10px;
      width: 251px;
      position: relative;
      border: none;

      &:last-of-type {
        margin-right: 0;
      }

      @include breakpoint(lg) {
        height: 233px;
        width: 270.5px;
      }

      &-aside {
        border-radius: 5px;
        width: 251px;
        overflow: auto;

        &::before {
          content: '';
          background-color: rgba(45, 41, 38, .70);
          height: 100%;
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          border-radius: 5px;
        }

        @include breakpoint(lg){
          width: 270.5px;
        }
      }

      &-body {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 40px;
      }

      &-title {
        color: $color-white;
        @include font-content(23px, bold, 1.087);
      }
    }
  }
}