//==============================================================================
// Buybox Content - Container
//==============================================================================
$buybox-content-width-desktop: 43%;
$buybox-variation-price-line-color: $color-text-tertiary;
[class*="tooltip-module_tip-container"] {
    @include breakpoint(xs) {
        right: -31px;
        padding: 12px 6px;
    }
    @media (min-width: $breakpoint-xs) {
        right: -7px;
    }
    @include breakpoint(md) {
        right: 0;
        padding: 12px 11px;
    }
}

.ms-buybox {
    &__content {
        height: 100%;
        width: 100%;
        margin: 32px auto 0;
        @include breakpoint(md) {
            margin: 20px auto 0;
        }
        @include breakpoint(xl) {
            max-width: $buybox-content-width-desktop;
            margin-top: 0;
            margin-right: auto;
        }
    }
    &__content-wrapper {
        @include breakpoint(xl) {
            margin-left: 64px;
        }
    }
    //==============================================================================
    // Buybox Content - Brand/Eyebrow Container Section
    //==============================================================================
    &__brand-eyebrow-section {
        @include clearfix;
        margin-bottom: 15px;
        @include breakpoint(md) {
            margin-bottom: 10px;
        }
    }
    //==============================================================================
    // Buybox Content - Brand
    //==============================================================================
    .mfrm-brand {
        display: inline-block;
        &__link {
            display: block;
        }
    }
    //==============================================================================
    // Buybox Content - Title
    //==============================================================================
    &__product-title {
        @include font-content($font-size-xxl + 0.28, 700, 36px);
        margin-top: 0;
        color: $color-text-primary;
    }
    //==============================================================================
    // Buybox Content - Rating
    //==============================================================================
    /*N/A*/
    //==============================================================================
    // Buybox Content - Product ID
    //==============================================================================
    &__price-id-section {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        .msc-rating {
            margin: 0;
            display: block;
            line-height: 1;
            &:hover {
                cursor: pointer;
            }
        }
    }
    &__product-id {
        @include font-content($font-size-l, normal, 24px);
        color: $color-text-primary;
        float: right;
    }
    &__review-link {
        @include font-content($font-size-m, normal, 1.42);
        text-decoration: underline;
        a {
            border: 0;
            padding-top: 10px;
            display: inline-block;
            /*override base style*/
            text-decoration: underline !important;
            &:hover {
                text-decoration: none !important;
            }
        }
    }
    //==============================================================================
    // Buybox Content - Field Set & Button
    //==============================================================================
    &__configure {
        display: flex;
        flex-direction: column;
        margin: 10px -15px 0;
        padding: 0px 15px 10px;
        @include breakpoint(md) {
            margin: 10px -30px 0;
            padding: 15px 30px 10px;
        }
    }
    &__dropdown {
        @include add-mfrm-icon($icon-caret-down, after);
        position: relative;
        order: 2;
        margin-top: 25px;
        //to hide style field
        // display: none;
        &::after {
            position: absolute;
            top: 57%;
            right: 15px;
            bottom: 50%;
            font-size: ($icon-size-xs - 4);
        }
        select {
            @extend %reset-input-style;
        }
    }
    &__dropdown-quantity-label,
    &__product-quantity-label-heading,
    .msc-size-label {
        @extend %field-control-label;
        @extend %float-label;
        position: relative;
        top: 10px;
        background: $color-white;
        width: auto;
        display: inline-block;
        padding: 0 5px;
        margin: 0 10px;
        z-index: 1;
    }
    &__dropdown-redesign {
        margin-top: 25px;
    }
    &__dropdown-quantity-label-redesign {
        @include font-content($font-size-l, 500, 24px);
        background: $color-white;
        width: auto;
        display: inline-block;
        padding: 0 5px;
        margin-bottom: 10px;
        text-transform: uppercase;
        text-align: left;
    }
    &__product-quantity-label-heading {
        top: 16px;
    }
    .msc-size-label {
        top: -8px;
        z-index: 1;
        position: absolute;
        margin: 0 0 0 -5px;
    }
    .msc-modal-value-toggle,
    .msc-single-value-no-toggle {
        //margin-top: 25px;
        &:not(.msc-single-value-no-toggle).focused {
            box-shadow: 0 0 0 1px $color-input-border-hover;
        }
    }
    .msc-adjustable-base-model-label,
    .msc-selected-size-label,
    .msc-selected-size-label-hide-caret {
        width: 100%;
        display: flex;
        justify-content: space-between;
        &:after {
            font-size: 8px;
        }
        .row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            margin: -15px 0;
        }
        .msc-variation-size {
            &__dimension-label {
                @include font-content($font-size-s, 400, 1.34);
                color: $buybox-variation-price-line-color;
            }
        }
        .msc-variation-total-price,
        .msc-variation-total-price-no-discount {
            text-align: right;
            color: $color-text-secondary;
            @include font-content($font-size-l, 500, 1.375);
            padding-right: 15px;
        }
        .msc-variation-total-price-no-discount {
            color: $color-text-primary;
            padding-right: 15px;
        }
        .msc-variation-price-discount {
            @include font-content($font-size-s, 700, 2);
            padding-right: 5px;
            color: $color-text-primary;
        }
        .msc-variation-actual-price {
            @include font-content($font-size-s, 400, 2);
            color: $buybox-variation-price-line-color;
            text-decoration: line-through;
        }
    }
    .msc-selected-size-label {
        @include add-mfrm-icon($icon-caret-down, after);
    }
    .msc-modal-value-toggle,
    .msc-single-value-no-toggle,
    .msc-dropdown__select,
    .msc-quantity-input {
        @extend %field-control-input;
        width: 100%;
        min-height: 72px;
        display: flex;
        position: relative;
        justify-content: space-between;
        @include font-content($font-size-m, 500, 1.14);
    }
    .msc-add-to-cart {
        @include btn(primary, large);
        width: 100%;
        display: block;
        margin: 0 auto 15px;
        padding: 14px 60px;
        /* override default styling */
        &.out-of-stock {
            border-color: $color-button-border !important;
            color: $color-button-secondary-text !important;
            background-color: $color-button-disabled-background !important;
            cursor: default;
            pointer-events: none;
        }
    }
    .msc-add-to-cart.out-of-market {
        color: $color-punch !important;
        border: 1px solid $color-punch !important;
        cursor: not-allowed;
    }
    &__continue-shopping-link {
        @include font-content($font-size-l, 400, 1.37);
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
    &__view-cart {
        width: 100%;
        text-align: center;
        margin: 15px 0;
        color: $color-text-primary;
        &__text {
            @include font-content($font-size-xl, 400, 1.33);
        }
        &__link {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    //==============================================================================
    // Buybox Content - TODO ITEMS
    //==============================================================================
    &__add-to-cart-container {
        margin: 10px 0 15px 0;
        text-align: center;
    }
    &__find-in-store,
    &__add-to-wishlist-container {
        display: none;
    }
    //==============================================================================
    // Buybox Content - Leasing to Own Text
    //==============================================================================
    &__lease-to-own {
        width: 100%;
        color: $color-text-primary;
        margin-top: 4px;
        &__text-link {
            cursor: pointer;
        }
        &:not(.redesign) {
            text-align: center;
            .ms-buybox__lease-to-own__text {
                @include font-content($font-size-s, 400, 1.33);
            }
            .ms-buybox__lease-to-own__text-link {
                @include font-content($font-size-s, 400, 1.33);
                text-decoration: underline;
            }
        }
        &.redesign {
            .ms-buybox__lease-to-own__text {
                @include font-content($font-size-l, 400, 24px);
            }
            .ms-buybox__lease-to-own__text-link {
                text-decoration: none;
                margin-left: 4px;
                @include font-content($font-size-l, 500, 20px);
                @include breakpoint(xs) {
                    display: inline;
                    margin-left: 8px;
                }
                @include breakpoint(xl) {
                    display: inline;
                    margin-left: 10px;
                }
            }
        }
        &__estimator-link-text {
            text-decoration: underline;
            white-space: nowrap;
        }
        &__text-link__disabled {
            @include font-content($font-size-s, 400, 1.33);
            cursor: default;
            opacity: 0.5;
            text-decoration: none;
            &:hover,
            &:focus {
                text-decoration: none;
                color: $color-text-primary;
            }
        }
    }
    //==============================================================================
    // Buybox Content - Call and Chat Buttons
    //==============================================================================
    &__call-chat-container {
        .call-chat-buttons {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 15px 0 30px 0;
            button {
                @include btn(primary, large);
                margin-bottom: 15px;
                width: 150px;
                padding: 16px 56px;
                width: 49%;
                &:last-child {
                    margin-bottom: 0;
                }
                @include breakpoint(md) {
                    margin-bottom: 0;
                    &:not(:last-child) {
                        margin-right: 0;
                    }
                }
            }
        }
        .call-chat__info-message:not(:empty) {
            text-align: center;
            @include font-content($font-size-l, 400, 1.375);
        }
        .call-chat__info-message-text {
            width: 100%;
            padding: 15px 15px 0 15px;
        }
    }
    //==============================================================================
    // Buybox Content - Delivery by Message
    //==============================================================================
    $delivery-message-input-height: 44px;
    $delivery-message-input-width: 345px;
    &__delivery-message {
        margin: 15px 0 10px 0;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        &-text {
            @include font-content($font-size-m, 400, 1.43);
            color: $color-black;
            position: relative;
            &::before {
                top: 50%;
                left: 0;
                position: absolute;
                font-size: ($icon-size-m + 6);
                color: $color-delivery-truck;
                transform: translateY(-50%);
            }
            @include breakpoint(md) {
                @include font-content($font-size-l, 400, 1.37);
            }
            &:not(:only-child) {
                margin-bottom: 10px;
            }
        }
        &-text.truck {
            @include add-mfrm-icon($icon-time-delivery-truck);
            padding-left: 39px;
        }
        &-text-redesign {
            @include font-content($font-size-m, 400, 1.43);
            color: $color-black;
            position: relative;
            &::before {
                top: 50%;
                left: 0;
                position: absolute;
                font-size: ($icon-size-m + 6);
                color: $color-black;
                transform: translateY(-50%);
            }
            @include breakpoint(md) {
                @include font-content($font-size-l, 400, 1.37);
            }
            &:not(:only-child) {
                margin-bottom: 10px;
            }
        }
        &-text-redesign.truck {
            @include add-mfrm-icon($icon-time-delivery-truck);
            padding-left: 39px;
        }
        &-text-left {
            margin-right: 8px;
        }
        &-text-error {
            @include font-content($font-size-m, normal, 1.43);
            display: block;
            color: $color-error;
            @include breakpoint(md) {
                @include font-content($font-size-l, normal, 1.37);
            }
        }
        &-link {
            @include font-content($font-size-m, 400, 1.43);
            @include vfi($color-black);
            @extend %btn-muted;
            padding: 0;
            display: inline-block;
            color: $color-text-primary;
            text-decoration: underline;
            outline-offset: 1px;
            cursor: pointer;
            &:hover {
                color: $color-text-primary;
                text-decoration: none;
            }
            @include breakpoint(md) {
                @include font-content($font-size-l, 400, 1.37);
            }
        }
        &-link-redesign {
            @include font-content($font-size-m, 400, 1.43);
            @include vfi($color-black);
            @extend %btn-muted;
            padding: 0;
            display: inline-block;
            color: $color-text-primary;
            text-decoration: underline;
            outline-offset: 1px;
            cursor: pointer;
            &:hover {
                color: $color-text-primary;
                @include font-content($font-size-l, 500, 1.37);
            }
            @include breakpoint(md) {
                @include font-content($font-size-l, 400, 1.37);
            }
        }
        &-input {
            width: 100%;
            margin-bottom: 0;
            @include breakpoint(sm) {
                width: $delivery-message-input-width;
            }
            .field-control {
                @extend %field-control-input-rounded;
                min-height: $delivery-message-input-height;
                height: $delivery-message-input-height;
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                @include breakpoint(sm) {
                    min-width: $delivery-message-input-width;
                    width: $delivery-message-input-width;
                    max-width: $delivery-message-input-width;
                }
                +.field-label {
                    top: 15px;
                }
            }
            &:not(:only-child) {
                margin-bottom: 15px;
            }
            +.ms-buybox__delivery-message-text-error {
                margin-left: 25px;
            }
        }
        &-button {
            @include btn(primary, large);
            position: absolute;
            top: 0;
            right: 0;
            height: $delivery-message-input-height;
            padding: 16px 30px;
        }
        &--input-active {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    .atc-button-container {
        width: fit-content;
        margin: auto;
        position: relative;
    }
    .atc-button-container-redesign {
        width: 100%;
        margin: auto;
        position: relative;
    }
    .atc-button-container.success {
        width: 216px;
        .msc-add-to-cart {
            width: 214px;
        }
    }
    .atc-button-success {
        width: 214px;
        height: 46px;
        position: absolute;
    }
    .promotional-message {
        border-radius: 4px;
        padding: 16px;
        background-color: $color-bg-promotion-msg;
        margin-bottom: 20px;
        font-family: "Rubik";
        @include font-content($font-size-l, 400, 24px);
        color: $color-dark-green;
        display: inline-block;
        margin-top: 1em;
    }
    .next_icon {
        margin-left: 10px !important;
        font-size: 12px;
    }
    &__icon {
        @include add-mfrm-icon($icon-chev-right);
        margin-right: 15px;
    }
    //==============================================================================
    // Buybox Content - Customer Satisfaction Score styling
    //==============================================================================
    &__csat-score {
        margin: 5px 5px 13px 0;
        display: flex;
        align-items: center;
        span {
            background-color: #faede2;
            border-radius: 4px;
            margin-right: 8px
        }
    }
    &__tooltip-content {
        width: 313px;
        @include breakpoint(lg) {
            width: 350px;
        }
        [class*="rating-module_root"] {
            color: $color-bg-quaternary;
            font-size: 14px;
            [class*="rating-module_decimal"] {
                margin-right: 5px
            }
        }
        .csat-rating-text {
            display: flex;
            padding-bottom: 5px;
            div {
                @include font-content($font-size-l, 400, 5px);
                margin: 6px 0 0 5px;
                span {
                    @include font-content($font-size-l, 500, 5px);
                    margin-right: 4px;
                }
            }
        }
    }
}