.cart-delivery {
    &-message {
        @include font-content($font-size-s, 400, 1.36);
        color: $color-link-primary;
        margin-top: 5px;
    }
    &-date {
        font-weight: 500;
        display: inline-block;
    }
}