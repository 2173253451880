$tabs-selector: tabs-module;
$tabs-indicator-width: 5px;
$tabs-item-spacing: 15px;

.mfrm-tab-accordion {
    .product-details-tabs-wrapper {
        @include full-width;
        position: relative;
        margin-bottom: 30px;
        border-bottom: $tabs-indicator-width solid $color-border-tabs-wrapper;
        overflow: visible;

        ul[class*="#{$tabs-selector}_tablist"] {
            max-width: $page-width-max;
            margin: 0 15px;
            padding: 0;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            list-style: none;
            @include breakpoint(md) {
                margin: 0 30px;
            }
            @include breakpoint(xl) {
                margin: 0 auto;
            }
        }
    }

    [class*="#{$tabs-selector}_tab-list-item"] {
        width: 100%;
        margin: 0;
        text-align: center;
        white-space: nowrap;
    }
    .product-details-tabs-title {
        @include font-content($font-size-l, 700, 1);
        @extend %btn-muted;
        width: 100%;
        margin-bottom: (-$tabs-indicator-width);
        padding: 15px 5px;
        display: block;
        color: $color-text-primary;
        text-transform: capitalize;
        text-decoration: none;
        outline: 0;
        border-bottom: 5px solid transparent;
        border-radius: 2.5px;
        transition: border-bottom 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:hover {
            border-bottom: 5px solid lighten($color-border-tab-item, 40%);
        }
        @include breakpoint(xl) {
            @include font-content($font-size-xl, 700, 1);
        }
    }
    [class*="#{$tabs-selector}_indicator"] {
        position: absolute;
        bottom: (-$tabs-indicator-width);
        background-color: $color-border-tab-item;
        height: $tabs-indicator-width;
        border-radius: 5px;
        transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
}
