.mfrm-recently-viewed {

    .msc-ss-carousel-slide {
        width: 100%;
        justify-content: center;

        @include breakpoint(md) {
            display: inline-flex;
            width: 100%;
        }
    }

    .ms-product-collection__item {
      &:last-child {
        margin-right: 0;
      }

      @include breakpoint(md) {
        margin: 0 15px 15px;
        width: 100%;
        max-width: none;

        &:first-child {
            margin-left: 0;
        }
        &:nth-child(3) {
            margin-right: 0;
        }
      }
    }

    .full-width-product {
        width: 100%;
    }

    .single-recent-product {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .msc-product {
            flex-direction: row;
        }

        .msc-cta__primary {
            display: none;

            @include breakpoint(md) {
                @include btn(primary, default);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 162px;
                height: 44px;
                margin-right: 20px;
                font-size: 16px;
            }
        }
    }
}

.flex-wrapper {
    .msc-ss-carousel-slide {
        display: inline-flex;
    }
}