  //==============================================================================
  // Buybox Content - Sleep Expert Summary component
  //==============================================================================
  .ms-buybox{
    &__sleep-expert-summary{
      width: auto;
      padding: 32px 16px;
      margin:32px 0 ; 
      text-align: left;
      background-color: $color-light-blue;
      color: $color-tealish-blue;
      border-radius: 4px;
      @include breakpoint(md){
        padding: 32px 64px;
      }
      @include breakpoint(lg){
        padding: 48px;
        margin:32px 0 32px 64px ; 
      }
      &-heading {
          @include font-content($font-size-xxl, 700, 24px);
          @include breakpoint(lg){
            @include font-content($font-size-xxl , 700, 28px);
         }
      }
      &-text {
        white-space: pre-line;
        @include font-content($font-size-l, 400, 24px);
        margin-top:16px;
        @include breakpoint(lg){
          @include font-content($font-size-xl + 0.085, 400, 32px);
       }
      }
    }
  }