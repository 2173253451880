.d5-mfrm-content-reel {

    max-height: 587px;
    overflow-y: hidden;
    
    &__heading {
        margin: 25px auto;
        margin-top: 0;
        font-size: $font-size-xl;
        line-height: 1.2;
        @include breakpoint(lg) {
          font-size: $font-size-xxl;
          line-height: 1.5;
        }
    }
    &__sale {
        border: 1px solid $color-mercury;
        border-radius: 5px;
        width: 268px;
        height: 350px;
        position: relative;
        color: $color-mercury;

        @include breakpoint(sm) {
            width: 226px;
            height: 384px;
        }
        @media (min-width: 800px) {
            width: 278px;
            height: 384px;
        }
    }

    &__sales-container {
        max-height: 384px;
        overflow-y: hidden;
    }

    &__badge-container {
        border-radius: 50px;
        position: absolute;
        top: 6px;
    }
    &__promo {
        min-width: fit-content;
        padding: 5px 10px;
        left: 6px;
    }
    &__countdown {
        right: 6px;
        min-width: fit-content;
        padding: 5px 8px;
        border: 0.5px solid $color-mercury;
        &>span.countdown-sm {
            width: 55px;
        }
        &>span.countdown-lg {
            width: 75px;
        }
    }

    &__title {
        white-space:nowrap;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        inline-size: 218px;
        overflow-wrap: break-word;
        white-space: initial;
        @include breakpoint(sm) {
            font-size: 20px;
        }
    }
    &__title.sales {
        color: $color-punch
    }

    &__text-container {
        color: $color-charcoal;
        height: 90px;
        padding-top: 19px;
        max-width: 220px;
        margin: auto;
        line-height: 19.2px;
        @include breakpoint(sm) {
            line-height: 24px;
        }
    }

    &__link {
        padding: 1px;
        &:focus {
            padding: 0;
            border: 2px solid $color-bombay !important;
            border-radius: 5px;
        }
        &:focus, &:hover {
            color: inherit;
            .mfrm-content-reel__title, .mfrm-content-reel__description-container {
                text-decoration: underline;
            }
        }
    }

    &__description-container {
        white-space: normal;
        font-size: 14px;
        line-height: 19.6px;
        @include breakpoint(sm) {
            font-size: 16px;
            line-height: 22.4px;
        }
    }
}