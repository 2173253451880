.msc-flyout {
    position: absolute;
    margin: 0;
    display: block;
    opacity: 0;
    z-index: 1060;

    &.show {
        opacity: 1;
    }
}

// Wrapper for the tooltip content
.msc-flyout-inner {
    padding: 0px;
    text-align: left;
}
