// Flex alignment
.flex-column {
  flex-direction: column;
}
.flex-center {
  align-items: center;
  justify-content: center;
}

// Width && Height
.full-width {
  width: 100%;
}
.fw-700 {
  font-weight: 700 !important;
}
// Visibility
.hidden {
  display: none !important;
  visibility: hidden;
}

.vfi-desktop {
    @include vfi($color-black);
}

.vfi-desktop-tab {
  @include breakpoint(lg) {
    &:focus > * {
      outline: 1px dotted $color-black;
    }
  }
}

// Speech Module 
.digits {
  speak: digits;
}

//Icon
.prevent-wrap{
  white-space: nowrap;
}