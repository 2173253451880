.badge {
    text-transform: uppercase;
    color:white;
    font-weight: bold;
    padding: 10px;
    border-radius: 0 0 10px 0;
    position: absolute;
    left: 0;
    top: 0;
    &.charcoal {
        background: $color-charcoal;
    }
}