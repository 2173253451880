$product-card-img-width-mobile: 115px;
$product-card-img-height-mobile: 86px;
$product-card-img-width-tab: 145px;
$product-card-img-height-tab: 108px;
$product-card-img-width-desktop: 100%;
$product-card-img-height-desktop: 208px;

.msc-product {
    position: relative;
    padding: 0 15px 16px;
    display: flex;
    border-bottom: 10px solid $color-border-product-card-secondary;
    flex-wrap: wrap;
    margin-bottom: 16px;
    @include breakpoint(lg) {
        padding: 0;
        display: block;
        border: 1px solid $color-border-product-card-primary;
        border-radius: 5px;
    }
    a {
        @extend %link-muted;
        position: relative;
        display: block;
        border: 0;
        @include breakpoint(lg) {
            min-height: 50px;
            width: 100%;
            &:focus {
                .msc-product__title {
                    text-decoration: underline;
                }
            }
        }
    }

    &__rating-holder {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 5px;
        .msc-rating {
            width: 100%;
            margin-bottom: 8px;
            @include breakpoint(md) {
                max-width: inherit;
            }
        }
    }
    // ------------- Product Card: IMAGE AREA ------------- //

    &__image {
        min-width: $product-card-img-width-mobile;
        width: $product-card-img-width-mobile;
        height: $product-card-img-height-mobile;
        overflow: hidden;
        border-radius: 5px;
        @include breakpoint(md) {
            width: $product-card-img-width-tab;
            height: $product-card-img-height-tab;
        }
        @include breakpoint(lg) {
            width: $product-card-img-width-desktop;
            height: $product-card-img-height-desktop;
            overflow: hidden;
            border-radius: 5px 5px 0 0;
        }
        img {
            width: $product-card-img-width-mobile;
            height: $product-card-img-height-mobile;
            
            @include breakpoint(md) {
                width: $product-card-img-width-tab;
                height: $product-card-img-height-tab;
            }
            @include breakpoint(lg) {
                width: $product-card-img-width-desktop;
                height: $product-card-img-height-desktop;
            }
        }
    }

    .msc-empty_image {
        @include image-placeholder(100%, $product-card-img-height-mobile);
        @include breakpoint(md) {
            @include image-placeholder(100%, $product-card-img-height-tab);
        }
        @include breakpoint(lg) {
            @include image-placeholder(100%, $product-card-img-height-desktop);
        }
    }
    // ------------- Product Card: BADGE IMAGE OVERLAY ------------- //
    .mfrm-product-badge {
        &__image {
            max-height: 30px;
            border-radius: 5px 0;
        }
    }
    // ------------- Product Card: TEXT AREA ------------- //
    &__details {
        margin-left: 15px;
        flex: 1;
        @include breakpoint(lg) {
            margin-left: 0;
            padding: 15px 15px 0;
        }
        @include breakpoint(xl) {
            min-height: 174px;
        }
    }
    // ------------- Product Card: RATING/EYEBROW ------------- //
    &__rating-eyebrow-wrapper {
        position: relative;
        display: flex;
        max-width: 100%;
        justify-content: flex-end;
        min-height: 26px;
        margin-top: 5px;
        margin-bottom: 5px;
        @include breakpoint(lg) {
            margin-top: 0;
        }
        .mfrm-eyebrow__button {
            @include font-content($font-size-s, 600, 1.17);
            padding: 0;
            cursor: default;

            sup {
                top: 2px;
                vertical-align: super;
                @include breakpoint(lg) {
                    top: 3px;
                }
            }
            .mfrm-eyebrow__button-plp {
                @include text-ellipsis;
                margin: 5px 10px;
                text-align: center;
                -webkit-line-clamp: 3;
            }
            &:hover {
                .mfrm-eyebrow__tooltip-title {
                    opacity: 1;
                }
            }
            .mfrm-eyebrow__tooltip-title {
                position: absolute;
                top: -35px;
                right: 0;
                margin: 0;
                padding: 8px;
                color: $color-bg-tooltip;
                background-color: transparentize($color: $color-text-tooltip, $amount: 0.1);
                border-radius: 2px;
                font-size: $font-size-s;
                font-weight: 500;
                opacity: 0;
                transition: opacity 0.3s ease;
                box-shadow: 0 1px 4px transparentize($color: $color-border-tooltip, $amount: 0.75);
            }
        }
    }
    // ------------- Product Card: PRODUCT BRAND ------------- //
    &__brand {
        position: relative;
        display: inline-block;
        margin-bottom: 16px;
        margin-top: 0;
        height: 27px;

        img {
            height: 25px;
            width: auto;
        }
        @include breakpoint(lg) {
            margin-bottom: 10px;
        }
    }
    // ------------- Product Card: PRODUCT TITLE ------------- //
    &__title {
        @include font-content($font-size-l, 500, 1.43);
        margin-bottom: 10px;
        color: $color-text-primary;
        outline-offset: 2px;
        min-height: 40px;
        @include breakpoint(md) {
            @include font-content($font-size-l, 500, 1.37);
            margin-bottom: 15px;
        }
        @include breakpoint(lg) {
            @include font-content($font-size-lg, 500, 1.43);
        }
    }
    &__text {
        display: none;
    }
    &__delivery-message {
        @include font-content($font-size-l, normal, 1.37);
        margin-top: 16px;
        color: $color-text-primary;
        @include breakpoint(lg) {
            @include font-content($font-size-l, normal, 1.37);
            margin-top: 16px;
        }
    }
}
