$stepper-circle-size: 22px;
.mfrm-stepper {
    margin-bottom: 30px;
    margin-top: 25px;
    @include breakpoint(lg) {
        margin-bottom: 0;
        margin-top: 8px;
    }
    @include breakpoint(lg) {
        margin-left: -15px;
    }
    @include breakpoint(xl) {
        margin-left: 0;
        margin-right: 5px;
    }
    //Stepper Container
    [class*="stepper-module_stepper-wrapper"] {
        width: calc(280px - 30px);
        @include breakpoint(xs) {
            width: calc(320px - 30px);
        }
        @media only screen and (min-width: $breakpoint-xs) {
            width: 345px;
        }
        margin: 0 auto;
        padding: 0;
        display: flex;
    }

    //Stepper Item
    [class*="stepper-module_progress-step"] {
        flex: auto;
        line-height: 1;
        min-height: auto;
        min-width: auto;
        //override inline styles for stepper layout shift
        position: relative;
        margin: 0;
        padding: 0;
        display: block;
        text-align: center;
        box-sizing: border-box;
        -webkit-box-flex: 1;
        @include breakpoint(lg) {
            &:focus span {
                outline: 0.75px dashed $color-stepper-text !important;
            }
        }
    }

    //Stepper Circle
    [class*="stepper-module_circle"] {
        width: $stepper-circle-size;
        height: $stepper-circle-size;
        box-shadow: none;
        cursor: default;
        z-index: 1;
        //override inline styles for stepper layout shift
        position: relative;
        margin: 0 auto;
        display: flex;
        background-color: $color-white;
        border-radius: 50%;
        box-sizing: border-box;
        border: none !important;
        align-items: center;
        justify-content: center;
        transition: none !important;
        outline-offset: 2px;
    }

    [class*="stepper-module_not-passed"] {
        opacity: 1;
    }

    //hide first stepper item line on initial load
    [class*="stepper-module_passed"]:first-child::after {
        display: none;
    }

    //Stepper Line
    [class*="stepper-module_progress-step"] {
        &::after {
            border-top: 1px solid;
            border-color: $color-stepper-inactive;
            top: 10px;
            right: 50%;
            width: 80%;
            //override inline styles for stepper layout shift
            content: "";
            position: absolute;
            height: 0;
            display: block;
            @media only screen and (min-width: $breakpoint-xs) {
                width: 75%;
            }
        }
        &:nth-child(3)::after {
            width: 130%;
            @media only screen and (min-width: $breakpoint-xs) {
                width: 120%;
            }
        }
        &:last-child::after {
            right: 55%;
            width: 100%;
        }

        &[class*="stepper-module_passed"] {
            &::after {
                border-top: 2px solid;
                border-color: $color-stepper-active;
            }
        }
    }

    //Stepper Label
    [class*="stepper-module_label"] {
        @include font-content($font-size-s, normal, 1);
        height: auto;
        padding: 5px 0 0;
        color: $color-stepper-text;
        //override inline styles for stepper layout shift
        display: block;
        box-sizing: border-box;
    }
    &__step--active,
    &__step--inactive {
        [class*="stepper-module_circle"] {
            cursor: default !important;
        }
    }
    &-disabled {
        pointer-events: none;
    }
}

.mfrm-circle--active,
.mfrm-circle--inactive {
    @include add-mfrm-icon($icon-circle-step);
    &::before {
        width: $stepper-circle-size;
        height: $stepper-circle-size;
        font-size: ($icon-size-m + 4);
    }
}
.mfrm-circle {
    &--active::before {
        color: $color-punch;
    }
    &--inactive::before {
        color: $color-bombay;
    }
}
.mfrm-check--active,
.mfrm-check--inactive {
    @include add-mfrm-icon($icon-check-step);
    &::before {
        width: $stepper-circle-size;
        height: $stepper-circle-size;
        font-size: ($icon-size-m + 4);
    }
}
.mfrm-check {
    &--active::before {
        color: $color-punch;
    }
    &--inactive::before {
        color: $color-bombay;
    }
}

// express checkout
.mfrm-express-stepper {
    [class*="stepper-module_stepper-wrapper"] {
        li {
            &:not(:first-child){
                div {
                    cursor: auto !important;
                }
            }
        }
    }
}
