$image-width-xs: 95px;
$image-width-sm: 135px;
$image-width-md: 95px;
$image-height-sm: 108px;
$image-height-md: 76px;
.ms-return-to-checkout {
    padding: 30px 0;
    &__title {
        @include font-content($font-size-xl, 700, 1.33);
        margin-bottom: 30px;
        color: $color-text-primary;
        text-align: center;
        @include breakpoint(lg) {
            @include font-content($font-size-xxl, 700, 1.166);
        }
    }
    &__container {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        border: 1px solid $color-border-checkout-primary;
        @include breakpoint(xs) {
            flex-direction: row;
            align-items: flex-start;
        }
        @include breakpoint(md) {
            padding: 12px;
        }
    }
    &__total-product,
    &__btn-go-to-cart {
        display: block;
        @include breakpoint(md) {
            display: inline-block;
        }
    }

    &__product-images {
        min-width: $image-width-xs;
        min-height: $image-width-xs;
        margin-bottom: 15px;
        display: inline-block;
        text-align: left;
        @include breakpoint(xs) {
            margin-bottom: 0;
        }
        @media (min-width: $breakpoint-xs) {
            min-width: $image-width-sm;
            min-height: $image-height-sm;
        }
        @include breakpoint(md) {
            min-width: $image-width-md;
            min-height: $image-height-md;
            max-height: $image-height-md;
        }
    }
    &__product-images a {
        max-width: $image-width-xs;
        min-width: $image-width-xs;
        display: none;
        overflow: hidden;
        border-radius: 5px;
        border: 0;
        &:first-child {
            display: inline-block;
        }

        /* override for breakpoints */
        &:only-child,
        &:last-child {
            margin-right: 0 !important;
        }

        @media (min-width: $breakpoint-xs) {
            min-width: $image-width-sm;
            max-width: $image-width-sm;
            max-height: $image-height-sm;
        }
        @include breakpoint(md) {
            max-width: $image-width-md;
            min-width: $image-width-md;
            max-height: $image-height-md;
            &:first-child {
                margin-right: 10px;
            }

            &:nth-child(-n + 2) {
                display: inline-block;
            }
        }
        @include breakpoint(lg) {
            &:nth-child(2) {
                margin-right: 10px;
            }
            &:nth-child(-n + 3) {
                display: inline-block;
            }
        }
    }
    &__product-images a img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        margin: 8px 0 18px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-flow: column wrap;
        text-align: center;
        @include breakpoint(md) {
            margin: auto 0;
            flex-flow: row wrap;
            text-align: left;
        }
    }
    &__total-product {
        width: 100%;
        @include breakpoint(md) {
            width: auto;
        }
        span {
            @include font-content($line-height: 1.14);
            margin-bottom: 20px;
            display: block;
            color: $color-text-primary;
            @include breakpoint(md) {
                @include font-content($line-height: 1.43);
                margin-bottom: 5px;
            }
        }
    }
    &__btn-go-to-cart {
        width: 100%;
        flex-grow: 1;
        text-align: center;
        @include breakpoint(md) {
            width: auto;
            text-align: right;
        }
        a,
        button {
            @include btn(primary, large);
            min-width: 143px;
            padding: 16px 20px;
            @include breakpoint(md) {
                min-width: 203px;
            }
        }
    }
    /* fallback: if no image is available */
    .msc-empty_image {
        @include image-placeholder($image-width-xs, $image-width-xs);
        min-width: $image-width-xs;
        overflow: hidden;
        border-radius: 5px;
        background-size: 100%;
        border: 1px solid $color-border-checkout-primary;
        @media (min-width: $breakpoint-xs) {
            @include image-placeholder($image-width-sm, $image-height-sm);
        }
        @include breakpoint(md) {
            @include image-placeholder($image-width-md, $image-height-md);
        }
    }
}
