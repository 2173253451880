.ms-content-block {
    //Content Block Layout
    &__twoCol {
        &-left {
            @include breakpoint(md) {
                padding-right: 19.354%;
            }
        
            @include breakpoint(lg){
                padding-right: 21.947%;
            }
        }

        &-right {
            position: relative;
  
            @include breakpoint(md) {
                position: absolute;
                left: 19.354%;
            }
      
            @include breakpoint(lg){
                position: absolute;
                left: 21.947%
            }
        }
    }

    //Content Block Styles
    &__pain {
        .msc-cta__primary {
            border: 0px;
        }

        .ms-content-block__title {
            font-size: 22px;
            line-height: 22px;
            padding-bottom: 10px;
            color: $color-text-primary;

            @include breakpoint(lg) {
                font-size: $font-size-xxl;
                line-height: 28px;
            }
        }

        .ms-content-block__text {
            font-size: $font-size-m;
            line-height: 20px;
            padding-bottom: 10px;
            color: $color-text-primary;

            @include breakpoint(lg) {
                font-size: $font-size-l;
                line-height: 26px;
            }
        }

        &-heading {
            @include font-content($font-size-xxl, 700, 1.167);

            @include breakpoint(lg) {
                font-size: $font-size-xxxl;
                line-height: 32px;
            }
        }

        &-link {
            text-decoration: underline;
            padding-bottom: 30px;
            @include font-content($font-size-m, 500, 1.429);
            color: $color-text-primary;

            @include breakpoint(lg){
                @include font-content($font-size-l, 500, 1.625);
            }
        }

        &-image {
            padding-bottom: 9.15px;
        }
    }
}