$wmap-color-error: $color-punch;
$wmap-color-warning: $color-supernova;
$wmap-color-check: $color-camaron;
.mfrm-wmap {
    // ------------------ WMAP ERROR/WARNING ------------------ //
    &__error,
    &__warning {
        margin-bottom: 30px;
        padding: 10px;
        border-radius: 3px;
    }
    &__error-message,
    &__warning-message {
        display: flex;
        flex-flow: row nowrap;
        color: $color-text-primary;
        &::before {
            position: relative;
            margin-right: 10px;
        }
    }
    // ------------------ WMAP ERROR ------------------ //
    &__error {
        background-color: lighten($color: $wmap-color-error, $amount: 45%);
    }
    &__error-message {
        @include add-mfrm-icon($icon-warning-fill, before);
        align-items: flex-start;
        &::before {
            top: 2px;
            color: $wmap-color-error;
            font-size: ($icon-size-m + 4);
        }

        a {
            text-decoration: underline;
            color: $wmap-color-error;
            display: inline-block;
        }
    }
    // ------------------ WMAP WARNING ------------------ //
    &__warning {
        background-color: lighten($color: $wmap-color-warning, $amount: 40%);
    }
    &__warning-message {
        @include add-mfrm-icon($icon-check-fill, after);
        align-items: center;
        &::before {
            content: "$";
            height: 30px;
            width: 30px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $icon-size-s;
            border: 1px solid $color-text-primary;
            border-radius: 100px;
        }
        &::after {
            position: absolute;
            top: 73px;
            left: 30px;
            background-color: $color-white;
            border-radius: 100px;
            color: $wmap-color-check;
            font-size: ($icon-size-s - 2);
        }
    }
}
