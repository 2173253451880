$color-punch: #D63426;
$color-charcoal:#2D2926;
$breakpoint-lg: 1025px;

@mixin icon-states {
    &:hover {
        color: #2D2926;
        text-decoration: none;
        background-color: $icon-hover;
        cursor: pointer;
    }

    &:focus-visible {
        background: #F8F8F8;
        box-shadow: 0px 0px 0px 3px #FFFFFF, 0px 0px 0px 5px #2D2926;
        border-radius: 100px;
    }

    &:active {
        background: #E1E1E1;
        border-radius: 100px;
    }
}

@mixin link-states {
    &:hover {
        color: #2D2926;
        text-decoration: underline;
        cursor: pointer;
    }

    &:active {
        color: #2D2926;
        text-decoration: underline;
    }

    &:focus-visible {
        box-shadow: 0px 0px 0px 3px #FFFFFF, 0px 0px 0px 5px #2D2926;
        color: inherit;
    }
}

.navbar-account {
    &_drawer {
        list-style: none;
        text-decoration: none;
    }

    &_wrapper {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
    }

    &_close {
        cursor: pointer;
        @include icon-states();
        &-icon {
            width: 25px;
            height: 25px;
        }
    }

    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 16px;
    }

    &_link {
        color: $color-punch;
        @include link-states();

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &:focus {
            color: $color-punch;
        }

        &-container {
            padding: 15px 0;
        }
    }

    &_body-link {
        padding: 20px 0;
        font-family: "Rubik",sans-serif;
        font-weight: 400;
        font-size: 1.143rem;
        line-height: 1;
        color: $color-charcoal;
        box-shadow: 0px 1px 0px #e4e4e4;
        @include link-states();

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }

}