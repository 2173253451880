$mfcl-carousel-selector: carousel-module;
.product-compare {
    &__header {
        text-align: left;
        &-heading {
            @include font-content($font-size-xxxl, 700, 32px);
        }
        &-description {
            margin: 0 0 40px;
            @include font-content($font-size-xl, 400, 28px);
        }
    }
    // Overriding MFCL carosuel
    .mfcl-carousel__button-position {
        top: -25px;
        @include breakpoint(md) {
            top: -64px;
            width: 40px;
            height: 40px;
        }
    }
    .mfcl-carousel__button-position[class*="#{$mfcl-carousel-selector}_prev"] {
        right: 25%;
        @include breakpoint(md) {
            right: 15%;
        }
       }
    &__content {
        display: flex;
        justify-content: center;
        &-left {
            align-self: flex-end;
            background-color: $color-light-blue;
            border-radius: 16px;
            margin-right: 17px;
            display: none;
            ul {
                list-style: none;
                padding: 0
            }
            @include breakpoint(lg) {
                width: 25%;
                padding: 40px 10px;
                display: block;
                position: relative;
                top: -20px;
            }
            .collection-specification {
                &__item {
                    @include font-content($font-size-l, 400, 28px);
                    margin-bottom: 33px;
                }
            }
            .mattribute_headings {
                border-top: 1px solid lightgray;
                padding: 32px 5px;
                &__item {
                    display: flex;
                    align-items: center;
                    @include font-content($font-size-lg, 400, 28px);
                    margin-bottom: 17px;
                }
                .mattribute-icon {
                    width: 32px;
                }
                .mattribute-name {
                    margin-left: 10px;
                }
            }
        }
        &-right ,&-current-product {
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            white-space: nowrap;
            @include custom-scrollbar-webkit();
            width: 50%;
            @include breakpoint(md) {
                width: 55%
            }
            @include breakpoint(lg) {
                width: 50%
            }
            .product-compare-card {
                position: relative;
                left: 0;
                top:0;
                @include no-decoration;
                width: 177px;
                height: 100%;
                display: block;
                border-radius: 5px;
                padding: 0;
                margin-right: 4px;
                color: $color-text-primary;
                white-space: normal;
                @include breakpoint(xs) {
                   width: 142px;
                }
                @media (min-width: $breakpoint-xs) {
                    width: 168px;
                }
                @include breakpoint(md) {
                    width: 297px;
                    margin-right: 10px;
                }
                @include breakpoint(lg) {
                    width: 270px;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .current-product{
                    position: relative;
                    top: 20px;
                    cursor: pointer;
                }
                &.current-product-border {
                    border: 2px solid $color-tealish-blue;
                }
                a {
                    @include no-decoration;
                }
                &__specs {
                    &-item {
                        margin-bottom: 8px;
                        .specs-lable {
                            @include font-content($font-size-m, 400, 28px);
                        }
                        .comfort{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            &-icon{
                             width: 32px;
                             height: 32px;
                            }
                        }
                        .specs {
                            @include font-content($font-size-m, 700, 16px);
                            @include text-ellipsis;
                            height: 33px;
                            @include breakpoint(lg) {
                                @include font-content($font-size-m, 700, 22px);
                                height: auto;
                        }
                     }
                    }
                }
                &__image {
                    width: 100%;
                    background-color: $color-bg-primary;
                    margin-top: -20px;
                    border: 5px 5px 0 0;
                    img {
                        width: 100%;
                        height: 187px;
                        border-radius: 5px 5px 0 0;
                        object-fit: cover;
                        @include breakpoint(lg) {
                            width: 278px;
                            height: 232px;
                        }
                    }
                }
                &__detail-title {
                    margin-bottom: 10px;
                    height: 84px;
                }
                &__title {
                    @include no-decoration;
                    @include text-ellipsis;
                    -webkit-line-clamp: 3;
                    @include font-content($font-size-xxl, 700, 28px);
                    color: $color-text-primary;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &__details {
                    @include font-content($font-size-l, 700, 1.125);
                    margin: 0;
                    background-color: $color-bg-primary;
                    color: $color-text-primary;
                    text-align: left;
                    padding: 0 10px 20px;
                    .msc-rating {
                        width: 100%;
                        margin-bottom: 8px;
                        @include breakpoint(md) {
                            max-width: inherit;
                        }
                        &__count {
                            @include font-content($font-size-s, normal, 24px);
                            text-align: left;
                            display: block;
                            @include breakpoint(md){
                                display: inline;
                            }
                        }
                    }
                    .product-ratings{
                        min-height: 16px;
                        pointer-events: none;
                    }
                }
                &__delivery {
                    color: $color-dark-green;
                    margin: 8px 0 0;
                    &-label {
                        @include font-content($font-size-m, 400, 20px);
                    }
                    &-message {
                        @include font-content($font-size-l, 700, 18px);
                        display: block;
                        height: 38px;
                    }
                    &-zipcode {
                        text-decoration: underline;
                        margin-left: 5px;
                    }
                }
                .mfrm-product-badge {
                    top: 0;
                    left: 0;
                    &__image{
                        max-height: 39px;
                    }
                }
                .product-price-section {
                    margin-bottom: 0;
                    .product-price {
                        @include font-content($font-size-l, 700, 24px);
                        color: $color-text-secondary;
                        .product-price-label {
                            @include font-content($font-size-s, 400, 1.3);
                            color: $color-text-primary;
                            @include breakpoint(md) {
                                @include font-content($font-size-s, 400, 1.17);
                            }
                            @include breakpoint(lg) {
                                @include font-content($font-size-s, 400, 1.4);
                            }
                        }
                    }
                    .product-original {
                        color: $color-text-tertiary;
                        margin: 0px 5px;
                        @include font-content($font-size-sm, 400, 24px);
                        &.price-text {
                            @include font-content($font-size-l, 700, 24px);
                            color: $color-text-primary;
                            margin: 0;
                        }
                    }
                    .product-original-price {
                        text-decoration: line-through;
                    }
                }
            }
            .shop-ATC-buttons {
                .shop-now-btn {
                    min-height: 44px;
                    width: 100%;
                    @include btn(primary, large);
                    @include font-content($font-size-m, 500, 12px);
                    display: block;
                    margin: 16px auto 0;
                    padding: 14px 0;
                    @include breakpoint(md) {
                        width: 70%;
                    }
                    @include breakpoint(lg) {
                        margin: 22px auto 0;
                    }
                }
            }
            .product-mattributes {
                border-top: 1px solid lightgray;
                text-align: left;
                margin: 10px 0;
                padding-top: 16px;
                color: $color-text-primary;
                &__heading {
                    @include font-content($font-size-m, 700, 10px);
                    margin-bottom: 15px;
                }
                .mattribute {
                    @include font-content($font-size-s, 500, 16px);
                    margin: 8px 0;
                    @include breakpoint(md) {
                        @include font-content($font-size-m, 500, 10px);
                    }
                }
                .mattribute-container{
                    span{
                        @include font-content($font-size-m, 500, 16px);
                    }
                }
                .score {
                    list-style: none;
                    display: flex;
                    padding: 0;
                    margin-bottom: 16px;
                    li {
                        height: 15px;
                        width: 45px;
                        background-color: #e5e5e5;
                        border-radius: 10px;
                        margin-right: 5px;
                        &.hidden-score {
                            visibility: hidden;
                        }
                        .filled {
                            width: 100%;
                            height: 100%;
                            background-color: #439F93;
                            border-radius: 10px;
                        }
                        .halffilled {
                            width: 50%;
                            height: 100%;
                            border-radius: 10px;
                            background-color: #439F93;
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                        }
                    }
                }
            }
        }
        .product-compare__content-current-product{
            display: block;
            overflow:hidden;
            width: 50%;
            @include breakpoint(md) {
                width: 45%;
            }
            @include breakpoint(lg) {
                width: 25%;
            }
        }
    }
    &__sleep-expert {
        width: 100%;
        margin-top: 47px;
        @include breakpoint(lg) {
            width: 75%;
            margin-left: auto;
        }
    }
}