.d5-mfrm-sleep-expert-picks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    max-width: $page-width-max;
    margin: 0 auto;
    font-size: $font-size-l;

    &__img {
        position: absolute;
        top: 0;

        &__avatar {
            width: 237px;
            height: 237px;
        }

        &__clip {
            clip-path: circle(50% at center);
        }
    }

    &__wrapper {
        margin-top: 160px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background: $color-white;
        width: 100%;
        padding-top: 95px;
    }

    &__title {
        @include font-content($font-size-xl, 400, 1.14);
        padding-bottom: 17px;
        color: $color-text-primary;

        @include breakpoint (md) {
            @include font-content($font-size-xl, 400, 1.14);
            padding-bottom: 8px;
        }

        @include breakpoint (lg) {
            @include font-content(32px, 400, 1.14);
            padding-bottom: 17px;
        }
    }

    &__content {
        @include font-content($font-size-m, 400, 1.625);
        text-align: center;
        color: $color-text-primary;
        max-width: 341px;
        padding: 0 55px 0 55px;
        padding-bottom: 20px;

        @include breakpoint (md) {
            max-width: 458px;
            @include font-content($font-size-s, 400, 1.6);
            padding: 0 0 10px 0;
        }

        @include breakpoint (lg) {
            max-width: 585px;
            @include font-content($font-size-m, 400, 1.6);
            padding: 0 0 22px 0;
        }
    }

    &__button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 30px;
        font-weight: 500;

        @include breakpoint (md) {
            flex-direction: row;
            display: inline-block;
            margin: 0 135px 30px
        }

        @include breakpoint (lg) {
            margin: 0 0 30px;
        }
        button, a {
            margin: 10px;
        }
        
    }

    .d5-mfrm-single-cta {
        @include btn(quinary, default); 
    }

    &__callout {
        background: #1B244D;
        width: 100%;
        color: #fff;
        text-align: center;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        padding: 6px 0;
    }

}
.sleep-expert{
    &__container{
        border-radius: 5px;
        background-color:$color-light-blue ;
        padding: 30px 0;
        text-align: center;
    }
    &__title {
        max-width: 290px;
        @include font-content($font-size-xxl, 700, 22px);
        padding-bottom: 10px;
        color: $color-tealish-blue;
        margin: auto;
        @include breakpoint (lg) {
        max-width: 100%;
        padding: 0 0 10px;
        }
    }

    &__content {
        @include font-content($font-size-xl, 400, 24px);
        text-align: center;
        color: $color-text-primary;
        max-width: 341px;
        padding: 0 10px 20px 10px;
        margin: auto;

        @include breakpoint (md) {
            max-width: 458px;
            padding: 0 0 10px 0;
        }

        @include breakpoint (lg) {
            max-width: 585px;
            padding: 0 0 20px 0;
            margin: auto;
        }
    }
    &__chat{
        &-link{
            @include font-content($font-size-l, 500, 20px);
            color: $color-text-secondary;
            border: none;
            background-color: transparent;
            &:hover{
                text-decoration: underline;
            }
            .caret{
                margin-left: 10px;
            }
        }
    }
}