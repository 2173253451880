.ms-search {
    display: inline-block;
    width: 100%;
    margin: auto;

    @include breakpoint(sm) {
        width: 60%;
        margin-right: 0;
    }

    @media (width: $breakpoint-lg) {
        width: 45%;
        margin-right: auto;
    }

    @include breakpoint(lg) {
        width: 27%;
        margin-right: 0;
    }

    @include breakpoint(xl) {
        width: 400px;
        margin-right: 0;
    }

    &__holder {
        @include breakpoint(md) {
            position: relative;
        }
    }

    &__searchForm {
        position: relative;
    }

    &__form-control {
        @extend %form-control-search;
        width: 100%;
        padding: 12px 20px;
    }

    &__form-submitSearch {
        @extend %btn-muted;
        @include search-icon($icon-search);
        position: absolute;
        top: 8px;
        right: 15px;
        height: 25px;
        width: 25px;
        padding: 0;
        font-size: 16px;
        color: $color-text-primary;
    }

    &__label,
    &__form-cancelSearch {
        display: none;
    }
}

.mfrm-search {
    display: inline-block;
    width: 100%;
    margin: auto;

    @include breakpoint(sm) {
        width: 60%;
        margin-right: 0;
    }

    @media (width: $breakpoint-lg) {
        width: 45%;
        margin-right: auto;
    }

    @include breakpoint(lg) {
        margin-left: 36px;
    }

    @include breakpoint(xl) {
        width: 700px;
        margin-right: 0;
    }

    .ms-search__holder {
        @include breakpoint(md) {
            position: relative;
        }
    }

    .ms-search__searchForm {
        position: relative;

        .ms-search__form-submitSearch {
            position: absolute;
            top: 10px;
            left: 15px;
            height: auto;
            width: 25px;
            padding: 0;
            font-size: 25px;
            color: $color-text-primary;
        }

        .ms-search__form-newSubmitSearch {
            position: absolute;
            top: 10px;
            left: 15px;
            height: auto;
            width: 25px;
            padding: 0;
            font-size: 25px;
            color: $color-text-primary;
            background: transparent;
            border: none
        }
    }

    .ms-search__form-control {
        @extend %form-control-search;
        max-height: 48px;
        width: 100%;
        padding: 12px 40px 12px 50px;
        background-color: $color-alabaster;

        &:hover {
            background-color: $color-mercury;
        }

        &:focus {
            box-shadow: 0px 0px 0px 2px $color-charcoal;
            background-color: $color-alabaster;
        }
    }

    .ms-search__label,
    .ms-search__form-cancelSearch {
        display: none;
    }
}