.msc-order-summary-skeleton {
    background-color: $color-bg-order-summary;
    border: 15px solid $color-bg-order-summary;
    margin: 0px -15px;
    position: relative;
    padding-bottom: 160px;

    @include breakpoint(lg) {
        width: 100%;
        margin: 30px 0 30px;
        padding: 15px 17.5px 30px;
        background-color: $color-bg-order-summary;
        border: none;
    }


    &__heading {
        width: 125px;
        height: 20px;
        margin-bottom: 20px !important;
    }

    &__container {
        background-color: $color-bg-primary;
        border: 1px solid $color-border-checkout-secondary;
        border-radius: 2px;
        padding: 15px 30px;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
    }

    &__sub-total {
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        border-top: 1px solid $color-border-checkout-secondary;
    }

    &__express {
        &-order-review {
            width: 106px;
            height: 20px;
            margin: 15px 0 5px 0 !important;

            @include breakpoint(lg) {
                margin: 30px 0 5px 0 !important;
            }
        }

        &-products-wrapper {
            width: 100%;
            padding: 15px;
            background-color: $color-bg-primary;
            margin-bottom: 5px;
        }

        &-chips {
            width: 70px;
            height: 20px;
            margin-bottom: 15px !important;
            border-radius: 50px !important;
        }

        &-delivery-date {
            width: 271px;
            height: 17px;
            margin: 15px 0 !important;
        }

        &-product-list {
            display: flex;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-product-info {
            width: 100%;
            margin: 5px 0 0 10px;
        }

        &-product-title {
            height: 15px;
            width: 100%;
            margin-bottom: 0px !important;
        }

        &-price-section {
            display: flex;
            margin-top: 5px;
            justify-content: space-between;
        }
    }
}