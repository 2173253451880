.mfrm-complete-your-bed {
    width: 100%;
    border-radius: 4px;
    padding: 32px 10px 24px;
    background: $color-light-blue;
    @include breakpoint(lg) {
        padding: 32px 17px;
    }
    .complete-your-bed {
        &__title {
            text-align: center;
            @include font-content($font-size-xl, 700, 22px);
            letter-spacing: 0px;
        }
        &__oos_badge {
            background: $color-charcoal;
            text-transform: uppercase;
            color: white;
            @include font-content($font-size-m, 700, 16px);
            padding: 10px;
            width: 128px;
            border-radius: 0 0 10px 0;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
        &__protector {
            display: flex;
            padding: 16px 10px;
            align-items: center;
            margin-top: 16px;
            position: relative;
            height: auto;
            background-color: $color-bg-primary;
            border-radius: 5px;
            & input[type="checkbox"] {
                min-width: 15px
            }
            .added-lable {
                @include font-content($font-size-s, 700, 14px);
                color: $color-dark-green;
                display: none;
                @include breakpoint(lg) {
                    padding: 8px 16px;
                    background: $color-light-green;
                    border-radius: 100px;
                    display: block;
                }
            }
            .selectable-card {
                display: flex;
                align-items: center;
                color: $color-charcoal;
                align-items: center;
                margin-left: 16px;
                border: none;
                padding: 0;
                .product-price {
                    display: block;
                    font-family: "Rubik";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                }
                .current-price {
                    margin-right: 8px;
                    color: #d63426;
                }
                .standard-price,
                .current-price {
                    display: inline-block;
                    position: relative;
                }
                .standard-price .strike {
                    position: absolute;
                    border-bottom: 1px solid black;
                    top: calc(50%);
                    width: calc(100%);
                }
                sup {
                    font-size: 10px;
                }
                .standard-price .crossed-out {
                    font-weight: 400;
                }
                &__image {
                    margin-right: 16px;
                    flex-shrink: 0;
                    width: 107px;
                }
                &__content {
                    text-align: left;
                }
                &__eyebrow {
                    color: #d63426;
                    font-family: "Rubik";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 14px;
                    text-transform: uppercase;
                }
                &__product-name {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    font-family: "Rubik";
                }
                &__rating {
                    line-height: 13px;
                    margin: 0 0 6px 0;
                }
                img {
                    height: 87.5px;
                }
            }
        }
        &__content {
            display: flex;
            padding: 16px 10px;
            position: relative;
            align-items: center;
            margin-top: 16px;
            height: auto;
            background-color: $color-bg-primary;
            border-radius: 5px;
            & input[type="checkbox"] {
                min-width: 15px
            }
            .selectable-card {
                display: flex;
                color: $color-charcoal;
                align-items: center;
                margin-left: 16px !important;
                border: none !important;
                padding: 0 !important;
            }
            .added-lable {
                @include font-content($font-size-s, 700, 14px);
                color: $color-dark-green;
                display: none;
                @include breakpoint(lg) {
                    padding: 8px 16px;
                    background: $color-light-green;
                    border-radius: 100px;
                    display: block;
                }
            }
            .product-price {
                font-family: "Rubik";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
            }
            .current-price {
                margin-right: 8px;
                color: #d63426;
            }
            .standard-price,
            .current-price {
                display: inline-block;
                position: relative;
            }
            .standard-price .strike {
                position: absolute;
                border-bottom: 1px solid black;
                top: calc(50%);
                width: calc(100%);
            }
            sup {
                font-size: 10px;
            }
            .standard-price .crossed-out {
                font-weight: 400;
            }
        }
    }
}