//--------------------------------------------------------
// *** TO-DO *** PLP - FINANCING PER MONTH
//--------------------------------------------------------
$financial-options-price-color: $color-text-secondary;

.ms-search-result-container {
  &__Products {
    margin: 0 -15px;
    overflow: hidden;

    @include breakpoint(md) {
      margin: 0;
    }
  }

  .msc-product {
    cursor: pointer;

    .msc-price {
      padding: 15px 15px;
      display: block;
    }

    &__financing-per-month {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0 0;
      min-height: 80px;

      @include breakpoint(lg) {
        margin: 16px 0 16px;
      }

      @include breakpoint(xl) {
        min-height: calc(100% - 413px);
      }

      .product-price-section {
        text-align: center;
        flex: 0 0 50%;
        margin-top: 9px;

        @include breakpoint(md) {
          margin-top: 7px;
        }

        @include breakpoint(lg) {
          margin-top: 12px;
        }
      }

      .financing-price-section {
        text-align: center;
        flex: 0 0 50%;
        padding-left: 5px;

        @include breakpoint(md) {
          padding-left: 0;
        }

        .financing-price-info.financing-price-text {
          & .cashback-message {
            padding: 0 5px;
          }
        }
      }

      .msc-price {
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .msc-price__strikethrough {
        order: 2;
        color: $color-input-text;
        margin-top: 5px;
      }

      .product-price-label,
      .financing-price-label {
        margin-bottom: 5px;
      }

      .msc-price__strikethrough,
      .product-price-label,
      .financing-price-label,
      .product-price-info,
      .financing-price-info .financing-price-modal-toggle {
        @include font-content($font-size-s, 400, 1.3);
        color: $color-black;

        @include breakpoint(md) {
          @include font-content($font-size-s, 400, 1.17);
        }

        @include breakpoint(lg) {
          @include font-content($font-size-s, 400, 16px);
        }
      }

      .financing-price-text {
        @include font-content($font-size-s, 400, 1.3);
        color: $color-charcoal;
        z-index: 100;
        cursor: context-menu;

        @include breakpoint(md) {
          @include font-content($font-size-s, 400, 1.17);
        }

        @include breakpoint(lg) {
          @include font-content($font-size-s, 400, 16px);
        }

        & .low-cost-message-text {
          white-space: break-spaces;
        }
      }

      .financing-price-modal-toggle {
        display: inline;
        margin-left: 2px;
        text-decoration: underline;
      }

      .financing-price-info {
        margin: 5px 12px 0 12px;
        min-height: 46px;

        @include breakpoint(md) {
          margin: 5px 22px 0 22px;
        }

        @include breakpoint(lg) {
          margin: 5px 12px 5px 12px;
          min-height: 42px;
        }
      }

      .product-price,
      .financing-price {
        @include font-content($font-size-l, 700, 1.25);
        color: $financial-options-price-color;
        margin-bottom: 5px;
      }

      .financing-price-right {
        margin: 0 10px;
        padding-left: 4px;

        @include breakpoint(md) {
          padding: 0 20px;
        }

        @include breakpoint(lg) {
          padding: 0 10px
        }
      }

      .msc-price__actual {
        font-weight: 700;
      }

      .partition {
        display: flex;
        align-items: center;
        border-right: 1px solid $color-border-product-card-primary;
        position: relative;
        height: 76px;

        .or_text {
          background: $color-white;
          left: -8px;
          position: absolute;
          @include font-content($font-size-s, 700, 1.83);
          white-space: nowrap;
        }
      }

      .financing-price-info.financing-price-text {
        & .cashback-message {
          display: inline;
        }
      }
    }
  }
}