$breakpoint-sm: 320px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;

$background-color: #F8F8F8;
$color-grayscale: #E1E1E1;
$color-charcoal: #2D2926;
$color-punch: #D63426;
$color-white: #FFFFFF;
$color-border:#f0f0f0;

.categories-wrapper{
    @media screen and (min-width: $breakpoint-lg){
        width: 75%;
    }
}

a:hover{
    text-decoration: none !important;
}

.mfrm-footer{
    &_container {
        background: $background-color;

        @media screen and (min-width: $breakpoint-md) {
            padding: 60px 30px;
        }

        @media screen and (min-width: $breakpoint-lg) {
            padding: 60px 120px
        }

        [class*="accordionItem-module_accordionItem"] {
            border-top: none !important;
            border-bottom: none !important;
            box-shadow: 0px 0px 0px $color-border, 0px 1px 0px $color-border;
        }

        [class*="accordionItem-module_title"] {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
        }

      [class*="accordionItem-module_openIcon"] {
      @include add-mfrm-icon($icon-chev-down-slim);
      margin-left: auto;
      margin-right: 10px;
      width: auto;
      height: auto;
      font-size: ($icon-size-s - 2);
      transition: transform 0.3s ease-in-out;
      background: none;
      opacity: 1;
        }
    }

    &_categories-socials-wrapper {
        display: block;

        @media screen and (min-width: $breakpoint-lg) {
            display: flex;
            width: 100%;
        }
    }

    &_categories {
        &_mobile-view{
            display:block;
            margin-bottom: 30px;

            @media screen and (min-width: $breakpoint-md) {
                display: none;
            }
            .mfrm-footer_categories_mfcl-accordion{
              ul.categories-list{
                list-style-type: none;
                padding-left: 15px;
              }
            }
        }

        &_desktop-view{
            display: none;

            @media screen and (min-width: $breakpoint-md) {
                width: 100%;
                display: flex;
                margin-bottom: 30px;

            }

            @media screen and (min-width: $breakpoint-lg) {
                width: 100%;
                margin-bottom: 0;
            }
        }

        &_container{
            display: flex;
            flex-direction: column;


            @media screen and (min-width: $breakpoint-md) {
                margin-right: 14px;
                width:33%
            }

            @media screen and (min-width: $breakpoint-lg) {
                margin-right: 31px;
            }
        }

        &_header{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $color-charcoal;
            margin-bottom: 15px;
        }

        &_item {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            text-decoration: none;
            text-align: left;
            display:block;

            &:focus-visible {
                box-shadow: 0px 0px 0px 4px #ffffff, 0px 0px 0px 5px #2d2926;
            }
        }
    }

    &_socials {
        &_icons-wrapper {
            display:flex;
            width: 100%;
            justify-content: flex-start;
            padding-left: 15px;

            @media screen and (min-width: $breakpoint-md) {
                margin-left: unset;
            }

            @media screen and (min-width: $breakpoint-lg) {
                justify-content: flex-end;
                width: 25%;
            }
        }

        &_icon-container {
            display: flex;
            flex-direction: column;
         }

         &_icon-link {
            display: flex;
            background: $color-punch;
            margin-right: 5px;
            width: 35px;
            height: 35px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 6px;
         }

         &_icon {
            &_twitter {
                width: 21px;
                height: 18px;
            }

            &_facebook {
                width: 11px;
                height: 23.09px;
            }

            &_youtube {
                width: 20px;
                height: 14px;
            }

            &_instagram {
                width: 20px;
                height: 20px;
            }

            &_tiktok {
                width: 20px;
                height: 23px;
            }

            &_pinterest {
                width: 20px;
                height: 26px;
            }
         }
     }

     &_accessibility {
        &_container {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 30px;
            margin-top: 30px;
            margin-left: 15px;

            @media screen and (min-width: $breakpoint-md) {
                margin-left: unset;
            }

            @media screen and (min-width: $breakpoint-lg) {
                justify-content: flex-end;

            }
        }

        &_link{
            background: $color-white;
            border: 1px solid $color-grayscale;
            border-radius: 22.5px;
            padding: 4px 9px;
        }

        &_image{
            height: 21px;
            width: 56px;
        }
     }

     &_disclosures {
        &_content {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: $color-charcoal;
            margin-bottom: 22px;
            line-height: 1.8;
            margin-left: 15px;
            margin-right: 15px;

            @media screen and (min-width: $breakpoint-md) {
                margin-left: unset;
                margin-right: unset
            }
        }
     }

    &_show-more-text-connect {
        white-space: nowrap;
    }

    &_show-more-text {
        background: none;
        border: 0em;
        text-decoration: underline;
    }
}