.ms-checkout-delivery-skeleton {
    @include breakpoint(lg) {
        margin-top: 35px;
    }

    &__accordian {
        width: 100%;
        border-top: 1px solid $color-border-accordion;

        &-text {
            margin: 15px !important;
            height: 20px;
            width: 80%;

            @include breakpoint(lg) {
                width: 450px;
            }
        }
    }

    &__backordered {
        width: 100%;
        height: 115px;
        background-color: $color-bg-error;
        margin-bottom: 15px;
        padding: 15px;

        &-heading {
            width: 250px;
            height: 20px;
            margin-bottom: 5px !important;
        }
    }

    &__delivery-method {
        border-top: 1px solid $color-border-accordion;
        margin-bottom: 15px !important;

        &-heading {
            width: 260px;
            height: 25px;
            margin: 15px 0 !important;
        }

        &-link {
            width: 255px;
            height: 20px;
        }

        &-options {
            height: 102px;
            border: 1px solid $color-border-accordion;
            border-radius: 5px;
            margin-top: 15px;
            padding: 15px;

            &-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
            }
        }
    }

    .msc-cart-line__container {
        display: flex;
        flex-grow: 1;
    }

    //shipping section styles
    &__shipping-section {
        width: 100%;
    }

    &__shipping-heading-payment {
        width: 170px;
        height: 22px;
        margin: 30px 0 15px !important;
    }

    &__shipping-heading {
        width: 125px;
        height: 35px;
        margin: 60px auto 10px auto !important;

        @include breakpoint(lg) {
            margin: 60px 0 10px 0 !important;
        }
    }

    &__shipping-paragraph {
        width: 90%;
        height: 17px;
        margin: 0 auto 15px auto !important;

        @include breakpoint(lg) {
            width: 465px;
            margin: 0 0 15px 0 !important;

        }
    }

    &__product-section {
        width: 100%;
        padding-top: 15px !important;
        border: 1px solid $color-border-checkout-primary;
        margin-bottom: 30px;
        padding: 0;

        &.payment-step {
            padding: 15px;
        }

        @include breakpoint(lg) {
            padding: 15px;
        }
    }
}