//==============================================================================
// UTILITY HEADER
//==============================================================================

$primary-logo-width: 210px;
$secondary-logo-width: 73px;
$element-spacing: 15px;
$preferred-store-height-sm: 45px;

.mfrm-nav-mobile-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
}

.mfrm-nav-line-one {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mfrm-nav-line-two {
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
}

.mfrm-header {
    &__container-logo {
        display: flex;
        align-items: center;

        a {
            @include vfi($color-black);
            display: block;
        }

        img {
            height: auto;
        }

        @include breakpoint(sm) {
            float: left;
            justify-content: left;
        }
    }

    &__logo {
        @include box-arrow(top, 30%, 8px, $color-white);
        position: relative;
        width: 50%;
        display: flex;
        justify-content: center;
        padding: $element-spacing;
        margin-top: 5px;

        &::after {
            z-index: 1;
        }

        @include breakpoint(sm) {
            padding-left: 0;
            width: auto;
        }

        @include breakpoint(lg) {
            margin-left: 40px;
        }

        img {
            max-width: $primary-logo-width;
            max-height: 28px;
        }
    }

    &__logo__secondary {
        width: 50%;
        display: flex;
        justify-content: center;
        padding: $element-spacing;
        border-left: 1px solid $color-border-header-primary;

        img {
            max-width: $secondary-logo-width;
            max-height: 20px;
        }

        @include breakpoint(sm) {
            width: auto;
        }
    }

    &__topbar {
        @include clearfix();
        position: relative;
        background-color: $color-white;

        @include breakpoint(sm) {
            padding: 0 $grid-gutter-width;
        }

        &__link {
            @include vfi($color-black);
            margin-left: $element-spacing;
            font-family: $font-family-primary;
            font-weight: 400;
            font-size: $font-size-s + 0.07;
            line-height: 1.23;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        &__link-mobile {
            margin-left: auto;
        }

        @include breakpoint(lg) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }
    }

    &__topbar__links {
        display: none;
        float: right;
        padding-top: $element-spacing;
        padding-bottom: $element-spacing;

        @include breakpoint(md) {
            display: flex;
        }

        &--mobile {
            display: block;

            @include breakpoint(md) {
                display: none;
            }
        }

        &--tab {
            display: none;

            @include breakpoint(md) {
                display: block;
            }

            @include breakpoint(lg) {
                display: none;
            }

            .mfrm-header__topbar__link:first-child {
                text-decoration: underline;
            }
        }

        &--desktop {
            display: none;

            @include breakpoint(lg) {
                display: block;
            }
        }
    }
}

//==============================================================================
// PRIMARY HEADER
//==============================================================================

$primary-header-bg-color: $color-bg-quaternary;

.mfrm-header {
    &__primary__container {
        position: relative;
    }

    &__primary {
        @include clearfix();
        position: relative;
        height: $header-primary-height;
        background-color: $primary-header-bg-color;

        &__wrapper {
            display: flex;
        }
    }

    &__primary__nav {
        display: none;

        @include breakpoint(lg) {
            display: inline-block;
            margin: auto 0;
            padding: 0 30px;
        }
    }

    &__primary__links {
        padding: 0 20px 0 15px;
        margin: auto 0;

        @include breakpoint(lg) {
            display: flex;
        }

        @include breakpoint(md) {
            padding: 0 30px;
        }
    }

    //=============  MOBILE NAV ICON  ==============//
    &__nav-icon {
        @extend %btn-muted;
        @include add-mfrm-icon($icon-menu);
        display: inline-block;
        min-width: 60px;
        width: 60px;
        text-align: center !important;
        padding: 24px 15px;
        color: $color-black;
        font-size: $icon-size-m + 2;
        line-height: 1;
        cursor: pointer;

        &[aria-expanded="true"] {
            @include add-mfrm-icon($icon-close);
        }

        @include breakpoint(md) {
            min-width: 50px;
            width: 50px;
            padding: 10px;
        }

        @include breakpoint(lg) {
            display: block;
        }
    }

    //=============  CART ICON  ==============//
    .ms-cart-icon {
        height: calc(62px / 2);
        display: block;

        .msc-cart-icon {
            position: relative;
            top: 4px;
            width: 35px;
            height: 100%;
            display: block;
            color: $color-text-primary;

            &[data-no-click="true"] {
                cursor: default;
                pointer-events: none;
            }

            &-v2 {
                img {
                    position: relative;
                    left: 8px;
                    top: 3px;
                }

                &__count {
                    top: -3px;
                    right: -6px;
                }
            }
        }
    }

    .msc-cart-icon__count {
        position: absolute;
        top: -6px;
        right: -1px;
        height: 18px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 700;
        line-height: 10px;
        background-color: $color-tealish-blue;
        border-radius: 100%;
        z-index: 10;
        color: $color-white;
        visibility: visible !important;
    }

    //=============  SIGNIN BUTTON  ==============//
    &__account-info {
        display: inline;
        margin-right: 10px;
        height: calc(62px / 2);

        @include breakpoint(md) {
            display: block;
        }

        @include breakpoint(sm) {
            display: block;
            margin-left: 5px;
        }
    }

    &__signin-button {
        @include icon-user-v2(black, 22px);
        @include vfi();
        position: relative;
        display: block;
        width: 33px;
        height: 100%;

        @include breakpoint(md) {
            display: inline-block;
            height: 33px;
            vertical-align: top;

            &.msc-btn {
                width: auto;

                &:after {
                    display: none;
                }
            }

            &-text {
                @include font-content($font-size-m, 400, 1.14);
                color: $color-text-secondary;
                display: block;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &--closed {
            display: none;
        }

        &--opened {
            @include box-arrow(top, 23%, 11px, $color-white);

            &::after {
                transform: rotate(180deg);
            }
        }

        .modal-store-locator-toggle & {
            pointer-events: none;
            touch-action: none;
            cursor: default;
        }
    }

    &__signup-button-text {
        text-transform: capitalize;
    }
}

//==============================================================================
// SECONDARY HEADER
//==============================================================================

.mfrm-header {
    &__secondary {
        position: relative;
        height: $header-secondary-height;
        display: flex;
        align-items: center;
        background-color: $color-bg-primary;
        box-shadow: 0px 0.5px 0 $color-border-header-primary;
    }

    &__secondary__nav {
        display: inline-block;
        padding: 9px 15px;
        margin: auto;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        @include breakpoint(md) {
            margin: auto auto auto 0;
            box-shadow: -0.5px 0 0 $color-border-header-primary;
        }

        @include breakpoint(lg) {
            box-shadow: none;
            padding: 0 10px 9px;
            white-space: normal;
            overflow-x: visible;
        }

        @include breakpoint(xl) {
            margin: auto;
        }
    }

    &__secondary__nav__link {
        @extend %link-muted;
        @include no-hover-state;
        display: inline-block;
        padding: 7px 10px;
        margin-right: 5px;
        font-family: $font-family-primary;
        font-weight: 400;
        font-size: $font-size-s + 0.07;
        line-height: 1.23;
        background-color: $color-bg-tertiary;
        color: $color-text-primary;
        border-radius: 100px;

        &:last-child {
            margin-right: 0;
        }

        @include breakpoint(lg) {
            margin-top: 9px;
        }
    }

    &__icons {
        width: 225px;
        margin-left: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__preferred-store-container {
        @include font-content($font-size-s + 0.07, 400, 1.23);
        position: relative;
        margin: auto 0 13px 15px;
        height: 45px;
        padding: 5px 15px;

        // background-color: $color-bg-store-locator;
        @include breakpoint(md) {
            background-color: $color-white;
            white-space: nowrap;
        }

        @include breakpoint(lg) {
            margin-right: -31px;
            margin-left: 40px;
        }

        &--desktop {
            display: none;

            @include breakpoint(md) {
                min-width: 198px;
                display: flex;
            }

            @include breakpoint(md) {
                height: 50px;
            }

            @include breakpoint(md) {
                height: 50px;
            }
        }

        &--mobile {
            display: flex;

            @include breakpoint(md) {
                display: none;
            }
        }
    }

    &__preferred-store-btn {
        @extend %link-muted;
        cursor: pointer;
        border: 0;
        padding: 0;
        background: none;
        color: $color-text-primary;

        @include breakpoint(md) {
            padding-right: 70px;
        }

        @include breakpoint(lg) {
            padding: 0;
        }

        img {
            position: relative;
            left: 8px;
        }
    }

    &__preferred-store-text {
        font-weight: normal;

        span {
            font-weight: bold;
        }

        margin-bottom:20px;
    }

    &__track-order {
        &-link {
            @include add-mfrm-icon($icon-track-my-order);
            font-size: 22px;

            &:hover {
                text-decoration: none;
                color: black;
            }
        }
    }
}

//==============================================================================
// FIXED HEADER
//==============================================================================
$header-fixed-z-index: 2047;

.android-app-banner {
    height: 87px;
    width: 100%;
}

.mfrm-header {

    &__container,
    &--fixed {
        position: relative;
    }

    &--fixed {
        .mfrm-header__secondary {
            margin-top: 0px;

            &--lock {
                margin-top: 167px;

                @include breakpoint(md) {
                    margin-top: 122px;
                }
            }
        }

        &.header-checkout {
            padding-top: 70px;
        }

        .mfrm-header__container__wrapper {
            width: 100%;
            z-index: 2047;

            @include breakpoint(md) {
                top: 0px;
            }

            &--lock {
                position: fixed;
                top: 0px;
            }
        }

        .modal-store-locator-toggle & {
            z-index: 5004;
        }
    }
}

//==============================================================================
// CHECKOUT HEADER
//==============================================================================

$header-checkout-bg-color: $color-bg-quaternary;

.mfrm-header {
    &__checkout {
        position: relative;
        height: $header-primary-height;
        display: flex;
        background-color: $header-checkout-bg-color;

        &--fixed {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: $header-fixed-z-index;
        }

        .mfrm-header__logo {
            width: auto;
            margin: auto 0;
            padding-left: 15px;
            display: inline-block;

            a {
                display: block;
            }

            img {
                max-width: 120px;
                max-height: 16.5px;
            }

            &::after {
                display: none;
            }
        }

        .ms-cart-icon {
            height: calc(70px / 2);
            margin: auto 0;
            padding: 0 15px 0 18px;

            @include breakpoint(lg) {
                padding: 0 15px 0 30px;
            }
        }

        a {

            &:hover,
            &:focus {
                text-decoration: none;
                color: $color-text-alert-message;

                @include breakpoint(lg) {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }

        &__phone-no {
            @include font-content($font-size-m, 700, 16px);
            @include add-mfrm-icon($icon-call);
            color: $color-text-alert-message;
            margin: auto;
            margin-right: 5px;

            &:before {
                font-size: ($icon-size-l - 2);
            }

            @include breakpoint(lg) {
                &:before {
                    display: none;
                }
            }

            span {
                display: none;

                @include breakpoint(lg) {
                    display: inline-block;
                }
            }
        }
    }
}