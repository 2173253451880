.homepage-hero-control {
  .MaxWidthPromo {
    .Image {
      object-fit: fill;
      height: 100%;
    }
  }
  .flickity-viewport {
    @include breakpoint(md) {
      max-height: 350px;
    }
  }
}

.brightspot-container {
  &__delivery-service{
    padding: 20px 30px;
    @include breakpoint(md) {
      padding: 35px 30px;
    }
  }
  #syndicate {
    .Page-main {
      min-height: auto;
      .cms-body-body3 {
        @include font-content($font-size-m, 700, 1.28);
      }
      @include breakpoint(md) {
        .MattressSizeList [data-slides] {
          display: flex;
          justify-content: center;
        }
        .MattressSizeList [data-slides] [data-slide] {
          width: 22%;
        }
      }
      @include breakpoint(lg) {
        padding-top: 0 !important;
      }
    }

    // temp hack for Brightspot module
    .ProductTestimonialCard-content-brandIcon {
      .Image {
        height: 29px;
      }
    }

    // temp hack for Brightspot module
    .BrandIcon {
      .Image {
        max-width: none;
      }
    }
  }
}
