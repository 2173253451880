$checkout-margin: 15px;

.ms-checkout {
    position: relative;
    &__body {
        .ms-checkout__side-control-first,
        .ms-checkout__line-items,
        .ms-checkout__side-control-second,
        .closed {
            display: none;
        }
        .ms-checkout__guided-card-footer {
            text-align: center;
        }
        .msc-order-summary-wrapper {
            padding: 60px 15px 80px;
        }
        @include breakpoint(lg) {
            display: flex;
            justify-content: space-between;
            .ms-checkout__main {
                width: 65.83%;
            }
            .ms-checkout__side {
                width: 31.67%;
                @include breakpoint(lg) {
                    position: sticky;
                    top: 110px;
                    align-self: flex-start;
                }
                .msc-order-summary-wrapper {
                    max-width: 100% !important;
                }
            }
        }
    }
}

.ms-checkout {
    margin: 0 auto;
    max-width: 1200px;
    &__header-container {
        margin: 0 -15px;
        padding: 30px 0 10px;
        background-color: $color-bg-primary;
        @include breakpoint(md) {
            margin: 0 -30px;
        }
        @include breakpoint(lg) {
            margin: 0;
            padding: 0 0 40px;
            display: flex;
            align-items: flex-start;
        }
    }
    &__guided-card-title {
        @include vfi-tab($color-black);
    }
    &__guided-card-title-step {
        display: none;
    }
    &__guided-card-title-text {
        @include vfi-tab($color-black);
    }
    &__guided-card-title-section {
        overflow: hidden;
        outline-offset: -1px;
    }
    &__main-control {
        .ms-checkout__btn-place-order {
            margin-right: 10px;
        }
    }
    &__guided-card-btn-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 15px;
        background-color: $color-white;
        border-top: 1px solid $color-border-checkout-secondary;
        z-index: 10;
        .ms-checkout__guided-card-btn-save {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            @media only screen and (min-width: ($breakpoint-xs + 50)) {
                width: auto;
            }
            &.progressive-submit-button {
                @include font-content($font-size-m, bold, 0.87);
                background-color: $color-button-quaternary-background;
                border: none;
                min-width: auto;
                padding-right: 32px !important;
                padding-left: 32px !important;
                &:active {
                    background-color: $color-button-quaternary-background !important;
                    border: none !important;
                }
            }
        }
        @include breakpoint(lg) {
            position: static;
            width: auto;
            padding: 0;
            background-color: transparent;
            border-top: 0;
        }
        [class*="button-module_btn-flex"] {
            [class*="loading-module_loading-ring"] {
                margin-right: 0 !important;
            }
        }
    }
    &__guided-card-btn-save {
        @include font-content($font-size-l, 700, 1);
        display: block;
        min-height: 44px;
        min-width: 257px;
        padding: 13px 20px !important;
        z-index: 2;
        position: relative;
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active:focus,
        &:focus,
        &:hover {
            background-color: $color-button-primary-background-hover;
            border-color: $color-button-primary-background-hover;
            box-shadow: none !important;
        }
        @include breakpoint(md) {
            min-width: 345px;
        }
        @include breakpoint(lg) {
            min-width: 257px;
            max-width: inherit;
            margin-top: 15px;
        }
        @include breakpoint(xl) {
            min-width: 345px;
        }
        &.paypal {
            // Used !important to override focus and hover colors
            @include font-content($font-size: $font-size-l);
            @include vfi-visible();
            background-color: $color-btn-paypal !important;
            color: $color-text-primary !important;
            border-color: transparent !important;
            i {
                @include icon-paypal();
                margin-left: 5px;
            }
        }
        &-price {
            @include breakpoint(lg) {
                display: none;
            }
        }
    }
    //heading
    &__title {
        @include font-content($font-size-xxxl, 700, 1.14);
        margin-bottom: 30px;
        text-align: center;
        @include breakpoint(lg) {
            font-size: 2.286em;
            line-height: 1.125;
            margin-bottom: 0;
            text-align: left;
        }
        &-wrapper {
            @include vfi-tab($color-black);
        }
    }
    // Button
    &__guided-card-btn-edit {
        @include btn(primary, small);
    }
    &__guided-form {
        // Checkout All headings
        &.__cinfo,
        &.__delivery,
        &.__payment {
            .ms-checkout__guided-card-title-text,
            .checkout-form-heading,
            .ms-checkout-delivery-shipping__heading,
            .ms-checkout-shipping-address_container h2 {
                @include font-content($font-size-xxl, 700, 1.17);
                @include vfi-tab($color-black);
                position: relative;
                margin-top: 30px;
                margin-bottom: 30px;
                color: $color-text-primary;
                text-align: center;
                @include breakpoint(md) {
                    text-align: left;
                }
                @include breakpoint(lg) {
                    @include font-content($font-size-xxxl, 700, 1.14);
                    margin-top: 0;
                }
            }
        }
        // Checkout customer info headings
        &.__cinfo {
            .ms-checkout__guided-card-title-text {
                @include breakpoint(lg) {
                    margin-bottom: 36px;
                }
            }
            .checkout-form-heading {
                margin-top: 0;
                margin-bottom: 20px;
                width: 100%;
            }
        }
        // Checkout delivery headings
        &.__delivery {
            .ms-checkout-delivery-shipping__heading {
                margin-top: 0;
                margin-bottom: 15px;
                @include breakpoint(lg) {
                    margin-top: 60px;
                    margin-bottom: 10px;
                }
            }
        }
        // Checkout payment headings
        &.__payment {
            .ms-checkout__guided-card-title-text {
                margin-bottom: 0;
            }
            .ms-checkout-shipping-address_container h2 {
                @include breakpoint(lg) {
                    margin-top: 30px;
                }
            }
            .ms-checkout-delivery-shipping__heading {
                @include font-content($font-size-l, 700, 1.25);
                margin-top: 0;
                margin-bottom: 15px;
                text-align: left;
                @include breakpoint(lg) {
                    @include font-content($font-size-xl, 700, 1.22);
                    margin-top: 30px;
                }
            }
        }
        .ms-checkout__guided-card-header {
            overflow: hidden;
        }
        .field-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            order: 1;
            width: 100%;
            &.required {
                .msc-address-form__label {
                    &:after {
                        content: " *";
                    }
                }
                .field-control:focus ~ .msc-address-form__label {
                    &:after {
                        color: $color-text-primary;
                    }
                }
            }
        }
    }
    &__loader{
        position: fixed;
        top: 0;
        padding-top: 125px;
        left: 0;
        background-color: rgba(255,255,255, 1);
        width: 100%;
        z-index: 15;
        height: 100%;
        @include breakpoint(lg){
            top: 0;
        }
    }
    &__loader-sub{
        display: flex;
        justify-content: center;
        @include breakpoint(lg){
            align-items: center;
            height: 100%;
        }
    }
    &__title-wrapper{
        display: none;
    }
    //to make submit order overlay modal transparent when user clicks Submit order CTA
    &__submit-order-overlay-modal + .msc-modal__backdrop {
        background-color: transparent;
    }
    //to hide checkout elements when skeleton code is enabled
    &__hide-section {
        // display: none !important; // Commented it because checkout doesn't render in this case
        position: absolute !important;
        opacity: 0;
        pointer-events: none;
        top: 0;
        left: 0;
    }
    &__progressive {
        &-wrapper {
            padding: 30px 0;
            position: relative;
            .sr-only {
                @include vfi-sronly();
                width: 100% !important;
                height: 65% !important;
            }
        }
        &-logo {
            width: 216px;
            margin-bottom: 16px    
        }
        &-heading {
            @include font-content($font-size-xl, 500, 1.55);
            margin-bottom: 0;
        }
        &-lease-id,
        &-amount {
            @include font-content($font-size-l, 400, 1.55);
        }
    }
}

// Error Alert
.ms-checkout {
    &__error {
        padding: 15px;
        margin-top: 15px;
        background-color: $color-bg-checkout-error;
        border-radius: 8px;
        width: 100%;
        display: block;

        &-title {
            @include font-content($font-size-l, 700, 1.25);
            @include vfi-visible($color-black);
            color: $color-text-secondary;
            width: fit-content;
        }

        &-message {
            @include font-content($font-size-l, 400, 1.38);
            @include vfi-visible($color-black);
            color: $color-text-primary;
            margin-top: 8px;
        }

        &-cta {
            @include font-content($font-size-s, 700, 1.33);
            @include vfi-visible($color-black);
            display: block;
            text-align: center;
            margin: auto;
            background-color: $color-bg-primary;
            margin-top: 24px !important;
            width: 100%;
            max-width: 310px;
            padding: 10px 0;
            border-radius: 100px;

            @include breakpoint(md){
                margin: 0;
            }
        }
        &.checkout-shipping-address {
            margin: 0 !important;
            @include breakpoint(lg){
                margin: 0 0 30px !important;
            }
        }
    }
    &__hidden,
    &.hidden {
        display: none;
    }
}