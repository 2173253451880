/**
 * Text color
 * =================================
 */
.font-red {
    color: $color-punch;
}

.font-charcoal {
    color: $color-charcoal;
}

.font-white {
    color: $color-white;
}

.font-blue {
    color: $color-tealish-blue;
}

.font-black {
    color: $color-black;
}

.font-green {
    color: $color-dark-green
}

/**
 * Background color
 * =================================
 */
 .bkg-red {
    background-color: $color-punch;
}

.bkg-charcoal {
    background-color: $color-charcoal;
}

.bkg-white {
    background-color: $color-white;
}

.bkg-blue {
    background-color: $color-tealish-blue;
}

.bkg-gray {
    background-color: $color-alabaster;
}

.bkg-light-blue {
    background-color: $color-light-blue;
}
