//==============================================================================
// ANIMATION FUNCTIONS
//==============================================================================

// duration     Specifies how many seconds or milliseconds an animation takes to complete one cycle. Default 0
//
// delay        Specifies when the animation will start. Default 0
//
// function     Describes how the animation will progress over one cycle of its duration. Default "ease"
//
// count        Specifies the number of times an animation is played. Default 1
//
// fill-mode    Specifies if the effects of an animation are before the animation starts and after it ends.
//
// visibility   Determines whether or not a transformed element is visible when it is not facing the screen.

$countDefault: 1 !default;
$durationDefault: 1s !default;
$delayDefault: 0s !default;
$functionDefault: ease !default;
$fillDefault: both;
$visibilityDefault: hidden !default;

@mixin count($count: 1) {
  -webkit-animation-iteration-count: $count;
  -moz-animation-iteration-count: $count;
  -ms-animation-iteration-count: $count;
  -o-animation-iteration-count: $count;
  animation-iteration-count: $count;
}

@mixin duration($duration: 1s) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -ms-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
}

@mixin delay($delay: 0.2s) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -ms-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin function($function: ease) {
  -webkit-animation-timing-function: $function;
  -moz-animation-timing-function: $function;
  -ms-animation-timing-function: $function;
  -o-animation-timing-function: $function;
  animation-timing-function: $function;
}

@mixin fill-mode($fill: both) {
  -webkit-animation-fill-mode: $fill;
  -moz-animation-fill-mode: $fill;
  -ms-animation-fill-mode: $fill;
  -o-animation-fill-mode: $fill;
  animation-fill-mode: $fill;
}

@mixin visibility($visibility: hidden) {
  -webkit-backface-visibility: $visibility;
  -moz-backface-visibility: $visibility;
  -ms-backface-visibility: $visibility;
  -o-backface-visibility: $visibility;
  backface-visibility: $visibility;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  -o-transform: $property;
  transform: $property;
}

@mixin transform-origin($transform-origin: center center) {
  -webkit-transform-origin: $transform-origin;
  -moz-transform-origin: $transform-origin;
  -ms-transform-origin: $transform-origin;
  -o-transform-origin: $transform-origin;
  transform-origin: $transform-origin;
}

@mixin transform-style($transform-style: flat) {
  -webkit-transform-style: $transform-style;
  -moz-transform-style: $transform-style;
  -o-transform-style: $transform-style;
  transform-style: $transform-style;
}

@mixin animation-name($animation-name) {
  -webkit-animation-name: $animation-name;
  -moz-animation-name: $animation-name;
  -ms-animation-name: $animation-name;
  -o-animation-name: $animation-name;
  animation-name: $animation-name;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation-transition(
  $animation-type,
  $count: $countDefault,
  $duration: $durationDefault,
  $delay: $delayDefault,
  $function: $functionDefault,
  $fill: $fillDefault,
  $visibility: $visibilityDefault
) {
  @include animation-name($animation-type);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}

//==============================================================================
// ANIMATION MIXIN
//==============================================================================

//=== Fade In ===//
@include keyframes(fadeIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//=== Faded In to less opacity ===//
@include keyframes(fadedIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

//=== Faded In to moderate opacity ===//
@include keyframes(fadedInMore) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

//=== Faded Out from less opacity ===//
@include keyframes(fadedOut) {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}

//=== Slide Up - Out from Screen ===//
@include keyframes(slideInUp) {
  0% {
    @include transform(translateY(2000px));
  }
  100% {
    @include transform(translateY(0));
  }
}

//=== Slide Down - Out from Screen ===//
@include keyframes(slideOutDown) {
  0% {
    @include transform(translateY(0));
  }
  100% {
    @include transform(translateY(2000px));
  }
}

//=== Fade In and disappear after defined seconds ===//
@include keyframes(fadeInOut) {
  from {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

//Div Loading animation
@include keyframes(placeholderShimmer) {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}


