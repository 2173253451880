.pulse {
    animation: pulse 1.5s ease-in-out 0.5s infinite;
    display: block;
    background-color: #e1e1e1;
    margin-bottom: 3px;
}

.transform {
    transform-origin: 0 55%;
    transform: scale(1, 0.8);
}

.text {
    height: 20px;
    border-radius: 4px;
}

.image {
    height: 110px;
}

.button {
    border-radius: 25px;
    height: 44px;
    width: 100%;
}

.button-secondary {
    background: transparent;
    border: 1px solid #e1e1e1;
}

.circle {
    border-radius: 50%;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}