//==============================================================================
// BUYBOX MODULE
//==============================================================================
$buybox-max-width: 1200px;
$gutter-width: 30px;
$main-image-height-desktop: 621px;

.pdp #main {
  max-width: 100%;
}

.mfrm-pdp-page {
  max-width: $buybox-max-width;
  margin: 0 auto;
}

.ms-buybox {
  margin-top: 19px;
  display: block;
  @include breakpoint(xl) {
    margin-top: 33px;
    display: flex;
    flex-flow: row wrap;
  }
}

.ratings-container {
  display: none;
}

.ms-product-specification {
  margin-top: 60px;
  overflow: hidden;
}


//==============================================================================
// BUYBOX - PRODUCT BADGES
//==============================================================================

.ms-buybox .mfrm-product-badge {
  margin-right: 80px;
  @include breakpoint(md) {
    max-height: 640px;
    margin-right: 85px;
  }
  @include breakpoint(lg) {
    max-height: 620px;
  }
}

.ms-buybox_sleep-style-disruptors-modules {
  @include breakpoint(lg) {
    display: flex;
  }
}