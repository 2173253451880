.ms-content-block {
  &[data-m-layout="left-right"] {
    .ms-content-block__image {
      width: 100%;
    }

    .ms-content-block__details {
      margin-top: auto;
      margin-bottom: auto;
    }

    .ms-content-block__cta {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.textplacement__left {
      .ms-content-block__details {
        .card-cta {
          justify-content: flex-start;
        }
      }
    }

    &.textplacement__right {
      .ms-content-block__details {
        .card-cta {
          justify-content: flex-end;
        }
      }
    }

    &.textplacement__center {
      .ms-content-block__details {
        text-align: center;
        .card-cta {
          justify-content: center;
        }
      }
    }

    .ms-content-block__image img {
      height: 192.98px;
    }

    /* body should have top margin only when there's something above it */
    * + .ms-content-block__text {
      margin-top: 15px;
    }

    /* links should have top margin only when there's something above it */
    * + .ms-content-block__cta {
      margin-top: 30px;
    }

    @include breakpoint(md) {
      &.textplacement__left {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;

        .ms-content-block__details {
          margin: auto 40px auto 20px;
        }
      }

      &.textplacement__right {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;

        .ms-content-block__details {
          margin: auto 20px auto 40px;
        }
      }

      &.textplacement__center {
        .ms-content-block__details {
          align-items: center;
          text-align: center;
        }
        .ms-content-block__image img {
          width: 100%;
        }
      }

      &.textplacement__left {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;

        .ms-content-block__image {
          grid-column: 2 / span 1;
          grid-row: 1 / span 1;
          margin-bottom: auto;
          margin-top: auto;
        }

        .ms-content-block__details {
          grid-column: 1 / span 1;
          grid-row: 1 / span 1;
        }
      }
    }

    @include breakpoint(md) {
      .ms-content-block__details {
        margin: auto;
      }

      .ms-content-block__image img {
        width: 100%;
      }
    }
  }
}
