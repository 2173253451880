//==============================================================================
// Footer Social Icons
//==============================================================================
$footer-social-icon-background: $color-bg-quaternary;
$footer-social-icon-width: 35px;
.footer-category-social {
  display: flex !important;
  @include breakpoint(lg) {
    justify-content: flex-end;
  }
  .ms-footer {
    &__list {
      display: flex;
    }
    &__item {
      margin-right: 5px;
    }
    &__list-item:last-child {
      .ms-footer__item {
        margin: 0;
      }
    }
  }
  a {
    cursor: pointer;
    display: flex !important;
    background: $footer-social-icon-background;
    width: $footer-social-icon-width;
    height: $footer-social-icon-width;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 6px;
  }
  span {
    display: none;
  }
  img {
    display: block !important;
    object-fit: contain;
  }
  .social-media-item-twitter img {
    width: 20px;
    height: 17px;
  }
  .social-media-item-facebook img {
    width: 11px;
    height: 23.09px;
  }
  .social-media-item-youtube img {
    width: 20px;
    height: 14px;
  }
  .social-media-item-instagram img {
    width: 20px;
    height: 20px;
  }
  .social-media-item-pinterest img {
    width: 20px;
    height: 26px;
  }
  .social-media-item-tiktok img {
    width: 20px;
    height: 23px;
  }
}
