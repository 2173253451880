$mfrm-accordion-selector: accordionItem-module;

.mfrm-accordion {
    &__product-benefits {
        margin: 48px 0;
        &-heading {
            margin-bottom: 32px;
            @include font-content($font-size-xl + 0.125, 700, 1.2);
            @include breakpoint(lg) {
                @include font-content($font-size-xxl, 700, 1.16);
            }
        }
    }
}
.product-benefits {
    [class*="#{$mfrm-accordion-selector}_line"] {
        padding: 20px 15px 36px 15px;
        @include breakpoint(md){
            padding: 18px 15px 48px 15px;
           }
        @include breakpoint(lg){
            padding: 34px 15px 32px 15px;
           }
    }
    [class*="#{$mfrm-accordion-selector}_icon"] {
        align-self: flex-start;
        width: 25px;
        margin-right: 0;
        position: absolute;
        left:18px;
    }
    [class*="#{$mfrm-accordion-selector}_center"] {
        width: 100%;
        height: auto;
    }
    [class*="#{$mfrm-accordion-selector}_title"] {
       @include breakpoint(md){
        display: flex;
        align-items: flex-start;
       }
    }
    [class*="#{$mfrm-accordion-selector}_preview"] {
        display: none;
        margin: 8px 10px 0 20px;
        @include font-content($font-size-l, 400, 24px);
        @include breakpoint(md) {
        margin-left: 38px;
        display: block; 
        }
    }
    [class*="#{$mfrm-accordion-selector}_openIcon"] {
        @include breakpoint(md) {
            opacity: 1;
        }
    }
    &__disabled{
        cursor: pointer;
        pointer-events: none;
        [class*="#{$mfrm-accordion-selector}_openIcon"] {
           opacity: 0;
        }
    }
    &__title {
        margin-right: 16px;
        margin-left: 20px;
        position: relative;
        left: 20px;
        @include font-content($font-size-xl, 400, 24px);
        @include breakpoint(md) {
            @include font-content($font-size-xxl, 400, 28px);
        }
    }
    &__score {
        background-color: $color-light-blue;
        width: fit-content;
        margin-top: 12px;
        padding: 4px 16px;
        border-radius: 100px;
        @include breakpoint(md) {
            margin-top: 0;
            position: relative;
            left: 20px;
        }
        &-text {
            color: $color-tealish-blue;
            margin: 0 10px;
            @include font-content($font-size-m, 400, 20px);
            @include breakpoint(md) {
                @include font-content($font-size-l, 400, 24px);
             }
        }
    }
    &__description {
        @include font-content($font-size-l, 400, 24px);
        padding: 0px 18px;
        @include breakpoint(md) {
           display: none;
        }
    }
    &__enhanced-content {
        @include font-content($font-size-l, 400, 1.375);
        padding: 10px 18px;
        @include breakpoint(md) {
          margin-left: 50px;
          padding: 0;
         }
    }
}
