.ms-checkout-express-skeleton {
    @include breakpoint(lg) {
        padding-top: 40px;
    }

    &__heading-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__heading-wrapper {
        margin: 15px 0;
    }

    &__customer-info {
        &-email {
            margin-top: 30px;
            width: 300px;
            height: 22px;
        }

        &-phone {
            margin-bottom: 30px !important;
            width: 160px;
            height: 22px;
        }
    }

    &__delivery {
        &-wrapper {
            margin-bottom: 0px !important;
            border-top: 15px solid #f8f8f8;
            margin: 0 -15px;
            padding: 15px;

            @include breakpoint(lg) {
                border-top: none;
                margin-bottom: 45px !important;
            }

            .ms-checkout-express__heading-wrapper {
                margin: 15px 0 15px;

                @include breakpoint(lg) {
                    margin: 30px 0 15px;
                }
            }
        }

        &-message {
            width: 70%;
            height: 17px;
        }
    }

    &__payment-wrapper {
        border-top: 15px solid #f8f8f8;
        margin: 0 -15px;
        padding: 15px;

        @include breakpoint(lg) {
            border-top: none;
            padding-top: 0 !important;
        }

        .ms-checkout-express__heading-wrapper {
            margin: 15px 0 15px;

            @include breakpoint(lg) {
                margin: 0px 0 15px;
            }
        }
    }

    &__side-section {
        @include breakpoint(lg) {
            padding-top: 30px;
        }

        padding-bottom: 80px;
    }
}