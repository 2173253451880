$form-heading-font-size: 37px;
.customer-service-form {
    #jcWrapper {
        .h2 {
            @include font-content(33px, 700, 1);
            @include breakpoint(md) {
                @include font-content($form-heading-font-size, 700, 1);
            }
            
        }
        h3 {
            @include font-content($font-size-xxxl, 700, 1);
        }
        .red {
            color: $color-text-secondary;
        }
        input {
            height: 40px;
            padding: 0 11px;
            text-indent: 0;
            width: 100%;
            color: $color-input-text;
            &#phone {
                max-width: 100%;
            }
        }
        .dtOrderTitle {
            font-size: 22px;
        }
        select {
            height: 40px;
            width: 100%;
        }
        textarea {
            height: 75px;
            width: 100%;
        }
        form {
            .selector {
                max-width: 100%;
            }
        }
    }
    p {
        font-size: 16px;
        font-family: $font-family-primary;
    }
    .bold {
        font-weight: 700;
    }
    #typeDeliveryData {
        a.dtButton {
            font-weight: 700;
            line-height: 1.2;
        }
    }
}
