.mfcl-carousel {
    &__container.carousel-loading {
        visibility: hidden;
    }
    &__button {
        //Overriding of MFCL buttons
        height: 60px !important;
        width: 60px !important;
        background-color: rgba(0, 0, 0, 0.5) !important;
        border: none !important;
        margin: 0px 3px;
        &:first-of-type {
            @include add-mfrm-icon($icon-chev-left);
        }
        &:last-of-type {
            @include add-mfrm-icon($icon-chev-right);
        }
        color: $color-white !important;
        font-size: 1.72rem !important;

        @include breakpoint(lg) {
            background-color:inherit !important;
            &:first-of-type, &:last-of-type {
                &::before {
                    opacity: 0;
                }
            }
        }

        &:focus {
            background-color: rgba(0, 0, 0, 0.5) !important;
            &::before {
                opacity: initial;
            }
        }

        &:disabled {
            opacity: 0 !important;
        }
    }

    @include breakpoint(lg) {
        &__container:hover &__button  {
            background-color: rgba(0, 0, 0, 0.5) !important;
            &:first-of-type, &:last-of-type  {
                // Display button when carousel-container is hovered on
                &::before {
                    opacity: initial;
                }
            }
        }
    }

    &__button.min-desktop {
        @include breakpoint(lg) {
            display: none;
        }
    }
}
