$franchise-card-bg-color: #f6f6f6;
$franchise-pill-color: #505050;

.no-scroll {
  position: fixed;
  top: 0;
  width: 100%;
  overflow-y: scroll !important;
  padding-right: 0 !important;
}

.ms-search__autoSuggest {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $color-bg-primary;
  border-radius: 0 0 5px 5px;
  z-index: 1040;

  @include breakpoint(md) {
    top: 55px;
  }

  @include breakpoint(lg) {
    width: 675px;
    left: -260px;
  }

  &__scroll {
    max-height: calc(var(--windowSize) - calc(var(--topBarSize) + 70px));
    overflow-y: auto;
    height: 80vh;

    @include breakpoint(lg) {
      height: 60vh;
    }
  }
}

.mfrm-search {
  & .ms-search__autoSuggest {
    position: absolute;
    top: 160px;
    left: 20px;
    right: 0;
    width: 90%;
    background-color: $color-bg-primary;
    box-shadow: 0 -7px 5px -5px $color-mercury;
    border-radius: 0 0 5px 5px;
    z-index: 1040;

    @include breakpoint(md) {
      top: 50px;
      left: 40px;
    }

    @include breakpoint(lg) {
      box-shadow: none;
      width: 675px;
      left: -12px;
    }

    &__scroll {
      height: 70vh;
      min-height: 300px;
      max-height: 610px;
      overflow-y: auto;
      @include breakpoint(md) {
       height: auto;
      }
      .search-result-stores {
        .search-result-stores__items {
          padding-left: 15px;
          @include breakpoint(md) {
            padding-left: 0;
          }
        }
      }
    }
    &.search-result-stores-product-suggestion {
      width: 90%;

      @include breakpoint(lg) {
        width: 1000px;
        left: -180px;
      }

      .autosuggest-card {
        .autosuggest-product-image-container {
          @include breakpoint(lg) {
            width: unset;
          }
        }
      }

      & .search-result-stores {
        width: 100%;
        text-align: left;

        .search-result-stores__items {
          padding-left: 15px;
        }

        @include breakpoint(md) {
          .search-result-stores__items {
            padding-left: 0;
          }
        }

        @include breakpoint(lg) {
          width: 60%;

          .search-result-stores__items {
            padding-left: 15px;
          }
        }


        &-header {
          align-items: start;
          justify-content: flex-start;
          padding: 0.5rem 0;
          width: inherit;
          @include font-content($font-size-xl, 700, 21px);

          @include breakpoint(lg) {
            padding-left: 15px;
          }
        }
      }
    }

    &.search-result-only-stores {
      width: 90%;

      @include breakpoint(lg) {
        width: 675px;
        left: -12px;
      }

      .search-result-stores {
        width: 100%;

        .search-result-stores__items {
          padding-left: 0;
        }

        .search-result-stores__items-title {
          text-align: left;
        }

        &-header {
          text-align: left;
        }
      }

    }
  }

  .ms-search-overlay.show {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 160px;
    left: 0;
    background-color: $modal-background-color;
    opacity: 0.4;
    right: 0;
    overflow: hidden;
    z-index: 10;
    pointer-events: none;

    @include breakpoint(lg) {
      top: 130px;
    }
  }
}

.search-modal__close-button {
  @include add-mfrm-icon($icon-close);
  position: absolute;
  right: 22px;
  top: 12px;
  padding: 6px;
  color: white;
  @include font-content($font-size-s, normal, 1);
  border: 0;
  background-color: #00000096;
  border-radius: 50%;

  @media (min-width: 1800px) {
    right: 35px;
  }

  @media (min-width: 2800px) {
    right: 45px;
  }

  @media (min-width: 3800px) {
    right: 65px;
  }
}

.search-result-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;

  @include breakpoint(lg) {
    flex-direction: row;
    padding: 0 10px;
    height: auto;
  }

  background-color: $color-white;
  text-decoration: none;
}

.autosuggest-link {
  text-decoration: none !important;
  text-align: left;
}

.search-result-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    color: $color-punch;
  }

  cursor: pointer;
  padding-bottom: 0.5rem;
  font-size: 16px;
}

.search-result__suggestions {
  display: flex;
  flex-direction: column;

  .suggestions-category-header {
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 0.5rem 0;
    text-align: left;
    @include font-content($font-size-xl, 700, 21px);
  }

  @include breakpoint(lg) {
    width: 100%;
  }
}

.search-result-container-keywords {
  display: flex;
  flex-direction: column;

  @include breakpoint(lg) {
    width: 40%;
  }
}

.search-result__recent-searches {
  display: flex;
  flex-direction: column;
}

.search-result__products {
  display: flex;
  flex-direction: column;

  .products-category-header {
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    width: inherit;
    @include font-content($font-size-xl, 700, 21px);
    @include breakpoint(lg) {
      margin-bottom: unset;
    }
  }

  @include breakpoint(lg) {
    width: 60%;
    padding: 0 10px 20px;
  }

  .card-link {
    text-decoration: none !important;
    color: $color-charcoal;
    height: auto;
    margin-bottom: 1rem;
     @include breakpoint(lg){
      height: 112px;
      margin-bottom: unset;
     }
    .autosuggest-card {
      display: flex;
      flex-direction: row;
      align-content: space-around;
      @include breakpoint(lg){
        margin-top: 1rem;
       }
      .autosuggest-product-image-container {
        align-self: center;
        justify-self: center;
        width: 40%;

        @include breakpoint(md) {
          width: unset;
        }

        @include breakpoint(lg) {
          width: 40%;
        }

        .autosuggest-product-image {
          border-radius: 5px;
          max-width: 100%;
          object-fit: cover;
          width: 115px;
          height: 77px;
        }
      }

      .autosuggest-product-info {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;
        text-align: left;
        margin-left: 1rem;
        width: 60%;

        .selectable-card__product-name {
          @include font-content($font-size-m, 400, 19px);

          &:hover {
            color: black;
            text-decoration: underline
          }
        }
      }

      .rec-product-price {
        @include font-content($font-size-l, 500, 20px);
        display: flex;
        align-items: center;
        margin: 1px 0 -3px 0;

        &:hover {
          text-decoration: none;
        }
      }

      .rec-current-price {
        margin-right: 8px;
        color: $color-punch;
      }

      .rec-standard-price,
      .rec-current-price {
        display: inline-block;
        position: relative;
      }

      .rec-standard-price .strike {
        position: absolute;
        border-bottom: 1px solid black;
        top: calc(50%);
        width: calc(100%);
        text-decoration: line-through;
      }

      sup {
        font-size: 10px;
      }

      .rec-standard-price.crossed-out {
        font-weight: 400;
        text-decoration: line-through !important;
        font-size: 14px;
        top: calc(50%);
        width: calc(100%);
        color: $color-midgray;
      }
    }
  }
}

.autosuggest-popup {
  padding: 7px 15px !important;
  .search-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    cursor: pointer;
    @include font-content($font-size-l, 400, 10px);

    .search {
      @include font-content($font-size-l, 700, 16px);
    }

    @include breakpoint(lg) {
      padding: 20px 10px;
    }
  }

  @include breakpoint(lg) {
    padding: 7px;
  }
}

.search-result-stores-product-suggestion {
  width: 100%;

  @include breakpoint(lg) {
    width: 1125px;
    left: -725px;
  }
}

.search-result-only-stores {
  width: 100%;

  @include breakpoint(lg) {
    width: 400px;
    left: 0;
  }

  .search-result-stores {
    width: 100%;
  }
}

.search-result-stores {
  width: 100%;

  @include breakpoint(lg) {
    width: 60%;
  }

  &-header {
    text-align: left;
    padding: 0.5rem 0;
    width: inherit;
    @include font-content($font-size-xl, 700, 21px);

    @include breakpoint(md) {
      margin-left: 0;
    }

  }

  &__list {
    border-bottom: 1px solid $color-mercury;
    margin-left: -15px;
    margin-right: -16px;

    &:last-child {
      border-bottom: none;
    }

    @include breakpoint(md) {
      margin-left: 0;
      margin-right: 0;
    }

    &.search-result-stores__list--franchise {
      background-color: $franchise-card-bg-color
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 15px 30px 15px;
    gap: 8px;
    background: $color-white;

    &.franchise {
      background-color: $franchise-card-bg-color;
    }

    @include breakpoint(lg) {
      width: 100%;
    }

    &-title {
      width: 100%;
      @include font-content($font-size-xl, 500, 18px);
      text-align: left;

      & .franchise-pill {
        color: white;
        background-color: $franchise-pill-color;
        padding: 5px 10px;
        border-radius: 12px;
        margin-top: -2px;
        font-size: 12px;
        float: right;
      }
    }

    &__time-distance {
      display: flex;
      align-items: center;

      &-time {
        @include font-content($font-size-m, 500, 17px);
        color: $color-green;

        &::after {
          content: '\007C';
          @include font-content($font-size-m, 400, 16px);
          color: $color-black;
          margin: 0 10px;
        }
      }

      &-distance {
        @include font-content($font-size-m, 400, 17px);
        color: $color-black;
      }
    }

    &-address {
      @include font-content($font-size-m, 400, 20px);
      text-align: left;

      p:last-of-type {
        display: inline-flex;
      }

      &-zipcode {
        margin-left: 5px;

        &-bold {
          @include font-content($font-size-m, 500, 20px);
          margin-left: 5px;
        }
      }
    }

    &-mystore-link {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-top: 10px
    }

    &-mystore {
      @include font-content($font-size-s, 700, 10px);
      color: $color-white;
      background: $color-green;
      border: 1px solid $color-green;
      border-radius: 3px;
      text-align: center;
      padding: 6px 20px 6px 20px;
    }

    &-link {
      @include font-content($font-size-m, 400, 18px);
      color: $color-text-secondary;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}