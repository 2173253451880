.hero-slide {
  color: $color-black;
  border: none;

  &:hover {
    text-decoration: none;
    color: $color-black;
  }

  &__title {
    position: absolute;
    top: 23px;
    left: 17px;
    @include font-content($font-size-xxl, 900);
    text-transform: uppercase;
  }

  &__details-container {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%,-50%);
    background: transparentize($color: $color-white, $amount: 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 55px;
    border-radius: 5px;
    width: 315px;

    @include breakpoint(lg) { 
      width: 400px;
    }
  }
  
  &__eyebrow {
    @include font-content($font-size-s, 700);
    margin-bottom: 10px;
    text-transform: uppercase;
    color: $color-text-secondary;
  }

  &__copy {
    @include font-content($font-size-xxxl, 700);
    margin-bottom: 10px;
  }

  &__subCopy {
    @include font-content($font-size-m, 400);
    text-align: center;
  }

  &__cta {
    @include btn(primary, large);
    margin-top: 20px;
  }

  &__img {
    min-height: 400px;
  }

  &__img img {
    height: auto;
  }

  &__seo-text {
    display: none;
    visibility: hidden;
    height: 0;
    width: 0;
  }
}

.hero-slide__two-col {
  flex-wrap: wrap;
  font-size: 16px;

  .hero-slide__img {
    min-height: auto;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &-content {
    margin: 30px 0 30px 15px;
    @include breakpoint(md) { 
      margin: 30px 0 30px 30px;
    }
  }

  &-h1 {
    @include font-content(32px, 700, 38px);
    color: $color-text-primary;
    margin-bottom: 15px;
  }

  // desktop styles
  @include breakpoint(lg) { 
    font-size: 18px;

    &-h1 {
      @include font-content(36px, 700, 40px);
    }

    &-content, .hero-slide__img {
      flex: 1
    }
  }

}

.hero-slide__two-col-alt {
  flex-wrap: wrap;
  font-size: $font-size-l;
  flex-direction: column-reverse;

  .hero-slide__img {
    min-height: auto;
    width: 100%;

    img {
      width: 100%;
    }
  }

  @include breakpoint(md) {
    flex-direction: row;
  }

  &-container {
    margin: 16% 8% 16% 8%;

    @include breakpoint(md) {
      margin: 0 8% 0 8%;
    }

    @include breakpoint(lg) { 
      margin: 0 30% 0 10%;
    }
  }

  // desktop styles
  @include breakpoint(sm) { 
    &-content, .hero-slide__img {
      flex: 1
    }
  }

}