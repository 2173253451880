//==============================================================================
// PRIMARY NAVIGATION GLOBAL STYLES
//==============================================================================
//==================  Nav Submenu Head - Back, CTA, Title  ==================//
.ms-nav {
    &__list__mobile__container__button {
        @extend %btn-muted;
        @include add-mfrm-icon($icon-chev-left-slim);
        display: flex;
        align-items: center;
        padding: 18px 10px 16px 5px;
        color: $color-link-secondary;
        font-weight: 400;
        font-size: $font-size-m;
        line-height: 1.14;

        @include breakpoint(lg) {
            padding: 18px 0 16px 15px;
        }

        &::before {
            font-size: $icon-size-s;
            margin-right: 10px;
        }
    }

    &__menu-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        margin: 0 5px;
        border-bottom: 1px solid $color-border-nav-item;

        @include breakpoint(lg) {
            margin: 0 15px;
        }
    }

    &__menu-title {
        @extend %btn-muted;
        @extend %link-muted;
        padding: 0 5px;
        font-family: $font-family-primary;
        font-weight: 700;
        font-size: $font-size-xl;
        line-height: 1.83;
        color: $color-text-primary;
        text-align: $ldirection;
        flex: 1;
        /* Overide bootstrap default button pointer */
        cursor: default !important;

        @include breakpoint(lg) {
            max-width: 240px;
            padding: 0 5px;
            flex: unset;
        }
    }

    a.ms-nav__menu-title {
        /* Overide bootstrap default cursor */
        cursor: pointer !important;
    }

    &__menu-clp-link {
        @extend %link-muted;
        @include btn(primary, default);
    }
}

//===============  End of Nav Submenu Head - Back, CTA, Title  ==============//

//==============================================================================
// PRIMARY NAVIGATION DESKTOP --> 1025px
//==============================================================================
@include breakpoint(lg) {

    //=============  Navigation Box Arrow  ==============//
    .ms-nav__list__item {

        &:hover,
        &:focus {
            >.ms-nav__list__item__button {
                @include box-arrow(bottom, 50%, 10px);

                &::after {
                    bottom: -1px;
                    transform: translateX(-50%);
                    right: 50%;
                }
            }
        }
    }

    //==========  End of Navigation Box Arrow  ==========//

    //==================  Nav Lvl - 0  ==================//
    .ms-nav {
        &__list {
            /* Override ul default margin */
            margin-left: -15px !important;
            white-space: nowrap;
        }

        &__list__item {
            position: relative;
            display: inline-block;
            padding: 0;
        }

        &__list__item:last-child {

            button,
            span,
            a {
                border-bottom: 0 !important;
            }
        }

        &__list__item__button,
        &__list__item__span,
        &__list__item__link {
            @include vfi();
            padding: 24px 15px;
            font-family: $font-family-primary;
            font-weight: bold;
            font-size: $font-size-l;
            color: $color-white;
            line-height: 1.375;
            letter-spacing: 0.25px;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            text-decoration: none;

            &:hover,
            &:focus,
            &:active {
                color: $color-white;
                text-decoration: none;
                outline-offset: -1px;
            }
        }

        &__list__item__span {
            cursor: default;
        }
    }

    //==============  End of Nav Lvl - 0  ===============//

    //==================  Nav Lvl - 1  ==================//
    .ms-nav {
        &__list__item>.ms-nav__list {
            position: absolute;
            top: 70px;
            left: 0;
            min-width: 375px;
            padding: 0;
            background-color: $color-white;
            border-radius: 0 0 5px 5px;
            z-index: 1040;
        }

        &__list__item>.ms-nav__list .ms-nav__list__item {
            display: block;
            width: 100%;
            padding: 0 15px;
        }

        &__list__item>.ms-nav__list .ms-nav {

            &__list__item__button,
            &__list__item__link,
            &__list__item__span {
                display: block;
                width: 100%;
                padding: 15px 5px 20px;
                margin: 5px 0;
                font-family: $font-family-primary;
                font-weight: 400;
                font-size: $font-size-l;
                line-height: 1;
                color: $color-link-primary;
                border-bottom: 1px solid $color-border-nav-item;
                text-align: left;

                &:hover,
                &:focus,
                &:active {
                    color: $color-link-hover;
                    /* override vfi */
                    outline: 0 !important;
                }

                &::after {
                    font-size: $icon-size-xs;
                    float: right;
                }
            }

            &__list__item__button,
            &__list__item__link {
                cursor: pointer;
            }

            &__list__item__button {
                @include add-mfrm-icon($icon-chev-right, after);

                /* Override Box-Arrow css properties */
                &::after {
                    transform: none;
                    position: static;
                    border: 0;
                    width: auto;
                }
            }
        }
    }

    //==============  End of Nav Lvl - 1  ===============//
}


//==============================================================================
// PRIMARY NAVIGATION DESKTOP --> 1025px to 1370px
//==============================================================================
@media only screen and (max-width: 1250px) and (min-width: $page-width-medium) {
    .ms-nav {
        &__list {
            &__item {
                display: none;
            }

            &__item:nth-child(-n + 4) {
                display: inline-block;
            }
        }
    }
}

@media only screen and (max-width: 1370px) and (min-width: $page-width-medium) {
    .ms-nav {
        &__list {
            &__item:last-child {
                display: none;
            }
        }
    }
}

//==============================================================================
// PRIMARY NAVIGATION MOBILE MENU
//==============================================================================

$mobile-hamburger-menu-item-margin: 10px;
$list-item-padding-tb: 17px;
$list-item-padding-lr: 25px;

@include breakpoint(md) {
    $list-item-padding-lr: 45px;
}

//==================  NAVIGATION MENU CONTAINER  ==================//

.ms-header {
    &__mobile-hamburger-menu {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $color-white;
        z-index: 1040;

        &--hidden {
            display: none;
        }

        &--show {
            display: block;
        }

        @include breakpoint(lg) {
            display: none;
        }

        a {
            margin: 0;
        }

        &-body {
            background-color: $color-white;
            height: 100%;
            /* Store-Selector, Topbar and Primary Header height */
            min-height: calc(var(--windowSize) - calc(var(--initialTopbarSize) + 115px));
            overflow-y: auto;
        }

        &__backdrop {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100vh;
            width: 100%;
            background-color: $color-white;
            z-index: -1;
        }
    }
}

//===============  END OF NAVIGATION MENU CONTAINER  ==============//

//===================  ACCOUNT LINKS  ====================//
.ms-header__mobile-hamburger-menu-links {
    padding: $list-item-padding-tb $list-item-padding-lr - 10 $list-item-padding-tb;
    border-bottom: 1px solid $color-border-nav-item;

    h2 {
        @include font-content($font-size-xl, 500, 0.89);
        margin-bottom: 10px;
        padding: 0 ($list-item-padding-lr - 15);
        color: $color-text-primary;
    }

    @include breakpoint(md) {
        padding: $list-item-padding-tb + 15 $list-item-padding-lr + 10;
    }

    a {
        position: static;
        width: unset;
        height: unset;
        display: inline-block;
        padding: 0 10px;
        font-family: $font-family-primary;
        font-weight: normal;
        font-size: $font-size-m;
        color: $color-link-secondary;
        line-height: 1;
        border-right: 1px solid $color-border-nav-item-separator;

        &::before,
        &::after {
            display: none;
        }

        &:last-child {
            border-right: 0;
        }

        .ms-header__signin-button-text {
            display: inline;
            text-transform: uppercase;
        }
    }
}

//================  END OF ACCOUNT LINKS  ================//

//==================  NAVIGATION LINKS  ==================//
.ms-header__mobile-hamburger-menu-body .ms-nav {
    &__list {
        padding: 0 15px;

        @include breakpoint(md) {
            padding: 0 35px;
        }
    }

    &__list__item:first-child {

        .ms-nav__list__item__span,
        .ms-nav__list__item__link,
        .ms-nav__list__item__button {
            border-top: 0;
        }
    }

    &__list__item__button {
        @include add-mfrm-icon($icon-chev-right, after);
    }

    &__list__item__button,
    &__list__item__link,
    &__list__item__span {
        @extend %btn-muted;
        @extend %link-muted;
        display: block;
        width: 100%;
        padding: 0;
        padding: 2 + $list-item-padding-tb $list-item-padding-lr - 15;
        border-top: 1px solid $color-border-nav-item;
        color: $color-text-primary;
        font-family: $font-family-primary;
        font-weight: 500;
        font-size: $font-size-xl;
        line-height: 1;
        text-align: left;

        &::after {
            margin-top: 5px;
            float: right;
            font-size: $icon-size-xs + 1;
        }
    }

    //==================  Nav Lvl - 1  ==================//
    &__list__item .ms-nav__list {
        padding: 0;

        &__item {
            padding-right: 0;

            &:first-of-type {

                .ms-nav__list__item__span,
                .ms-nav__list__item__link,
                .ms-nav__list__item__button {
                    border-top: 0;
                }
            }
        }
    }

    //==============  End of Nav Lvl - 1  ===============//

    //==================  Nav Lvl - 2  ==================//
    &__list__item .ms-nav__list .ms-nav {
        &__list {
            padding: 0;
        }

        &__list__item__button,
        &__list__item__link,
        &__list__item__span {
            font-weight: 400;
        }
    }

    //==============  End of Nav Lvl - 2  ===============//
}

//==============  END OF NAVIGATION LINKS  ==============//

//===================  TOPBAR LINKS  ====================//
.ms-header__topbar__links {

    &--mobile,
    &--tab {
        text-align: left;

        .ms-header__mobile-hamburger-menu-body & {
            margin: 0 15px;
            border-top: 1px solid $color-border-nav-item;

            @include breakpoint(md) {
                text-align: center;
                margin: 0 35px;
            }
        }

        li {
            .ms-header__mobile-hamburger-menu-body & {
                padding: $list-item-padding-tb - 2 $list-item-padding-lr - 15;

                &:first-child {
                    padding-top: 13 + $list-item-padding-tb;
                }

                @include breakpoint(md) {
                    padding: $list-item-padding-tb - 2 $list-item-padding-lr + 20;
                }
            }
        }

        a {
            font-family: $font-family-primary;
            font-size: $font-size-m - 0.07;
            font-weight: 400;
            color: $color-text-primary;
            line-height: 1;

            .ms-header__mobile-hamburger-menu-body & {
                font-size: $font-size-l;
                text-decoration: none !important;
            }
        }

        .link-callus,
        .link-chat {
            &::before {
                margin-right: $mobile-hamburger-menu-item-margin;
            }
        }

        .link-chat {
            @include add-mfrm-icon($icon-chat);
        }

        .link-callus {
            @include add-mfrm-icon($icon-phone);
        }
    }
}

//================  END OF TOPBAR LINKS  ================//

//================   SEO CRAWLER  ================//
.nav-menu-link-items {
    display: none;
}

//================================================//