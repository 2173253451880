//==============================================================================
// PROMO BANNER
//==============================================================================
$banner-font-size: 12px;
$banner-font-weight: 500;
$banner-link-font-weight: 600;
$banner-spacing: 15px;
$banner-line-height: 22px;
$banner-text-color: $color-text-primary;
$box-shadow-color-charcoal: #2D2926;
$banner-countdown-text-color: $color-text-secondary;
$rotate-banner-spacing: 15px;
$rotate-banner-prev-next-margin: -15px;
$rotate-banner-carousel-desktop-width: 60%;
$rotate-banner-carousel-mobile-width: 80%;
$rotate-banner-carousel-inner-width: 90%;

@media only screen and (max-width: 576px) {
  .ms-promo-banner {
    height: 75px;
    display: flex;
  }
}.rotate-promo-banner-v2 {
  @media only screen and (max-width: 576px) {
    .ms-promo-banner {
       height: 40px;
    }
  }
  & .ms-promo-banner {
    margin-bottom: 0px;
    background-color: $color-light-blue;
    min-height: 40px;
    padding: 9px 0;
    line-height: 16px;

    &__content * {
      display: inline;
      font-size: $banner-font-size;
      font-weight: $banner-font-weight;
      line-height: 22px;
      sup{
        position: relative;
        top: -3px;
      }
    }

    &__link {
      text-decoration: none;
      padding: 0 5px;
      border: none;
      &:hover{
        color: unset;
      }
      &:focus {
        box-shadow: 0px 0px 0px 2px $box-shadow-color-charcoal;
      }

      div {
        font-weight: $banner-link-font-weight;
      }
    }
    .ms-promo-banner__carousel.msc-carousel{
      .msc-carousel__control__next{
        margin-right: 0 ;
        &__icon{
          margin-right: 0 ;
        }
      }
      .msc-carousel__control__prev{
        margin-left: 0 ;
        &__icon{
          margin-left: 0 ;
        }
      }
    }
  }

}

.ms-promo-banner {
  font-weight: 700;
  color: $banner-text-color;
  line-height: $banner-line-height;
  padding: $banner-spacing;

  @include breakpoint(md) {
    padding: $banner-spacing 0;
  }

  &__content * {
    display: inline;
  }

  &__link {
    div {
      padding-left: 5px;
      text-decoration: underline;

    }

    &:focus {
      box-shadow: 0px 0px 0px 2px $box-shadow-color-charcoal;
      color: inherit;
    }
  }

  &__close-button {
    @include add-mfrm-icon($icon-close);
    border: 0;
    background: none;
    padding-right: $banner-spacing;
    position: absolute;
    top: -5px;
    right: 0;

    @include breakpoint(md) {
      top: 15px;
      right: 10px;
    }

    &__label {
      display: none;
    }
  }

  .countdown {
    color: $banner-countdown-text-color;
    display: inline-block;

    span {
      &:first-child {
        padding-left: 3px;
      }
    }

    .second {
      display: inline-block;
    }
  }

  .msc-carousel {
    margin: 0 auto;
    width: 100%;

    @include breakpoint(md) {
      width: $rotate-banner-carousel-mobile-width;
    }

    @include breakpoint(lg) {
      width: $rotate-banner-carousel-desktop-width;
    }

    &__anchor {
      &:focus {
        color: inherit;

        [class*="ms-promo-banner__rich-text"] {
          box-shadow: 0px 0px 0px 2px $box-shadow-color-charcoal;
        }
      }
    }

    // hide tooltip arrow on hover
    .msc-tooltip {
      display: none !important;
    }

    &__inner {
      color: $banner-text-color;
      width: $rotate-banner-carousel-inner-width;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__control {

      &__next,
      &__prev {
        @include breakpoint(md) {
          top: -12px;
        }
      }

      &__next {
        margin-right: $rotate-banner-prev-next-margin;
      }

      &__prev {
        margin-left: $rotate-banner-prev-next-margin;
      }
    }

    &__indicators {
      display: none;
    }

    .msc-tooltip {
      /* override inline style */
      display: none !important;
    }
  }
}