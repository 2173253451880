$color-bg-error: $color-bg-error-page;
$color-link: $color-bg-quaternary;
$desktop-font-size: 64px;
$mobile-font-size: 54px;
$layout-gap: 30px;
$desktop-content-block-width: 395px;
$mobile-content-block-width: 354px;
.error-container-404 {
    background: $color-bg-error;
    display: flex;
    justify-content: center;
    position: relative;
    margin: -15px -15px 0;
    @include breakpoint(md) {
        margin: 0 -30px;
    }
    @include breakpoint(lg) {
        margin: 0;
        background: none;
    }
    .row {
        display: flex;
        justify-content: center;
        .col-12:first-child {
            position: absolute;
        }
    }
    .ms-content-block.error-background {
        display: none;
        @include breakpoint(lg) {
            display: block;
        }
        margin: 0;
        .ms-content-block__details {
            display: none;
        }
        img {
            max-width: 100%;
        }
    }
    .ms-content-block {
        background: $color-white;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        margin: 40px 15px;
        @include breakpoint(md) {
            flex-direction: row-reverse;
            margin: $layout-gap;
        }
        @include breakpoint(lg) {
            margin: 60px 0;
        }

        &__details {
            padding: $layout-gap;
            @include breakpoint(md) {
                padding: $layout-gap $layout-gap 11px;
                width: $mobile-content-block-width;
            }
            @include breakpoint(lg) {
                padding: 60px 60px 0;
                width: $desktop-content-block-width;
            }
            .ms-content-block__details {
                padding: 0;
                width: auto;
            }
        }
        &__title {
            @include font-content($mobile-font-size, 700, 0.925);
            @include breakpoint(lg) {
                @include font-content($desktop-font-size, 700, 0.9375);
            }
        }
        img {
            border-radius: 5px 5px 0 0;
            width: 100%;
            @include breakpoint(md) {
                border-radius: 0 5px 5px 0;
            }
        }

        &__cta {
            display: flex;
            justify-content: center;
            padding-top: $layout-gap;
            @include breakpoint(md) {
                padding: $layout-gap 0 38px;
            }
            a {
                @include btn(primary, large);
                color: $color-white;
                width: 100%;
            }
        }
    }
    .error {
        &_content {
            @include font-content($font-size-xl, 400, 1.33);
            padding: 15px 0;
            @include breakpoint(lg) {
                @include font-content(($font-size-xxl - 2), 400, 1.36);
                padding: 15px 0 $layout-gap 0;
            }
        }
        &_code {
            @include font-content($font-size-l, 500, 1.375);
            padding: 0 0 15px 0;
        }
        &_pages {
            padding: 10px 0 0;
            li a {
                @include font-content($font-size-l, 500, 1.375);
                color: $color-link;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

}

@media screen and (max-width: $breakpoint-md){
    .error-container-404 {
        margin: 0 0;
    }
}

@media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .error-container-404 {
        margin: 0 0;
    }

    .error-container-404 img {
        height: 100%;
    }
}
