.ms-buybox{
    //==============================================================================
    // Try in Store - related styles for the buybox 
    //==============================================================================
    $try-in-store-width-mobile: 345px;
    $try-in-store-width-tab: 708px;
    $try-in-store-width-desktop: 483px;
    &__try-in-store {
      @include add-mfrm-icon($icon-location);
      @include font-content($font-size-m, normal, 1.29);
      @include vfi($color-black);
      @extend %btn-muted;
      max-width: $try-in-store-width-mobile;
      margin-top: 15px;
      margin-left: auto;
      padding: 10px 15px;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $color-text-primary;
      text-align: left;
      border: 1px solid $color-border-store-select;
      border-radius: 100px;
      outline-offset: 0;
      cursor: pointer;
      &::before {
        margin-right: 5px;
        font-size: ($icon-size-m + 4);
      }
      @media (min-width: $breakpoint-xs) {
        min-width: $try-in-store-width-mobile;
      }
      @include breakpoint(md) {
        max-width: $try-in-store-width-tab;
      }
      @include breakpoint(xl) {
        max-width: $try-in-store-width-desktop;
      }
      /* toggle states */
      &--unavailable {
        &::before {
          color: $color-text-secondary;
        }
      }
      &--online {
        padding: 12px 15px;
        text-align: center;
        justify-content: center;
        font-weight: 500;
        cursor: default;
        pointer-events: none;
        touch-action: none;
        &::before {
          display: none;
        }
      }
    }
    &__try-in-store-text span {
      font-weight: 700;
    }
    //==============================================================================
    // Try-In Store - V2
    //==============================================================================
    &__try-in-store--v2 {
      position: relative;
      top: 0;
      left:0;
      &__available {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 32px;
      }
      &-marker{
        @include add-mfrm-icon($icon-location);
        @include font-content($font-size-xxl, normal, 1.29);
        @include vfi($color-black);
      }
      &_text-left {
        padding: 0;
        margin-left: 14px;
        @include font-content($font-size-m, 400, 1.43);
        color: $color-black;
        @include breakpoint(md) {
          @include font-content($font-size-l, 400, 1.37);
        }
      }
      &_link {
        @include add-mfrm-icon($icon-chev-down, after);
        @include font-content($font-size-m, 400, 1.43);
        @include vfi($color-black);
        @extend %btn-muted;
        padding: 0;
        color: $color-text-primary;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 8px;
        &::after {
          display: inline-block;
          margin-left: 10px;
          font-size: $icon-size-xs - 6;
        }
        &:hover {
          color: $color-text-primary;
          @include font-content($font-size-l, 500, 1.37);
        }
        @include breakpoint(md) {
          @include font-content($font-size-l, 400, 1.37);
        }
      }
      /* toggle states */
      &__unavailable {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 32px;
        .ms-buybox__try-in-store--v2-marker{
          color: $color-bombay;
        }
      }
      &__open {
        @include font-content($font-size-m, normal, 1.29);
        @include vfi($color-black);
        @extend %btn-muted;
        width: 100%;
        position: absolute;
        top: -8px;
        left:-11px;
        z-index: 100;
        background-color: $color-white;
        padding-top: 16px;
        color: $color-text-primary;
        text-align: left;
        border-radius: 16px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.47);
        @include breakpoint(md) {
          width: 50%;
        }
        @include breakpoint(lg) {
          width: 104%;
          left: -15px;
        }
        .ms-buybox__try-in-store--v2__available {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 32px;
          margin-left: 14px;
        }
        .ms-buybox__try-in-store--v2_link {
          @include add-mfrm-icon($icon-chev-up, after);
        }
        &-store-details {
          padding: 0 34px;
          @include font-content($font-size-l, 400, 22px);
          color: $color-text-primary;
          .address-lines{
            margin-bottom: 16px;
            padding-left: 20px;
          }
          .store-contact-text {
            @include font-content($font-size-l, 400, 24px);
            margin-top: 16px;
            text-decoration: underline;
            padding-left: 20px;
          }
          .open-until-text {
            @include font-content($font-size-l, 500, 24px);
            margin-top: 16px;
            padding-left: 20px;
          }
          &-CTA {
            display: flex;
            justify-content: space-between;
            margin: 16px -34px 0 -34px;
            a {
              @include btn(primary, large);
              display: block;
              padding: 16px;
              border: none;
              cursor: pointer;
              width: 49.5%;
            }
            .btn-store-details {
              border-radius: 0px 0px 0px 16px;
            }
            .btn-change-store {
              border-radius: 0px 0 16px 0;
            }
          }
        }
      }
    }
  }