.textBoxStyle {
    @extend %field-control-input;
    @extend %float-label;
    margin-left: 0;
    width: 100%;
    &--error {
        @include input-field-error($color-error);
    }
}
.slds-form-element__label {
    @extend %field-control-label;
}
.sidebarHeader {
    h2 {
        @include font-content($font-size-m, 700, 2.64);
    }
}

//Temp Styling for Chat box

.embeddedService {
    &Invitation {
        max-width: 180px;
        padding: 15px;
        margin-bottom: 30px;
        margin-left: auto;
        background-color: $color-bg-primary;
        border: 1px solid $color-border-chatbot-primary;
        border-radius: 10px;
        z-index: 1;
        @if (position == "fixed") {
            margin-right: 30px;
        }
    }
    &InvitationHeader {
        position: relative;
        display: flex;
        flex-flow: column wrap;
        img {
            width: 64px;
            height: 64px;
            margin: auto;
        }
    }
    &TitleText {
        @include font-content($font-size-s, bold, 1);
        display: inline-block;
        text-align: center;
        margin-bottom: 5px;
    }
    &CloseIcon {
        @extend %btn-muted;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        border-radius: 100px;
        font-size: ($icon-size-m + 6);
        line-height: 0.6;
    }
    &InvitationBody {
        @include font-content($font-size-xs, normal, 1.4);
        margin-bottom: 10px;
        text-align: center;
    }
    &InvitationFooter {
        display: flex;
        flex-flow: column-reverse wrap;
        justify-content: space-between;
        margin-top: 15px;
    }
    &ActionButton {
        @include btn(primary, default);
        min-width: 120px;
        &:first-of-type {
            @include btn(secondary, default);
            margin-top: 10px;
        }
    }
    &SidebarState {
        .cMFRMPreChatForm {
            .bg-secondary {
                background-color: $color-white !important;
            }
        }
    }
}

//--------------------------------------------
//SCSS Override	Chat-bot close-minimize icons  for ios
//--------------------------------------------
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) and (-webkit-touch-callout: none) {
        embeddedservice-chat-header lightning-icon,
        embeddedservice-chat-header svg.slds-icon.slds-icon-text-default.slds-icon_x-small {
            width: 20px;
            height: 20px;
            position: relative;
            right: 5px;
        }
    }
}
