$financing-modal-text-color: $color-text-secondary;
// Removing scrollbar for the time being - UX may bring back in the future
// $financing-modal-scrollbar-bg-color: $color-bg-scrollbar;
// $financing-modal-scrollbar-track-border-color: $color-border-scrollbar-track;
// $financing-modal-scrollbar-track-bg-color: $color-bg-scrollbar-track;
$financing-modal-payment-color: $color-text-tertiary;
$financing-modal-header-font-size: 2.286rem;

.mfrm-financing-modal {
  @include vfi($color-black);

  &.msc-modal__dialog {
    width: 100%;

    @include breakpoint(md) {
      width: 768px;
    }
  }

  .msc-modal {
    &__header {
      position: sticky;
      top: 0;
      left: 0;
      background-color: $color-white;
      border-radius: 5px;
      z-index: 10;

      .msc-modal__close-button {
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        @include add-mfrm-icon($icon-close);
        border: 0.5px solid $color-modal-border-close-button;
        border-radius: 100%;

        &:before {
          font-size: $font-size-m;
        }

        @include breakpoint(md) {
          top: 30px;
          right: 30px;
        }
      }
    }

    &__body {
      padding: 0 15px;

      @include breakpoint(md) {
        padding: 0 30px;
      }
    }
  }

  .mfrm-financing-modal__button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    position: sticky;
    bottom: 0;
    width: 100%;
    align-self: flex-end;
    justify-content: space-evenly;

    @include breakpoint(md) {
      max-width: 900px;
      flex-direction: row;
      justify-items: center;
      justify-content: space-evenly;
    }
  }

  .mfrm-financing-modal__button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;

    @include breakpoint(md) {
      &:last-child {
        margin-left: 15px;
      }
    }

    .label-text {
      padding-top: 5px;
      text-align: center;
      max-width: 100%;
      word-break: normal;
      @include font-content($font-size-m, normal, 20px);
    }
  }


  &__content {
    @include breakpoint(md) {
      //TODO Scrollbar
      @include hide-scrollbar-webkit;
      scrollbar-width: none;

      // Work done for a potential future A/B test on scroll bar showing

      // &::-webkit-scrollbar,
      // &::-webkit-scrollbar-button {
      //   width: 4px;
      // }

      // &::-webkit-scrollbar-track {
      //   background: $financing-modal-scrollbar-track-bg-color;
      //   border: thin solid $financing-modal-scrollbar-track-border-color;
      //   border-radius: 10px;
      // }

      // &::-webkit-scrollbar-thumb {
      //   background: $financing-modal-scrollbar-bg-color;
      //   border-radius: 10px;
      // }
    }
  }

  &__synchrony {
    margin: 0 auto;
    width: 170px;
    padding-bottom: 50px;

    @include breakpoint(md) {
      padding-top: 20px;
    }
  }

  &__cashback-banner-wrapper {
    display: flex;
    height: auto;
    min-height: 250px;
    background: #F7F7F7;
  }

  &__red-bar {
    height: inherit;
    background: $color-punch;
    width: 42px;
  }

  &__cashback-banner-content {
    display: flex;
    padding: 25px 20px;
    text-align: left;

    @include breakpoint(md) {
      padding: 35px;
    }
  }

  &__cashback-banner-text {
    width: 50%;

    @include breakpoint(md) {
      width: 60%;
      align-self: center;
    }

    .cashback-heading {
      color: $color-punch;
      text-transform: uppercase;
      white-space: nowrap;
      @include font-content(16px, 600, 20px);

      @include breakpoint(md) {
        @include font-content(28px, 600, 25px);
      }

    }

    .cashback-subheading {
      white-space: nowrap;
      @include font-content(14px, 500, 16px);

      @include breakpoint(md) {
        margin-top: 8px;
        @include font-content(20px, 500, 18px);
      }

      sup {
        font-size: 10px;
        font-weight: bold;

        @include breakpoint(md) {
          font-size: 12px;
        }
      }
    }

    .cashback-text {
      margin-top: 15px;
      @include font-content(12px, normal, 18px);

      @include breakpoint(md) {
        @include font-content(14px, normal, 16x);
      }
    }
  }

  &__cashback-banner-image {
    position: relative;
    width: 50%;

    @include breakpoint(md) {
      width: 40%;
    }

    & .banner-image {
      position: absolute;
      left: 30px;
      object-fit: contain;
      width: 100%;
      height: 100%;

      @include breakpoint(md) {
        top: 8px;
      }

    }
  }

  &__header-wrapper {
    padding: 0 30px;
    text-align: center;

    @include breakpoint(md) {
      padding-bottom: 30px;
    }

    .h2 {
      @include vfi-tab($color-black);
      @include font-content($financing-modal-header-font-size, 700, 1.19);
    }
  }

  &__logo-container {
    display: flex;
    justify-content: center;
    margin: 30px 0;

    @include breakpoint(md) {
      margin: 60px 0 30px;
    }
  }

  &__logo {
    width: 140px;

    @include breakpoint(md) {
      width: 224px;
      height: 80px;
    }

    img {
      width: auto;
      height: auto;
    }
  }

  &__subheader {
    @include vfi-tab($color-black);
    @include font-content($font-size-l, 400, 1.25);
    margin-top: 15px;
  }

  &__details-container {
    border: 1px solid $color-border-financing-modal-secondary;
    border-radius: 10px;
    margin: 30px 0px;
    padding: 15px;

    @include breakpoint(md) {
      margin: 30px 0px;
      padding: 30px;
    }
  }

  .h3 {
    color: $financing-modal-text-color;
    @include font-content($font-size-xxl, 700, 1.35);
    margin-bottom: 5px;
    display: inline-block;
  }

  &__per-month {
    @include font-content($font-size-xl, 400, 1.33);
  }

  &__details-top {
    @include vfi-tab($color-black);
    display: flex;
    justify-content: space-between;
    position: relative;

    .sr-only {
      @include vfi-sronly();
      width: auto !important;
      height: 100% !important;
    }
  }

  &__dates-container {
    @include vfi-tab($color-black);
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: -15px;
    margin-top: 15px;

    @include breakpoint(md) {
      right: -30px;
      margin-top: 0;
    }
  }

  &__valid-dates {
    @include font-content($font-size-s, 700);
    color: $color-white;
    background: $financing-modal-text-color;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    margin: 0 0 30px 0;
    text-transform: uppercase;
  }

  &__payments {
    @include vfi-tab($color-black);
    color: $color-charcoal;
    font-size: $font-size-l;
    position: relative;

    .campaign-monthly-cost,
    .campaign-months {
      @include font-content($font-size-xxl, 700, 1.35);
    }

    .sr-only {
      @include vfi-sronly();
      width: auto !important;
      height: 100% !important;
    }
  }

  &__offer-details {
    width: 100%;
    margin-top: 15px;
    display: block;

    @include breakpoint(md) {
      margin-bottom: 10px;
      align-items: flex-end;
      flex-direction: row;
    }
  }

  &__offer-description {
    text-align: left;

    p {
      @include font-content($font-size-s, 400, 1.35);
    }
  }

  &__detail {
    flex-grow: 1;
    margin-bottom: 15px;
    position: relative;

    @include breakpoint(md) {
      margin-bottom: 0;
      display: inline-block;
      margin-right: 16px;
    }

    .sr-only {
      @include vfi-sronly();
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__detail-heading {
    @include font-content($font-size-l, 400, 1.25);
    display: block;
  }

  &__sub-text {
    @include font-content(22px, 700, 1.25);
    display: block;
    margin: 5px 0px;
  }

  .h4 {
    margin-bottom: 15px;
    padding: 0px 35px;
    @include font-content(1.571rem, 700, 1.18);
  }

  &__offer-container {
    @include vfi-tab($color-black);
    text-align: center;

    @include breakpoint(md) {
      margin: 0px 60px;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    background: $color-white;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px;
  }

  &__button {
    @include btn(primary, large);
    line-height: 20px;
    @include no-decoration;

    @include breakpoint(md) {
      width: 100%;
    }
  }

  &__action-container {
    margin: 30px 0;
  }

  &__action-header {
    @include vfi-tab($color-black);
    text-align: center;
    @include font-content($font-size-xl, 700, 1.22);
    margin-bottom: 30px;
  }

  &__action-cards {
    display: flex;
    align-items: center;
    overflow: scroll;

    @include breakpoint(md) {
      justify-content: center;
      overflow: hidden;
    }
  }

  &__disclaimer {
    @include font-content($font-size-s, 400, 1.35);
    margin-bottom: 60px;
  }
}