//==============================================================================
// HANGTAG MODAL-DEFAULT
//==============================================================================
.hangtag-modal {
  width: 100% !important;
  .msc-modal {
    &__content {
      border-radius: 10px;
    }
    &__progressive-header {
      z-index: 100;
      background-color: $color-bg-primary;
      width: 100%;
      height: 80px;
      border-radius: 10px 10px 0 0;
      img {
        margin: 15px auto;
        height: 50px;
      }
    }
    &__close-button {
      width: 30px;
      height: 30px;
      font-size: $icon-size-xs;
      font-weight: 600;
      border: 1px solid $color-modal-border-close-button;
      border-radius: 50%;
    }
    &__iframe {
      width: 100%;
      height: 541px;
      margin-top: -51px;
      @include breakpoint(md) {
        height: 571px;
      }
    }
    &__body {
      padding: 0;
    }
  }
  @include breakpoint(md) {
    width: auto !important ;
    .msc-modal {
      &__iframe {
        width: 449px;
        height: 682px;
      }
    }
  }
}
