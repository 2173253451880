.msc-pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &__prev {
        padding-left: 4px;
        display: inline-block;
    }
    &__next {
        padding-right: 4px;
        display: inline-block;
    }
    .msc-page {
        &-item {
            padding-right: 10px;
            &.previous {
                margin-right: 0;
            }
            &.next {
                margin-left: 0;
            }
            &.disabled .msc-page-link {
                cursor: auto;
                pointer-events: none;
            }
            &.active {
                color: $color-link-hover;
            }
        }
        &-link {
            display: block;
            padding: 8px 4px;
            line-height: 1.25;
            color: $color-link-primary;
            &:before {
                //oob icon
                // @include msv-icon();
                content: "";
            }
        }
    }
    .ms-search-result {
        &__pagination-left {
            @include add-mfrm-icon($icon-chev-left, before);
        }
        &__pagination-right {
            @include add-mfrm-icon($icon-chev-right, after);
        }
    }
    .prev-text,
    .next-text {
        padding: 0 4px;
        display: none;
    }
}
