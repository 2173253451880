//==============================================================================
// Product List 
//==============================================================================
.msc-cart-line-skeleton {
    &__content {
        width: 100%;
        margin-top: -7px;
    }

    &__qty {
        height: 44px;
        width: 105px;
        display: block !important;
        border-radius: 25px !important;
    }

    &__chips {
        width: 70px;
        height: 20px;
        margin-bottom: 15px !important;
        border-radius: 50px !important;
    }

    &__checkout-wrapper {
        border: none !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        @include breakpoint(lg) {
            padding-bottom: 15px !important;
        }
    }

    &__checkout-images {
        min-height: 90px !important;
        min-width: 105px !important;
        height: 90px !important;
        width: 105px !important;

        @include breakpoint(lg) {
            min-height: 135px !important;
            min-width: 160px !important;
            height: 135px !important;
            width: 160px !important;
        }
    }

    &__product {
        &-description {
            width: 100%;
        }

        &-additional-info-desktop {
            display: none;

            @include breakpoint(lg) {
                display: flex;
                justify-content: space-between;
                margin: 0 0 10px 15px;
            }
        }

        &-additional-info-mobile {
            display: flex;
            margin-bottom: 15px;
            justify-content: space-between;

            @include breakpoint(lg) {
                display: none;
            }
        }

        &-title {
            height: 43px;

            @include breakpoint(lg) {
                height: 45px;
            }
        }

        &-price {
            align-items: flex-end;
            display: none;

            @include breakpoint(lg) {
                display: flex;
            }
        }
    }

    &__quantity {
        &-container-desktop {
            display: none;

            @include breakpoint(lg) {
                position: absolute;
                align-items: center;
                display: flex;
                right: 0;
                bottom: 0;
            }
        }

        &-container-mobile {
            position: relative;
            display: flex;
            align-items: start;

            @include breakpoint(lg) {
                display: none;
            }
        }

        &-icon {
            height: 20px;
            width: 16px;
            border-radius: 100% !important;
        }

        &-text {
            height: 20px;
            width: 10px;
            margin: 0 10px;
        }
    }
    &__qty-price-mobile {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        @include breakpoint(lg) {
            display: none;
        }
    }
    &__cart-line-item {
        border-bottom: 1px solid $color-border-cart-line-Item;

        &:first-child {
            border-top: 1px solid $color-border-cart-line-Item;
        }
    }
}

//==============================================================================
// Order Summary
//==============================================================================
.msc-order-view-summary-skeleton {
    &__item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
    }

    &__total {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
    }
}

//==============================================================================
// Stepper 
//==============================================================================
.mfrm-stepper-skeleton {
    &__container {
        &-desktop {
            display: none !important;

            @include breakpoint(lg) {
                display: block !important;
                height: 50px;
                width: 345px;
                margin: 30px auto 30px auto !important;
            }

            &.checkout {
                margin-top: 0 !important;
            }
        }

        &-mobile {
            display: block !important;
            height: 50px;
            width: 345px;
            margin: 25px auto 30px auto !important;

            &.checkout {
                margin-top: 15px !important;
            }

            @include breakpoint(lg) {
                display: none !important;
            }

            //hide this section when cart is empty
            .ms-cart-empty & {
                display: none !important;
            }
        }
    }
}

//==============================================================================
// Cart Button
//==============================================================================
.msc-cart {
    &__skeleton-btn {
        &-desktop {
            display: none !important;
            border-radius: 25px !important;

            &.applePay {
                margin-right: 15px;
            }

            &.checkout {
                margin-top: 30px;
                margin-left: auto;
                margin-right: auto;
            }

            @include breakpoint(lg) {
                display: block !important;
            }
        }

        &-mobile {
            display: block !important;
            border-radius: 25px !important;

            &.applePay {
                margin-right: 15px;
            }

            @include breakpoint(lg) {
                display: none !important;
            }
        }
    }
}

//==============================================================================
// Change zipcode section
//==============================================================================
.msc-cart {
    &__skeleton-delivery-message {

        //hide this section when cart is empty
        .ms-cart-empty & {
            display: none !important;
        }
    }
}