// ------------------ AMOUNT TO PAY ------------------ //
.mfrm-wmap {
    &__amount-pay {
        margin-top: 40px;
        margin-bottom: 30px;
        &__radio {
            display: flex;
            flex-flow: column wrap;
            align-content: center;
            @include breakpoint(lg) {
                flex-flow: row wrap;
            }
            &__select {
                position: relative;
                width: 100%;
                min-height: 134px;
                margin-bottom: 30px;
                padding: $wmap-radio-spacing;
                background-color: $wmap-radio-bg-color;
                border-width: 2px;
                border-style: solid;
                border-color: transparent;
                border-radius: 5px;
                transition: 0.2s ease-in-out border-color;
                @media (min-width: $breakpoint-xs) {
                    width: 290px;
                }
                @include breakpoint(lg) {
                    margin-right: 30px;
                    margin-bottom: 0;
                }
                @include breakpoint(xl) {
                    width: 360px;
                }
                &__label {
                    @include font-content();
                    position: relative;
                    top: -1px;
                    padding-left: 30px;
                    color: $color-text-primary;
                    @include breakpoint(md) {
                        @include font-content($font-size-l, normal, 1);
                    }
                }
                input[type="radio"] {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }
                &--mark {
                    position: absolute;
                    top: $wmap-radio-spacing;
                    left: $wmap-radio-spacing;
                    height: 16px;
                    width: 16px;
                    background-color: $color-white;
                    border-width: 1px;
                    border-style: solid;
                    border-color: $wmap-radio-border-color;
                    border-radius: 50%;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: $color-text-secondary;
                        display: none;
                    }
                }
                input[type="radio"]:checked ~ .mfrm-wmap__amount-pay__radio__select--mark {
                    border-color: $color-text-secondary;
                    &::after {
                        display: block;
                    }
                }
                &:hover {
                    border-color: $wmap-radio-border-color;
                }
                &.checked {
                    border-color: $color-text-secondary;
                    &:hover {
                        border-color: $color-text-secondary;
                    }
                }
            }
            &__amount {
                @include font-content($font-size-xxl, normal, 1.63);
                margin-left: 30px;
                display: block;
                color: $color-text-primary;
            }
            &__textinput {
                margin-left: 30px;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                @include breakpoint(xl) {
                    flex-wrap: nowrap;
                }
                &::before {
                    @include font-content($font-size-xxl, normal, 1.63);
                    content: "$ ";
                    margin-right: 10px;
                    color: $color-text-primary;
                }
                input {
                    @include font-content($font-size-l, 600, 1);
                    height: 54px;
                    width: 150px;
                    padding: 0 10px;
                    color: $color-text-primary;
                    background-color: $color-white;
                    border-radius: 3px;
                    border-width: 1px;
                    border-style: solid;
                    border-color: $wmap-table-border-color;
                    @include breakpoint(lg) {
                        width: 140px;
                    }
                    &.input-error {
                        border-color: $color-text-secondary;
                        border-width: 2px;
                    }
                }
                &--error {
                    @include font-content(($font-size-xs + 0.066), 600, 1.63);
                    position: relative;
                    top: 8px;
                    margin-left: 25px;
                    color: $color-text-secondary;
                    line-height: 18px;
                    @include breakpoint(xl) {
                        position: static;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
