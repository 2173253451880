$modal-bonus-item-spacing: 15px;
$modal-bonus-footer-height: 111px;
$modal-bonus-item-width: 175px;
$modal-bonus-width-sm: 315px;
$modal-bonus-width-lg: 850px;
.msc-modal .msc-gift-modal {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    vertical-align: top;
    @include breakpoint(xs) {
        width: $modal-bonus-width-sm;
    }
    @include breakpoint(lg) {
        position: relative;
        width: $modal-bonus-width-lg;
        vertical-align: middle;
        height: auto;
    }
    .msc-modal__header {
        padding: 15px;
        text-align: right;
        @include breakpoint(lg) {
            padding: 30px;
            padding-bottom: 0;
        }
    }
    .msc-modal__close-button {
        @include add-mfrm-icon($icon-close-bold);
        position: static;
        padding: 8px;
        border: 1px solid $color-modal-border-close-button;
        border-radius: 100px;
        line-height: 0;
        &::before {
            font-size: $icon-size-xs;
        }
    }
    .msc-modal__body {
        padding: 0;
        max-height: calc(100% - (#{$modal-bonus-footer-height} + 69px));
        flex-basis: calc(100% - (#{$modal-bonus-footer-height} + 69px));
        @include breakpoint(lg) {
            padding-top: 0;
            max-height: unset;
            flex-basis: auto;
        }
    }
    .msc-modal__content {
        border-radius: 0;
        height: 100%;
        @include breakpoint(lg) {
            height: auto;
        }
    }
    .modal-footer,
    .msc-modal__footer {
        height: $modal-bonus-footer-height;
        padding: 15px 30px;
        display: block;
        border-top: 0;
        border-radius: 0;
        box-shadow: 0px -1px 3px transparentize($color: $color-black, $amount: 0.75),
            inset 0px 1px 0px $color-border-bonus-modal-primary;
            @include breakpoint(lg) {
                height: auto;
            }
    }
}

.msc-gift {
    &-modal {
        h2 {
            @include font-content($font-size-xxl, 700, 1.17);
            margin-bottom: $modal-bonus-item-spacing;
            padding-left: $modal-bonus-item-spacing;
            padding-right: $modal-bonus-item-spacing;
            color: $color-text-primary;
            text-align: center;
            @include breakpoint(lg) {
                @include font-content($font-size-xxxxl, 700, 1.1);
            }
        }
        p {
            @include font-content($font-size-m, normal, 1.71);
            margin-bottom: $modal-bonus-item-spacing;
            padding-left: $modal-bonus-item-spacing;
            padding-right: $modal-bonus-item-spacing;
            color: $color-text-primary;
            text-align: center;
            @include breakpoint(lg) {
                @include font-content($font-size-l, normal, 1.5);
                margin-bottom: 0;
            }
            span {
                display: block;
                font-weight: 500;
            }
        }
    }
    &__bonus_container {
        max-height: calc(100% - #{$modal-bonus-footer-height});
        padding: $modal-bonus-item-spacing;
        overflow-y: auto;
        @include breakpoint(lg) {
            max-height: unset;
            padding: $modal-bonus-item-spacing ($modal-bonus-item-spacing - 5);
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            &::after {
                content: "";
                height: ($modal-bonus-item-spacing + 15);
                display: grid;
                grid-column: 1;
                clear: both;
            }
        }
    }
    &__bonus-block {
        width: 100%;
        background-color: $color-bg-primary;
        border: 1px solid $color-border-bonus-modal-primary;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        padding: 10px;
        cursor: pointer;
        &:focus {
            box-shadow: inset 0 0 0 2px lighten($color-border-bonus-modal-secondary, 50%);
            border-color: transparent;
        }
        &.selected {
            box-shadow: inset 0 0 0 2px $color-border-bonus-modal-secondary;
            border-color: transparent;
        }
        &:disabled {
            opacity: 0.5;
            cursor: default;
        }
        @include breakpoint(lg) {
            min-width: $modal-bonus-item-width;
            max-width: $modal-bonus-item-width;
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 30px;
            padding: 15px;
            flex-flow: column wrap;
        }
    }
    &__bonus__image {
        width: 75px;
        height: 65px;
        margin-right: 10px;
        @include breakpoint(lg) {
            width: 105px;
            height: 90px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
        }
        .msc-empty_image {
            @include image-placeholder(75px, 65px);
            border: 1px solid $color-border-image-placeholder;
            border-radius: 2px;
            @include breakpoint(lg) {
                @include image-placeholder(105px, 90px);
            }
        }
        img {
            height: 100%;
            width: 100%;
        }
    }
    &__bonus__content {
        text-align: left;
        align-self: flex-start;
        .msc-price__strikethrough {
            @include font-content($font-size-s, normal, 1.67);
            margin-left: 5px;
            color: $color-text-tertiary;
        }
    }
    &__bonus__name {
        @include font-content($font-size-s, 500, 1.25);
        color: $color-text-primary;
        margin-bottom: 5px;
        text-transform: capitalize;
        @include breakpoint(lg) {
            min-height: 30px;
            margin-bottom: 15px;
        }
    }
    &__bonus__price {
        @include font-content($font-weight: bold);
        display: inline-block;
        color: $color-text-secondary;
        text-transform: uppercase;
    }

    &__bonus__count {
        @include font-content($line-height: 1.43);
        margin: 0 0 15px;
        color: $color-text-primary;
        text-align: center;
    }
    &__bonus__action {
        margin: 0;
        text-align: center;
        button {
            @include btn(primary, large);
            padding-left: 30px;
            padding-right: 30px;
            width: 100%;
            margin: 0;
            @include breakpoint(lg) {
                width: auto;
                padding-left: 60px;
                padding-right: 60px;
                margin: 0 auto;
            }
        }
    }
}
