//==============================================================================
// MISC MIXINS
//==============================================================================

/* Adds outline on focus */
@mixin vfi($color: $color-white) {
  @include breakpoint(lg) {
    &:focus {
      outline: 1px dotted $color !important;
    }
  }
}

@mixin vfi-visible($color: $color-black) {
  @include breakpoint(lg) {
    &:focus-visible {
      outline: 1px dotted $color !important;
    }
  }
}

@mixin vfi-tab($color: $color-black) {
  @include breakpoint(lg) {
    &:focus > * {
      outline: 1px dotted $color !important;
    }
  }
}

@mixin vfi-sronly($color: $color-black) {
  @include breakpoint(lg) {
    &:focus + * {
      outline: 1px dotted $color !important;
    }
  }
}

/* Removes outline on focus */
@mixin no-outline {
  &:focus {
    outline: 0 !important;
  }
}

/* Remove hover state */
@mixin no-hover-state {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit !important;
  }
}
/* Remove text decoration */
@mixin no-decoration {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

//==============================================================================
// FONT
//==============================================================================

@mixin font-content($font-size: $font-size-m, $font-weight: 400, $line-height: 1) {
  font-family: $font-family-primary;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}

//==============================================================================
// Clearfix
//==============================================================================
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

//==============================================================================
// Mobile and Desktop Hidden
//==============================================================================
@mixin mobile_hidden {
  display: none;
  @include breakpoint(lg) {
    display: block;
  }
}

@mixin desktop_hidden {
  display: block;
  @include breakpoint(lg) {
    display: none;
  }
}

//==============================================================================
// Safari Form Field Fix
//==============================================================================
@mixin safari-field-fix {
  /* Safari 11+ */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
      font-size: 16px !important;
      line-height: 1 !important;
    }
  }

  /* Safari 10.1 */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
      @content;
      font-size: 16px !important;
      line-height: 1 !important;
    }
  }

  /* Safari 6.1-10.0 (not 10.1) */
  @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
    @content;
    font-size: 16px !important;
    line-height: 1 !important;
  }
}

//==============================================================================
// Product Badge Placement
//==============================================================================

@mixin badge-placement($position) {
  #{$position}: 0;
  &__image {
    border-radius: 10px 0;
  }
  @if ($position == bottom) {
    &__image {
      border-radius: 0 10px;
    }
    /* Buybox specific */
    .ms-buybox & {
      margin-bottom: 21px;
      @include breakpoint(md) {
        margin-bottom: 90px;
      }
      @include breakpoint(xl) {
        margin-bottom: 100px;
      }
    }
  }
}

//Box Arrows
@mixin box-arrow($position: top, $alignment: 0, $arrow-size: 12px, $color: $color-white) {
  &::after {
    #{$position}: 100%;
    left: $alignment;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-#{$position}-color: $color;
    border-width: $arrow-size;
  }
  @if ($position==bottom) {
    &::after {
      transform: rotate(360deg);
    }
  }
}

//Box Arrows
@mixin box-arrow-border() {
  &::before {
    border-color: transparent transparent $color-bombay transparent;
    border-width: 0 0.4rem 0.55rem 0.4rem;
    top: -2px;
    position: absolute;
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    width: 0;
    box-sizing: border-box;
  }
  &::after {
    border-width: 0 0.4rem 0.54rem 0.4rem;
    border-color: transparent transparent white transparent;
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
    box-sizing: border-box;
  }
}

//==============================================================================
// Text Ellipsis - 2 lines max
//==============================================================================

@mixin text-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

//==============================================================================
// Full Width Element
//==============================================================================

@mixin full-width {
  margin-left: calc(50% - (var(--windowWidth) / 2));
  margin-right: calc(50% - (var(--windowWidth) / 2));
}

//==============================================================================
// GRID SPACE
//==============================================================================

@mixin add-grid-padding-tab {
  padding-left: $grid-gutter-width-mobile;
  padding-right: $grid-gutter-width-mobile;
  @include breakpoint(md) {
    padding-left: $grid-gutter-width-tab;
    padding-right: $grid-gutter-width-tab;
  }
  @include breakpoint(lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

//==============================================================================
// CHECKBOX / RADIO INPUT
//==============================================================================

@mixin input-checkbox($checkbox-size: 18px) {
  /* Hide default checkbox */
  &[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    left: 8px;
    top: 8px;
    cursor: pointer;
    outline-offset: 2px;
    &:focus {
      + *::before {
        outline: 1px dotted $color-black !important;
        outline-offset: 2px;
      }
    }
  }
  + *::before,
  + *::after {
    cursor: pointer;
    border-style: solid;
    content: "";
  }
  + *::after {
    display: none;
  }
  /* Checkbox */
  + *::before {
    position: static;
    min-width: $checkbox-size;
    width: $checkbox-size;
    min-height: $checkbox-size;
    height: $checkbox-size;
    display: inline-block;
    background-color: $color-bg-primary;
    border-width: 1.5px;
    border-color: $color-input-border;
    border-radius: 2px;
  }
  &[aria-checked="true"] {
    + *::before {
      background-color: $color-bg-quinary;
      border-color: $color-bg-quinary;
    }
    /* Checkmark */
    + *::after {
      position: absolute;
      left: 6px;
      top: 3px;
      width: 6px;
      height: 10px;
      display: inline-block;
      border-width: 0 2px 2px 0;
      border-color: $color-bg-primary;
      transform: rotate(45deg);
      @include breakpoint(lg) {
        border-width: 0 3px 3px 0;
      }
    }
  }
  &[aria-checked="false"] {
    + *::before {
      transition: background-color 0.3s ease;
    }
     &:hover,
     &:focus {
      + *::before {
        background-color: $color-bg-secondary;
      }
    }
  }
}

//==============================================================================
// ORDER CONFIRMATION SPECIFIC MIXINS
//==============================================================================

@mixin order-confirmation-font-alignment {
  text-align: center;
  @include breakpoint(lg) {
    text-align: left;
  }
}
@mixin order-confirmation-word-spacing {
  padding: 0 30px;
  @include breakpoint(lg) {
    padding: 0;
  }
}
@mixin order-confirmation-add-margin {
  @media (max-width: $breakpoint-lg) {
    margin-left: 30px;
    margin-right: 30px;
  }
  @include breakpoint(md, max-width) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

//==============================================================================
// Placeholder Animation
//==============================================================================

@mixin empty-placeholder-animation($background-color) {
  position: relative;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background-color: $background-color;
  background: linear-gradient(
    280deg,
    $background-color 10%,
    lighten($background-color, 1.5%) 20%,
    $background-color 35%
  );
  background-size: 900px 150px;
}

//==============================================================================
// HIDE SCROLLBAR (WEBKIT BROWSERS)
//==============================================================================

@mixin hide-scrollbar-webkit {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

//==============================================================================
// CUSTOM SCROLLBAR (WEBKIT BROWSERS)
//==============================================================================

@mixin custom-scrollbar-webkit($width: 8px) {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-button {
    width: $width;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $color-mercury;
    border: thin solid $color-border-scrollbar-track;
    box-shadow: 0px 0px 3px $color-border-scrollbar-track inset;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-bombay;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-bg-scrollbar-hover;
  }
}

@mixin link-with-arrow($arrow-location) {
  @if ($arrow-location == after) {
    @include add-mfrm-icon($icon-chev-right, after);
    &::after {
      margin-left: 5px;
    }
  } @else {
    @include add-mfrm-icon($icon-chev-left, before);
    &::before {
      margin-right: 5px;
    }
  }
  display: inline-block;
  cursor: pointer;
  color: $color-link-secondary !important;
  &::#{$arrow-location} {
    font-size: 8px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
  }
}
@mixin link-with-right-arrow($arrow-location:after) {
    @include add-mfrm-icon($icon-chev-right, after);
    &::after {
      margin-left: 10px;
    }
  display: inline-block;
  cursor: pointer;
  color: $color-link-primary !important;
  &::#{$arrow-location} {
    font-size: 11px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin alert($type) {
  padding: 30px 15px 15px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: $color-bg-error;
  &-title {
    @include font-content($font-size-l, bold, 1.25);
    margin-bottom: 15px;
    @include breakpoint(md) {
      @include font-content($font-size-xl, bold, 1.22);
    }
  }
  &-message {
    @include font-content($font-size-l, normal, 1.37);
    margin-bottom: 15px;
  }
  @if ($type == error) {
    &-title {
      color: $color-punch;
    }
  } @else if ($type == info) {
    &-title {
      color: $color-dark-cerulean;
    }
  } @else if ($type == success) {
    &-title {
      color: $color-green;
    }
  } @else if ($type == warning) {
    &-title {
      color: darken($color-yellow-alert, 20%);
    }
  }
}

//==============================================================================
// INPUT FIELD ERROR 
//==============================================================================
@mixin input-field-error($color-error) {
  border-color: $color-error;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus {
    border-color: $color-error;
  }
  &:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0 1px $color-error;
  }
  +.field-label {
    color: $color-error;
  }
}