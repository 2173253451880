//==============================================================================
// DISABLED FIELDS
//==============================================================================
%disabled {
  position: relative;
  color: #ccc !important;
  pointer-events: none;
  &::after {
    content: "/";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(25deg);
    color: $color-input-disabled;
    font-size: $font-size-xl;
    &.react-datepicker__day--keyboard-selected {
      pointer-events: none;
      touch-action: none;
      cursor: default;
      &:hover,
      &:focus {
        pointer-events: none;
        touch-action: none;
        cursor: default;
        background-color: transparent;
      }
    }
  }
  &:hover {
    cursor: default;
    background-color: transparent;
  }
  &:not(.react-datepicker__day--selected):not(.react-datepicker__day--today) {
    color: #ccc;
    background-color: transparent;
    pointer-events: none;
    touch-action: none;
    cursor: default;
    &:hover,
    &:focus {
      pointer-events: none;
      touch-action: none;
      cursor: default;
      background-color: transparent;
    }
  }
}
//==============================================================================
// REACR DATEPICKER
//==============================================================================
.react-datepicker {
  background-color: #fff;
  color: #2d2926;
  border: 0;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  box-shadow: 0px 2px 30px rgba(150, 111, 111, 0.25);
  &__triangle {
    position: absolute;
    left: 50px;
  }
  &__header {
    text-align: center;
    background-color: $color-white;
    border-bottom: 4px solid $color-mercury;
    border-top-left-radius: 1px;
    padding: 25px 0 15px;
    position: relative;
    &:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 1px;
    }
    &--time {
      padding-bottom: 8px;
      padding-left: 5px;
      padding-right: 5px;
    }
    &--time:not(.react-datepicker__header--time--only) {
      border-top-left-radius: 0;
    }
  }
  &__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 18px;
    padding: 10px;
    border: none;
    z-index: 1;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: $color-alabaster;
    }
    &--previous {
      @include add-mfrm-icon($icon-chev-left);
      left: 25px;
    }
    &--next {
      @include add-mfrm-icon($icon-chev-right);
      right: 25px;
    }
    &--previous,
    &--next {
      &::before {
        position: relative;
        font-size: 12px;
        color: $color-punch;
      }
    }
    &--previous::before {
      left: -1px;
    }
    &--next::before {
      left: 1px;
    }
    &--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 85px;
    }
    &--years {
      position: relative;
      top: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    &--years-previous {
      top: 4px;
    }
    &--years-upcoming {
      top: -4px;
    }
    &:hover {
      *::before {
        border-color: $color-text-secondary;
      }
    }
  }
  // Portal
  &__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }
  &__portal & {
    &__day-name,
    &__day,
    &__time-name {
      width: 3rem;
      line-height: 3rem;
      @media (max-width: 400px), (max-height: 550px) {
        width: 2rem;
        line-height: 2rem;
      }
    }
    &__current-month,
    &-time__header {
      font-size: 1.44rem;
    }
  }
  &__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: $breakpoint-lg) {
      input {
        touch-action: none;
        pointer-events: none;
      }
    }
    .react-datepicker__aria-live{
      display: none;
    }
  }
  &__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }
  &__navigation-icon {
    display: none;
  }
  .react-datepicker__day,
  .react-datepicker__navigation {
    @include vfi-visible($color-black);
  }
  &__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    &::after {
      cursor: pointer;
      background-color: #d93527;
      color: #fff;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      padding: 2px;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      content: "×";
    }
  }
  // Time
  &--time-only & {
    &__triangle {
      left: 35px;
    }
    &__time-container {
      border-left: 0;
    }
    &__time,
    &__time-box {
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  }
  &__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 85px;
    &--with-today-button {
      display: inline;
      border: 1px solid #aeaeae;
      border-radius: 0.3rem;
      position: absolute;
      right: -72px;
      top: 0;
    }
  }
  &__time-container & {
    &__time {
      position: relative;
      background: white;
      border-bottom-right-radius: 0.3rem;
    }
    &__time .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem;
    }
    &__time .react-datepicker__time-box ul.react-datepicker__time-list {
      list-style: none;
      margin: 0;
      height: calc(195px + (1.7rem / 2));
      overflow-y: scroll;
      padding-right: 0;
      padding-left: 0;
      width: 100%;
      box-sizing: content-box;
      li.react-datepicker__time-list-item {
        height: 30px;
        padding: 5px 10px;
        white-space: nowrap;
        &:hover {
          cursor: pointer;
          background-color: #f0f0f0;
        }
        &--selected {
          background-color: #d93527;
          color: white;
          font-weight: bold;
          &:hover {
            background-color: #d93527;
          }
        }
        &--disabled {
          position: relative;
          color: #ccc;
          pointer-events: none;
          &::after {
            content: "/";
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(25deg);
            color: $color-input-disabled;
            font-size: $font-size-xl;
            &.react-datepicker__day--keyboard-selected {
              pointer-events: none;
              touch-action: none;
              cursor: default;
              &:hover,
              &:focus {
                pointer-events: none;
                touch-action: none;
                cursor: default;
                background-color: transparent;
              }
            }
          }
          &:hover {
            cursor: default;
            background-color: transparent;
          }
          &:not(.react-datepicker__day--selected):not(.react-datepicker__day--today) {
            color: #ccc;
            background-color: transparent;
            pointer-events: none;
            touch-action: none;
            cursor: default;
            &:hover,
            &:focus {
              pointer-events: none;
              touch-action: none;
              cursor: default;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  &__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
  }
  &__input-time-container & {
    &-time__caption,
    &-time__input-container {
      display: inline-block;
    }
    &-time__input-container .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px;
      input {
        width: auto;
      }
      input[type="time"] {
        -moz-appearance: textfield;
      }
      input[type="time"]::-webkit-inner-spin-button,
      input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    &-time__input-container .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block;
    }
  }
  &__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }
  // Year/Month/Quarter/Day
  &__year-text,
  &__month-text,
  &__quarter-text,
  &__day {
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
    // &--today {
    //   font-weight: bold;
    // }
    &--highlighted {
      border-radius: 0.3rem;
      background-color: #3dcc4a;
      color: #fff;
      &:hover {
        background-color: #32be3f;
      }
    }
    &--highlighted-custom-1 {
      color: magenta;
    }
    &--highlighted-custom-2 {
      color: green;
    }
    &--disabled {
      @extend %disabled;
    }
    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: 0.3rem;
      background-color: #d93527;
      color: #fff !important;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      &:hover {
        background-color: #d63426;
      }
    }
    &--in-selecting-range {
      &:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
        background-color: rgba(214, 52, 38, 0.5);
      }
    }
    &--keyboard-selected {
      border-radius: 0.3rem;
      background-color: lighten($color: #d63426, $amount: 40%);
      color: #fff;
      &:hover {
        background-color: lighten($color: #d63426, $amount: 40%);
      }
    }
  }
  // Year/Month Readview
  &__year-read-view,
  &__month-year-read-view,
  &__month-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
    position: relative;
    &:hover {
      cursor: pointer;
      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow {
        border-top-color: #b3b3b3;
      }
    }
  }
  &__year-read-view,
  &__month-read-view,
  &__month-year-read-view {
    &--down-arrow {
      transform: rotate(135deg);
      right: -16px;
      top: 0;
    }
  }

  // Month/Quarter
  &__month,
  &__quarter {
    &--disabled {
      @extend %disabled;
    }
    &--selected,
    &--in-range,
    &--in-selecting-range {
      border-radius: 0.3rem;
      background-color: #d93527;
      color: #fff;
      &:hover {
        background-color: #d63426;
      }
    }
  }
  &__month-text,
  &__quarter-text {
    &:hover {
      background-color: #f0f0f0;
    }
    &.react-datepicker__month,
    &.react-datepicker__quarter {
      &--selected,
      &--in-range {
        &:hover {
          background-color: #d93527;
        }
      }
    }
  }
  &__month--selecting-range & {
    &__day--in-range,
    &__month-text--in-range,
    &__quarter-text--in-range,
    &__year-text--in-range {
      &:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
        background-color: #f0f0f0;
        color: #000;
      }
    }
  }

  // Week
  &__week {
    white-space: nowrap;
  }
  &__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    &--clickable {
      cursor: pointer;
    }
    &--clickable:hover {
      border-radius: 0.3rem;
      background-color: #f0f0f0;
    }
  }
  // Day
  &__day,
  &__day-name {
    @include font-content($font-size-m, normal, 1);
    color: $color-text-primary;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--windowWidth) / 7 - 12.5px);
    height: calc(var(--windowWidth) / 7 - 12.5px);
    text-align: center;
    margin: 2px;
    padding: 8px;
    border-radius: 50% !important;
    transition: background-color 0.3s ease-in-out;
    @media (min-width: ($breakpoint-sm - 156)) {
      width: 42px;
      height: 42px;
    }
    @include breakpoint(md) {
      @include font-content($font-size-l, normal, 1);
    }
  }
  &__day-names {
    margin-bottom: -8px;
    white-space: nowrap;
  }
  &__year-read-view,
  &__month-read-view,
  &__month-year-read-view {
    &--down-arrow {
      border-color: #ccc;
      border-style: solid;
      border-width: 3px 3px 0 0;
      content: "";
      display: block;
      height: 9px;
      position: absolute;
      top: 6px;
      width: 9px;
    }
  }
  // Dropdown Container
  &__year-dropdown-container,
  &__month-dropdown-container,
  &__month-year-dropdown-container {
    &--select,
    &--scroll {
      display: inline-block;
      margin: 0 2px;
    }
  }
  &__current-month,
  &-time__header,
  &-year-header {
    @include font-content($font-size-m, bold, 1);
    margin-top: 0;
    margin-bottom: 5px;
    color: $color-text-primary;
    letter-spacing: 2px;
    @include breakpoint(md) {
      @include font-content($font-size-l, bold, 1);
    }
  }
  &__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }
  &__year {
    margin: 0.4rem;
    text-align: center;
  }
  &__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
  &__month-container {
    float: left;
  }
  &__month {
    margin: 15px;
    text-align: center;
  }
  &__month-text,
  &__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
  // Year/Month Dropdown
  &__year-dropdown,
  &__month-dropdown,
  &__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
    &:hover {
      cursor: pointer;
    }
    &--scrollable {
      height: 150px;
      overflow-y: scroll;
    }
  }
  &__year-option,
  &__month-option,
  &__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &:hover {
      background-color: #ccc;
      .react-datepicker__navigation--years-upcoming,
      .react-datepicker__navigation--years-previous {
        border-bottom-color: #b3b3b3;
      }
    }
    &:first-of-type {
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }
    &:last-of-type {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
    &--selected {
      position: absolute;
      left: 15px;
    }
  }
  .react-datepicker__aria-live {
    display: none;
  }
}
// Datepicker time
.react-datepicker-time {
  &__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.react-datepicker-wrapper {
  @extend %field-control-input;
  @extend %float-label;
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
  ~ .field-label {
    @include font-content($font-size: $font-size-s);
    @extend %field-control-label;
    transform: translateY(-21px);
  }
  &:not(.msc-single-value-no-toggle):not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      border-color: $color-text-primary;
    }
    &:focus {
      box-shadow: inset 0 0 0 1px $color-text-primary;
    }
  }
  @at-root .disabled#{&},
    &:disabled {
    color: #b3b5b7;
    user-select: none;
    touch-action: none;
    cursor: default;
    pointer-events: none;
  }
}

// DatePicker Popper

.react-datepicker-popper {
  z-index: 2;
  &[data-placement^="top"],
  &[data-placement^="bottom"] {
    .react-datepicker__triangle {
      position: absolute;
      width: 0;
      transform: translateX(50px) !important;
      &::before,
      &::after {
        box-sizing: content-box;
        position: absolute;
        border: 8px solid transparent;
        height: 0;
        width: 1px;
        content: "";
        z-index: -1;
        border-width: 8px;
        left: -8px;
      }
      &::before {
        border-bottom-color: $color-white;
      }
    }
  }

  &[data-placement^="top"] {
    padding-bottom: 15px;
    .react-datepicker__triangle {
      bottom: 0;
      margin-bottom: -8px;
      &::before,
      &::after {
        border-bottom: none;
        border-top-color: #fff;
      }
      &::before {
        bottom: -1px;
        border-top-color: $color-white;
      }
      &::after {
        bottom: 0;
      }
    }
  }

  &[data-placement^="bottom"] {
    padding-top: 15px;
    .react-datepicker__triangle {
      top: 0;
      margin-top: -8px;
      &::before,
      &::after {
        border-top: none;
        border-bottom-color: $color-white;
      }
      &::before {
        top: 1px;
        border-bottom-color: $color-white;
      }
      &::after {
        top: 0;
      }
    }
  }
  &[data-placement="bottom-end"],
  &[data-placement="top-end"] {
    .react-datepicker__triangle {
      left: auto;
      right: 50px;
    }
  }
  &[data-placement="right"] {
    padding-left: 8px;
    .react-datepicker__triangle {
      left: auto;
      right: 42px;
    }
  }
  &[data-placement="left"] {
    padding-right: 8px;
    .react-datepicker__triangle {
      left: 42px;
      right: auto;
    }
  }
}
