$checkout-checkbox-size: 18px;
$checkout-margin: 15px;

//safari address form zoom fix
.msc-address-form__dropdown,
.msc-address-form__input {
    @include safari-field-fix();
}

.ms-checkout-billing-address {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .field-control:not(:placeholder-shown) ~ .field-label,
    .field-control:not(:placeholder-shown)+.msc-address-form__label,
    .msc-address-form__input:not(:placeholder-shown)+.msc-address-form__label {
        visibility: hidden;
        .ms-checkout-payment-instrument-creditcard .ms-checkout-payment-instrument--visible & {
            visibility: visible;
        }
    } 
    &__shipping-address-label {
        @include vfi($color-black);
        display: block;
        margin-bottom: 0;
        .field-group {
            display: inline-flex;
            height: $checkout-checkbox-size;
            margin-top: 1px;
            vertical-align: top;
            width: $checkout-checkbox-size;
        }
        .field-label,
        .msc-address-form__label {
            display: none;
        }
    }
    &__shipping-address-checkbox-text {
        @include font-content($font-size-l, 400, 1.37);
        display: inline-block;
        margin-left: 18px;
    }
    &__input-checkbox {
        @include input-checkbox();
    }
    &__checkbox-icon {
        z-index: 1;
    }
    &__heading {
        @include font-content($font-size-l, 700, 1.25);
        margin: 30px 0;
        order: 2;
        flex-basis: 100%;
        @include breakpoint(lg) {
            @include font-content($font-size-xl, 700, 1.22);
        }
    }
    // hidden: only shown on wmap
    &__subheading {
        display: none;
    }
    .msc-address-form {
        order: 3;
        &__item {
            &-address {
                margin-top: $checkout-margin + 30px;
                @include breakpoint(md) {
                    margin-top: $checkout-margin + 15px;
                }
            }
        }
        &__item-invalid {
            .msc-address-form__alert {
                margin-left: 0px;
            }
        }
        &__button-save {
            margin: 0 5px;
            @include btn(primary, default);
        }
        &__button-cancel {
            margin: 0 5px;
            @include btn(secondary, default);
        }
    }
}

.ms-checkout__guided-card-content ~ .ms-checkout__guided-card-footer {
    margin: 15px 0;
}

.ms-checkout-billing-buttons {
    margin: auto;
    @include breakpoint(lg) {
        margin-left: auto;
        margin-right: 0px;
    }
}
.ms-checkout-shipping-checkbox {
    margin: 0 !important;
}