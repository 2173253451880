//==============================================================================
// Buy Box Media Gallery
//==============================================================================
$media-gallery-width-desktop: 57%;
$main-image-spacing: 20px;
$flipper-icon-size: 60px;
$thumb-img-size: 72px;
$thumb-img-spacing: 6px;
$thumb-img-border-color: $color-border-media-gallery-primary;
$media-gallery-img-height-mobile: 310px;
$media-gallery-img-height-tab: 640px;
$media-gallery-img-height-desktop: 513px;

.ms-buybox__media-gallery {
  position: relative;
  margin: 0 auto;
  @include breakpoint(xl) {
    position: sticky;
    top: 120px;
    align-self: flex-start;
    margin: 0;
    min-width: $media-gallery-width-desktop;
    max-width: $media-gallery-width-desktop;
  }
}

.ms-media-gallery {
  &__carousel {
    display: block;
  }
  .msc-carousel__inner {
    @include breakpoint(xl) {
      margin-bottom: 10px;
    }
  }
  .msc-carousel__item {
    max-height: $media-gallery-img-height-mobile;
    @include breakpoint(sm) {
      max-height: $media-gallery-img-height-tab;
    }
    @include breakpoint(xl) {
      max-height: $media-gallery-img-height-desktop;
    }
    // Empty Image
    .msc-empty_image {
      @include image-placeholder(100%, $media-gallery-img-height-mobile);
      @include breakpoint(xl) {
        @include image-placeholder(100%, $media-gallery-img-height-desktop);
      }
      ~ .ms-fullscreen-section__overlay .ms-fullscreen-section__magnifying-glass-icon {
        pointer-events: none;
        user-select: none;
        touch-action: none;
        cursor: default;
      }
    }
    img {
      object-fit: fill;
      height: 100%;
      width: 100%;
      min-height: $media-gallery-img-height-mobile;

      @include breakpoint(sm) {
        min-height: $media-gallery-img-height-tab;
      }
      @include breakpoint(xl) {
        min-height: $media-gallery-img-height-desktop;
      }
    }
  }
  .msc-tooltip-inner {
    background-color: transparent;
    color: $color-white;
    padding: 0;
  }
}

//==============================================================================
// Vertical Container
//==============================================================================

.ms-media-gallery.vertical {
  display: inline-flex;
}
.ms-media-gallery {
  &.vertical & {
    &__carousel {
      align-items: start;
      order: 1;
      flex-grow: 1;
      @include breakpoint(md) {
        margin-left: 20px;
        margin-bottom: 0;
      }
    }
  }
}
