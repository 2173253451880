.hero-carousel {
   margin: 0 auto;

   &.hero-carousel__1200 {
    max-width: 1200px;
  }

  &.hero-carousel__1440 {
    max-width: 1440px;
  }
  

  .msc-carousel__indicators {
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      box-sizing: border-box;
      border-radius: 100px;
      max-width: 150px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      height: 30px;
      position: absolute;
      bottom: 15px;

      li {
        border: none;
        background: grey;
        width: 8px;
        height: 8px;
      }

      li.active {
        background: $color-black;
        width: 14px;
        height: 14px;
      }
    }


}