.msc-choice-summary {
  &__selection {
    flex: 1 0 100%;
    margin: -5px 0 30px;
  }
  &__label {
    @include font-content($font-size-m, 500, 0.86);
    margin-bottom: 15px;
    color: $color-text-primary;
    display: block;
    @include breakpoint(md) {
      margin-bottom: 0;
      margin-right: 12px;
      display: inline-block;
    }
  }
  &__list {
    display: inline;
  }
  &__list-item {
    display: inline-block;
  }
  &__item {
    @include font-content($font-size-m, normal, 0.86);
    @extend %link-muted;
    padding: 9px 15px;
    margin-bottom: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    color: $color-text-primary;
    background-color: $color-bg-primary;
    border: 1px solid $color-border-choice-summary-primary;
    border-radius: 50px;
    text-transform: capitalize;
    &:hover,
    &:focus {
      color: $color-text-primary;
      border-color: $color-border-choice-summary-primary-hover;
    }
  }
  &__glyph {
    @include add-mfrm-icon($icon-close-bold);
    margin-left: 15px;
    font-size: ($icon-size-xs - 3);
  }
  &__clear-all {
    @include font-content($font-size-m, 500, 0.86);
    @include vfi($color-black);
    margin-left: 10px;
    display: inline-block;
    color: $color-text-secondary;
    text-decoration: underline;
    text-transform: capitalize;
    outline-offset: 4px;
    .msc-search-result-modal & {
      @include font-content($font-size-l, normal, 0.75);
    }
  }
}
