.msc-price {
    &__strikethrough {
        @include font-content($font-size-s, 400, 2);
        margin-right: 5px;
        text-decoration: line-through;
        color: $color-text-tertiary;
    }
    &__actual {
        @include font-content($font-size-l, 500, 1.25);
        color: $color-text-secondary;
    }
}
