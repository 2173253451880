.mfrm-android-banner-container {
    display: none;
    justify-content: flex-start;
    background: #F2F1F6;
    padding: 7.5px 18px 7.5px 8px;
    position: relative;
    align-items: center;
  }
  
  .android-app-banner-close {
    padding: 0 8px 0 5px;
    cursor: pointer;
  }
  
  .android-app-banner-icon img {
    border-radius: 20px;
  }
  
  .android-app-banner-content {
    padding-left: 9px;
  }
  
  .android-app-banner-stars {
    display: flex;
  }
  
  .android-app-banner-name {
    font-size: $font-size-s;
  }
  
  .android-app-banner-company {
    font-size: $font-size-s;
  }
  
  .android-app-banner-text {
    font-size: $font-size-s;
  }
  
  .android-app-banner-btn {
    position: absolute;
    right: 0;
    color: #367AF6;
    text-decoration: none;
    font-size: $font-size-l;
    padding-right: 18px;
  }