$account-modal-height: calc(-70px + var(--windowSize) - var(--initialTopbarSize));
$account-modal-selector: msc-modal;
$account-modal-width: 305px;
$account-header-height: calc(var(--initialTopbarSize) + 70px);
$account-links-spacing: 10px;

.#{$account-modal-selector}.account-dialog {
    .#{$account-modal-selector}__close-button {
        right: 12.72px;
        top: 11.19px;
        &::before {
            font-weight: 600;
        }
    }
    .#{$account-modal-selector}__dialog {
        position: absolute;
        right: 0;
        bottom: 0;
        min-height: $account-modal-height;
        height: $account-modal-height;
        width: $account-modal-width;
        max-width: $account-modal-width;
        background-color: $color-bg-primary;
        animation: none;
        transition: none;
        .#{$account-modal-selector}__content {
            border-radius: 0;
            box-shadow: none;
        }
    }
    .#{$account-modal-selector}__body {
        padding: 0;
        .ms-header {
            &__account-info,
            &__account-links {
                height: auto;
                margin: 0;
                display: flex;
                flex-flow: column wrap;
            }
            &__primary__links {
                padding: 0 15px;
            }
            &__signin-button,
            &__signout-button {
                @include font-content($line-height: 1.14);
                @include vfi($color-black);
                @extend %link-muted;
                position: static;
                height: auto;
                margin: 18px auto 16px 0;
                padding: 0 $account-links-spacing;
                color: $color-link-secondary;
                border: 0;
                outline-offset: 3px;
                &::before,
                &::after {
                    display: none;
                }
                &-text {
                    text-transform: capitalize;
                }
            }
        }
        .ms-header__signup-button,
        .ms-signin-info__account-link-button {
            @include font-content($font-size: $font-size-l);
            @include vfi($color-black);
            @extend %link-muted;
            margin: 0;
            padding: 20px $account-links-spacing;
            display: block;
            color: $color-link-primary;
            border: 0;
            box-shadow: 0px 1px 0px $color-button-border-tertiary;
            outline-offset: -4px;
            &:hover {
                color: $color-link-hover;
            }
        }
    }
}

@include breakpoint(lg) {
    /* Body tag style overrrides */
    .header-fixed {
        right: 0 !important;
        overflow-y: scroll;
    }
    /* Z-Index Overrides */
    .header-fixed .ms-header {
        &__topbar,
        &__primary,
        &__primary--fixed {
            z-index: $modal-z-index;
        }
    }
    /* Backdrop Gap */
    .account-dialog + .msc-modal__backdrop {
        top: $account-header-height;
    }
}
