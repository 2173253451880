$modal-max-width: 375px;


.modal-zipcode {
    z-index: ($modal-z-index + 10);

    .modal-open & {
        overflow: hidden;

        @media only screen and (max-device-width: $breakpoint-lg) and (orientation: landscape) {
            overflow-y: auto;
        }
    }

    &.show .msc-modal__dialog {
        @include animation-transition(slideInUp);
    }

    &:before {
        vertical-align: bottom;

        @include breakpoint(md) {
            vertical-align: middle;
        }
    }

    &.msc-modal__dialog {
        width: 100%;
        vertical-align: middle;

        @include breakpoint(md) {
            width: auto;
            max-width: max-content;
        }
    }

    .msc-modal__content {
        @include breakpoint(md) {
            justify-content: center;
        }
    }

    &__subtitle {
        @include font-content(1.285rem, normal, 1.33);
        margin-bottom: 31px;
        color: $color-text-primary;
        text-align: center;

        @include breakpoint(sm) {
            margin-bottom: 38px;
            @include font-content($font-size-l, normal, 1.375);
        }
    }

    &__body {
        text-align: initial;
        padding: 37px 15px 35px;

        @include breakpoint(sm) {
            padding: 62px 32px 59px;
        }
    }

    &__title {
        @include font-content($font-size-xxxl + 0.285, bold, 1.18);
        margin-bottom: 16px;
        color: $color-text-primary;
        text-align: center;
    }

    &__form {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        padding: 0;
        align-items: baseline;
        flex-direction: column;
        padding: 0 15px;

        @include breakpoint(sm) {
            flex-direction: row;
            padding: 0 85px;
        }
    }

    &__button {
        @include btn(primary, large);
        width: 100%;
        margin-top: 0;
        margin-bottom: 10px;
        padding: 16px;
        text-align: center;
        height: 44px;

        @include breakpoint(sm) {
            width: 178px;
        }

        &:disabled,
        &.disabled {
            color: $color-text-tertiary;
        }
    }

    &__text {
        @include font-content($font-size-xs, normal, 1.6);
        margin-top: 22px;
        color: $color-text-primary;

        a {
            text-decoration: underline;
        }
    }

    .field-group {
        display: inline-block;
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;

        @include breakpoint(sm) {
            width: auto;
            margin-bottom: 0;
            margin-right: 8px;
        }

        .field-label {
            margin-left: 30px;
        }

        .field-control {
            width: 100%;
            border-radius: 50px;
            max-height: 44px;
            padding: 15px 15px 15px 30px;

            @include breakpoint(sm) {
                width: 345px;
            }
        }

        .field-control:not(.msc-single-value-no-toggle):not(:disabled):not(.disabled):focus {
            box-shadow: none;
        }

        .field-control.invalid:not(.msc-single-value-no-toggle):not(:disabled):not(.disabled):focus,
        .field-control.invalid:not(.msc-single-value-no-toggle):not(:disabled):not(.disabled):hover,
        .field-control.invalid {
            border-color: $color-text-secondary;
        }

        .field-control--value.field-control.invalid~.field-label {
            color: $color-text-secondary;
        }
    }

    &__alert-message {
        @include animation-transition(fadeIn, $duration: 0.45s);
        @include font-content($font-size: $font-size-s, $line-height: 1.6);
        height: 0;

        &--error {
            color: $color-error;
        }
    }

    &__success .modal-zipcode,
    &__error {
        visibility: hidden;
        @include font-content($font-size-s, normal, 1.25);
        margin-top: 5px;
        padding: 0;
        color: $color-text-secondary;
        text-align: left;
        padding-left: 26px;

        @include breakpoint(sm) {
            padding-left: 30px;
        }

        a {
            color: $color-text-secondary;
            text-decoration: underline;
            pointer-events: all;
        }

        &__show {
            visibility: visible;
        }

        &__bold {
            font-weight: 600;
        }
    }

}

.mfrm-wmap-page {
    .msc-modal__backdrop.show {
        background-color: rgba(30, 30, 30, 0.4);
    }
}