.mfrm-promo {
    overflow-y: hidden;
    &__mfcl-carousel-container {
        .mfrm-promo {
            &__badge {
                order: 2;
            }

            &__badge-container {
                left: 20px;
                margin-top: 15px;

                @include breakpoint(lg) {
                    left: 35px;
                }
            }
        }
    }

    &__mfcl-carousel-desktop {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .mfrm-promo {
            &__link {
                min-width: 255px;
                margin-right: 15px;
                flex: 1;

                &:active,
                &:hover,
                &:focus,
                &:focus-visible {
                    border: 1px solid $color-black;
                    text-decoration: underline;
                    cursor: pointer;
                }

                @include breakpoint(md) {
                    min-width: 226px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @include breakpoint(lg) {
                    margin-right: 29px;
                    min-width: 380px;
                }
            }

            &__sale {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            &__image {
                order: 1;
                height: 134px;
                @include breakpoint(md) {
                    height: auto;
                }
                @include breakpoint(lg) {
                    height: 200px;
                }
                img {
                    height: 100%;
                    border-radius: 5px 5px 0 0;
                }
            }

            .image {
                text-align: -webkit-center;
            }

            &__title {
                @include font-content($font-size-l, 700, 1.25);
            }

            &__text-container {
                background: $color-bg-error-page;
                color: $color-bg-primary;
                text-align: left;
                padding: 45px 20px;
                border-radius: 0 0 5px 5px;
                display: flex;
                flex-direction: column;
                flex: 1;
                order: 3;

                @include breakpoint(md) {
                    padding: 45px 20px 47px;
                }

                @include breakpoint(lg) {
                    padding: 45px 35px 53px;
                }
            }

            &__description-container {
                margin: 10px 0px 5px;

                @include breakpoint(md) {
                    margin: 10px 0px 4px;
                }

                @include breakpoint(lg) {
                    margin: 10px 0px 2px;
                }
            }
        }
    }

    &__title {
        @include font-content($font-size-xl, 700, 1.22);

        @include breakpoint(md) {
            @include font-content($font-size-l, 700, 1.25);
        }

        @include breakpoint(lg) {
            @include font-content($font-size-xl, 700, 1.22);
        }
    }

    &__description-container {
        text-align: left;
        @include font-content($font-size-m, 400, 1.428);
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 10px 0px;
        white-space: pre-wrap;
    }

    &__plus-divider {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $color-black;
        font-weight: normal;
        padding: 15px 0;
    }

    &__heading {
        margin: 25px auto;
        margin-top: 0;
        font-size: $font-size-xl;
        line-height: 1.2;

        @include breakpoint(lg) {
            font-size: $font-size-xxl;
            line-height: 1.5;
        }
    }

    &__mfcl-carousel-desktop,
    &__brand-specific-container,
    &__secondary-sale-items {
        .mfrm-promo {
            &__link {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 0;
                border: 1px solid $color-mercury;
                border-radius: 5px;
                position: relative;
                color: $color-mercury;
                cursor: pointer;
            }
        }
    }

    &__brand-specific-container,
    &__secondary-sale-items {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        @include breakpoint(md) {
            flex-direction: row;
        }

        .mfrm-promo {
            &__link {
                flex-basis: 100%;
                padding: 45px 29px 30px;
                margin-right: 0;
                margin-bottom: 15px;

                &:focus {
                    padding: 0;
                    border: 2px solid $color-bombay !important;
                    border-radius: 5px;
                }

                @include breakpoint(md) {
                    padding: 45px 20px 20px;
                    margin-right: 15px;
                }

                @include breakpoint(lg) {
                    padding: 45px 30px;
                    margin-right: 30px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:hover,
                &:focus {
                    cursor: pointer;
                    text-decoration: none;
                }
            }

            &__sale {
                flex: 1;
                display: flex;
                flex-direction: column;
                width: 100%;

                .button {
                    flex: none;
                    flex-grow: 0;
                    width: 100%;
                }
            }

            &__image {
                margin-bottom: 10px;

                img {
                    height: auto;
                }
            }

            &__title {
                @include font-content($font-size-l, 700, 1.25);
            }

            &__text-container {
                width: 100%;
                flex-grow: 1;
            }

            &__description-container {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }

        .button {
            @include breakpoint(md) {
                margin-top: 15px;
            }

            @include breakpoint(lg) {
                margin-top: 0;
            }
        }

        .button a {
            padding: 10px 20px;
            line-height: 10px;
            border: none;

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
                cursor: pointer;
                background-color: $color-button-primary-background-hover;
            }
        }
    }

    &__brand-specific-container {
        .mfrm-promo {
            &__image {
                width: 180px;
            }
        }

        .mfrm-promo__badge-container {
            top: 15px;
        }
    }

    &__secondary-sale-items {
        .mfrm-promo {
            &__image {
                margin-bottom: 0;
            }
        }

        .button {
            margin-top: 0;
        }
    }

    &__badge-container {
        padding: 5px 10px;
        border-radius: 2px;
        position: absolute;
        color: $color-input-text;
        background-color: $color-badge;
        border: 0px;
        @include font-content($font-size-s, 700, 0.83);
        text-transform: uppercase;
    }

    &__msc-cta__primary {
        @include btn(primary, small);
    }

    &__text-container {
        color: $color-charcoal;
    }
}
