$flipper-size: 60px;
$thumbnail-size: 60px;

.ms-media-gallery {
    &__thumbnails-container {
        display: none;
        order: 0;
        flex-grow: 0;
        @include breakpoint(md) {
            display: flex;
        }
    }

    &__thumbnails-container .msc-ss-carousel {
        &__flipper i {
            height: $flipper-size;
            width: $flipper-size;
            border-radius: 100%;
            background-color: $color-bg-senary;
        }
        &__flipper {
            top: -8px;
            left: auto;
            height: 100%;
            padding: 0 10px;
            background-color: $color-bg-primary;
            transform: none;
            /* override default styles */
            &:hover {
                outline: 0 !important;
            }
            &:hover i,
            &:focus i {
                transform: scale(1.1);
                box-shadow: 2px 2px 4px transparentize($color: $color-black, $amount: 0.85);
            }
            &--next {
                right: 0;
            }

            .msi-chevron-left,
            .msi-chevron-up {
                @include add-mfrm-icon($icon-chev-left);
            }
            .msi-chevron-right,
            .msi-chevron-down {
                @include add-mfrm-icon($icon-chev-right);
            }
        }
        &-strip {
            padding: 15px 8px;
            @include breakpoint(md) {
                padding: 20px 8px 35px;
            }
            @include breakpoint(xl) {
                padding: 15px 8px 35px;
            }
        }
        &-slide {
            display: flex;
        }
    }
    &__thumbnail-item {
        min-width: $thumbnail-size;
        width: $thumbnail-size;
        height: $thumbnail-size;
        border-radius: 100%;
        transition: transform ease 0.3s;
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 15px;
        }
        &:hover {
            transform: scale(1.2);
        }
        .msc-empty_image {
            @include image-placeholder($thumbnail-size, $thumbnail-size);
        }
        &.product-video picture {
            @include add-mfrm-icon($icon-pause, before);
            position: relative;
            display: inline-block;
            height: 60px;
            &:before {
                top: 25px;
                left: 26.5px;
                z-index: 2;
                font-size: 8px;
                line-height: 12px;
                position: absolute;
                color: $color-text-secondary;
            }
            &:after {
                top: 18px;
                left: 18px;
                width: 24px;
                height: 24px;
                content: "";
                position: absolute;
                border-radius: 50%;
                background: $color-bg-primary;
            }
        }

        p {
            @include font-content($font-size-s, 400, 1);
            text-align: center;
            color: $color-text-tertiary;
            padding-top: 5px;
        }
        picture > div{
            height: 100%
        }
    }
    &__thumbnail {
        min-width: $thumbnail-size;
        height: 100%;
        border-radius: 100%;
        border: 1px solid $color-border-media-gallery-primary;
        object-fit: cover;
        transition: transform ease 0.3s, box-shadow ease 0.3s;
    }
    &__thumbnail-item-active {
        transform: scale(1.2);
        box-shadow: 0px 4px 4px transparentize($color: $color-black, $amount: 0.75);
        img {
            padding: 5px;
            border-color: $color-icon-hover;
        }
    }
    .thumbnail-image{
        width: 100%;
        height: 60px;
    }
}

//==============================================================================
// Vertical Thumbnails
//==============================================================================
.ms-media-gallery {
    &.vertical & {
        &__thumbnail-item:not(:last-child) {
            margin-bottom: 20px;
            margin-right: 0;
        }
        &__thumbnail-item-active {
            box-shadow: 4px 0 4px transparentize($color: $color-black, $amount: 0.75);
        }
    }

    .msc-ss-carousel-vert {
        &-strip {
            padding: 8px 15px;
        }
        &__flipper {
            right: 4px;
            padding: 10px;
            .msi-chevron-up,
            .msi-chevron-down {
                /* override scale effect */
                transform: rotate(90deg) !important;
            }
        }
    }
}
