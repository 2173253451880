.mfrm-wmap {
    &__confirmation {
        margin-top: 15px;
        margin-bottom: 40px;
        .field-group {
            margin-top: 5px;
            margin-bottom: 10px;
        }
        .field-control {
            width: 100%;
            margin-bottom: 5px;
        }
        .field-label {
            transition: transform ease 0.2s, font-size ease 0.2s;
            &::after {
                content: " *";
            }
        }
        &__cta {
            text-align: center;
        }
        &__cta-button {
            @include btn(primary, large);
            &.disabled,
            &:disabled {
                touch-action: none !important;
                user-select: none !important;
                pointer-events: none !important;
                cursor: default !important;
            }
        }
        &__disclaimer {
            margin: 25px 0;
            &__text {
                @include font-content($font-size-xxl, normal, 1.46);
                color: $color-text-primary;
                text-align: center;
            }
        }
        &__alert {
            @include font-content();
            padding: 0 15px;
            display: inline-block;
            color: $color-text-secondary;
        }
    }
}
