//==============================================================================
// BUTTONS/LINKS
//==============================================================================
%btn-muted {
  background: transparent;
  border: 0;
}

%link-muted {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

//==============================================================================
// SEARCH FORM
//==============================================================================
%form-control-search {
  @include no-outline();
  font-family: $font-family-primary;
  max-height: 40px;
  color: $color-input-text;
  font-size: $font-size-l;
  font-weight: 400;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 15px;
  line-height: 1;
  transition: border-color ease-in-out 0.3s;

  &::placeholder,
  &::-moz-placeholder,
  &::-webkit-input-placeholder {
    color: $color-input-placeholder;
  }
}

//==============================================================================
// INPUT FORM
//==============================================================================
%field-control-input {
  @include font-content($font-size-l, 400, 1.375);
  @include no-outline;
  max-height: $max-field-height;
  padding: 15px;
  color: $color-input-text;
  background-color: $color-white;
  border: 1px solid $color-input-border;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
  &:not(.msc-single-value-no-toggle):not(:disabled):not(.disabled):hover,
  &:not(.msc-single-value-no-toggle):not(:disabled):not(.disabled):focus {
    border-color: $color-input-border-hover;
  }
  &:not(.msc-single-value-no-toggle):not(:disabled):not(.disabled):focus {
    box-shadow: inset 0 0 0 1px $color-input-border-hover;
  }
  &:disabled,
  &.disabled {
    color: $color-input-disabled;
    user-select: none;
    touch-action: none;
    cursor: default;
    pointer-events: none;
  }
}

%field-control-input-rounded {
  padding: 15px 25px;
  border-radius: 50px;
  ~ .field-label {
    /* Override specifity if rounded applied */
    margin-left: 20px !important;
  }
}

%field-control-label {
  @include font-content();
  padding: 0 5px;
}

%float-label {
  ~ .field-label,
  + .msc-address-form__label {
    position: absolute;
    top: calc(#{$max-field-height} / 3);
    left: 0;
    margin-bottom: 0;
    margin-left: 10px;
    display: block;
    background-color: $color-white;
    pointer-events: none;
    transition: top ease-in-out 0.2s;
  }
  &:placeholder-shown + .field-label,
  &:placeholder-shown + .msc-address-form__label {
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  &:not(:placeholder-shown),
  &:focus:not(:placeholder-shown) {
    ~ .field-label,
    + .msc-address-form__label {
      visibility: visible;
      z-index: 1;
      opacity: 1;
    }
  }
  &.field-control--value,
  &:focus,
  &:active {
    ~ .field-label,
    + .msc-address-form__label {
      @include font-content($font-size: $font-size-s);
      @extend %field-control-label;
      transform: translateY(-19px);
    }
  }
}

%field-control-dropDown {
  @include font-content($font-size-l, 400, 1.375);
  @include no-outline;
  max-height: $max-field-height;
  padding: 15px;
  color: $color-input-text;
  background-color: $color-white;
  border: 1px solid $color-input-border;
  border-radius: 5px;
  box-sizing: border-box;
  transition: top ease-in-out 0.3s, transform ease-in-out 0.3s, border-color ease-in-out 0.3s;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus {
    border-color: $color-input-border-hover;
  }
  &:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0 1px $color-input-border-hover;
  }
  &:disabled,
  &.disabled {
    color: $color-input-disabled;
    user-select: none;
    touch-action: none;
    cursor: default;
    pointer-events: none;
  }
}
%dropDown-label {
  ~ .field-label,
  + .msc-address-form__label {
    @extend %field-control-label;
    position: absolute;
    top: -5px;
    left: 0;
    margin-bottom: 0;
    margin-left: 10px;
    display: block;
    background-color: $color-white;
    pointer-events: none;
    transition: top ease-in-out 0.2s, transform ease-in-out 0.2s;
  }
}

//==============================================================================
// RESET APPEARANCE
//==============================================================================

%reset-input-style {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}