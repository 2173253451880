.mfrm-content-reel {
    max-height: 500px;
    overflow-y: hidden;

    &__heading {
        margin: 25px auto;
        margin-top: 0;
        font-size: $font-size-xl;
        line-height: 1.2;

        @include breakpoint(lg) {
            font-size: $font-size-xxl;
            line-height: 1.5;
        }
    }
    &__text-white {
        color: $color-white;
    }
    &__text-red {
        color: $color-punch;
    }
    &__text-black {
        color: $color-black;
    }

    &__sale {
        border: 1px solid $color-mercury;
        border-radius: 5px;
        width: 285px;
        height: 339px;
        position: relative;
        color: $color-mercury;

        @include breakpoint(sm) {
            width: 226px;
            height: 287px;
        }
        @media (min-width: 800px) {
            width: 278px;
            height: 333px;
        }
    }

    &__square {
        border-radius: 5px;
        position: relative;
        width: 211px;
        height: 208.25px;

        &-image {
            width: 100%;
            height: 158.26px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        @include breakpoint(lg) {
            width: 330px;
            height: 305.5px;
            &-image {
                width: 100%;
                height: 247.5px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }

        &-no-image {
            border: 3px solid $color-white;
            border-radius: 5px;
            width: 211px;
            height: 208.25px;
     
            @include breakpoint(lg) {
                width: 330px;
                height: 305.5px;
            }
        }
    }

    &__cardBkgColor-white {
        background-color: $color-white;
    }

    &__sales-container {
        max-height: 335px;
        overflow-y: hidden;
    }

    &__badge-container {
        border-radius: 2px;
        position: absolute;
        top: 6px;
    }
    &__promo {
        min-width: fit-content;
        padding: 5px 10px;
        left: 6px;
    }
    &__countdown {
        right: 6px;
        min-width: fit-content;
        padding: 5px 8px;
        border: 0.5px solid $color-mercury;
        & > span.countdown-sm {
            width: 55px;
        }
        & > span.countdown-lg {
            width: 75px;
        }
    }

    &__title {
        white-space: nowrap;
        margin-bottom: 3px;
        font-size: 16px;
        font-weight: bold;

        &-square {
            white-space: nowrap;
            font-weight: bold;
            font-size: 16px;
    
            @include breakpoint(lg) {
                font-size: 24px;
            }

            &-no-image {
                white-space: normal;
                font-size: 22px;
                font-weight: bold;
                line-height: 22px;
        
                @include breakpoint(lg) {
                    font-size: 24px;
                    line-height: 28px;
                }
            }
        }
    }

    &__title.sales,
    &__title-square.sales {
        color: $color-punch;
    }

    &__text-container {
        color: $color-charcoal;
        height: 90px;
        padding-top: 14px;
        max-width: 220px;
        margin: auto;
        line-height: 18px;

        &-square {
            height: 35px;
            padding-top: 15px;
            max-width: 181px;
            line-height: 20px;
            margin: auto;
    
            @include breakpoint(lg) {
                height: 58px;
                padding-top: 15px;
                max-width: 300px;
                line-height: 28px;
            }

            &-no-image {
                height: 44px;
                padding-top: 57.75px;
                max-width: 188px;
                margin: auto;
        
                @include breakpoint(lg) {
                    height: 126px;
                    padding-top: 54px;
                    max-width: 277px;
                }
            } 
        }
    }

    a {
        text-decoration: none;
    }

    &__link {
        padding: 1px;
        &:focus {
            padding: 0;
            border: 2px solid $color-bombay !important;
            border-radius: 5px;
        }
        &:focus,
        &:hover {
            color: inherit;
            .mfrm-content-reel__title,
            .mfrm-content-reel__description-container,
            .mfrm-content-reel__title-square {
                text-decoration: underline;
            }
            .mfrm-content-reel__red-cta {
                background-color: $color-button-primary-background-hover;
            }
        }
    }

    &__description-container {
        white-space: normal;
        &-no-image {
            display: none;
    
            @include breakpoint(lg) {
                display: block;
                padding-top: 10px;
                line-height: 20px;
                white-space: normal;
            }
        }
    }

    &__icon-image {
        position: absolute;
        top: 113.25px;
        right: 10px;
        cursor: pointer;
        display: flex;
        background: $color-white;
        width: 35px;
        height: 35px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 9.5px 10.25px 9.5px 9.5px;

        @include breakpoint(lg) {
            top: 202.5px;
        }

        img {
            object-fit: contain;
        }
    }

    &__red-cta {
        @include btn(primary, default);
        margin-top: 15px;
        padding: 11px 30px;
        font-size: 14px;
        justify-content: center;

        @include breakpoint(lg) {
            margin-top: 10px;
            padding: 16px 62px;
            font-size: 16px;
        }
    }
}
