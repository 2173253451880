$cart-promo-button-height: 44px;
$cart-promo-button-border-radius: 50px;
$max-field-height: 44px;
.msc-order-summary__zipcode {
  speak: spell-out;
}
.msc-order-summary__items {
  .msc-promo-code {
    &__wrap {
      border-bottom: 1px solid $color-border-cart-secondary;
      padding-bottom: 15px;
      margin-bottom: 15px;
      a {
        @include vfi($color-black);
        @include font-content($font-size-m, normal, 1.43);
        text-decoration: underline;
      }
      a.msc-promo-code-enabled {
        display: block;
        margin-left: auto;
        width: 50px;
        margin-top: 15px;
      }
    }
    &__line-container {
      margin-bottom: 15px;
    }
    &__line-value {
      @include font-content($font-size-m, 500, 1.57);
      color: $color-text-store-timing;
      display: inline-block;
      width: calc(100% - 55px);
      padding: 0;
      &.msc-text-error {
        color: $color-error;
      }
    }
    &__line__btn-remove {
      @include vfi($color-black);
      display: inline;
      background: transparent;
      border: none;
      text-decoration: underline;
      @include font-content($font-size-m, normal, 1.57);
      padding: 0;
      color: $color-text-primary;
    }
    &-alert {
      color: $color-text-secondary;
      @include font-content($font-size-m, normal, 1.43);
      margin-top: 5px;
      padding-left: 25px;
    }
    &__form-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .msc-promo-code__group {
        padding-top: 0;
        margin-top: 10px;
      }
    }
    &__group {
      position: relative;
      width: 100%;
      .msc-promo-code__input-box {
        width: calc(100%);
        flex: none;
        padding-left: 24px;
        padding-right: 130px;
      }
    }
    &__input-box {
      @extend %field-control-input;
      @extend %field-control-input-rounded;
      @extend %float-label;
      @include font-content($font-size-m, 400, 1);
      width: calc(100% - 100px);
      display: block;
      margin: 0;
      position: relative;
      flex: none;
      max-height: 44px;
      box-sizing: border-box;
      @include font-content($font-size-l, normal, 1.37);
      //border: 2px solid $color-input-border-hover;
      &.field-control {
        &:not(:disabled):not(.disabled):focus {
          box-shadow: inset 0 0 0 1px $color-input-border-hover;
        }
      }
      & + .field-label {
        top: calc(#{$max-field-height} / 3);
      }
      &.field-control--error {
        border-color: $color-error;
        color: $color-error;
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
          border-color: $color-error;
        }
        &:not(:disabled):not(.disabled):focus {
          box-shadow: inset 0 0 0 1px $color-error;
          border-color: $color-error;
        }
      }
    }
    &__label {
      @extend %field-control-label;
    }
    &__apply-btn {
      @include font-content($font-size-l, 700, 1.25);
      order: 2;
      display: flex;
      height: $cart-promo-button-height;
      width: 107px;
      cursor: pointer;
      border-radius: $cart-promo-button-border-radius;
      align-items: center;
      justify-content: center;
      color: $color-button-primary-text;
      background: $color-bg-quaternary;
      border: 1px solid $color-bg-quaternary;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 1;
      &:hover,
      &:active,
      &:focus-visible {
        cursor: pointer;
        background: $color-button-primary-background-hover;
      }
      .button-module_btn-flex__7VoFL .loading-module_loading-ring__QpEas {
        margin: 0 !important;
      }
    }
  }
}

.msc-notification {
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: relative;
  &--error {
    background-color: $color-error;
    color: $color-white;
  }
  &--success {
    background-color: $color-green;
    color: $color-white;
  }
  &__message {
    @include font-content($font-size-s, 400, 1.2);
    padding: 0 40px;
    display: inline-block;
    vertical-align: middle;
    @include breakpoint(md) {
      @include font-content($font-size-s, 400, 1.67);
      padding: 12.5px;
    }
  }
  &__close {
    position: absolute;
    top: 6px;
    right: 16px;
    @include font-content(2.286rem, 300, 0.6);
    font-family: $font-family-system;
  }
}
