$carousel-indicator-size: 14px;
$carousel-indicator-spacing-top: 17px;
$carousel-indicator-spacing-left-right: 8px;
$carousel-indicator-color-active: $color-bg-quaternary;
$carousel-flipper-size-sm: 30px;
$carousel-flipper-size-lg: 60px;

.ms-media-gallery__carousel .msc-carousel {
    &__inner,
    img {
        border-radius: 10px;
    }
    &__control__prev,
    &__control__next {
        border: 0;
        margin: 0;
        width: $carousel-flipper-size-sm;
        height: $carousel-flipper-size-sm;
        opacity: 0.5;
        display: none;
        font-size: $icon-size-xs - 2;
        line-height: $carousel-flipper-size-sm;
        border-radius: 50%;
        text-align: center;
        color: $color-icon-secondary;
        background-color: $color-text-primary;
        transition: opacity ease 0.3s;
        &__icon {
            margin: 0 !important;
            height: auto;
            vertical-align: middle;
        }
        &:hover {
            /* override default carousel style */
            outline: 0 !important;
            opacity: 1;
        }
        @include breakpoint(md) {
            width: $carousel-flipper-size-lg;
            height: $carousel-flipper-size-lg;
            font-size: $icon-size-m - 1;
            line-height: $carousel-flipper-size-lg;
        }
    }
    &__control__prev {
        &__icon {
            margin-left: 12px;
        }
    }
    &__control__next {
        &__icon {
            margin-right: 12px;
        }
    }
    &__indicators {
        position: static;
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
        @include breakpoint(md) {
            display: none;
        }
        li {
            height: $carousel-indicator-size;
            width: $carousel-indicator-size;
            margin: 12px ;
            border: 0;
            opacity: 1;
            background-color: $color-bg-indicator;
            border-radius: 50%;
            transition: background-color ease 0.3s, transform ease 0.3s;
            &.active {
                background-color: $carousel-indicator-color-active;
                transform: scale(1.3);
            }
        }
    }
}
