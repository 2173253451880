//==============================================================================
// Buybox size dimensions
//==============================================================================
.msc-dimensions-lable {
    @include font-content($font-size-l, 500, 24px);
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: left;
    span {
        @include font-content($font-size-l, 400, 24px);
    }
}

.msc-skeleton {
    &-dimensions {
        display: flex;
        flex-direction: row;
        margin-bottom: -6px;
        @include breakpoint(md) {
            margin-bottom: -12px;
        }
        @include breakpoint(lg) {
            margin-bottom: -12px;
        }
        span {
            display: flex;
            margin-right: 5px;
            width: 85px;
            height: 32px;
            @include breakpoint(md) {
                height: 90px;
                width: 135px;
            }
            @include breakpoint(lg) {
                height: 90px;
                width: 135px;
            }
        }
    }
    &-dimension-label {
        margin-top: 35px;
    }
}

.msc-dimensions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    &-wrapper {
        border: 1px solid $color-bombay;
        border-radius: 4px;
        padding: 4px;
        margin-bottom: 8px;
        margin-right: 8px;
        display: inline-block;
        background-color: transparent;
        &:hover {
            border: 1px solid $color-bg-quaternary;
            box-shadow: inset 0 0 0 1px $color-bg-quaternary;
        }
        &-active {
            border: 1px solid $color-bg-quaternary;
            box-shadow: inset 0 0 0 1px $color-bg-quaternary;
        }
        &-disable {
            border: 1px solid $color-midgray;
            &:hover {
                border: 1px solid $color-midgray;
                box-shadow: inset 0 0 0 1px $color-midgray;
                color: $color-white;
                font-weight: bold;
            }
        }
        button {
            border-radius: 4px;
            color: $color-black;
            background-color: transparent;
            border: none;
            width: 78px;
            padding: 0 3px;
            height: 100%;
            vertical-align: middle;
            @include font-content($font-size-l, 400, 20px);
            cursor: pointer;
            @media (min-width: ($breakpoint-sm)) {
                width: auto;
                min-width: 72px;
                height: 25px;
                font-size: 14px;
            }
            @include breakpoint(md) {
                width: 171px;
                height: 64px;
            }
            @include breakpoint(lg) {
                width: 132px;
                white-space: normal;
                padding: 0 5px;
            }
            &:hover {
                color: $color-bg-quaternary;
            }
            &:active {
                background-color: $color-bg-quaternary;
                color: $color-white;
                font-weight: bold;
            }
            &:disabled {
                font-weight: normal;
                text-decoration: line-through;
                cursor: not-allowed;
                &.active {
                    font-weight: normal;
                }
            }
        }
    }
    .active {
        background-color: $color-bg-quaternary;
        color: $color-white;
        font-weight: bold;
        &:hover {
            background-color: $color-bg-quaternary;
            color: $color-white;
            font-weight: bold;
        }
    }
}