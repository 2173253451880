.reason-to-buy-container {
    padding: 34px 0;
    margin: 32px 0;
    border-top: 1px solid $color-mercury;
    border-bottom: 1px solid $color-mercury;
    @include breakpoint(lg) {
        margin-left: 64px;
    }
    .reason-to-buy {
        &__header-text {
            color: $color-black;
            margin-bottom: 32px;
            h2 {
                @include font-content($font-size-l, 700, 20px);
                @include breakpoint(md) {
                    @include font-content($font-size-xxl, 700, 28px);
                }
            }
        }
        &__item {
            display: flex;
            margin-bottom: 13px;
        }
        &__image {
            width: 31px;
        }
        &__detail {
            width: 100%;
            margin-left: 16px;
            text-align: left;
        }
        &__title {
            @include font-content($font-size-l, 700, 1.25);
            color: $color-black;
            margin-bottom: 12px;
        }
        &__description {
            @include font-content($font-size-l, 400, 1.25);
            color: $color-black;
        }
    }
}