.mfrm-hero-with-tabs {

    //Overwrite tab styles to match figma
    [class*="tabs-module_tablist"] {
        justify-content: center;
        display: flex;
        flex-wrap: nowrap;
    }

    [class*="tabs-module_tab-list-item"] {
        margin: 0 5px 0 0;
        width: auto;

        @include breakpoint(md) {
            margin: 0 15px 0 0;
        }
    }

    [class*="tabs-module_tab-item"] {
        border-bottom: 0;
        &:hover {
            border-bottom: 0;  
        }
    }

    [class*="tabs-module_indicator"] {
        height: 5px; 
    }

    //Sleep disruptor specific styles
    &__sleep-disruptors {
        &-container {
            display: flex;
            flex-direction: column-reverse;
        }

        &-tabs-wrapper {
            height: 60px;
            background-color: $color-alabaster;
            overflow: visible;
        }

        &-tabs-title {
            @include font-content($font-size-l, 500, 1.375);
            @extend %btn-muted;
            padding: 19px 5px 19px 5px;
    
            &[aria-selected="true"] {
                font-weight: 700;
            }

            @include breakpoint(xs) {
                padding-left: 15px;
                padding-right: 15px;
            }
    
            @include breakpoint(md) {
                font-size: $font-size-xl;
            }
        }

        &-tab-hero {
            display: none;

            &.active {
                display: flex;
                position: relative;
                flex-direction: column-reverse;
                @include breakpoint(md) {
                    flex-direction: row; 
                }
            }
        }

        &-tab-items {
            display: none;
            &.active {
                display: block;
            }
        }

        &-text-container {
            width: 100%;
            padding: 8%;
    
            @include breakpoint(md) {
                width: 35.286%;
                padding: 0 5% 0 2.5%;
                margin: auto;
            }
    
            @include breakpoint(lg) {
                width: 34.166%;
                padding: 0 5% 0 2.5%;
                margin: auto;  
            } 
        }

        &-title-container {
            @include font-content($font-size-xxxxl, 700, 1.22);
            padding-bottom: 10px;
    
            @include breakpoint(md) {
                padding-bottom: 7.03%;
            }
    
            @include breakpoint(lg) {
                font-size: 44px;
                line-height: 48px;
                padding-bottom: 3.95%;
            }
        }

        &-description-container {
            @include font-content($font-size-l, 400, 1.375);
    
            @include breakpoint(lg) {
                font-size: $font-size-xl;
            }
        }

        &-image {
            width: 100%;
            img {
                min-height: 120px;
            }
    
            @include breakpoint(md) {
                width: 64.714%;
                img {
                    min-height: 300px;
                }
            }
    
            @include breakpoint(lg) {
                width: 65.834%;
                img {
                    min-height: 400px;
                }
            }
        }
    }
}