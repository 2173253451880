$pencil-banner-background: #F2F5FF;
$pencil-banner-text-color: #0B1232;
$pencil-banner-cta-text-color: #2D2926;

@mixin pencil-banner-text {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $pencil-banner-text-color;
}

@mixin pencil-banner-cta-text {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: $pencil-banner-cta-text-color;
}

@mixin focus-states {
  &:focus-visible {
      color:$pencil-banner-text-color;
      outline: none;
      border-color: $color-charcoal;
  }
}

.carousel {
  width: 95%;
  padding: 15px 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 22px;

  @include breakpoint(lg){
    width: 65%;
  }

  >div {
    flex-grow: 1;
  }

  >div>div>div {
    height: auto !important;
    min-height: 22px !important;
  }

  .mfrm-pencil-banner-item {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;

    > a {
      background-color: transparent;

      &:hover {
        color: $pencil-banner-text-color;
        text-decoration: none ;
      }

      span {
        p {
          display: inline;
        }
      }
    }

    &-link {
      width: 90%;
      line-height: 14px;
      border: 1px solid transparent;
      @include focus-states();
    }
  }

}

.mfrm-pencil-banner {
  &_container {
    background: $pencil-banner-background;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 40px;
  }

  &_html {
    &_anchor {
      text-decoration: none;
      background-color: transparent;
      @include pencil-banner-text;

      &:hover {
        color: $pencil-banner-text-color;
        text-decoration: none !important;
      }
    }

    &_container {
      @include pencil-banner-text;
    }
  }

  &_countdown {
    &_container {
      display: inline-block;
      margin-left: 4px;
    }

    &_text {
      @include pencil-banner-text;
      color: $color-punch;

      .second {
        width: 16px;
        display: inline-block;
      }
    }
  }

  &_cta {
    &_anchor {
      margin-left: 8px;
      @include pencil-banner-cta-text;
      text-decoration: underline;

      &:hover {
        color: $pencil-banner-cta-text-color;
        text-decoration: underline;
      }
    }
  }
}