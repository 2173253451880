$wmap-cs-container-width: 220px;
$wmap-border-color-nav: $color-athensgray;
$wmap-default-padding: 30px;
$wmap-table-border-color: #dde3e8;
$wmap-radio-bg-color: #f1f1f1;
$wmap-radio-border-color: $color-bombay;
$wmap-radio-spacing: 20px;

//==============================================================================
// @import "filepath"
//==============================================================================
@import "wmap-amount-to-pay";
@import "wmap-base";
@import "wmap-billing-address";
@import "wmap-customer-service";
@import "wmap-order-summary";
@import "wmap-payment";
@import "wmap-confirmation";
@import "wmap-thankyou";
@import "wmap-alerts";
@import "wmap-print-page";
@import "wmap-modal";
