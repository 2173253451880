.ms-checkout-section-container.col-12 {
  padding: 0;
}

// Override backdrop for Synchrony and Paypal
.iframe-synchrony {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999999;
  width: 100vw;
  height: 100vh !important;
}
.iframe-paypal {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh;
  z-index: 12051 !important;
  margin: 0 !important;
}

//HIDE PAYMENT CHANGE BUTTON
.checkout-mfrm-checkout-section-container,
.mfrm-checkout-section-container {
  .ms-checkout__guided-card-btn-edit {
    display: none;
  }
}
//Submit Order Disable
.submit-order-disable {
  pointer-events: none;
  touch-action: none;
  cursor: default !important;
}
// connectors visibility
.ms-checkout-payment-instrument {
  &--visible {
    visibility: visible;
    cursor: default;
  }

  &--hidden {
    visibility: hidden;
    width: 0;
    height: 0;
    opacity: 0;
    .ms-checkout-payment-instrument__loading-background {
      display: none;
    }
  }
}
// Payment Loading
.ms-checkout-payment-instrument {
  &__loading-background {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin: ($spacer * 2) 0;
    &--full {
      position: fixed;
      height: 100vh;
      width: 100%;
      top: 0;
      left: 0;
      background-color: transparentize($color-white, 0.15);
      z-index: 1;
    }
  }
  &__loading-message {
    @include font-content($font-size-l, 500, 1);
    margin-top: 15px;
  }
}

// Error Alert
.ms-checkout-payment-instrument {
  &__error {
    @include alert(error);
  }
  &__button,
  &__link {
    margin-top: 20px;
    margin-bottom: 15px;
    cursor: pointer;
  }
  &__button {
    @include btn(primary, small);
  }
  &__link {
    @include vfi-tab($color-black);
    @include font-content($font-size-l, bold, 1.12);
    @include add-mfrm-icon($icon-chev-right, after);
    @extend %link-muted;
    @extend %btn-muted;
    padding: 0;
    display: inline-block;
    color: $color-text-secondary;
    &::after {
      margin-left: 5px;
      vertical-align: middle;
      font-size: ($icon-size-xs - 4);
      font-weight: bold;
    }
  }
  &__hidden,
  &.hidden {
    display: none;
  }
}

// Payment Method Summary

.ms-checkout-payment-instrument {
  &__show {
    width: 100%;
    max-width: 345px;
    display: none !important;
    flex-flow: column wrap;
    order: 4;
    border-radius: 8px;
    border: 1px solid $color-border-checkout-secondary;
    box-shadow: 0px 2px 5px lighten($color-input-border, 15%);
    background-color: $color-bg-order-summary;
    padding: 15px;
    margin: 15px auto;
    @include breakpoint(sm) {
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }
    p {
      @include font-content($font-size-m, normal, 1.43);
    }
  }
  &__card-title,
  &__billing-address-title {
    font-weight: bold !important;
    border-bottom: 1px solid $color-border-checkout-secondary;
    padding-bottom: 5px;
    margin-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
  }
  &__billing-address-title {
    margin-top: 10px;
  }
  &__card-name,
  &__card-type-id,
  &__card-number,
  &__card-expiration,
  &__billing-address-1,
  &__billing-address-3 {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &::before {
      content: "-";
      color: $color-punch;
      margin-right: 5px;
    }
  }
  &__btn-edit {
    @include btn(primary, small);
    margin: 10px auto 0;
    display: flex;
  }
  &__separator {
    @include full-width();
    height: $separator-height;
    background-color: $color-bg-cart-primary;
    @include breakpoint(lg) {
      display: none;
    }
  }
  &__paypal-email {
    @include font-content($font-size-l, 400, 1.39);
    margin-bottom: 5px;
  }
}

//==============================================================================
// PAYMENT METHODS
//==============================================================================

$radio-size: 25px;
$radio-size-selected: 15px;
$radio-size-space-between: 15px;

%payment-radio-select-buttons {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $color-text-primary;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
  }
  &::before {
    position: relative;
    height: $radio-size;
    width: $radio-size;
    margin-right: $radio-size-space-between;
    background-color: $color-white;
    border-width: 2px;
    border-style: solid;
    border-color: $color-input-border;
  }
  &[aria-checked="true"] {
    &::before {
      border-color: $color-bg-quaternary;
    }
    &::after {
      position: absolute;
      left: 5px;
      height: $radio-size-selected;
      width: $radio-size-selected;
      background-color: $color-bg-quaternary;
    }
  }
}

.ms-checkout__guided-form.__payment {
  .ms-checkout__guided-card-header,
  .ms-checkout__guided-card-title {
    margin-bottom: 0;
  }
  .ms-checkout__guided-card-title {
    text-align: center;
    @include breakpoint(lg) {
      text-align: left;
    }
  }
}
.ms-checkout-payment-instrument-creditcard,
.ms-checkout-payment-instrument-paypal,
.ms-checkout-payment-instrument-progressive,
.ms-checkout-payment-instrument-synchrony {
  position: relative;
  &__radio-select {
    @include font-content($font-size-xl, 500, 1.39);
    @extend %payment-radio-select-buttons;
    @include breakpoint(lg) {
      @include font-content(($font-size-xl + 0.14), 500, 1.25);
    }
    &:focus span {
      @include breakpoint(lg) {
        outline: 1px dotted $color-black;
      }
    }
    &-input {	
      position: absolute;	
      left: 0;	
      top: 0;	
      width: 100%;	
      height: 0;	
      opacity: 0;	
      cursor: pointer;	
      &[aria-checked="false"] {	
        height: 100%;	
      }	
      &:focus-visible+label {	
        outline: 1px dotted black;	
      }	
    }
  }
  &__duration {
    &__radio-select {
      @include font-content($font-size-l, bold, 1.25);
      @extend %payment-radio-select-buttons;
      margin-bottom: 15px;
      &:first-of-type {
        margin-top: 15px;
      }
      &:focus span {
        @include breakpoint(lg) {
          outline: 1px dotted $color-black;
        }
      }
    }
    // Links inside Radio
    &__link {
      @include link-with-arrow(after);
      @include font-content($font-size-l, 500, 1.12);
      margin-bottom: 15px;
      margin-left: ($radio-size + $radio-size-space-between);
      &:hover,
      &:focus {
        text-decoration: none;
      }
      &:focus > * {
        @include breakpoint(lg) {
          outline: 1px dotted $color-black;
        }
      }
    }
    &__financing-offer-details {
      margin-left: ($radio-size + $radio-size-space-between);
      margin-bottom: 15px;
    }
  }
  // Primary Links
  &__link {
    @include link-with-arrow(after);
    @include font-content($font-size-l, 500, 1.12);
    margin-bottom: 15px;
    margin-left: 40px;
    &:hover,
    &:focus {
      text-decoration: none;
    }
    &:focus > * {
      @include breakpoint(lg) {
        outline: 1px dotted $color-black;
      }
    }
    .ms-checkout-payment-instrument-synchrony__special-offer & {
      margin-left: 0;
    }
  }
  &__campaign-link {
    margin-left: 0 !important;
  }
  &__campaign-message {
    margin: 0 0 10px 40px;
  }
  &__info {
    @include vfi-tab($color-black);
    @include font-content($font-size-m, normal, 1.43);
    margin-bottom: 15px;
    color: $color-text-primary;
    p,
    h4 {
      @include font-content($font-size-m, normal, 1.43);
      color: $color-text-primary;
    }
    .ms-checkout-payment-instrument-synchrony__special-offer & {
      margin-left: 0;
      margin-bottom: 20px
    }
  }
  &__selected-email {
    @include font-content($font-size-m, normal, 1.43);
    margin-top: ($grid-gutter-width / 2);
    margin-bottom: ($grid-gutter-width / 2);
    color: $color-text-primary;
  }
  &__remove-link {
    @include font-content($font-size-m, normal, 1.43);
    @include vfi-tab();
    color: $color-text-secondary;
    text-decoration: underline;
  }
}

// Credit Card

.ms-checkout-payment-instrument-creditcard {
  margin-top: $spacer;
  cursor: pointer;
  @include breakpoint(md) {
    margin-top: ($spacer * 2);
  }
  .ms-checkout-section-container__item.initialized.pending {
    .ms-checkout-payment-instrument__add {
      visibility: hidden;
      height: 0;
    }
  }
  iframe {
    width: 100%;
    display: flex;
    box-sizing: border-box;
  }
  .ms-checkout-payment-instrument__btn-save {
    display: none;
  }
  .ms-checkout-billing-address {
    margin: 0;
  }
  .ms-checkout-payment-instrument__add {
    &.is-fetching {
      visibility: hidden;
      height: 0;
    }
  }
  &__radio-select-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__radio-select {
    margin-right: 5px;
    margin-bottom: 13px;
  }
  &-icons {
    display: none;
    flex-flow: row wrap;
    margin-top: 2px;
    margin-bottom: 10px;
    @media only screen and (min-width: $breakpoint-xs) {
      display: flex;
    }
    img {
      margin-right: 5px;
      min-height: 20px;
      height: 20px;
      max-height: 20px;
    }
    picture:last-child img {
      margin-right: 0;
    }
    &.visa img:not([title="visa"]),
    &.mastercard img:not([title="mastercard"]),
    &.amex img:not([title="amex"]),
    &.discover img:not([title="discover"])  {
      opacity: 0.3;
    }
  }
}

// Paypal

.ms-checkout-payment-instrument-paypal,
.ms-checkout-payment-instrument-synchrony {
  cursor: pointer;
  margin-top: (($spacer * 2) + 15px);
  @include breakpoint(md) {
    margin-top: ($spacer * 2);
  }
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  &__radio-select[aria-checked="false"] {
    margin-bottom: (($spacer * 2) + 15px);
    @include breakpoint(md) {
      margin-bottom: ($spacer * 2);
    }
  }
  .ms-checkout-payment-instrument__container {
    width: 100%;
    flex-basis: 100%;
    order: 3;
    cursor: default;
  }
  .checkout-payment-instrument__iframe {
    @include full-width;
    @include add-grid-padding-tab;
    width: 100vw;
    position: relative;
    margin-top: 15px;
    z-index: 1;
    @include breakpoint(lg) {
      width: 90vw;
      margin-left: 0;
      margin-right: 0;
    }
    @include breakpoint(xl) {
      width: 80vw;
    }
  }
  .ms-checkout-payment-instrument__btn-save {
    display: none;
  }
  &__image {
    @include vfi-tab($color-black);
    width: 56px;
    height: auto;
    order: 1;
    display: flex;
    align-self: flex-start;
    margin-top: -20px;
    margin-bottom: 20px;
    @media (min-width: ($breakpoint-xxs - 17)) {
      margin-top: 5px;
      margin-bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__info {
    margin-top: 30px;
    margin-bottom: 0;
    order: 2;
    flex-basis: 100%;
    cursor: default;
  }
}

.ms-checkout-payment-instrument-paypal {
  margin-top: 30px;
  @include breakpoint(md) {
    margin-top: 15px;
  }

  &__hidden-paypal {
    position: absolute;
    top: 46px;
    opacity: 0.001;
    z-index: 999;

    .checkout-payment-instrument__iframe {
      width: 65px !important;
      margin-left: -20px !important;

      @include breakpoint(md) {
        width: 90px !important;
        margin-left: -33px !important;
      }

      @include breakpoint(lg) {
        width: 45px !important;
        margin-left: 0px !important;
      }
    }
  }

}

//Synchrony
.ms-checkout-payment-instrument-synchrony {
  margin-top: 0px;
  margin-bottom: 20px;
  iframe {
    width: 100%;
    height: auto;
    @include breakpoint(lg) {
      width: 80vw;
      &.iframe-synchrony {
        width: 100%;
      }
    }
  }
  &__radio-select[aria-checked="false"] {
    margin: 0;
  }
  &__image {
    width: 70px;
    height: auto;
  }
  &__info {
    color: $color-text-tertiary;
    margin: 0 0 10px 40px;
    p,
    h4 {
      color: $color-text-tertiary;
    }
    &.primary,
    &.secondary,
    &.subtitle,
    &.cash-back,
    &.subtitle-cash-back {
      color: $color-text-primary;
      p,
      h4 {
        color: $color-text-primary;
      }
    }
    &.primary,
    &.secondary,
    &.subtitle,
    &.note,
    &.cash-back,
    &.subtitle-cash-back {
      margin-left: 0;
    }
    &.subtitle {
      margin-bottom: 30px;
    }
    &.primary {
      @include font-content($font-size-l, bold, 1.25);
      p,
      h4 {
        @include font-content($font-size-l, bold, 1.25);
      }
    }
    &.note {
      color: $color-text-tertiary;
      display: none;
      p,
      h4 {
        color: $color-text-tertiary;
      }
    }
    &.cash-back {
      margin-bottom: 25px;
    }
    &.subtitle-cash-back {
      margin-bottom: 10px;
    }
  }
  .ms-checkout-payment-instrument__btn-save {
    display: none;
  }
  &__error-message {
    padding: 15px;
    background: $color-bg-error;
    h3 {
      @include font-content($font-size-l, 700, 1.25);
      color: $color-text-secondary;
      margin-bottom: 15px;
      }
    p {
      @include font-content($font-size-l, normal, 1.375);
      color: $color-text-primary;
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
    a {
      text-decoration: underline;
      }
    }
  }
}

.sync-cta-wrapper {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
  @include breakpoint(md) {
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: baseline;
  }
  &.sync-cta-prequalify {
    align-items: center;
    flex-direction: column-reverse;
    padding-bottom: 5px;
    @include breakpoint(md) {
        flex-direction: row;
    }
  }
}

.msax-ApplyNow {
  @include btn(primary, large);
  margin-bottom: 15px;
  @include breakpoint(md) {
    margin-bottom: 0;
  }
  &.msax-prequalify {
    display: inline-block !important;
    margin: 0;
    @include breakpoint(md) {
      margin-top: 23px;
    }
  }
}

.msax-Submit {
  @include font-content($font-size-m, normal, 1.43);
  @extend %btn-muted;
  margin-bottom: 0px;
  text-decoration: underline;
  color: $color-text-primary;
  @include breakpoint(md) {
    margin-bottom: 0;
    margin-left: 30px;
  }
  @include breakpoint(lg) {
    @include vfi($color-black);
  }
}

.syfdymodal-open #submitBtn {
  visibility: hidden;
}

.syfdymodal-dialog {
  margin-top: 80px !important;
  @include breakpoint(md) {
    margin: 120px auto !important;
  }
}

.sync-prequalify-text {
    text-align: center;
    @include font-content($font-size-m, normal, 1.43);
    color: $color-text-tertiary;
    @include breakpoint(md) {
        text-align: left;
    }
}
.msax-hidden {
  .msax-ApplyNow {
    display: none !important;
  }
}