/*
 * A collection of variables to override Bootstrap4 defaults. @see /node_modules/bootstrap/scss/_variables.scss
 */

/** Spacer sizes, used for classes like my-2, pl-md-4, mt-lg-0, etc */
$spacer: 15px;
$spacers: (
  0: 0,
  1: ($spacer * 0.5),
  2: $spacer,
  3: ($spacer * 2),
  4: ($spacer * 3),
  5: ($spacer * 4),
  6: ($spacer * 6),
  7: ($spacer * 7),
  8: ($spacer * 8)
);

/** Default Bootstrap breakpoints, with addition of xxl. Grid breakpoints are based on minimum width media queries, meaning they apply to that one breakpoint and all those above it (e.g., .col-sm-4 applies to small, medium, large, and extra large devices, but not the first xs breakpoint). @link https://getbootstrap.com/docs/4.0/layout/grid/#how-it-works */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

/** A custom implementation of breakpoints for D365 modules can be found @see /src/themes/mattressfirm/styles/00-abstract/responsive-breakpoints.scss */

/** Font defaults */
$h1-font-size: 3.1429rem;
$headings-font-weight: 700;
