.mfrm-action-card {
    @include vfi($color-black);
    @include no-hover-state;
    @include no-decoration;
    position: relative;
    min-height: 160px;
    min-width: 226px;
    margin-right: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid $color-border-action-card-primary;
    border-radius: 2px;
    border-bottom: 2px solid $color-border-action-card-secondary;
    outline-offset: -2px;
    .sr-only {
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
    }
    @include breakpoint(md) {
        min-width: auto;
    }
    &:last-child {
        margin-right: 0;
    }
    &__wrapper {
        display: flex;
        flex-flow: column wrap;
    }
    &__icon {
        margin-bottom: 10px;
        &.icon-checkmark-outline {
            @include add-mfrm-icon($icon-checkmark-outline);
        }
        &.icon-phone {
            @include add-mfrm-icon($icon-phone);
        }
        &.icon-chat-outline {
            @include add-mfrm-icon($icon-chat-outline);
        }
        &::before {
            font-size: ($icon-size-l - 2);
            color: $color-icon-primary;
        }
        &-chevron {
            @include add-mfrm-icon($icon-chev-right);
            margin-top: 20px;
            align-self: flex-end;
            color: $color-icon-primary;
            &:before {
                font-size: ($icon-size-m - 1);
                font-weight: bold;
            }
        }
    }
    &__heading {
        @include font-content($font-size-xl, 700, 1.22);
        margin-bottom: 10px;
        color: $color-text-secondary;
    }
    &__description {
        line-height: 1.29;
    }
}