$breakpoint-md: 768px;
$breakpoint-lg: 1024px;

body.mfrm-wmap-page {

  .mfrm-header__wrapper {
    height: auto;
  }

  .mfrm-header__menu-bar-wrapper {
    display: none;
  }

  .mfrm-search__wrapper {
    display: none;
  }

  .utility-icons {
    width: 55px;
    justify-content: center;

    .utility-icon {
      &.cart-icon {
        display: none;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    .utility-icons {
      display: flex;
    }
  }

  @media (min-width: $breakpoint-lg) {

    .mfrm-header__wrapper {
      height: 88px;
    }

    .mfrm-header__menu-bar-wrapper {
      display: flex;
    }

    .utility-icons {
      display: flex;
      width: 215px;
      justify-content: space-between;
    }

    .mfrm-search__wrapper {
      display: flex;
      visibility: hidden;
    }
  }
}