/*
 * Carousel base styles from starter-kit (modified and refined)
 * @msdyn365-commerce-modules\starter-theme-pack\src\modules\starter
 */

//==============================================================================
// Carousel - Primary Component Global Styles
//==============================================================================

$carousel-enable-transitions: true;
$carousel-enable-prefers-reduced-motion-media-query: true;
$carousel-base-transition: transform ease-in-out 0.2s;

@mixin carousel-transition($transition...) {
  @if $carousel-enable-transitions {
    @if length($transition) == 0 {
      transition: $carousel-base-transition;
    } @else {
      transition: $transition;
    }
  }

  @if $carousel-enable-prefers-reduced-motion-media-query {
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
}

$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$color-white}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);

$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$color-white}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);

$carousel-primary-base-class: msc-carousel;

$carousel-primary-dismiss-color: $color-white;
$carousel-primary-dismiss-bg-color: $color-black;

$carousel-primary-caption-color: $color-white;
$carousel-primary-caption-position: 15%;
$carousel-primary-caption-padding: 20px;

$carousel-primary-flipper-size: 48px;
$carousel-primary-flipper-size-icon: 20px;
$carousel-primary-flipper-opacity: 1;
$carousel-primary-flipper-opacity-hover: 0.9;
$carousel-primary-flipper-transition-duration: 0.15s;

$carousel-primary-indicator-transition-duration: 0.6s;
$carousel-primary-indicator-margin: 6px;
$carousel-primary-indicator-size: 16px;

$carousel-primary-item-transition-duration: 0.6s;

.msc-carousel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__anchor {
    border: 0px solid transparent;
    &:hover{
      color: black;
      text-decoration: none;
    }
  }

  &__inner {
    @include clearfix;
    position: relative;
    width: 100%;
    color: $color-white;
    overflow: hidden;
    @include breakpoint(md) {
      color: $color-black;
    }
  }

  &__item {
    @include carousel-transition(transform ease-in-out $carousel-primary-item-transition-duration);
    position: relative;
    height: 100%;
    width: 100%;
    margin-right: -100%;
    display: none;
    text-decoration: none;
    float: left;
    backface-visibility: hidden;
    &.active {
      text-decoration: none;
    }
    &.active,
    &__next,
    &__prev {
      display: block;
    }
  }

  &__control__prev,
  &__control__next {
    @include clearfix;
    @include carousel-transition(opacity ease $carousel-primary-flipper-transition-duration);
    position: absolute;
    bottom: 0;
    top: calc(50% - 24px);
    height: $carousel-primary-flipper-size;
    width: $carousel-primary-flipper-size;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: $carousel-primary-flipper-opacity;
    transition: none;
    z-index: 16;
    &:hover,
    &:focus {
      opacity: $carousel-primary-flipper-opacity-hover;
      text-decoration: none;
      &__icon {
        outline: 0;
      }
      &__icon::before {
        outline: 0;
      }
    }
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      outline-offset: 1px;
    }
    &__icon {
      height: $carousel-primary-flipper-size-icon;
      width: $carousel-primary-flipper-size-icon;
      display: inline-block;
      background: no-repeat 50% / 100% 100%;
    }
  }

  &__control__prev {
    left: 0;
    margin-left: 2px;
    justify-content: flex-start;
    &__icon {
      @include add-mfrm-icon($icon-chev-left);
      margin-left: 15px;
    }
  }

  &__control__next {
    right: 0;
    margin-right: 2px;
    justify-content: flex-end;

    &__icon {
      @include add-mfrm-icon($icon-chev-right);
      margin-right: 15px;
    }
  }

  &__dismiss {
    @include add-mfrm-icon($icon-close);
    position: absolute;
    right: 0;
    top: 0;
    background-color: $carousel-primary-dismiss-color;
    border-color: $carousel-primary-dismiss-color;
    color: $carousel-primary-dismiss-color;

    &:before {
      position: unset;
      font-size: $icon-size-m + 2;
      text-align: center;
      vertical-align: text-bottom;
      visibility: visible;
    }
  }

  &__caption {
    position: absolute;
    left: $carousel-primary-caption-position;
    right: $carousel-primary-caption-position;
    bottom: 20px;
    padding-top: $carousel-primary-caption-padding;
    padding-bottom: $carousel-primary-caption-padding;
    color: $carousel-primary-caption-color;
    text-align: center;
    z-index: 10;
  }

  &__indicators {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 16px;
    margin-right: $carousel-primary-indicator-margin;
    margin-left: $carousel-primary-indicator-margin;
    padding-left: 0;
    display: flex;
    justify-content: center;
    list-style: none;
    z-index: 15;

    li {
      @include carousel-transition(opacity ease $carousel-primary-indicator-transition-duration);
      height: $carousel-primary-indicator-size;
      width: $carousel-primary-indicator-size;
      margin-right: $carousel-primary-indicator-margin;
      margin-left: $carousel-primary-indicator-margin;
      border: 1px solid $color-white;
      border-radius: 50%;
      background: transparent;
      background-clip: padding-box;
      box-sizing: content-box;
      cursor: pointer;
      flex: 0 1 auto;
      opacity: 1;
      text-indent: -999px;
    }

    .active {
      background: $color-white;
      opacity: 1;
    }

    @include breakpoint(md) {
      left: 0;
      bottom: 48px;
    }
  }

  /* Misc. out of box styles */
  .pointer-event {
    touch-action: pan-y;
  }
}
/* Carousel Fade */

.msc-carousel.carousel-fade {
  &__item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }
  .msc-carousel__item.active,
  .msc-carousel__item__next.msc-carousel__item__left,
  .msc-carousel__item__prev.msc-carousel__item__right {
    z-index: 1;
    opacity: 1;
  }

  .active.msc-carousel__item__left,
  .active.msc-carousel__item__right {
    @include carousel-transition(opacity ease $carousel-primary-flipper-transition-duration);
    z-index: 0;
    opacity: 0;
  }
}

.#{$carousel-primary-base-class}__item__next:not(.#{$carousel-primary-base-class}__item__left),
.active.#{$carousel-primary-base-class}__item__right {
  transform: translateX(100%);
}

.#{$carousel-primary-base-class}__item__prev:not(.#{$carousel-primary-base-class}__item__right),
.active.#{$carousel-primary-base-class}__item__left {
  transform: translateX(-100%);
}

//==============================================================================
// Carousel - Secondary Component Global Styles
//==============================================================================

$carousel-secondary-flipper-bg-color: $color-bg-senary;
$carousel-secondary-flipper-icon-color: $color-icon-secondary;
$carousel-secondary-flipper-border-color: transparent;
$carousel-secondary-flipper-border-radius: 50%;
$carousel-secondary-flipper-size: 48px;
$carousel-secondary-flipper-size-icon: $icon-size-m;
$carousel-secondary-flipper-disabled-opacity: 0;

/* ---------------------------------------------- *
 *    Carousel Secondary - Horizontal/Vertical    *
 * ---------------------------------------------- */

.msc-ss-carousel,
.msc-ss-carousel-vert {
  position: relative;
  height: auto;
  width: 100%;
  touch-action: pan-y pinch-zoom;

  &__flipper {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 1px solid $carousel-secondary-flipper-border-color;
    outline-offset: 2px;
    z-index: 1;
    &--next {
      left: auto;
      right: 0;
    }
    i {
      height: $carousel-secondary-flipper-size;
      width: $carousel-secondary-flipper-size;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $carousel-secondary-flipper-icon-color;
      font-size: $carousel-secondary-flipper-size-icon;
      border-radius: $carousel-secondary-flipper-border-radius;
      background-color: $carousel-secondary-flipper-bg-color;
      transition: color ease 0.3s;
      z-index: 1;
    }
    .msi-chevron-left {
      @include add-mfrm-icon($icon-chev-left);
    }
    .msi-chevron-right {
      @include add-mfrm-icon($icon-chev-right);
    }
  }

  &__flipper:disabled,
  &__flipper.disabled {
    display: none;
    opacity: $carousel-secondary-flipper-disabled-opacity;
  }

  &-strip {
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  &-slide {
    position: relative;
    left: 0;
    display: inline-block;
    padding-inline-start: 0;
    transition: left cubic-bezier(0.16, 1, 0.29, 0.99) 0.667s;
    white-space: nowrap;
  }
  .msc-tooltip {
    /* override inline style */
    display: none !important;
  }
}

/* ----------------------------------------------- *
 *     Carousel Secondary - Vertical Overrides     *
 * ----------------------------------------------- */

.msc-ss-carousel-vert {
  width: auto;
  height: 100%;
  display: inline-block;
  touch-action: pan-y pinch-zoom;

  &-item {
    display: block;
    white-space: normal;
  }

  &-strip {
    width: auto;
    height: 100%;
  }
  &-slide {
    display: block;
  }
  &__flipper {
    top: 0;
    left: auto;
    height: auto;
    transform: none;
    &--next {
      bottom: 0;
      right: auto;
      top: auto;
    }
    .msi-chevron-up {
      @include add-mfrm-icon($icon-chev-up);
    }
    .msi-chevron-down {
      @include add-mfrm-icon($icon-chev-down);
    }
  }
}
