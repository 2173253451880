.ms-checkout-express-paypal {
    width: 100%;

    &__section-heading {
        @include font-content($font-size-l, bold, 1.25);
        @include vfi-tab($color-black);

        margin-top: 15px;
        margin-bottom: 5px;

        @include breakpoint(lg) {
            margin-top: 30px;
        }
    }

    &__section-container {
        background-color: $color-bg-primary;
        padding: 15px;
        margin-bottom: 5px;
    }

    &__content-wrapper {
        margin: 15px;
    }

    &__price-section {
        display: flex;
        justify-content: space-between;
        margin-top: -2px;
    }

    &__price {
        @include font-content($font-size-l, bold, 1.68);

        &.discount {
            display: flex;
            flex-direction: row-reverse;
            color: $color-text-secondary;

            p {
                color: $color-text-primary;
                @include font-content($font-size-m, 700, 1.42);
                display: inline-block;

                span {
                    color: $color-text-tertiary;
                    margin-left: 5px;
                    text-decoration: line-through;
                    @include font-content($font-size-s, 400, 1.66);
                }
            }

            @include breakpoint(md) {
                @include font-content($font-size-xl, 700, 1.25);

                p {
                    display: block;

                    label {
                        display: inline;
                    }

                    span {
                        @include font-content($font-size-s, 400, 1.68);
                    }
                }
            }

            .msc-cart-line__product-price {
                display: block;
                text-align: right;
                margin-top: 3px;
                margin-right: 5px;

                label {
                    display: none;

                    @include breakpoint(md) {
                        display: inline-block;
                    }
                }
            }
        }

        &-discounted {
            @include font-content($font-size-l, 700, 1.68);
        }
    }

    &__delivery-date {
        @include font-content($font-size-m, 400, 1.68);
        margin: 15px 0;
        @include vfi-tab($color-black);
    }

    &__delivery-only-date {
        margin: 0 0 15px 0 !important;
    }

    &__wrapper {
        margin: 0 0 30px 0;
        padding: 0;

        &:last-child {
            margin: 0;
        }
    }

    &__product {
        &-image {
            float: left;
            margin-right: 10px;
            width: 50px;
            height: 50px;
            overflow: hidden;
            font-size: 8px;
            @include vfi-tab($color-black);
            &:focus > * {
                outline-offset: -1px;
              }
        }

        &-holder {
            padding: 0;
            overflow: hidden;
            position: relative;
        }

        &-name {
            @include font-content($font-size-s, bold, 1.25);
            margin-bottom: 5px;
            text-transform: capitalize;

            &-link {
                @include vfi-tab($color-black);
                @include no-decoration;
                display: inline-block;
            }
        }

        &-color {
            @include font-content($font-size-s, normal, 1.68);
            color: $color-text-tertiary;
            margin-top: 3px;
        }
    }

    &__delivery-heading {
        @include vfi-tab($color-black);
        @include font-content($font-size-s, 700, 0.8);
        background-color: $color-bg-delivery-tag;
        border-radius: 100px;
        display: inline-block;
        padding: 5px 10px;
        color: $color-text-primary;
    }

    &__btn-save {
        @include font-content($font-size-l, 700, 0.8);
        display: block;
        min-height: 44px;
        width: 100%;
        padding: 16px 0 !important;
        border-radius: 50px;
        z-index: 0;
        position: relative;
        background-color: transparent;
        border: 2px solid $color-bg-quaternary;
        color: $color-bg-quaternary;
        margin: 30px auto 15px auto;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active:focus,
        &:focus,
        &:hover {
            background-color: $color-button-primary-background-hover;
            border-color: $color-button-primary-background-hover;
            box-shadow: none !important;
            color: $color-text-alert-message;
        }

        @include breakpoint(lg) {
            width: 345px;
            margin: 30px 0;
        }
        [class*="button-module_btn-flex"] {
            [class*="loading-module_loading-ring"] {
                svg {
                    circle {
                        &:last-child {
                            stroke: $color-button-primary-background;
                        }
                    }
                }
            }
        }
    }

    &__btn-payment {
        display: none;

        @include breakpoint(lg) {
            display: block;
            color: $color-text-alert-message;
            background-color: $color-bg-quaternary;
        }
    }

    &__btn-submit {
        padding: 16px 0 !important;
        min-height: 46px;
    }

    &__loading {
        display: flex;
        justify-content: center;
    }

    &__error-message {
        padding: 15px;
        background-color: $color-bg-primary;

        @include breakpoint(lg) {
            background: $color-bg-error;
        }

        .cart & {
            padding-bottom: 15px;
            border-bottom: 1px solid $color-bg-delivery-tag;

            @include breakpoint(lg) {
                margin-bottom: 30px;
                border-bottom: none;
            }
        }

        h3 {
            @include font-content($font-size-l, 700, 1.375);
            color: $color-text-secondary;
            margin-bottom: 15px;

            @include breakpoint(lg) {
                @include font-content($font-size-xl, 700, 1.25);
            }

        }

        p {
            @include font-content($font-size-l, normal, 1.375);
            color: $color-text-primary;
        }

        a {
            @include font-content($font-size-l, normal, 1.375);
            text-decoration: underline;
            text-align: center;
            display: block;

            @include breakpoint(lg) {
                display: inline-block;
                margin-left: 30px;
            }

        }
    }

    &__try-again-button {
        display: block;
        max-width: 315px;
        margin: 30px auto 15px;

        @include breakpoint(lg) {
            display: inline-block;
            min-width: 215px;
            margin: 30px 0 0 0;
        }
    }

    &__paypal-image {
        width: 93px !important;
        height: 25px !important;
        max-width: 93px;
        margin-bottom: 5px;
    }

    // Customization - #ApplePay - Checkout logo
    &__applepay-image {
        margin-bottom: 5px;
        width: 50px;
        height: 20px;
    }

    &__hidden-paypal {
        position: absolute;
        opacity: 0;
        top: 32px;
        right: -7px;

        @include breakpoint(lg) {
            top: 5px;
        }

        #paybox-spinner {
            display: none !important;
        }
    }

    &__summary-text {
        .msc-order-summary__heading {
            display: block;
            @include font-content($font-size-l, bold, 1.25);
            margin-bottom: 15px;
        }
    }

    &__section-border {
        border-top: 15px solid $color-bg-cart-primary;
        margin: 0 -15px;

        @include breakpoint(md) {
            margin: 0 -30px;
        }

        @include breakpoint(lg) {
            border: none;
            margin: 0;
        }
    }

    &__payment-section {
        padding-bottom: 15px;

        .ms-checkout-payment-instrument-paypal {
            margin-top: 15px;

            &__image {
                margin-top: 0;
            }

            &__paypal-email {
                @include font-content($font-size-l, bold, 0.89);
            }
        }



        .ms-checkout-section-container__item {
            display: none;
        }

        .ms-checkout-payment-instrument {

            &-synchrony,
            &__separator,
            &-creditcard {
                display: none;
            }
        }

    }

    &__disabled {
        pointer-events: none;
        opacity: 0.7;
        cursor: pointer;
    }

    &__payment-connector {
        display: flex;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 100px;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 346px;
        background-color: $color-btn-paypal;
        padding: 13px 0;
        z-index: 0;

        &:first-child {
            margin-right: 15px;
        }

        &:only-child {
            margin-right: 0;
        }

        @include breakpoint(lg) {
            &:nth-child(4) {
                margin-right: 15px;
            }
        }

        &:hover,
        &:active {
            background-color: $color-btn-paypal;
        }

        &.disabled {
            img {
                filter: grayscale(100%) !important;
                margin: 0 auto;
            }
        }

    }

    &__loading-wrapper-container{
        width: 100%;
    }

    &__loading-wrapper {
        display: none;
        width: 100%;
        height: 46px;
        margin-top: 15px !important;
        cursor: pointer;
        margin: 0 auto;
        max-width: 346px;

        @include breakpoint(lg) {
            display: flex;
        }
    }

    &__loading-wrapper-mobile {
        display: flex;
        width: 100%;
        height: 46px;
        margin-top: 15px !important;
        cursor: pointer;
        margin: 0 auto;
        max-width: 346px;

        @include breakpoint(lg) {
            display: none;
        }
    }

    &__payment-wrapper {
        display: none;
        width: 100%;
        margin-top: 15px !important;
        cursor: pointer;
        margin: 0 auto;
        max-width: 346px;

        @include breakpoint(lg) {
            display: flex;
        }
    }

    &__button-image {
        margin: auto;
        filter: none !important;
        z-index: 0
    }

    &__cart-paypal-hidden {
        position: absolute;
        // width: initial !important;
        opacity: 0.001;
        z-index: 15;
    }

    &__cart-applePay-hidden {
        display: none !important;
    }

    &__cart-paypal-mobile {
        max-width: 165px !important;

        // @include breakpoint(lg) {
        //     max-width: 346px;
        // }

    }
    &__express-payment-hidden {
        opacity: 0.001;
        height: 0;
        margin: 0 !important;
    }

    &__express-checkout-loader {
        padding-top: 15px;
        display: flex;
        justify-content: center;
    }

    &__disabled-applePay {
        opacity: 0.01;
        position: absolute;
        height: 0;
        pointer-events: none;
    }

    &__paypal-disabled {
        background-color: $color-bg-primary !important;
        border: $color-button-border-disabled 1px solid !important;
    }

    &__hide-payment-connector {
        display: none !important;
    }

    &__wrapper-disabled {
        cursor: default;
    }

    &__conditional-margin {
        margin-right: 0 !important;
    }
}

.ms-checkout-express {
    cursor: pointer;
}

// For styling loading button 
.ms-checkout-express-paypal-in-cart-page {
    .msc-waiting-circular {
        display: block;
        margin: 5px auto;
    }

    .ms-checkout-express__loading-message {
        display: none;
    }
}

//when paypal button is loading -- prevents flicker 
.ms-checkout-express__add {
    &.is-fetching {
        display: none;
        visibility: hidden;
        height: 0;
    }
}

// Customization - #ApplePay - Cart error msg
#payjs-applePayError {
    display: none;
}

// Customization - #ApplePay - Cart button style
.apple-pay-button {
    height: 48px !important;
    border-radius: 50px;
}

// Customization - Express checkout on Cart page - #ApplePay
.ms-checkout-express-applepay-in-cart-page {
    width: 100%;
}

.ms-checkout-express__applepay-cart {
    background-color: transparent !important;
    padding: 0 !important;
}

/* .ms-checkout-express__applepay-cart:empty {
    display: none;
} */