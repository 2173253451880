//==============================================================================
// SEARCH AUTOSUGGEST
//==============================================================================
$autoSuggestSelector: msc-autoSuggest;

.ms-search-overlay.show {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 70px;
    left: 0;
    background-color: $modal-background-color;
    opacity: 0.4;
    right: 0;
    overflow: hidden;
    z-index: 10;
    pointer-events: none;
}

.ms-search__autoSuggest {
    position: absolute;
    top: 100%;
    left:0;
    right: 0;
    width: 100%;
    background-color: $color-bg-primary;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    z-index: 1040;
    @include breakpoint(md) {
        top: 55px;
    }
    &__keyword{
        /*TODO*/
        display: none;
    }
    &__product {
        /*TODO*/
        border: 0;
    }
    &__category {
        padding-bottom: 30px;
    }
    &__see-all-results {
        @include font-content($font-size-m, 700, 1.16);
        @include btn(primary, default);
        padding: 12px 20px 11px;
        margin: 15px auto;
        display: block;
        max-width: 164px;
    }
    &__scroll {
        max-height: calc(var(--windowSize) - calc(var(--topBarSize) + 70px));
        overflow-y: auto;
    }
}


.#{$autoSuggestSelector} {
    &__screen-reader {
        @include visually-hidden;
    }
    &__productResults-title {
        /*TODO*/
        display: none;
    }
    &__categoryResults-title {
        @include font-content($font-size-xl, 700, 1.16);
        font-weight: bold;
        text-transform: capitalize;
        padding: 15px 20px 0;
    }
    &__categoryResults-item {
        padding: 15px 20px 0;
        a {
            @include font-content($font-size-l, 400, 1.12);
            @include no-decoration;
            display: block;
        }
    }
}

li.#{$autoSuggestSelector}__productResults-item {
    padding: 15px 20px;
}

a.#{$autoSuggestSelector}__productResults-item {
    @include no-decoration;
    @include font-content($font-size-l, 400, 1.25);
    @include add-mfrm-icon($icon-arrow-top-left, after);
    display: block;
    position: relative;
    padding-right: 20px;
    &:after {
        top: 50%;
        right: 0;
        margin-top: -8px;
        position: absolute;
    }
}