$tooltip-arrow-width: 12px;
$tooltip-arrow-height: 6px;
$tooltip-padding: 12px;

// Base class
.msc-tooltip {
  @include font-content($font-size-l, 400, 1.25);
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  word-wrap: break-word;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  .msc-arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: 12px;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.msc-bs-tooltip-top {
  padding: $tooltip-padding 0;

  .msc-arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: $color-border-tooltip;
    }
  }
}

.msc-bs-tooltip-right {
  padding: 0 $tooltip-padding;

  .msc-arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: $color-border-tooltip;
    }
  }
}

.msc-bs-tooltip-bottom {
  padding: $tooltip-padding 0;

  .msc-arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-bottom-color: $color-border-tooltip;
    }
  }
}

.msc-bs-tooltip-left {
  padding: 0 $tooltip-padding;

  .msc-arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $color-border-tooltip;
    }
  }
}

.msc-bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .msc-bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .msc-bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .msc-bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .msc-bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.msc-tooltip-inner {
  max-width: 200px;
  padding: 4px 8px;
  color: $color-text-tooltip;
  text-align: center;
  background-color: $color-bg-tooltip;
  border-radius: 4px;
}

//mfcl tooltip fix d365

@media only screen and (max-width: ($breakpoint-sm - 1)) {
  [class*="tooltip-module_tip-container-wrapper"] {
    transform: translate3d(calc(-100% - 8px), 10px, 0) !important;
  }
}
