.price-skeleton {
    &-default {
        margin-bottom: -2px;
        margin-top: -1px;
        height: 27px;
        width: 45%;
        border-radius: 4px;
    }
    &-mattresses {
        margin-bottom: -2px;
        margin-top: -1px;
        height: 27px;
        width: 45%;
        border-radius: 4px;
    }
    &-pillows {
        margin-bottom: -2px;
        margin-top: -1px;
        height: 27px;
        width: 45%;
        border-radius: 4px;
    }
    &-bedding {
        margin-bottom: -2px;
        margin-top: -1px;
        height: 27px;
        width: 45%;
        border-radius: 4px;
    }
}

.financing-skeleton {
    &-default {
        height: 38px;
        width: 100%;
    }
    &-mattresses {
        height: 60px;
        width: 100%;
    }
    &-pillows {
        height: 48px;
        width: 100%;
    }
    &-bedding {
        height: 48px;
        width: 100%;
    }
}

.progressive-skeleton {
    &-default {
        height: 22px;
        width: 80%;
    }
    &-mattresses {
        height: 22px;
        width: 80%;
    }
    &-pillows {
        height: 22px;
        width: 80%;
    }
    &-bedding {
        height: 22px;
        width: 80%;
    }
}

.promotional-skeleton {
    &-default {
        display: none;
    }
    &-mattresses {
        height: 101px;
        width: 100%;
        margin-top: 4px;
        margin-bottom: 22px;
    }
    &-pillows {
        display: none;
    }
    &-bedding {
        display: none;
    }
}

.tryinstore-skeleton {
    &-default {
        height: 41px;
        width: 100%;
    }
    &-mattresses {
        height: 41px;
        width: 100%;
    }
    &-pillows {
        display: none;
    }
    &-bedding {
        display: none;
    }
}

.dimension-label-skeleton {
    &-default {
        margin-top: 35px;
        height: 25px;
        width: 35%;
    }
    &-mattresses {
        margin-top: 35px;
        height: 25px;
        width: 35%;
    }
    &-pillows {
        margin-top: 35px;
        height: 25px;
        width: 35%;
    }
    &-bedding {
        margin-top: 30px;
        height: 25px;
        width: 35%;
    }
}

.dimensions-skeleton {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 8px;
    @include breakpoint(lg) {
        margin-top: 10px;
    }
    span {
        display: flex;
        margin-right: 8px;
        margin-bottom: 8px;
        height: 32px;
        width: 96px;
        @include breakpoint(md) {
            height: 74px;
            width: 181px;
        }
        @include breakpoint(lg) {
            height: 74px;
            width: 142px;
        }
    }
}

.swatch-label-skeleton {
    &-default {
        display: none;
    }
    &-mattresses {
        display: none;
    }
    &-pillows {
        display: none;
    }
    &-bedding {
        width: 96px;
        height: 20px;
        width: 100%;
        margin-top: 15px;
        @include breakpoint(lg) {
            margin-top: 30px;
        }
    }
}

.swatch-skeleton {
    &-default {
        display: none;
    }
    &-mattresses {
        display: none;
    }
    &-pillows {
        display: none;
    }
    &-bedding {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 15px;
        span {
            border-radius: 50% !important;
            display: flex;
            margin-right: 8px;
            margin-bottom: 10px;
            width: 60px;
            height: 60px;
        }
    }
}

.foundation-skeleton {
    &-default {
        display: none;
    }
    &-mattresses {
        height: 80px;
        width: 100%;
    }
    &-pillows {
        display: none;
    }
    &-bedding {
        display: none;
    }
}