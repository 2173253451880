$order-confirmation-item-spacing: 15px;
$order-confirmation-summary-width: 31.667%;
$order-confirmation-information-width: 165px;
$order-confirmation-contact-detail-width-tab: 48.73%;
$order-confirmation-contact-detail-width-desktop: 46.343%;
$order-confirmation-cash-back-promo-title: 20px;

.order-confirmation #main {
    background-color: $color-bg-order-summary;
    @include breakpoint(lg) {
        background-color: $color-bg-primary;
    }
    @media (max-width: $breakpoint-lg) {
        margin: 0;
    }
}

//hide store locator
.order-confirmation {
    .ms-header__preferred-store-container {
        display: none !important;
    }
}
.ms-order-delivery-section {
    background-color: $color-white;
    margin: 15px -15px 0;
    padding: 20px 15px 5px;
    @include breakpoint(lg) {
        margin: 0;
        padding: 0;
    }
}

.ms-order-confirmation {
    @include breakpoint(lg) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__customer-info,
    &__payment-section,
    &__payment-methods-paypal-image,
    &__payment-methods-paypal-email {
        @include vfi-tab($color-black);
    }
    &__summary-section {
        position: relative;
        .sr-only {
            @include vfi-sronly();
            width: 100% !important;
            height: auto !important;
            bottom: 0;
        }
    }
    &__alert {
        margin: 30px 0;
        display: block;
        flex: 1 0 100%;
    }
    &__order-confirmation-wrapper {
        flex: 1;
        @include breakpoint(xl) {
            padding-right: 30px;
        }
    }
    &__order-information-container {
        @include order-confirmation-add-margin;
        margin: 30px 0;
        @include breakpoint(xxl) {
            margin: 70px 0 45px;
        }
    }
    &__order-information {
        padding: $order-confirmation-item-spacing 0;
    }
    &__heading {
        @include order-confirmation-font-alignment;
        @include font-content($font-size-xxxl, bold, 1.14);
        @include vfi-tab($color-black);
        margin-bottom: $order-confirmation-item-spacing;
        color: $color-text-primary;
        @include breakpoint(lg) {
            @include font-content(($font-size-xxxxl - 4), bold, 1.12);
        }
    }
    &__subheading {
        @include order-confirmation-font-alignment;
        @include order-confirmation-word-spacing;
        @include vfi-tab($color-black);
        @include font-content($font-size-l, normal, 1.37);
        margin-bottom: $order-confirmation-item-spacing;
        color: $color-text-primary;
    }
    &__print {
        // Story: 58028 - Remove print button
        // @include order-confirmation-font-alignment;
        // margin-bottom: $order-confirmation-item-spacing;
        display: none;
        // &-btn {
        //     @include add-mfrm-icon($icon-chev-right, after);
        //     @include font-content($font-size-l, 500, 1.12);
        //     @include vfi($color-black);
        //     color: $color-link-secondary;
        //     outline-offset: 2px;
        //     &::after {
        //         margin-left: 5px;
        //         display: inline-block;
        //         font-size: ($icon-size-xs - 4);
        //         font-weight: bold;
        //         vertical-align: middle;
        //     }
        // }
    }
    &__customer-email {
        @include order-confirmation-font-alignment;
        @include order-confirmation-word-spacing;
        @include vfi-tab($color-black);
        margin-bottom: 22px;
        .ms-order-confirmation__order-information-receipt-email {
            @include font-content($font-size-l, 400, 1.37);
            position: relative;
            word-wrap: break-word;
            .sr-only {
                @include vfi-sronly();
                width: 100% !important;
                height: auto !important;
            }
            span {
                font-weight: 700;
            }
        }
    }
    &__spam-text {
        @include order-confirmation-font-alignment;
        @include order-confirmation-word-spacing;
        @include font-content($font-size-s, normal, 1.6);
        @include vfi-tab($color-black);
    }
    &__customer-care-cta {
        @include vfi-tab();
        position: relative;
        text-decoration: underline;
        @media (max-width: ($breakpoint-xs - 1)) {
            display: block;
        }
        .sr-only {
            width: 100% !important;
            height: auto !important;
            left: 0;
        }
    }

    //Order Information
    &__order-information {
        @include order-confirmation-font-alignment;
        &-channel-reference-id,
        &-created-date {
            @include font-content($font-size-m, bold, 1.43);
            position: relative;
            min-width: $order-confirmation-information-width;
            max-width: $order-confirmation-information-width;
            display: inline-block;
            color: $color-text-primary;
            .sr-only {
                @include vfi-sronly();
                width: 100% !important;
                height: 100% !important;
            }
            span {
                @include font-content($font-size-m, normal, 1.43);
                display: block;
            }
        }
    }

    //Order Summary
    &__order-summary {
        margin: 30px 0;
        @include breakpoint(md) {
            margin: 60px;
        }
        @include breakpoint(lg) {
            width: $order-confirmation-summary-width;
            max-width: $order-confirmation-summary-width;
            margin: 60px 0;
            padding: 15px 17.5px 45px;
            background-color: $color-bg-order-summary;
        }
        @include breakpoint(xxl) {
            margin: 140px 0 60px;
        }
        &-tabItem {
            @include vfi-tab($color-black);
        }
        &-subLabel {
            @include vfi-tab($color-black);
        }
        &-heading {
            @include mobile_hidden;
            @include font-content($font-size-l, bold, 1.25);
            margin-bottom: 15px;
            color: $color-text-primary;
            text-transform: capitalize;
        }
        &-line {
            padding: 15px;
            background-color: $color-bg-primary;
            border: 1px solid $color-border-choice-summary-primary;
            @include vfi-tab($color-black);
            @include breakpoint(md) {
                padding: 15px 30px;
            }
            @include breakpoint(lg) {
                padding: 15px;
            }
            p {
                display: flex;
                justify-content: space-between;
            }
            &-subtotal,
            &-savings,
            &-instant-gift,
            &-recycling-fee,
            &-shipping,
            &-tax-amount,
            &-lease-To-Own-Cost,
            &-initial-payment,
            &-delivery,
            &-total-tax,
            &-lease-to-own-total,
            &-progressive-total {
                @include font-content($font-size-m, normal, 1.43);
                margin-bottom: 15px;
                color: $color-text-primary;
                .msc-price__actual,
                .msc-price__discounted {
                    @include font-content($font-size-m, normal, 1.43);
                }
                .msc-price__actual {
                    color: $color-text-primary;
                }
                .msc-price__discounted,
                .msc-price__discounted span,
                .msc-price__discounted .msc-price__actual {
                    color: $color-text-secondary;
                }
            }
            &-total-amount {
                @include font-content($font-size-l, bold, 1.25);
                border-top: 1px solid $color-border-choice-summary-primary;
                padding-top: 15px;
                color: $color-text-primary;
                .msc-price__actual {
                    @include font-content($font-size-l, bold, 1.25);
                    color: $color-text-primary;
                }
            }
            &-lease-to-own-total {
                font-weight: 700;
                border-top: 1px solid $color-border-checkout-secondary;
                padding-top: 15px;
                .msc-price__actual {
                    font-weight: 700;
                }
            }
            &-progressive-total {
                border-top: 1px solid $color-border-checkout-secondary;
                padding-top: 15px;
                font-weight: 700;
                .msc-price__actual {
                    font-weight: 700;
                }
            }
        }
        // Promo Code - Order confirmation
        .msc-promo-code {
            &__wrap {
                margin-bottom: 15px;
            }
            &__line-container {
                @include vfi-tab($color-black);
                margin-bottom: 5px;
            }
            &__line-value {
                @include font-content($font-size-m, 500, 1.57);
                color: $color-text-store-timing;
            }
        }
        &-lease-to-own-description {
            @include font-content($font-size-s, 400, 1.33);
            @include vfi-tab($color-black);
        }
    }

    &__delivery-info-container {
        background: $color-white;
        padding: 20px 15px;
        margin: 0 -15px;
        @include breakpoint(lg) {
            padding: 0;
            margin: 0;
        }
    }
    //Customer Information
    &__customer-info-container {
        @include vfi-tab($color-black);
        padding: 30px 15px;
        margin: 0 -15px 30px;
        background-color: $color-bg-primary;
        @include breakpoint(lg) {
            margin: 0;
            padding: 0 30px 0 0;
        }
        &__address-wrapper {
            position: relative;
            .sr-only {
                @include vfi-sronly();
                width: 100% !important;
                height: 65% !important;
            }
        }
    }
    &__customer-info-heading {
        @include vfi-tab();
        @include font-content($font-size-l, bold, 1.25);
        margin: 15px 0;
        color: $color-text-primary;
        @include breakpoint(md) {
            margin-top: 0;
            margin-bottom: 30px;
        }
        @include breakpoint(lg) {
            @include font-content($font-size-xl, bold, 1.25);
            margin: 15px 0 30px;
        }
    }
    &__address {
        span {
            @include font-content($font-size-l, normal, 1.37);
            color: $color-text-primary;
        }
        &-name {
            display: block;
            text-transform: capitalize;
        }
        &-threeletterisoregionname {
            visibility: hidden;
        }
        &-full-address,
        &-apt-no {
            display: block;
        }
    }
    &__payment-methods {
        &-title {
            @include font-content($font-size-l, bold, 1.25);
            @include vfi-tab($color-black);
            padding: 15px 0;
            @include breakpoint(lg) {
                @include font-content($font-size-xl, bold, 1.25);
                padding-top: 0;
            }
        }
        &-card,
        &-paypal {
            img {
                height: 20px;
                margin-bottom: 5px;
                @include breakpoint(md) {
                    margin-bottom: 10px;
                }
            }
            p {
                @include font-content($font-size-l, normal, 1.37);
                display: block;
                color: $color-text-primary;
                overflow-wrap: break-word;
            }
        }
    }
    &__customer-care {
        @include font-content($font-size-m, normal, 1.43);
        margin: 30px 15px 0;
        color: $color-text-primary;
        text-align: center;
        @include breakpoint(md) {
            margin: 30px 0 0;
        }
        a {
            text-decoration: underline;
            cursor: pointer;
        }
        @include breakpoint(md) {
            text-align: left;
        }
    }
    &__customer-info-details {
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
    }
    &__customer-info-detail {
        @include vfi-tab($color-black);
    }
    &__groups,
    &__receipt-contact {
        min-width: 100%;
        max-width: 100%;
        @include breakpoint(md) {
            min-width: $order-confirmation-contact-detail-width-tab;
            max-width: $order-confirmation-contact-detail-width-tab;
        }
        @include breakpoint(lg) {
            min-width: $order-confirmation-contact-detail-width-desktop;
            max-width: $order-confirmation-contact-detail-width-desktop;
        }
    }
    &__receipt-contact {
        margin-top: 22px;
        @include breakpoint(md) {
            margin-top: 0;
            padding-left: 30px;
        }
        &-email,
        &-phone {
            @include font-content($font-size-l, normal, 1.37);
            display: block;
            color: $color-text-primary;
        }
    }
    &__billing-address {
        @include font-content($font-size-l, normal, 1.37);
        margin-top: 30px;
        text-align: left;
        color: $color-text-primary;
        @include breakpoint(lg) {
            text-align: left;
        }
        &-container {
            position: relative;
            .sr-only {
                @include vfi-sronly();
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
    &__delivery-window-main,
    &__heading-section {
        @include vfi-tab($color-black);
    }
    &__billing-message {
        margin-top: 20px;
        @include font-content($font-size-l, normal, 1.37);
    }
    &__visa-info {
        @include font-content($font-size-l, normal, 1.37);
        position: relative;
        .sr-only {
            @include vfi-sronly();
            width: 100% !important;
            height: 100% !important;
        }
    }
    &__apple-pay-section {
        &-method {
            display: inline !important;
            margin-left: 10px;
            @include font-content($font-size-l, normal, 1.37);
        }
        &-card {
            margin-top: 3px;
            @include font-content($font-size-l, normal, 1.37);
        }
        &-img {
            display: inline;
            margin-bottom: 5px !important;
        }
    }
    &__cash-back-promo-container {
        background: $color-bg-sleep-expert;
        padding: 15px;
        border-radius: 8px;
        margin-top: 15px;
    }
    &__cash-back-promo-title {
        text-align: center;
        margin: auto;
        @include font-content($order-confirmation-cash-back-promo-title, 700, 1.2);
        span {
            display: block;
        }

        h2 {
            color: $color-bg-quinary;
            @include font-content($order-confirmation-cash-back-promo-title, 700, 1.2);
        }

        @include breakpoint(lg) {
            text-align: left;
            @include font-content($font-size-xxl, 700, 1.01);
            span {
                display: inline;
            }

            h2 {
                color: $color-bg-quinary;
                @include font-content($font-size-xxl, 700, 1.01);
            }
        }
    }
    &__cash-back-promo-description {
        width: 313px;
        text-align: center;
        margin: 8px auto 25px auto;

        p {
            color: $color-bg-quinary;
            @include font-content($font-size-l, normal, 1.49);
        }

        @include breakpoint(lg) {
            width: 100%;
            text-align: left;
            margin: 15px 0;
        }
    }
    &__cash-back-button-wrapper {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include breakpoint(lg) {
            display: block;
            margin-bottom: 15px;
        }
        .submit-online-btn {
            @include font-content($font-size-m, 700, 0.85);
            display: inline-block;
            background: $color-button-quaternary-background;
            border-radius: 100px;
            color: $color-text-alert-message;
            padding: 14px 32px;
        }
        .print-mail-btn {
            @include font-content($font-size-m, normal, 1.49);
            text-decoration: underline;
            margin-top: 15px;

            &:hover {
                text-decoration: underline !important;
            }
            
            @include breakpoint(lg) {
                margin-bottom: 15px;
                margin-left: 15px;
            }
        }
    }
    &__cash-back-promo-footer-text {
        text-align: center;

        p {
            color: $color-bg-quinary;
            @include font-content($font-size-m, normal, 1.49);
        }
        @include breakpoint(lg) {
            text-align: left;
        }
    }
    &__progressive{
        &-summary {
        margin-top: 15px;
        padding: 15px 15px 10px 15px;
        background-color: $color-bg-primary;
        border: 1px solid $color-border-choice-summary-primary;
        
        p {
            display: flex;
            justify-content: space-between;
        }
    }
    &-method-title,
    &-lease-id {
        @include font-content($font-size-l, normal, 1.38);
        @include vfi-tab($color-black);
        width: auto;
    }
}
}

// Delivery part on order confirmation starts from here

.ms-order-delivery-heading {
    @include font-content($font-size-xxl, bold, 28px);
    @include vfi-tab($color-black);
    margin: 10px 0 15px;
    text-align: center;
    @include breakpoint(md) {
        margin: 10px 0 30px;
    }
    @include breakpoint(lg) {
        text-align: left;
        margin: 45px 0 15px;
    }
}

.ms-order-delivery {
    border: 1px solid $color-border-payment-method;
    border-radius: 4px;
    padding: 15px 15px;
    margin-bottom: 15px;
    &__shipment {
        margin: 45px 0px;
    }
    &__delivery-date {
        @include font-content($font-size-l, 700, 20px);
        margin: 15px 0px;
        @include breakpoint(lg) {
            @include font-content($font-size-xl, 700, 22px);
        }
    }
    &__delivery-description {
        @include font-content($font-size-l, normal, 22px);
        margin-bottom: 15px;
    }
    &__recieving-message {
        background: #f8f8f8;
        border-radius: 2px;
        padding: 15px;
        @include vfi-tab($color-black);
        &__heading,
        h4 {
            @include font-content($font-size-m, bold, 20px);
        }
        &__description,
        p {
            margin-top: 5px;
            @include font-content($font-size-m, normal, 20px);
        }

        @media only print {
            padding-top: 8em;
        }
    }
    &__recieving-message-conditional {
        @media only print {
            padding: 15px !important;
        }
    }
    &__delivery-window {
        margin-top: 15px;
        @include vfi-tab($color-black);
        @include font-content($font-size-m, normal, 20px);
    }
}
.ms-order-chip {
    display: inline-block;
    padding: 5px 10px;
    @include vfi-tab($color-black);
    @include font-content($font-size-s, bold, 10px);
    border-radius: 10px;
    background-color: #e1e1e1;
}

/* Print order confirmation (Temp - TODO) */

@media only print {
    .order-confirmation {
        visibility: hidden;
        .ms-header {
            visibility: hidden;
        }
        .ms-header__primary__container,
        .ms-footer-container,
        .ms-order-confirmation__print {
            display: none;
        }
    }
    .ms-header__topbar,
    .ms-order-confirmation,
    .ms-order-confirmation * {
        visibility: visible;
    }
}

/* Hide Store Locator and move secondary nav behind the primary nav on order confirmation page */
.order-confirmation {
    .ms-header__secondary {
        margin-top: 72px !important;
    }

    .ms-header__preferred-store-container--mobile {
        display: none;
    }
}

.ms-handy-section {
    padding: 15px 15px;
    margin-top: 15px;
    background-color: $color-bg-sleep-expert;
    position: relative;

    @include breakpoint(lg) {
        padding: 15px 32px;
    }

    &__container {
        display: block;

        @include breakpoint(md) {
            display: flex;
        }
    }

    &__image {
        margin: 0 auto;
        height: 40px;
        width: auto;

        @include breakpoint(md) {
            margin: 0 30px 0 0;
        }
    }

    &__content {
        margin-top: 15px;
        text-align: center;
        @include font-content($font-size-m, 400, 1.45);

        @include breakpoint(md) {
            width: 315px;
            margin: 0;
            text-align: start;
        }
    }

    &__button {
        display: block;
        color: $color-text-handy;
        @include font-content($font-size-m, 700, 0.89);
        background-color: transparent;
        border-radius: 50px;
        padding: 10px 30px;
        border: 1px solid $color-button-quaternary-background;
        position: relative;
        margin: 15px auto 0 auto;
        width: fit-content;

        &:hover{
            text-decoration: none;
        }

        @include breakpoint(md) {
            position: absolute;
            top: 18px;
            right: 30px;
            margin: 0;
        }
    }
}

// this is product recommendation caraousel css on OC
.product-recommendations-container {
    .order-confirmation & {
        margin-top: 30px;
    }
}