.ms-checkout-shipping-skeleton {
    padding-bottom: 110px;
    @include breakpoint(lg) {
        margin-top: 35px;
        padding-bottom: 0;
    }
    &__heading {
        width: 200px;
        height: 35px;
        margin-bottom: 36px !important;
        margin: auto;

        @include breakpoint(lg) {
            margin: 0;
        }
    }

    &__sub-heading {
        width: 225px;
        height: 35px;
        margin-bottom: 20px !important;
        margin: auto;

        @include breakpoint(lg) {
            width: 300px;
            margin: 0;
        }
    }

    &__sign-in {
        width: 230px;
        height: 20px;
        margin-bottom: 20px !important;
    }

    &__container {
        display: block;
        justify-content: space-between;

        @include breakpoint(lg) {
            display: flex;
        }

        &-input {
            width: 100%;
            height: 40px;
            margin-bottom: 20px !important;

            @include breakpoint(lg) {
                width: 384px;
            }
        }

    }

    &__input-long {
        width: 100%;
        height: 40px;
        margin-bottom: 20px !important;
    }

    &__email {
        width: 100%;
        height: 22px;
        margin-bottom: 20px !important;

        @include breakpoint(lg) {
            width: 485px;
        }
    }
}