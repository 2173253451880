$progressive-overview-font-size: 1.45rem; //20px
$progressive-breakpoint-xs: 381px;

%flex-section {
    display: flex;
    justify-content: space-between;
}

.ms-checkout-payment-instrument-progressive {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    .ms-checkout-payment-instrument__container {
        width: 100%;
        flex-basis: 100%;
        order: 3;
        cursor: default;
    }

    &__image {
        img {
            height: 25px;
        }
    }

    &__container {
        margin: 30px 0;

        @include breakpoint(lg) {
            margin-bottom: 60px;
        }
    }

    &__heading-steps {
        width: fit-content;
        color: $color-text-primary;
        margin-bottom: 8px;
        @include font-content($font-size-l, 700, 1.25);
        @include vfi-visible($color-black);

        &.second {
            margin-top: 40px;
        }
    }

    &__info-text {
        color: $color-text-primary;
        margin-bottom: 8px;
        @include font-content($font-size-l, 400, 1.5);
        @include vfi-visible($color-black);
    }

    &__info-cta-section {
        margin-top: 16px;
        display: flex;
    }

    &__apply-now-cta {
        @include vfi-visible($color-black);
        @include font-content($font-size-s, 700, 1.35);
        padding: 10px 16px;
        border: 1px solid $color-border-checkout-secondary;
        border-radius: 100px;

        &:hover,
        &:focus {
            border-color: $color-button-primary-background;
        }
    }

    &__learn-more-cta {
        @include vfi-visible($color-black);
        @include font-content($font-size-m, 400, 1.45);
        margin: auto 0 auto 15px;
        text-decoration: underline;

        &:hover {
            text-decoration: underline !important;
        }
    }

    &__input-form-container {
        display: block;

        @include breakpoint(lg) {
            display: flex;
        }
    }

    &__input-heading {
        @include font-content($font-size-m, 500, 1.13);
    }

    &__input-field-container {
        width: 100%;

        &:first-child {
            margin-bottom: 8px;
        }

        @include breakpoint(lg) {
            max-width: 340px;

            &:first-child {
                margin-right: 8px;
                margin-bottom: 0;
            }
        }

        .msc-address-form__input {
            background-color: $color-bg-cart-primary;
            border: none;
            border-radius: 8px;
        }

        &.msc-address-form__item-invalid {
            .ms-checkout-payment-instrument-progressive {
                &__input-heading {
                    color: $color-text-secondary;
                }
            }
        }
    }

    &__submit-button,
    &__goto-cart-button {
        @include vfi-visible($color-black);
        @include font-content($font-size-m !important, 700, 0.85);
        color: $color-button-primary-text;
        background-color: $color-button-quaternary-background;
        padding: 13px 0 !important;
        margin-top: 15px;
        border-radius: 100px;
        width: 100%;
        max-width: 266px;
        border: none;

        &._disabled {
            color: $color-text-tertiary !important;
            background-color: $color-bg-disabled-button !important;

        }

        &:hover,
        &:active {
            background-color: $color-button-quaternary-background;
            border: none;
        }

        [class*="button-module_btn-flex"] {
            [class*="loading-module_loading-ring"] {
                margin-right: 0 !important;
            }
        }
    }

    &__support-text-error {
        color: $color-text-secondary;
        @include font-content($font-size-s, 400, 1.35);
    }

    &__iframe {
        width: 100%;
        height: 100%;
    }

    &__goto-cart-button {
        width: fit-content;
        display: block;
        padding: 13px 32px !important;
        max-width: none;

        &:hover,
        &:focus {
            color: $color-button-primary-text;
        }
    }

    &__overlay-section {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
}

//==============================================================================
// Progressive Modal Styles
//==============================================================================
.mfrm-progressive-connector-modal {
    overflow-y: hidden !important;

    .msc-modal {
        &__dialog {
            width: 100%;
            vertical-align: bottom !important;
            height: 100%;
            max-height: calc(100% - 140px);

            @media screen and (min-width: $progressive-breakpoint-xs) {
                height: auto;
                max-height: none;
            }

            @include breakpoint(md) {
                width: 600px;
                vertical-align: middle !important;
            }

            &.iframe {
                .msc-modal__content {
                    height: 100%;

                    @media screen and (min-width: $progressive-breakpoint-xs) {
                        height: 80vh;
                    }
                }

                .msc-modal__body {
                    max-height: none;
                    overflow: hidden;
                }
            }
        }

        &__title {
            padding: 20px 0;
            border-bottom: 1px solid $color-border-checkout-secondary;
            @include font-content($font-size-m, 500, 1.45);
        }

        &__body {
            padding: 32px 25px 15px;
            height: 100%;
            overflow: hidden auto;

            @media screen and (min-width: $progressive-breakpoint-xs) {
                max-height: 80vh;
            }
        }

        &__content {
            border-radius: 20px 20px 0 0;
            overflow-y: auto;
            max-height: 100%;

            @include breakpoint(md) {
                border-radius: 20px;
            }
        }

        &__close-button {
            display: none;
        }
    }

    &__close-btn {
        display: block !important;
        @include add-mfrm-icon($icon-close);
        background-color: $color-bg-progressive-close-btn;
        border-radius: 100px;
        padding: 10px;
        font-size: $font-size-s;
    }

    &__title {
        @include vfi-visible($color-black);
    }

    &__docu-sign-iframe {
        height: 100%;
        width: 100%;
    }
}

//==============================================================================
// Progressive Learn More Modal Styles
//==============================================================================
.mfrm-progressive-info-modal {
    &__description {
        @include font-content($font-size-l, 400, 1.38);
        @include vfi-visible($color-black);
        margin: 8px 0 24px;
    }

    &__progressive-benefits {
        @include vfi-visible($color-black);
        padding: 16px;
        border: 2px solid $color-border-progressive-benefits;
        border-radius: 16px;
    }

    &__progressive-logo {
        @include vfi-visible($color-black);
        display: block;
        margin: 0 auto;
        width: 250px;
    }

    &__benefit {
        &-section {
            @include vfi-visible($color-black);
        }

        &-label {
            text-align: center;
            margin: 16px 0 8px 0;
            @include font-content($font-size-l, 700, 1.38);

            span {
                display: block;
            }
        }
    }

    &__benefits-list {
        @include font-content($font-size-l, 400, 1.5);
        list-style: disc !important;

        li {
            @include vfi-visible($color-black);
            margin-bottom: 8px;
            margin-left: 15px;
        }
    }

    &__agreement-text {
        @include vfi-visible($color-black);
        @include font-content($font-size-s, 400, 1.35);
        padding: 24px 0;
    }

    &__apply-now-cta {
        @include font-content($font-size-m, 700, 0.85);
        @include vfi-visible($color-black);
        padding: 12px 0;
        color: $color-button-primary-text;
        background-color: $color-button-quaternary-background;
        border-radius: 100px;
        text-align: center;
        display: block;
        width: 100%;
        max-width: 270px;
        margin: 32px auto;

        &:hover,
        &:focus {
            color: $color-button-primary-text;
        }
    }
}

//==============================================================================
// Progressive approved Modal Styles
//==============================================================================
.mfrm-progressive-approved-modal {
    &__approval {
        &-section {
            padding: 16px;
            margin: 24px 0 8px;
            background-color: $color-bg-progressive-success-section;
            border: 2px solid $color-border-progressive-success;
            border-radius: 8px;
            .sr-only {
                @include vfi-sronly();
                width: 86% !important;
                height: 8%!important;
            }
        }

        &-amount-wrapper {
            @extend %flex-section;
            @include font-content($font-size-l, 500, 1.25);
        }

        &-lease-identity {
            @include font-content($font-size-s, 400, 1.33);
            margin-top: 16px;
        }
    }

    &__support-text {
        @include vfi-visible($color-black);
        color: $color-text-tertiary;
        @include font-content($font-size-s, 400, 1.33);
    }

    &__overview {
        &-section {
            margin-top: 48px;
        }

        &-heading {
            @include vfi-visible($color-black);
            @include font-content($progressive-overview-font-size, 700, 1.19);
            margin: auto;
            width: fit-content;
        }

        &-description {
            @include font-content($font-size-l, 400, 1.5);
            @include vfi-visible($color-black);
            margin: 8px 0 16px;
        }
    }

    &__payment-overview {
        padding: 16px;
        background-color: $color-bg-progressive-payment-overview;
        border: 2px solid $color-border-checkout-secondary;
        border-radius: 8px;
    }

    &__cart-subTotal {
        @extend %flex-section;
        @include vfi-visible($color-black);
        @include font-content($font-size-l, 400, 1.5);
        padding-bottom: 16px;
        border-bottom: 1px solid $color-border-checkout-secondary;
    }

    &__terms {
        &-heading {
            @include font-content($font-size-l, 500, 1.25);
            @include vfi-visible($color-black);
            width: fit-content;
            margin: 16px 0 8px;
        }

        &-content {
            @extend %flex-section;
            @include vfi-visible($color-black);
            padding-bottom: 16px;
            border-bottom: 1px solid $color-border-checkout-secondary;
        }

        &-text {
            @include font-content($font-size-m, 400, 1.45);
        }

        &-sub-text {
            @include font-content($font-size-m, 400, 1.15);
            color: $color-text-tertiary;
        }

        &-total {
            @include font-content($font-size-l, 400, 1.5);
            margin: auto 0;
        }

        &-subTotal {
            @extend %flex-section;
            @include vfi-visible($color-black);
            margin-top: 16px;

            &-text {
                @include font-content($font-size-m, 500, 1.45);
            }

            &-value {
                @include font-content($font-size-l, 700, 1.3);
            }
        }
    }

    &__pill {
        @include font-content($font-size-m, 500, 1.45);
        @include vfi-visible($color-black);
        background-color: $color-bg-checkout-pill;
        border-radius: 16px;
        color: $color-text-alert-message;
        width: 100%;
        max-width: 100px;
        padding: 4px 0;
        text-align: center;
        margin-top: 24px;
    }

    &__early-purchase {
        &-heading {
            @include font-content($font-size-l, 700, 1.25);
            @include vfi-visible($color-black);
            margin: 8px 0;
        }

        &-description {
            @include font-content($font-size-l, 400, 1.5);
            @include vfi-visible($color-black);
            margin-bottom: 24px;
        }
    }

    &__purchase {
        &-total {
            @extend %flex-section;
            @include vfi-visible($color-black);
            margin-bottom: 20px;

            &-text {
                @include font-content($font-size-m, 500, 1.45);
            }

            &-value {
                @include font-content($font-size-l, 700, 1.3);
            }
        }

        &-options {
            background-color: $color-bg-progressive-payment-overview;
            border: 2px solid $color-border-checkout-secondary;
            border-radius: 8px;
            margin: 8px 0 16px;
            padding: 16px;

            &-heading {
                @include font-content($font-size-l, 500, 1.25);
                @include vfi-visible($color-black);
                margin-bottom: 16px;
            }

            &-weekly {
                @extend %flex-section;
                @include font-content($font-size-m, 400, 1.45);
                @include vfi-visible($color-black);
                margin-bottom: 16px;
            }

            &-total {
                @extend %flex-section;
                @include font-content($font-size-m, 400, 1.45);
                margin-bottom: 20px;
            }
        }
    }

    &__cta-section {
        padding: 32px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__button {
        width: 100%;
        max-width: 270px;
        @include vfi-visible($color-black);

        &.submit {
            margin-bottom: 16px;
            background-color: $color-button-quaternary-background;
            color: $color-button-primary-text;
            border: none;

            [class*="button-module_btn-flex"] {
                [class*="loading-module_loading-ring"] {
                    margin-right: 0 !important;
                }
            }
        }

        &.cancel {
            margin-bottom: 16px;
            background-color: $color-btn-checkout-background;
            color: $color-text-primary;
            border: none;
        }
    }
}

//==============================================================================
// Progressive Common Modal Styles
//==============================================================================
.mfrm-progressive-approved-modal,
.mfrm-progressive-info-modal {
    &__heading {
        @include font-content($font-size-xxxl, 700, 1.15);
        @include vfi-visible($color-black);
        margin: auto;
        width: fit-content;
    }
}