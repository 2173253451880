//all set for min-width so it will be this breakpoint and up
$breakpoint-sm: 320px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;
$color-tealish-blue: #1b244d;
$color-east-bay: #495071;
$color-grayscale: #E1E1E1;
$color-punch: #D63426;
$color-charcoal: #2D2926;
$color-midgray: #626366;
$color-white: #FFFFFF;
$color-black: #000000;
$icon-hover: #F8F8F8;

@mixin icon-states {
  &:hover {
      color: $color-charcoal;
      text-decoration: none;
      background-color: $icon-hover;
      cursor: pointer;
  }

  &:active {
      background: $color-grayscale;
      border-radius: 100px;
  }
}

@mixin primary-menu-list-item-state {
  &:hover {
    border: 1px solid $color-midgray;
    border-radius: 5px;
    color: inherit;
  }

  &:active {
    border: 1px solid $color-midgray;
    border-radius: 5px;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 1px $color-charcoal;
    border: solid 1px $color-charcoal;
    border-radius: 5px;
  }

  &:focus {
    color: inherit;
  }
}

@mixin secondary-menu-list-item-state {
  &:hover {
    color: $color-charcoal !important;
    text-decoration: underline;
    cursor: pointer;
  }

  &:active {
      color: color-charcoal;
      text-decoration: underline;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 3px $color-white, 0px 0px 0px 5px $color-charcoal;
    color: inherit;
  }
}

.product-category-menu {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-right: 22px;
  padding-left: 22px;

  @include breakpoint(lg) {
    padding-right: 57px;
    padding-left: 0;
  }
}

.subcategory-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subcategory {
  display: flex;
  flex-direction: column;
  height: 30%;
  width: 100%;
}

.subcategory-item {
  display: flex;
  flex-direction: row;
  border: solid 1px $color-grayscale;
  border-radius: 1rem;
  cursor: pointer;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
}

.submenu-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.L2-wrapper {
  width: 100%;
}

.nav-L2-link-container {
  display: inline;

  @include breakpoint(lg) {
    display: flex;
    // padding-top: 34px;
  }
}

.promo-banner-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 64px;
  padding: 8px 22px;
  background-color: $color-white;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;

  @include breakpoint(lg) {
    width: 96.5%
  }

  .promo-banner-link {
    display: block;
  }

  .promo-banner-img {
    height: 48px;
    object-fit: cover;
  }
}

.product-category-menu-header {
  display: flex;
  align-items: start;
  flex-direction: column;

  @include breakpoint(lg) {
    align-items: center;
    flex-direction: row;
    padding: 30px 0px 16px;
    // height: 86px;
  }
}

.subcategory-menu-wrapper {
  margin-left: 0;

  @include breakpoint(lg) {
    margin-left: 120px;
  }
}

.shop-all-container {
  & .shop-all-btn {
    background: $color-tealish-blue;
    color: $color-white;
    border: none;
    border-radius: 74px;
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin: 2px;

    &:hover {
      background-color: $color-east-bay;
    }
  }

  &:focus {
    box-shadow: 0 0 0 0.5px $color-white, 0 0 0 2.5px $color-black;
    border-radius: 74px;
  }

  a {
    border: none;
  }
}

.desktop-back-button {
  display: none;

  @include breakpoint(lg) {
    position: relative;
    left: 6px;
    display: inline;
    cursor: pointer;
    width: 170px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  @include breakpoint(xxl) {
    width: 130px;

  }
}

.mobile-back-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 1.15rem;
  border-bottom: solid 1px $color-grayscale;
  cursor: pointer;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 8px;
  position: fixed;
  background: $color-white;

  @include breakpoint(lg) {
    display: none;
  }
}

.back-icon {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  position: relative;
  right: 5px;
  @include icon-states();

  @include breakpoint(lg) {
    position: fixed;
    left: 40px;
    top: 110px;

    &:hover {
      background-color: $icon-hover;
      cursor: pointer;
    }
  }

  &-img {
    position: relative;
    top: 8px;
    left: 8px;
    width: 24px;
    height: 24px;

    &:focus-visible {
      background: $icon-hover;
      box-shadow: 0px 0px 0px 3px $color-white, 0px 0px 0px 5px $color-charcoal;
      border-radius: 100px;
  }
  }

  &-text {
    margin-left: 16px;
    font-size: 14px;
  }
}

.category-header {
  width: 100%;
  color: $color-charcoal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px;
  margin-top: 60px;

  @include breakpoint(lg) {
    padding: 0;
    margin-top: 0;
  }

  @include breakpoint(xl){
    padding-left: 23px;
  }

  &.category-header-column {
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint(lg) {
      margin-top: -5px;
    }
  }
}

.category-title {
  padding-right: 1rem;
}

.subcategory-menu {
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 64px;
  }

  @include breakpoint(lg) {
    // margin-top: 16px;
  }
}

.subcategory-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-bottom: solid 1px $color-grayscale;
  padding: 8px 16px 8px 0px;
  margin-bottom: 16px;
}

.subcategory-primary-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  border: solid 1px $color-grayscale;
  border-radius: 5px;
  cursor: pointer;
  height: 56px;
  margin-bottom: 8px;
  color: $color-charcoal;
  @include primary-menu-list-item-state();

  &:last-of-type {
    margin-bottom: 16px;
  }


  &:hover {
    border: solid 1px $color-charcoal;
    color: $color-charcoal;
  }

  &-text {
    margin-left: 10px;
    line-height: 20px;
  }

  &-icon {
    max-width: 100px;
    max-height: 80px;
    border-radius: 10px;
    margin-left: 8px;
  }

  &-maximum {
    text-decoration: underline;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $color-charcoal;
    margin-bottom: 24px;
    cursor: pointer;
    @include secondary-menu-list-item-state();
  }
}

.subcategory-secondary-menu-container {
  display: flex;

  // &:nth-child(1) {
  //   margin-top: 15px;
  // }
  &:last-of-type {
    margin-bottom: 16px;
  }

  &:first-of-type {
    margin-top: 16px;
  }
}

.subcategory-content-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  color: $color-charcoal;
}

.subcategory-secondary-menu-item {
  font-size: 14px;
  font-weight: 400;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 10px;
  cursor: pointer;
  color: $color-charcoal;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include secondary-menu-list-item-state();

  &-icon {
    margin-right: 0.25rem;
  }
}

.subcategory-badge-wrapper {
  display: flex;
  align-items: center;
}