$cartline-txt-align-left: left;
$cart-image-width-lg: 210px;
$cart-image-width-xs: 160px;
$cart-quantity-dropdown-width: 105px;
$cart-quantity-dropdown-height: 44px;
$cart-product-price-size: 22px;

.msc-cart {
    &-title {
        display: none;
        @include breakpoint(lg) {
            display: flex;
            padding: 15px;
            background-color: $color-bg-cart-primary;
            span {
                @include font-content($font-size-l, 500, 1.125);
                display: inline-block;
                color: $color-text-primary;
                &:first-child {
                    flex: 0 0 62.5%;
                    max-width: 100%;
                    text-align: $cartline-txt-align-left;
                    @include breakpoint(xl) {
                        flex: 0 0 460px;
                    }
                }
                &:last-child {
                    display: flex;
                    flex-grow: 1;
                    justify-content: flex-end;
                }
            }
        }
    }
    &-line__mobile-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        justify-content: space-between;

        @include breakpoint(lg) {
            display: none;
        }

        .msc-edit-gift {
            display: inline-block;
            padding: 0 15px;
            align-self: flex-end;
            @include breakpoint(lg) {
                display: none;
            }
        }
    }
    //==============================================================================
    // Cart Line Items
    //==============================================================================
    &-lines-item {
        background-color: $color-bg-primary;
        overflow: hidden;
        margin: 0;
        padding: 15px;
        position: relative;
        display: table;
        width: 100%;
        @include breakpoint(md) {
            padding: 15px 30px;
        }
        @include breakpoint(lg) {
            padding: 15px 0;
        }
        .msc-edit-gift {
            &__button {
                @include font-content();
                @include vfi($color-black);
                @extend %btn-muted;
                padding: 0;
                color: $color-text-primary;
                text-decoration: underline;
                text-transform: capitalize;
                outline-offset: 2px;
            }
        }
        &-container {
            border-bottom: 1px solid $color-border-cart-line-Item;

            &:first-child {
                border-top: 1px solid $color-border-cart-line-Item;
            }
        }
    }
    &-line {
        position: relative;
        width: 100%;
        @include font-content($font-size-l, normal, 1.375);
        @include breakpoint(lg) {
            margin: 0;
            display: flex;
            @include font-content($font-size-xl, normal, 1.34);
        }
        &__container {
            .msc-edit-gift {
                display: inline-block;
            }
        }
        //==============================================================================
        // Cart Product Image
        //==============================================================================
        &__product-image {
            @include vfi-tab($color-black);
            min-height: ($cart-image-width-xs - 20);
            min-width: $cart-image-width-xs;
            height: ($cart-image-width-xs - 20);
            width: $cart-image-width-xs;
            display: inline-block;
            overflow: hidden;
            border-radius: 8px;
            @include breakpoint(lg) {
                min-height: ($cart-image-width-lg - 30);
                min-width: $cart-image-width-lg;
                height: ($cart-image-width-lg - 30);
                width: $cart-image-width-lg;
            }
            a {
                display: inline-block;
                min-width: $cart-image-width-xs;
                max-width: $cart-image-width-xs;
                height: ($cart-image-width-xs - 20);
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                @include breakpoint(lg) {
                    min-width: $cart-image-width-lg;
                    max-width: $cart-image-width-lg;
                    height: ($cart-image-width-lg - 30);
                }
            }

            .msc-empty_image {
                @include image-placeholder($cart-image-width-xs, ($cart-image-width-xs - 20px));
                border: 1px solid $color-border-cart-primary;
                @include breakpoint(lg) {
                    @include image-placeholder($cart-image-width-lg, ($cart-image-width-lg - 30px));
                }
            }
        }
        &__product-description {
            width: 100%;
        }
        &__content {
            display: inline-block;
            vertical-align: top;
            @include breakpoint(lg) {
                padding: 0;
                display: flex;
                flex-direction: row;
                flex-grow: 1;
            }
        }
        &__product-title {
            @include font-content($font-size-m, 500, 1.43);
            @include vfi($color-black);
            color: $color-link-primary;
            @include text-ellipsis;
            -webkit-line-clamp: 3;
            &:hover {
                text-decoration: none;
            }
            @include breakpoint(lg) {
                @include font-content($font-size-l, 500, 1.3);
            }
        }
        &__product-variants {
            line-height: 0;
            margin-top: 3px;
        }
        &__product-variant-item {
            @include font-content($font-size-s, normal, 1.34);
            display: inline-block;
            color: $color-link-primary;
            &:after {
                content: " "; // remove comma before variant style
                display: inline-block;
                margin: 0 1px;
            }
            &:last-child {
                // hide the variant style e.g. PRIME
                display: none;
            }
            &:last-child:after {
                display: none;
            }
        }
        &__product {
            min-height: 115px;
            margin-left: 15px;
            @include breakpoint(md) {
                min-height: 105px;
            }
            @include breakpoint(lg) {
                flex: 0 0 70%;
                max-width: 70%;
            }
            @include breakpoint(xl) {
                flex: 0 0 360px;
                max-width: 360px;
            }
        }
        //==============================================================================
        // Cart Product Price
        //==============================================================================
        &__product-price,
        &__product-price__mobile {
            @include font-content($cart-product-price-size, bold, 1.01);
            position: relative;
            color: $color-text-primary;
            text-align: right;
            .free,
            .discounted {
                color: $color-text-secondary;
            }
            .sr-only {
                right: 0;
                height: 30% !important;
                width: 15% !important;
            }
        }
        &__product-price {
            display: block;
            @include breakpoint(lg) {
                display: flex;
                flex: 1 0 26%;
                max-width: 100%;
                text-align: right;
                flex-flow: column wrap;
            }
            @include breakpoint(xl) {
                flex: 1 0 156px;
            }
            &__mobile {
                display: block;
                float: right;
                flex: 1;
                justify-content: flex-end;
                @include breakpoint(lg) {
                    display: none;
                }
            }
        }
        .msc-disc-offer {
            @include font-content($font-size-s, normal, 1.67);
            @include breakpoint(md) {
                @include font-content($font-size-m, 400, 1.42);
            }
            .msc-price__strikethrough {
                @include font-content($font-size-s, 400, 1.18);
                color: $color-text-primary;
                text-decoration: line-through;
                display: block;
                margin: 4px 0;
                .msc-cart-line__product-cents {
                    top: -6px;
                    font-weight: 500;
                }
            }
        }
        .msc-discount-price {
            @include font-content($font-size-s, bold, 1.01);
            color: $color-discount-price;
            background-color: $color-bg-quinary;
            font-weight: bold;
            padding: 4px;
            border-radius: 2px;
            + .msc-price__strikethrough {
                margin-right: 0;
            }
        }
        &__image-badge {
            position: absolute;
            top: 4px;
            left: 4px;

            @include breakpoint(lg) {
                top: 8px;
                left: 8px;
            }

            .mfrm-product-badge__image {
                margin: 0 0 5px;
                max-height: 24px;
                border-radius: 5px;
            }
        }
        &__product {
            &-cents {
                position: relative;
                line-height: 0;
                vertical-align: baseline;
                font-size: 50%;
                top: -0.8em;
            }
            &-additional-info {
                &-mobile,
                &-desktop {
                    min-height: 20px;
                    align-items: center;
    
                    .msc-rating__count {
                        @include font-content($font-size-s, normal, 1.18);
                        vertical-align: middle;
                    }
                }
                &-desktop {
                    display: none;
    
                    @include breakpoint(lg) {
                        display: flex;
                        margin: 0 0 10px 15px;
                    }
                }
                &-mobile {
                    display: flex;
                    margin-bottom: 15px;
    
                    @include breakpoint(lg) {
                        display: none;
                    }
                }
            }
            &-rating {
                &-container {
                    @include vfi($color-black);
                }
            }
            &-eye-brow {
                &-section {
                    margin-left: auto;
                    @include vfi($color-black);
                    outline-offset: -1px;
                }
                &-container {
                    display: flex;
                    align-items: center;
                }
                &-message {
                    @include font-content($font-size-s, 500, 1.38);
                    margin-left: 8px;
                }
                &-image {
                    max-width: 16px;
                    max-height: 16px;
                }
            }
        }
    }
}
