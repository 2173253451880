$checkout-margin: 15px;

//SignIn
.ms-checkout__signin {
    margin-bottom: 20px;
    display: flex;
    &-text {
        @include font-content($font-size-l, 400, 1.22);
        color: $color-text-primary;
    }
    &-cta {
        @include font-content($font-size-l, 500, 1.12);
        @include add-mfrm-icon($icon-chev-right, after);
        @extend %link-muted;
        color: $color-text-secondary;
        margin-left: 8px;
        &::after {
            margin-left: 5px;
            font-size: ($icon-size-xs - 5);
            font-weight: 600;
            vertical-align: middle;
        }
    }
}

.msc-address-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__error {
        display: none;
    }
    .state_field {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;

        .msc-address-form__dropdown {
            transition: border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
            &:not(:disabled):not(.disabled):hover,
            &:not(:disabled):not(.disabled):focus {
                border-color: $color-input-border-hover;
            }
        }

        .msc-address-form__alert {
            order: 2;
            padding: 5px 15px 0;
            color: $color-text-secondary;
        }
        &.item-invalid {
            .required {
                .msc-address-form__label {
                    &:after {
                        content: " *";
                    }
                }
                .field-control:focus ~ .msc-address-form__label {
                    &:after {
                        color: $color-text-primary;
                    }
                }
            }
            .msc-address-form__field.state {
                &:after {
                    color: $color-text-secondary;
                }
            }
            .msc-address-form__alert {
                color: $color-text-secondary;
                order: 2;
            }
            .field-group {
                border: 1px solid $color-text-secondary;
            }
            .msc-address-form__dropdown {
                color: $color-text-secondary;
                &:not(:disabled):not(.disabled):hover,
                &:not(:disabled):not(.disabled):focus {
                    box-shadow: 0 0 0 1.5px $color-error;
                    border-color: $color-error;
                }
                option {
                    color: $color-text-primary;
                }
            }
            .msc-address-form__label {
                color: $color-text-secondary;
            }
        }
    }
    // Placeholder override for phone field
    &__input {
        @extend %field-control-input;
        @extend %float-label;
        @include font-content($font-size-m, 400, 1);
        width: 99.8%;
         // over ride content box due to auto suggest address
         box-sizing: border-box !important;
        &:focus,
        &.field-control--value {
            &:placeholder-shown + .msc-address-form__label {
                padding-right: 5px;
            }
        }
        &:placeholder-shown + .msc-address-form__label {
            visibility: visible;
            z-index: 1;
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
            padding-right: 50px;
            padding-bottom: 4px;
        }
        &--error {
            @include input-field-error($color-error);
        }
    }
    &__label {
        @extend %field-control-label;
    }
    &__dropdown {
        @extend %field-control-dropDown;
        @extend %dropDown-label;
        @extend %reset-input-style;
        margin-bottom: $checkout-margin + 5px;
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            border-color: $color-input-border-hover;
            box-shadow: 0 0 0 1px $color-input-border-hover;
        }
    }
    &__item-state,
    &__item-threeletterisoregionname {
        margin-bottom: $checkout-margin + 15px;
        .field-group {
            border: 1px solid $color-input-border;
            border-radius: 5px;
            display: flex;
            margin-bottom: 0;
            .msc-address-form__label {
                @include font-content($font-size-s, 400, 1);
                order: 1;
            }
            .msc-address-form__dropdown {
                @include font-content($font-size-m, 400, 1.375);
                border: none;
                margin-bottom: 0;
                max-height: none;
                padding: 10px 10px 9px 10px;
                order: 2;
                width: 100%;
                height: 38px;
            }
        }
    }
    &__item {
        align-content: baseline;
        flex: 0 0 100%;
        margin-bottom: $checkout-margin + 5px;
        max-width: 100%;
        @include breakpoint(md) {
            &-firstname,
            &-lastname,
            &-city,
            &-state {
                flex: 0 0 48.73%;
                max-width: 48.73%;
            }
        }
        &-address {
            position: relative;
        }
    }
    &__item-phone {
        margin-bottom: $checkout-margin;
        .msc-address-form__field {
            .msc-alert--error ~ .field-control {
                &::placeholder {
                    color: $color-text-secondary;
                }
                ~ .field-label:after {
                    color: $color-text-secondary;
                }
                &--value ~ .field-label:after {
                    color: $color-text-primary;
                }
            }
        }
    }
    &__item-invalid {
        display: flex;
        flex-wrap: wrap;
        .msc-address-form {
            &__input {
                border: 1px solid $color-error;
                margin-bottom: $checkout-margin - 10px;
                &:not(:disabled):not(.disabled):focus,
                &:not(:disabled):not(.disabled):hover {
                    border-color: $color-error;
                }
                &:not(:disabled):not(.disabled):focus {
                    box-shadow: 0 0 0 1px $color-error;
                }
            }
            &__label {
                color: $color-text-secondary;
            }
        }
        .required {
            .msc-address-form__label {
                &:after {
                    content: " *";
                }
            }
            .field-control:focus ~ .msc-address-form__label {
                &:after {
                    color: $color-text-primary;
                }
            }
        }
        .msc-address-form__alert {
            padding: 0 15px;
            color: $color-text-secondary;
            order: 2;
        }
        &.msc-address-form__item-state {
            .field-group {
                border: 1px solid $color-text-secondary;
            }
            .msc-address-form__dropdown {
                color: $color-text-secondary;
                &:not(:disabled):not(.disabled):hover,
                &:not(:disabled):not(.disabled):focus {
                    border-color: $color-error;
                    box-shadow: 0 0 0 1.5px $color-error;
                }
                option {
                    color: $color-text-primary;
                }
            }
            .state::after {
                color: $color-error;
            }
            .msc-address-form__alert {
                margin: $checkout-margin - 10px 0 0;
            }
        }
    }
    &__field {
        &.state {
            @include add-mfrm-icon($icon-chev-down-slim, after);
            position: relative;
            &.active:after {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            &:after {
                position: absolute;
                top: 15px;
                right: 10px;
                font-weight: bold;
                font-size: 11px;
                pointer-events: none;
            }
        }
        .msc-address-form__input {
            order: 1;
        }
        .msc-alert--error {
            padding: 0 15px;
            color: $color-text-secondary;
            order: 3;
        }
        .msc-alert--error ~ .field-control {
            border-color: $color-error;
            color: $color-text-secondary;
            margin-bottom: $checkout-margin - 10px;
            &:not(:disabled):not(.disabled):hover,
            &:not(:disabled):not(.disabled):focus {
                border-color: $color-error;
            }
            &:not(:disabled):not(.disabled):focus {
                box-shadow: 0 0 0 1px $color-error;
            }
        }
        .msc-alert--error ~ .field-label {
            color: $color-text-secondary;
            &:after {
                color: $color-text-primary;
            }
        }
    }
    &__checkbox-label{
        position: relative;
        margin-left: 33px !important;
        margin-top: -22px;
        @include breakpoint(md) {
            margin-left: 15px !important;
            margin-top: 0;  
        }
    }
}
.ms-checkout-shipping-address .msc-address-detail {
    &__item-firstname,
    &__item-email,
    &__item-address-detail_Phone,
    &__item-newline,
    &__item-apt,
    &__item-zipcode {
        display: block;
    }
    &__item-lastname {
        margin-left: 5px;
    }
    &__item-city {
        &::after {
            content: ",";
            margin-right: 3px;
        }
    }
    &__item-state {
        display: inline-block;
    }
    &__item-phone-label {
        &::after {
            content: ":";
            margin-right: 3px;
        }
    }
}

.ms-checkout-shipping-address {
    &_inner-container {
        position: relative;
    }
    &_customer-info-cta {
        @include vfi-tab();
        position: absolute;
        top: 0;
        right: 0;
        text-decoration: underline;
        &.paypal-express {
            top: 6px;
        }
    }
    &_container {
        position: relative;
        .sr-only {
            @include vfi-sronly();
            width: 100% !important;
            height: 65% !important;
        }
        &__head {
            display: flex;
            margin-bottom: 15px;
            justify-content: space-between;
            h4 {
                @include font-content($font-size-l, 700, 1.25);
                display: inline-block;
                margin: 0;
                text-align: left;
                @include breakpoint(lg) {
                    @include font-content($font-size-xl, 700, 1.22);
                }
            }
            a {
                @include font-content($font-size-m, 400, 1.42);
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
            @include breakpoint(lg) {
                margin-bottom: 30px;
                h2 {
                    @include font-content($font-size-xl, 700, 1.22);
                }
            }
        }
        &__customerInfo {
            ul {
                list-style-type: none;
                margin: 0 0 30px;
                padding: 0;
                @include font-content($font-size-l, 400, 1.37);
                li {
                    margin-bottom: 3px;
                }
                &:last-child {
                    margin: 0;
                }
                @include breakpoint(md) {
                    display: inline-block;
                    margin: 0;
                    vertical-align: top;
                    width: 50%;
                }
            }
        }
    }
    &__paypal-express {
        &-email {
            margin-top: 30px;
        }
    
        &-heading {
            @include font-content(22px, 700, 0.99);
            @include breakpoint(lg) {
                @include font-content($font-size-xxl, 700, 1.2);
            }
        }

        &-phone {
            margin-top: 20px;
        }

        &-number {
            margin-bottom: 15px !important;
            @include breakpoint(lg) {
                margin-bottom: 30px !important;
            }

        }
    }
}

// MCS Auto suggest Location Styles override.
.MicrosoftMap {
    //Override MSC inline css
    position: relative !important;
    width: 100%;
    order: 1;

    .as_container_search {
        width: 99.8% !important;
    }
}