$spacer-size-desktop: 30px;
$spacer-size-mobile: 15px;

.vert-space-sm {
  @extend .mt-2;
  @extend .mb-2;
}

.vert-space-md {
  @extend .mt-3;
  @extend .mb-3;
}

.vert-space-lg {
  @extend .mt-5;
  @extend .mb-5;
}

.ms-spacer {
  height: $spacer-size-mobile;

  @include breakpoint(lg) {
    & {
      height: $spacer-size-desktop;
    }
  }
  &0x {
    height: 0;
  }
  &1x {
    height: 5px;
  }
  &2x {
    height: 10px;
  }
  &3x {
    height: 15px;
  }
  &4x {
    height: 20px;
  }
  &5x {
    height: 25px;
  }
  &6x {
    height: 30px;
  }
  &7x {
    height: 35px;
  }
  &8x {
    height: 40px;
  }
  &9x {
    height: 45px;
  }
  &10x {
    height: 50px;
  }
  &11x {
    height: 55px;
  }
  &12x {
    height: 60px;
  }
  &13x {
    height: 65px;
  }
  &14x {
    height: 70px;
  }
  &15x {
    height: 75px;
  }
  &16x {
    height: 80px;
  }
  &17x {
    height: 85px;
  }
  &18x {
    height: 90px;
  }
  &19x {
    height: 95px;
  }
  &20x {
    height: 100px;
  }
}
