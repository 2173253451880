$mfrm-accordion-selector: accordionItem-module;

.mfrm-delivery-shipping {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-charcoal;
        text-align: center;

        .learnMore {
            text-align: center;

            a {
                color: $color-punch;
            }
        }
    }

    &__card {
        &-flex-items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            border-radius: 2px;
            border: 1px solid $color-gainsboro;
            padding: 30px 0;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }

            hr {
                width: 100%;
                border-top: 1px solid $color-gainsboro;
                border-bottom: 0;
                margin: 30px 0;
            }
        }

        &-primary {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            align-items: center;
            width: 100%;
        }

        &-title {
            @include font-content($font-size-xxxxl, bold, 1.11);
        }

        &-badge {
            margin-top: 20px;
            @include font-content($font-size-l, 500, 1.25);
            padding: 9px;
            background-color: $color-punch;
            color: $color-white;
            width: 100%;
        }

        &-listedPrice {
            @include font-content($font-size-xl, normal, 1.22);
            text-decoration: line-through;

            &-container {
                margin-top: 20px;
                min-height: 22px;
            }
        }

        &-salePrice {
            @include font-content($font-size-xxxxl, bold, 1.11);
            margin-top: 5px;
        }

        &-secondary {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-basis: 100%;
            padding: 0 45px;
            justify-content: center;
        }

        &-description {
            width: 100%;
            @include font-content($font-size-l, bold, 1.375);
            text-align: left;
            margin-bottom: 20px;
        }

        &-details {
            @include font-content($font-size-m, normal, 1.143);
            text-align: left;

            p {
                display: flex;
                @include add-mfrm-icon($icon-checkmark, before);

                &::before {
                    margin-right: 10px;
                    color: $color-punch;
                    border-radius: 50%;
                    box-shadow: 0 0 0 1.75px $color-punch;
                    padding: 5.5px;
                    max-height: 25px;
                }

                &:not(:last-of-type) {
                    margin-bottom: 20px;
                }
            }
        }

        @media (min-width: 1025px) {
            &-flex-items {
                padding: 0;

                hr {
                    display: none;
                }
            }

            &-primary {
                flex-basis: 38%;
                padding: 60px 0;
                border-right: 1px solid $color-gainsboro;
            }

            &-secondary {
                flex-basis: 62%;
                padding: 60px 50px 40px;
            }

            &-details {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;

                p {
                    width: calc(50% - 20px);
                }
            }
        }
    }
}

.mfrm-product-details {
    .mfrm-accordion {
        [class*="#{$mfrm-accordion-selector}_line"] {
            padding: 20px 15px 20px 15px;
        }
        [class*="#{$mfrm-accordion-selector}_innerContent"] {
            margin-bottom: 58px;
        }
        [class*="#{$mfrm-accordion-selector}_title"] {
           @include breakpoint(lg){
            padding-left: 32px;
           }
         }
        .mfrm-tab-accordion__delivery-tab {
            padding: 32px 8px 0 8px ;
            @include breakpoint(lg){
                padding: 40px 0 0 0;
            }
            & .mfrm-delivery-shipping-redesign {
                &__container {
                    width: 100%;
                    @include custom-scrollbar-webkit();
                    display: inline-flex;
                    overflow-x: auto;
                    white-space: nowrap;
                    padding-bottom: 28px;
                }
                &__card {
                    width: 302px;
                    height: 400px;
                    margin-right: 8px;
                    &:last-of-type{
                        margin-right: 0;
                      }
                    @include breakpoint(lg) {
                        width: 389px;
                        margin-right: 16px;
                    }
                    &-top {
                        color: $color-charcoal;
                        height: 300px;
                        width: 302px;
                        background: $color-white;
                        border: 1px solid $color-mercury;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        border-bottom-color: white;
                        padding-top: 40px;
                        @include breakpoint(lg) {
                            width: 389px;
                        }
                    }
                    &-title {
                        text-align: center;
                        margin-bottom: 28px;
                        @include font-content(20px, 700, 24px);

                        @include breakpoint(lg) {
                            @include font-content($font-size-xxxl, 700, 28px);
                        }
                    }
                    &-description{
                        width: 100%;
                        @include font-content(14px, bold, 1.375);
                        text-align: left;
                        padding: 0 17px 0 24px;
                        margin-bottom: 10px;
                        @include breakpoint(lg){
                            padding: 0 64px 0 64px;
                        }
                    }
                    &-details {
                        padding: 0 17px 40px 24px;
                        text-align: left;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        position: relative;
                        z-index: 2;
                        @include breakpoint(lg){
                            padding: 0 64px 40px 64px;
                        }
                        p {
                            display: flex;
                            align-items: center;
                            white-space: break-spaces;
                            margin-bottom: 8px;
                            color: $color-charcoal;
                            @include font-content($font-size-s, 400, 16px);
                            @include add-mfrm-icon($icon-checkmark, before);
                            &::before {
                                width: 16px;
                                height: 16px;
                                padding: 2px;
                                border-radius: 50%;
                                margin-right: 10px;
                                color: $color-white;
                                box-shadow: 0 0 0 1.75px $color-punch;
                                background-color: $color-punch;
                            }
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                            @include breakpoint(lg) {
                                margin-bottom: 20px;
                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &-bottom {
                        background: $color-punch;
                        width: 100%;
                        height: 100px;
                        box-sizing: border-box;
                        color: black;
                        border-radius: 10px;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        padding: 10px 15px;
                        position: relative;

                        &::before {
                            content: "";
                            width: 300px;
                            height: 25px;
                            position: absolute;
                            top: 0;
                            left: 1px;
                            z-index: 1;
                            background: white;
                            border-radius: 60%;
                            border: 1px solid white;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            transform: translatey(-50%);
                            @include breakpoint(lg) {
                                width: 387px;
                            }
                        }
                    }
                }

                &__pricing {
                    position: relative;
                    color: white;
                    text-align: center;
                    top: 15px;
                    & .strike {
                        height: 28px;
                        & .mfrm-delivery-shipping-redesign__strikethrough-price {
                            text-decoration: line-through;
                            @include font-content(16px, 400, 28px);
                        }
                    }
                    @include breakpoint(lg) {
                        @include font-content(20px, 400, 28px);
                    }
                }
                &__saleprice {
                    @include font-content(22px, 700, 28px);
                    @include breakpoint(lg) {
                        @include font-content($font-size-xxxl, 700, 28px);
                    }
                }
            }
        }
    }
}
