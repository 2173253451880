$bv-color-background: #d63426;
$bv-color-background-hover: #9d1c11;
$bv-border-color: #f8f8f8;
$bv-button-color-disable: #b3b5b7;
$bv-color-white: #fff;
$bv-color-text: #2d2926;
$bv-font-family: "Rubik", sans-serif;
$bv-border-radius: 7.5px;

//Breakpoints
$bv-breakpoint-sm: 576px;
$bv-breakpoint-md: 768px;
$bv-breakpoint-lg: 1025px;
$bv-breakpoint-xl: 1200px;

.mfrm-bazaarvoice-container {
	scroll-margin-top: 40px;
    // override for Rating dropdown
    select[id*="bv-reviews"]{
      line-height: 16px !important;
    }
	@include breakpoint(xl) {
		max-width: 1200px;
		margin: 0 auto;
	}
}
//Add spacing
.mfrm-bazaarvoice-container [data-bv-show] .bv-cleanslate .bv-shared {
	padding: 30px 0 !important;
	@media only screen and (min-width: $bv-breakpoint-md) {
		padding: 60px 0 !important;
	}
}

//--------------------------------------------
//	BazaarVoice SCSS Override Start
//--------------------------------------------

// Root override classes
.mfrm-bazaarvoice-container [data-bv-show] .bv-cleanslate .bv-shared {
	// 1. Action Bar
	.bv-action-bar {
		padding: 0 0 30px !important;
		display: flex !important;
		flex-direction: column !important;
		justify-content: space-between !important;
		align-items: center !important;
		&::before,
		&::after {
			display: none !important;
		}
		@media only screen and (min-width: $bv-breakpoint-md) {
			padding: 0 0 60px !important;
			flex-direction: row !important;
		}
		.bv-action-bar-header {
			margin: 0 0 15px !important;
			font-family: $bv-font-family !important;
			font-size: 0 !important;
			font-weight: bold !important;
			line-height: 1.14 !important;
			float: none !important;
			color: $bv-color-text !important;
			text-align: center !important;
			@media only screen and (min-width: $bv-breakpoint-md) {
				margin: 0 !important;
				float: left !important;
				text-align: left !important;
			}
			&::before {
				display: block !important;
				content: "Customer Ratings & Reviews" !important;
				font-size: 28px !important;
			}
		}
		.bv-write-review-container {
			float: none !important;
			@media only screen and (min-width: $bv-breakpoint-md) {
				float: right !important;
			}
		}
		.bv-write-review {
			margin: 0 auto !important;
			padding: 15px 60px !important;
			font-family: $bv-font-family !important;
			font-size: 16px !important;
			font-weight: bold !important;
			line-height: 1 !important;
			background-color: $bv-color-background !important;
			background-image: none !important;
			box-shadow: none !important;
			color: $bv-color-white !important;
			text-decoration: none !important;
			outline: 0 !important;
			outline-offset: 0 !important;
			border: 1px solid $bv-color-background !important;
			border-radius: 100px !important;
			transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;
			&:hover,
			&:active,
			&:not(:disabled):not(.disabled):active,
			&:not(:disabled):not(.disabled).active {
				background-color: $bv-color-background-hover !important;
				border-color: $bv-color-background-hover !important;
			}
			&:focus {
				outline: 1px dotted $bv-color-text !important;
			}

			@media only screen and (min-width: $bv-breakpoint-md) {
				margin: 0 !important;
			}
		}
	}

	// 2. Section Summary
	.bv-section-summary {
		.bv-flex-container-responsive {
			flex-direction: column !important;
			@media only screen and (min-width: $bv-breakpoint-md) {
				flex-direction: row !important;
				justify-content: space-between !important;
			}
			.bv-section-summary-block {
				width: 100% !important;
				&.bv-content-top-review {
					width: 100% !important;
				}
				@media only screen and (min-width: $bv-breakpoint-md) {
					width: 49% !important;
				}
			}
		}
		//Rating Snapshot
		.bv-inline-histogram-ratings {
			padding-bottom: 30px !important;
			@media only screen and (min-width: $bv-breakpoint-md) {
				padding-bottom: 0 !important;
			}
			.bv-content-title {
				padding: 0 0 10px !important;
				font-family: $bv-font-family !important;
				font-size: 18px !important;
				font-weight: bold !important;
				line-height: 1.22 !important;
				color: $bv-color-text !important;
				background-color: transparent !important;
			}
			.bv-histogram-filter-helper {
				padding: 0 0 15px !important;
				margin: 0 !important;
				font-family: $bv-font-family !important;
				font-size: 16px !important;
				font-weight: normal !important;
				line-height: 1.37 !important;
				color: $bv-color-text !important;
			}
			.bv-flex-container-column {
				width: 100% !important;
				min-width: auto !important;
				margin: 0 !important;
			}
			.bv-inline-histogram-ratings-star-container {
				margin: 0 0 10px !important;
				min-height: 28px;
				//Star icon with number
				.bv-inline-histogram-ratings-star {
					padding: 0 15px 0 0 !important;
					font-family: $bv-font-family !important;
					font-size: 16px !important;
					font-weight: bold !important;
					line-height: 1.37 !important;
					color: $bv-color-text !important;
					.bv-glyph {
						margin-left: 5px !important;
						font-size: 20.5px !important;
						color: $bv-color-background !important;
					}
				}
				.bv-inline-histogram-ratings-bar {
					margin: 0 !important;
					padding: 0 !important;
					width: auto !important;
					flex: 1 !important;
					.bv-content-secondary-ratings-container,
					.bv-content-secondary-ratings-value {
						height: 15px !important;
						box-shadow: none !important;
						background-image: none !important;
						border-radius: $bv-border-radius !important;
					}
				}

				.bv-inline-histogram-ratings-score {
					min-width: 7% !important;
					width: auto !important;
					max-width: 11% !important;
					margin: 0 !important;
					padding: 0 !important;
					flex: 0 !important;
					flex-basis: auto !important;
					font-family: $bv-font-family !important;
					font-size: 16px !important;
					font-weight: normal !important;
					line-height: 1.37 !important;
					color: $bv-color-text !important;
				}
			}
		}
		//Average Customer Ratings
		.bv-secondary-rating-summary {
			.bv-content-title {
				padding: 0 0 15px !important;
				font-family: $bv-font-family !important;
				font-size: 18px !important;
				font-weight: bold !important;
				line-height: 1.56 !important;
				color: $bv-color-text !important;
				background-color: transparent !important;
				border-radius: 0 !important;
				@media only screen and (min-width: $bv-breakpoint-md) {
					min-height: 52px !important;
					text-align: right !important;
				}
				@media only screen and (min-width: $bv-breakpoint-xl) {
					min-height: 52px !important;
					text-align: left !important;
				}
			}
			.bv-secondary-rating-summary-list {
				margin: 0 !important;
				display: flex !important;
				flex-direction: column !important;
				.bv-secondary-rating-summary-stars,
				.bv-secondary-rating-summary-bars-container {
					display: flex !important;
					flex-direction: row !important;
					align-items: center !important;
					min-height: 28px !important;
					margin: 0 0 10px !important;
					.bv-secondary-rating-summary-id,
					.bv-secondary-rating-summary-rating {
						width: auto !important;
						padding: 0 !important;
						flex: 0 !important;
						flex-basis: auto !important;
						font-family: $bv-font-family !important;
						font-size: 16px !important;
						color: $bv-color-text !important;
					}
					.bv-secondary-rating-summary-id {
						min-width: 24% !important;
						max-width: 24% !important;
						font-weight: bold !important;
						line-height: 1.37 !important;
						width: 67px !important;
						min-width: auto !important;
						padding-right: 20px !important;
						overflow: hidden !important;
						height: 22px !important;
						word-spacing: 100vw !important;
						@media only screen and (min-width: $bv-breakpoint-sm) {
							min-width: 15% !important;
							max-width: 15% !important;
						}
						@media only screen and (min-width: $bv-breakpoint-md) {
							min-width: 28% !important;
							max-width: 28% !important;
						}
						@media only screen and (min-width: $bv-breakpoint-lg) {
							min-width: 20% !important;
							max-width: 20% !important;
						}
						@media only screen and (min-width: $bv-breakpoint-xl) {
							min-width: 17% !important;
							max-width: 17% !important;
						}
					}
					.bv-secondary-rating-summary-rating {
						padding-left: 10px !important;
						font-weight: normal !important;
						line-height: 1 !important;
					}
				}
				.bv-secondary-rating-summary-stars {
					span.bv-table-cell:not(.bv-secondary-rating-summary-id):not(.bv-secondary-rating-summary-rating) {
						flex: 1 !important;
						flex-basis: auto !important;
						padding: 0 !important;
					}
					.bv-rating-stars-container {
						.bv-rating-stars {
							font-size: 20.5px !important;
							letter-spacing: 4px !important;
						}
						.bv-rating-stars-off {
							color: $bv-button-color-disable !important;
						}
						.bv-rating-stars-on {
							color: $bv-color-background !important;
						}
					}
				}
				//Bars
				.bv-secondary-rating-summary-bars-container {
					.bv-secondary-rating-summary-container {
						margin: 0 !important;
						padding: 0 !important;
						flex: 1 !important;
						.bv-secondary-rating-summary-bars,
						.bv-secondary-rating-summary-bar,
						.bv-secondary-rating-summary-value {
							height: 15px !important;
							background-image: none !important;
							box-shadow: none !important;
							border-radius: $bv-border-radius !important;
						}
					}
				}
			}
		}
	}
	// 3. Control Bar
	.bv-control-bar {
		margin-bottom: 15px !important;
		@media only screen and (min-width: $bv-breakpoint-md) {
			margin-bottom: 30px !important;
		}

		// Sort Filter
		.bv-control-bar-sort {
			width: 100% !important;
			margin-right: 0 !important;
			@media only screen and (min-width: $bv-breakpoint-md) {
				width: 345px !important;
			}
			@media only screen and (min-width: $bv-breakpoint-xl) {
				width: 241px !important;
			}
			.bv-dropdown {
				border: 1px solid $bv-button-color-disable !important;
				border-radius: 5px !important;
				background-color: $bv-color-white !important;
				.bv-dropdown-target {
					padding: 14px 15px !important;
					.bv-dropdown-label,
					.bv-dropdown-select-reviews {
						font-family: $bv-font-family !important;
						font-size: 14px !important;
						font-weight: bold !important;
						line-height: 1 !important;
						color: $bv-color-text !important;
					}
					.bv-dropdown-label {
						font-weight: 400 !important;
					}
					button {
						font-weight: 700 !important;
					}
					.bv-dropdown-arrow {
						@include add-mfrm-icon($icon-chev-down);
						visibility: hidden !important;
						line-height: 0 !important;
						padding-right: 6px !important;
						font-size: 7px !important;
						&::before {
							display: block !important;
							visibility: visible !important;
							line-height: 1 !important;
						}
					}
				}
			}
		}
		.bv-expand-filter-button {
			display: none !important;
		}
	}
	// 4. Content List
	.bv-content-list {
		.bv-content-item {
			border: 1px solid $bv-button-color-disable !important;
			border-radius: 5px !important;
			margin-bottom: 15px !important;
			@media only screen and (min-width: $bv-breakpoint-xl) {
				margin-bottom: 30px !important;
			}
			.bv-content-container {
				.bv-content-details-offset-on {
					width: 100% !important;
					@media only screen and (min-width: $bv-breakpoint-xl) {
						width: 75% !important;
					}
				}
				// content header
				.bv-content-header {
					padding: 15px !important;
					background-color: $bv-border-color !important;
					background-image: none !important;
					border-radius: 5px 5px 0 0 !important;
					@media only screen and (min-width: $bv-breakpoint-xl) {
						padding: 15px 30px !important;
					}
					.bv-content-header-meta {
						display: flex !important;
						align-items: center !important;
						margin: 0 !important;
						padding-bottom: 10px !important;
						flex-wrap: wrap !important;
					}
					.bv-rating-stars-container {
						margin-right: 15px !important;
						@media only screen and (min-width: $bv-breakpoint-md) {
							margin-right: 10px !important;
						}
					}
					.bv-content-rating .bv-rating-stars {
						font-size: 20.5px !important;
						letter-spacing: 4px !important;
					}
					.bv-content-rating .bv-rating-stars-off {
						color: $bv-button-color-disable !important;
					}
					.bv-content-meta-wrapper {
						.bv-author {
							font-family: $bv-font-family !important;
							font-size: 16px !important;
							font-weight: bold !important;
							line-height: 1 !important;
							color: $bv-color-text !important;
						}
						.bv-content-datetime {
							font-family: $bv-font-family !important;
							font-size: 15px !important;
							font-weight: normal !important;
							line-height: 1 !important;
							color: $bv-color-text !important;
						}
					}
					.bv-content-title {
						margin: 0 !important;
						font-family: $bv-font-family !important;
						font-size: 16px !important;
						font-weight: bold !important;
						line-height: 1.5 !important;
						color: $bv-color-text !important;
						@media only screen and (min-width: $bv-breakpoint-md) {
							font-size: 21px !important;
							line-height: 1 !important;
						}
					}
					//Badge
					.bv-content-badges-container {
						position: relative !important;
						padding: 0 !important;
						width: 100% !important;
						text-align: right !important;
						@media only screen and (min-width: $bv-breakpoint-md) {
							position: absolute !important;
							padding: 0 !important;
						}
					}
				}
				// content body
				.bv-content-summary {
					padding: 15px !important;
					@media only screen and (min-width: $bv-breakpoint-xl) {
						padding: 30px !important;
					}
					.bv-content-summary-body-text p {
						margin-bottom: 15px !important;
						@media only screen and (min-width: $bv-breakpoint-xl) {
							margin-bottom: 30px !important;
						}
					}
					// No style/show up
					.bv-content-product-questions,
					.bv-content-tag-dimensions {
						margin: 0 !important;
					}
					.bv-content-media-container {
						margin-top: 0 !important;
						margin-bottom: 0 !important;
						.bv-media-item {
							max-width: 96px !important;
							.bv-media-item-wrapper {
								padding-top: 100% !important;
							}
							.bv-image-wrapper img {
								width: 100% !important;
								height: 100% !important;
								object-fit: cover !important;
								border-radius: 2px !important;
							}
						}
						.bv-media-item-border {
							border: 0 !important;
						}
					}
					.bv-content-data {
						margin-bottom: 0 !important;
						.bv-content-data-recommend-yes,
						.bv-content-data-recommend-no {
							.bv-content-data-label-container,
							.bv-content-data-value {
								font-family: $bv-font-family !important;
								font-size: 15px !important;
								font-weight: bold !important;
								line-height: 1.5 !important;
								color: $bv-color-text !important;
							}
							.bv-content-data-value {
								margin-right: 3px !important;
								.bv-content-data-icon {
									visibility: hidden !important;
									font-size: 0 !important;
									&::before {
										visibility: visible !important;
										font-size: 15px !important;
										margin-right: 5px !important;
										color: $bv-color-background !important;
									}
								}
							}
						}
						.bv-content-data-recommend-yes .bv-content-data-value .bv-content-data-icon {
							@include add-mfrm-icon($icon-check-fill);
						}
						.bv-content-data-recommend-no .bv-content-data-value .bv-content-data-icon {
							@include add-mfrm-icon($icon-times-fill);
						}
					}
				}
				// Content Ratings
				.bv-content-details-container {
					margin: 0 !important;
					width: 100% !important;
					@media only screen and (min-width: $bv-breakpoint-md) {
						width: 50% !important;
						float: left !important;
					}
					@media only screen and (min-width: $bv-breakpoint-xl) {
						width: 23.79% !important;
						float: right !important;
					}
				}
				.bv-content-details-container .bv-secondary-ratings {
					padding: 15px 15px 0 !important;
					margin: 0 0 15px !important;
					@media only screen and (min-width: $bv-breakpoint-xl) {
						padding: 0 30px !important;
						margin: 30px 0 15px !important;
					}
					.bv-content-secondary-ratings-label {
						margin-bottom: 10px !important;
						font-family: $bv-font-family !important;
						font-size: 15px !important;
						font-weight: bold !important;
						line-height: 1 !important;
						color: $bv-color-text !important;
						overflow: hidden !important;
						white-space: nowrap !important;
						word-spacing: 100vw !important;
					}
					.bv-popup-histogram-ratings-bar {
						padding-bottom: 15px !important;
					}
					.bv-content-secondary-ratings-container,
					.bv-content-secondary-ratings-bars,
					.bv-content-secondary-ratings-bar,
					.bv-content-secondary-ratings-value {
						height: 10px !important;
						border-radius: $bv-border-radius !important;
						box-shadow: none !important;
						background-image: none !important;
					}
				}
			}
			// feedback area
			.bv-content-actions-container {
				margin: 0 !important;
				padding: 0 15px 0 !important;
				@media only screen and (min-width: $bv-breakpoint-xl) {
					padding: 0 30px 15px !important;
				}
				.bv-feedback-container {
					width: 100% !important;
					.bv-content-feedback-vote,
					.bv-content-feedback-vote-active {
						display: flex !important;
						align-items: center !important;
						.bv-content-feedback-btn-container {
							margin: 0 5px 0 0 !important;
							button {
								margin-bottom: 0 !important;
							}
						}
					}
				}
			}
		}
	}
	// 5. Pagination
	.bv-content-pagination {
		margin: 0 !important;
		.bv-content-pagination-container {
			.bv-control-bar-count {
				padding: 0 !important;
			}
			.bv-content-pagination-buttons {
				margin: 0 !important;
				.bv-content-pagination-buttons-item {
					margin-right: 5px !important;
				}
				.bv-content-btn-pages-inactive {
					background-color: $bv-border-color !important;
					.bv-content-btn-pages-prev,
					.bv-content-btn-pages-next {
						color: $bv-button-color-disable !important;
					}
				}
				.bv-content-btn-pages-active {
					background-color: $bv-color-background !important;
					.bv-content-btn-pages-prev,
					.bv-content-btn-pages-next {
						color: $bv-color-white !important;
					}
				}
			}
		}
		.bv-content-btn-pages,
		.bv-content-btn-pages:hover {
			background-color: $bv-color-background !important;
			color: $bv-color-white !important;
		}
	}
}


.mfrm-bazaarvoice-heading {
	display: none !important;
}
.pdp {
	.bv-cv2-cleanslate {
		.bv-shared.bv-content-sort-dropdown {
			.bv-compat {
				.bv-dropdown {
					ul.bv-dropdown-active {
						.bv-dropdown-item {
							&:hover {
								background-color: $bv-color-white !important;
								color: $bv-color-text !important;
								font-weight: 700 !important;
								font-size: 14px !important;
							}
						}
					}
				}
			}
		}
	}
}
