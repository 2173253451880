$chat-bot-color: $color-text-secondary;
$chat-bot-bg-color: $color-bg-chat-invitation;

/* Moving Scroll Icon a Little Bit Top */
/* Setting Z Index High for Chat for Moving Scroll Topper Behind Chat Widget */
/* Change icon to face photo */
/*mobile*/
.embeddedServiceHelpButton {

	// .cart &,
	// .checkout & {
	// 	display: none !important; // !important to override inline CSS when chat closed on cart/checkout

	// 	&.show-chat {
	// 		display: block;
	// 	}
	// }
	.cart &,
	.checkout & {
		display: none !important;
		.helpButton {
			bottom: 100px !important;
			width: 46px !important;
			min-width: 46px !important;
			right: 30px;

			@include breakpoint(lg) {
				bottom: 30px !important;
			}

			.uiButton {
				padding: 4px 0 0 10px;
				width: 46px;
				display: block;
				border-radius: 50px;
				min-width: 46px !important;
				background: $color-bg-error-page !important;
				box-shadow: none;

				.embeddedServiceIcon {
					display: block !important;
					font-size: 21px !important;
					@include add-mfrm-icon($icon-chat, after);

					&::before {
						display: none !important;
					}
				}

				.helpButtonLabel {
					display: none;
				}

				&:focus,
				&:hover {
					&::before {
						background-color: transparent;
					}
				}
			}
		}
	}

	.helpButton {
		.uiButton {
			font-family: "Rubik", sans-serif;
			background: $color-bg-chat-invitation !important;
			box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);

			&:focus {
				outline: 1px solid $chat-bot-color;
			}
		}

		display: block !important;
	}

	.embeddedServiceIcon {
		width: 26px;

		&::before {
			background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/cncgmclkfv/imageFileData/MAgUo1?pubver=1);
			background-size: cover;
			content: "" !important; // !important needed to hide the chat bubble icon coming from SFCC.
			height: 35px;
			width: 35px;
			display: block;
			border-radius: 50%;
			margin: -2px -8px 0 -6px;
		}
	}

	@include breakpoint(md) {
		z-index: 10010 !important; // !important needed to prevent account drawer covering chat button.
	}
}

.helpButton {
	font-size: 14px !important;
	width: auto;
	min-width: 215px !important;

	.uiButton {
		min-width: 215px !important;
	}
}

button.uiButton {
	text-transform: capitalize;
}

.embeddedServiceSidebarMinimizedContainer {
	background-color: $chat-bot-bg-color !important;
	background-color: $chat-bot-bg-color !important;

	&:hover {
		text-decoration: none !important;
		text-decoration: none !important;
	}
}

.embeddedServiceLiveAgentStatePrechatDefaultUI {
	.startButtonWrapper {
		padding: 0px 24px 24px;
	}

	.backgroundImgColorMask {
		height: 98px;
		height: 98px;
	}

	.backgroundImg {
		height: 98px;
	}

	.list {
		margin: 0px 24px 0px 12px;
	}
}

#rejectInvite {
	line-height: 20px !important;
}

#acceptInvite {
	line-height: 20px !important;
}

h2[embeddedService-chatHeader_chatHeader] {
	color: $color-white;
	font-size: 14px;
	font-weight: 700 !important;
}

button[embeddedService-chatHeader_chatHeader] {
	padding: 0 5px;
}

.announcement[embeddedService-chatHeaderAnnouncement_chatHeaderAnnouncement] {
	color: $color-white;
}

svg.slds-icon.slds-icon-text-default.slds-icon_x-small {
	width: 16px;
	height: 16px;
}

.sidebarHeader[embeddedService-chatHeader_chatHeader] {
	align-items: normal;
	padding: 0 10px;
}

button {
	&:hover[embeddedService-chatHeader_chatHeader] {
		&:before {
			top: 7px;
			width: 29px;
			height: 29px;
		}
	}
}

button.minimizeButton {
	&:hover[embeddedService-chatHeader_chatHeader] {
		&:before {
			right: 42px;
		}
	}
}

button.closeButton {
	&:hover[embeddedService-chatHeader_chatHeader] {
		&:before {
			right: 11px;
		}
	}
}

.embeddedServiceSidebar.modalContainer {
	// TODO: replace SFCC styles to prevent overwrite.
	z-index: 10010 !important;
}

.cart,
.checkout {
	.embeddedServiceSidebar {
		&.modalContainer {
			z-index: 4000 !important;
		}
		.embeddedServiceSidebarMinimizedDefaultUI {
			padding: 0;
			right: 30px;
			border-radius: 50px;
			width: 45px;
			min-width: 45px !important;
			height: 45px !important;
    		max-height: 45px !important;
			

			&:not(.helpButton) {
				background-color: $color-bg-error-page  !important;
				border: none;
				bottom: 165px !important;

				@include breakpoint(lg) {
					bottom: 30px !important;
				}

				.minimizedText {
					display: block;
					margin-top: 5px;
					font-size: 21px !important;
					@include add-mfrm-icon($icon-chat);

					span {
						display: none;
					}
				}
			}

			&.helpButton {
				bottom: 165px !important;
				background-color: $color-bg-error-page  !important;
				border: none;
				box-shadow: none !important;

				@include breakpoint(lg) {
					bottom: 30px !important;
				}
			}

			.content {
				padding: 0 !important;
			}

			.embeddedServiceIcon {
				font-size: 21px;
				margin-right: 0 !important;
				@include add-mfrm-icon($icon-chat);

				svg {
					display: none;
				}
			}

			.minimizedText {
				display: none;
				font-size: 11px !important;
			}
			
			.loadingBallContainer{
				display: none;
			}

			&:focus {
				text-decoration: none !important;
			}
		}
	}
}

#closeInvite {
	padding: 0px !important;
	line-height: 20px !important;
}

.embeddedServiceSidebarForm {
	.fieldList {
		margin: 0px 24px 0 12px !important;
	}
}

.embeddedServiceSidebarForm.buttonWrapperchange {
	padding: 0px 24px 24px !important;
}

.embeddedServiceLiveAgentStateChatMenuMessage {
	.rich-menu-item {
		text-transform: capitalize;
		padding: 0px;
	}

	.rich-menu-itemOptionIsClicked {
		text-transform: capitalize;
		padding: 0px;
	}
}

.embeddedServiceSidebarMinimizedDefaultUI {
	background-color: $chat-bot-color !important;
	text-transform: capitalize;

	.content {
		padding: 0px;
	}

	.minimizedText {
		overflow: inherit;
	}

	.minimizedImage {
		display: none;
	}
}

.embeddedServiceLiveAgentStateChatItem.chatMessage {
	button {
		height: auto;
		font-size: $font-size-m;
	}
}

.embeddedServiceSidebarExtendedHeader {
	background-color: $color-icon-tertiary;

	.queuePositionContent {
		.queuePositionMessage {
			p {
				color: $color-white;
			}
		}
	}
}

#snapins_invite {
	background-color: $color-white;
	font-family: $font-family-primary;
	overflow: visible;
	border-radius: 8px;
	visibility: hidden;
	display: none;
}

.embeddedServiceInvitation {
	background-color: transparent;
	max-width: 290px;
	max-height: 210px;
	-webkit-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.28);
	-moz-box-shadow: 0 7px 12px rgba(0, 0, 0, 0.28);
	box-shadow: 0 7px 12px rgba(0, 0, 0, 0.28);

	>.embeddedServiceInvitationHeader {
		width: inherit;
		height: 32px;
		line-height: 32px;
		padding: 10px;
		color: $color-white;
		background-color: $color-bg-chat-invitation;
		overflow: initial;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	>.embeddedServiceInvitationBody {
		background-color: $color-white;
		max-height: 120px;
		min-width: 260px;
		margin: 0 8px;
		font-size: 14px;
		line-height: 20px;
		overflow: auto;
	}

	>.embeddedServiceInvitationFooter {
		width: inherit;
		color: $color-white;
		text-align: right;
		background-color: $color-white;
		padding: 10px;
		max-height: 50px;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}

.embeddedServiceInvitationHeader {
	#embeddedServiceAvatar {
		width: 32px;
		height: 32px;
		border-radius: 50%;
	}

	.embeddedServiceTitleText {
		font-size: 18px;
		color: $color-white;
		overflow: hidden;
		word-wrap: normal;
		white-space: nowrap;
		text-overflow: ellipsis;
		align-self: stretch;
		flex-grow: 1;
		max-width: 100%;
		margin: 0 12px;
	}

	.embeddedServiceCloseIcon {
		border: none;
		border-radius: 3px;
		cursor: pointer;
		position: relative;
		bottom: 3%;
		background-color: transparent;
		width: 32px;
		height: 32px;
		font-size: 23px;
		color: $color-white;

		&:focus {
			outline: none;

			&::before {
				content: " ";
				position: absolute;
				top: 11%;
				left: 7%;
				width: 85%;
				height: 85%;
				background-color: rgba(255, 255, 255, 0.2);
				border-radius: 4px;
				pointer-events: none;
			}
		}

		&:active {
			background-color: $color-white;
			color: rgba(0, 0, 0, 0.7);
			opacity: 0.7;
		}
	}
}

.embeddedServiceCloseIcon {
	&:hover {
		background-color: $color-white;
		color: rgba(0, 0, 0, 0.7);
		opacity: 0.7;
	}
}

.embeddedServiceInvitationBody {
	p {
		color: $color-text-chat;
		padding: 8px;
		margin: 12px 0;
	}
}

.embeddedServiceInvitationFooter {
	>.embeddedServiceActionButton {
		font-size: 14px;
		max-height: 40px;
		border: none;
		border-radius: 4px;
		padding: 10px;
		margin: 4px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		cursor: pointer;
	}

	>#acceptInvite {
		background-color: $chat-bot-color;
		color: $color-white;
	}

	>#rejectInvite {
		background-color: $color-white;
		color: $chat-bot-color;
	}
}

.stateBody.cMFRMPreChatForm.prechatContent.textBoxStyle {
    font-size: 16px!important;
	&:focus {
        font-size: 16px!important;
    }
}

@media screen and (max-width: 767px) {
	.embeddedServiceSidebarMinimizedDefaultUI {
		.content {
			height: auto;
		}
	}

	#snapins_invite {
		display: none;
	}
}

@media screen and (max-width: 375px) {
	.embeddedServiceSidebarMinimizedDefaultUI.sidebarHeader {
		bottom: 2%;
	}
}

@media screen and (min-width: 40.5em) {
	.scrollToTop.button {
		bottom: 60px !important;
		right: 70px !important;
	}
}

@media only screen and (min-width: 48em) {
	.embeddedServiceInvitation {
		max-width: 332px;
		max-height: 210px;
	}
}