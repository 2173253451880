$buy-now-pay-later-options-cost-color: $color-text-secondary;
$buy-now-pay-later-options-gap: 30px;
$buy-now-pay-later-options-border: $color-input-border;
$progressive-leasing-modal-header-font-size-mobile: 2.28rem;
$progressive-leasing-modal-header-font-size: 2.57rem;
$progressive-leasing-modal-check-icon-color: $color-button-border;
$progressive-leasing-modal-disclaimer: $color-text-tertiary;
$progressive-leasing-modal-space: 30px;
//==============================================================================
// Buy Now Pay Later Options
//==============================================================================
.mfrm-buy-now-pay-later {
  @include full-width();
  padding: 30px 0;
  // margin-bottom: 150px;
  background: $color-bg-primary;
  margin-bottom: 40px;
  @include breakpoint(md) {
    padding: 30px;
  }
  @include breakpoint(lg) {
    width: 100%;
    max-width: $page-width-max;
    padding: 50px 30px 0px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0px;
  }
  &.single-display {
    .mfrm-buy-now-pay-later-options__section-left {
      padding: 0;
    }
    .mfrm-buy-now-pay-later-options__section-right {
      padding: 0;
      border: none;
    }
    @include breakpoint(md) {
      .mfrm-buy-now-pay-later-options__heading {
        .mfrm-buy-now-pay-later-options & {
          width: 135px;
          padding-right: 60px;
        }
      }
      .mfrm-buy-now-pay-later-options__section {
        margin: 0;
      }
      .mfrm-buy-now-pay-later-options__section-left,
      .mfrm-buy-now-pay-later-options__section-right {
        flex: 0 0 100%;
        padding: 0;
      }
    }
  }
  &-options {
    text-align: center;
    @include breakpoint(md) {
      max-width: 100%;
    }
    &__heading {
      @include font-content($font-size-xxl, bold, 1.17);
      margin-bottom: $buy-now-pay-later-options-gap;
      @include breakpoint(md) {
        @include font-content($font-size-xxxl, bold, 1.14);
      }
    }
    &__section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include breakpoint(md) {
        flex-direction: row;
      }
      &-left,
      &-right {
        text-align: left;
        flex: 0 0 50%;
        margin: 0 15px;
        @include breakpoint(md) {
          margin: 0;
        }
        &__heading {
          @include font-content($font-size-l, bold, 1.25);
          margin-bottom: 10px;
        }
        &__monthly-cost {
          @include font-content($font-size-xl, 400, 1.33);
          position: relative;
          color: $buy-now-pay-later-options-cost-color;
          margin-bottom: 10px;
          .sr-only {
            width: auto !important;
            height: 100% !important;
          }
        }
        &__monthly-cost__amount {
          @include font-content($font-size-xxl, 700, 1.17);
        }
        &__offer-details,
        &__additional-content {
          display: list-item;
          @include font-content($font-size-m, 400, 1.43);
          color: $color-midgray;
          margin-left: 15px;
        }
        &__richtext {
          @include font-content($font-size-m, 400, 1.43);
          color: $color-midgray;
          ul {
            padding-left: 15px;
            margin-bottom: 0;
          }
          a {
            @include vfi-visible($color-black);
            margin-top: 0 !important;
            font-size: $font-size-m !important;
            font-weight: 400 !important;
            line-height: 1.43 !important;
            color: $color-midgray !important;
            text-decoration: underline !important;
            &::after{
              display: none !important;
            }
          }
          @include breakpoint(md) {
            margin-right: 30px;
          }
          @include breakpoint(lg) {
            margin-right: 15px;
          }
        }
        &__additional-content__container {
          // Override reset.scss styles
          padding-left: 0 !important;
          margin-bottom: 0 !important;
          list-style: disc !important;
          width: 100%;
          @include breakpoint(md) {
            width: 265px;
          }
        }
        &__cta {
          @include vfi($color-black);
        }
        a:not(:empty) {
          @include font-content($font-size-l, 500, 1.125);
          color: $buy-now-pay-later-options-cost-color;
          @include add-mfrm-icon($icon-chev-right, after);
          @extend %link-muted;
          display: inline-block;
          margin-top: 15px;
          &:hover {
            color: $buy-now-pay-later-options-cost-color;
          }
          &::after {
            font-size: 9px;
            padding-left: 5px;
            font-weight: bold;
            vertical-align: middle;
          }
        }
        &__buy-now-alternate-label {
          @include font-content($font-size-l, 500, 1.17);
        }
      }
      &-left {
        padding-bottom: 30px;
        @include breakpoint(md) {
          padding-bottom: 0;
        }
      }
      &-right {
        border-top: 1px solid $buy-now-pay-later-options-border;
        padding-top: $buy-now-pay-later-options-gap;
        @include breakpoint(md) {
          padding-top: 0;
          padding-left: 45.5px;
          border: 0;
        }
        @include breakpoint(lg) {
          padding-left: 64.5px;
        }
      }
    }
    &__partition {
      display: none;
      @include breakpoint(md) {
        display: flex;
      }
      align-items: center;
      border-right: 1px solid $buy-now-pay-later-options-border;
      font-weight: bold;
      position: relative;
      .or_text {
        background: $color-white;
        left: -11px;
        position: absolute;
      }
    }
  }
}

//==============================================================================
// Buy Now Pay Later Options Modal
//==============================================================================

.mfrm-progressive-leasing-modal {
  .msc-modal {
    &__dialog {
      max-width: 100%;
      @include breakpoint(md) {
        max-width: 617px;
      }
      @include breakpoint(lg) {
        max-width: 790px;
      }
    }
    &__content {
      border-radius: 2px;
    }
    &__body {
      padding: 90px 15px $progressive-leasing-modal-space;
      text-align: center;
      @include breakpoint(md) {
        text-align: left;
      }
      @include breakpoint(lg) {
        padding: 90px $progressive-leasing-modal-space $progressive-leasing-modal-space;
      }
    }
    &__close-button {
      top: $progressive-leasing-modal-space;
      right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $progressive-leasing-modal-space;
      height: $progressive-leasing-modal-space;
      @include breakpoint(lg) {
        right: $progressive-leasing-modal-space;
      }
      border: 0.5px solid $color-modal-border-close-button;
      border-radius: 100%;
      &:before {
        font-size: $font-size-m;
        font-weight: bold;
      }
    }
  }
  &__header {
    @include font-content($progressive-leasing-modal-header-font-size-mobile, 700, 1.19);
    text-align: left;
    @include breakpoint(md) {
      @include font-content($progressive-leasing-modal-header-font-size, 700, 1.12);
    }
  }
  &__offer-description {
    padding: 15px 0 $progressive-leasing-modal-space;
    text-align: left;
    @include font-content($font-size-l, 400, 1.375);
    @include breakpoint(md) {
      padding: $progressive-leasing-modal-space 0;
    }
  }
  &__offer-highlights__content {
    display: flex;
    margin-top: $progressive-leasing-modal-space;
    flex-wrap: wrap;
    justify-content: space-around;
    @include breakpoint(md) {
      justify-content: center;
    }
  }
  &__offer-highlights {
    padding: 0 0 15px;
    .highlight-container {
      flex: 0 0 38%;
      margin-bottom: 15px;
      @include breakpoint(md) {
        width: 18%;
        flex: none;
        margin-bottom: 0;
        margin-right: $progressive-leasing-modal-space;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .highlight-text {
      @include add-mfrm-icon($icon-checkmark);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include font-content($font-size-s, 400, 1.34);
      &:before {
        border: 2px solid $progressive-leasing-modal-check-icon-color;
        color: $progressive-leasing-modal-check-icon-color;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        padding: 5px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $icon-size-s;
        margin-bottom: 10px;
      }
    }
  }
  &__apply-now-cta {
    text-align: center;
    button {
      @extend %btn-muted;
    }
  }
  &__offer-details,
  &__disclaimer {
    @include font-content($font-size-s, 400, 1.67);
    color: $progressive-leasing-modal-disclaimer;
    text-align: left;
  }
  &__offer-highlights__label {
    @include font-content($font-size-xl, 700, 1.22);
    text-align: center;
    @include breakpoint(md) {
      text-align: left;
    }
  }
  &__offer-button {
    @include btn(primary, large);
    margin: 20px 0;
    @include breakpoint(md) {
      margin: $progressive-leasing-modal-space 0;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
