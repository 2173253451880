.mfrm-slider-navigation {
    max-height: 400px;
    overflow-y: hidden;

    .carousel-module_carousel-wrapper-slides__LgUqO .carousel-module_slide__1zbI4 {
        min-height: 90px;
    }

    &__heading {
        margin: 15px auto;
        margin-top: 0;
        font-size: $font-size-xxl;
        line-height: 28px;
        text-align: center;
        max-width: 345px;

        @include breakpoint(md) {
            max-width: initial;
        }

        @include breakpoint(lg) {
            font-size: $font-size-xxxl;
            line-height: 32px;
            max-width: initial;
        }
    }

    &__text-white {
        color: $color-white;
    }
    &__text-red {
        color: $color-punch;
    }
    &__text-black {
        color: $color-black;
    }

    &__disruptor {
        border-radius: 5px;
        position: relative;
        width: 187px;
        height: 190.25px;

        &-image {
            width: 100%;
            height: 140.25px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        @include breakpoint(md) {
            height: 90px;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;

            &-image {
                height: 90px;
                width: 119px;
                max-width: 119px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }

        &-no-image {
            border-radius: 5px;
            border: 3px solid $color-white;
            width: 187px;
            height: 190.25px;
     
            @include breakpoint(md) {
                border: 2px solid $color-white;
                width: initial;
                height: 90px;
                display: flex;
                align-items: center;
            }
        }
    }

    &__cardBkgColor-white {
        background-color: $color-white;
    }

    &__title {
        &-disruptor {
            white-space: nowrap;
            font-weight: bold;
            font-size: 16px;
    
            @include breakpoint(md) {
                font-size: 16px;
            }

            &-no-image {
                white-space: normal;
                font-size: 16px;
                font-weight: bold;
                line-height: 20px;
        
                @include breakpoint(md) {
                    white-space: nowrap;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }

    &__text-container {
        &-disruptor {
            height: 35px;
            padding-top: 15px;
            max-width: 157px;
            margin: auto;
            line-height: 20px;

            @include breakpoint(md) {
                height: initial;
                padding-top: initial;
                max-width: initial;
                margin: initial;
                line-height: 22px;
                padding-left: 20px;
                padding-right: 20px;
            }

            &-no-image {
                height: 44px;
                padding-top: 50.5px;
                max-width: 158px;
                margin: auto;
        
                @include breakpoint(md) {
                    height: initial;
                    padding-top: initial;
                    max-width: initial;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            } 
        }
    }

    &__link {
        margin: 0 5px;
        &:focus {
            padding: 0;
            border: 2px solid $color-bombay !important;
            border-radius: 5px;
        }
        &:focus,
        &:hover {
            color: inherit;
            .mfrm-slider-navigation__title-disruptor,
            .mfrm-slider-navigation__title-disruptor-no-image {
                text-decoration: underline;
            }
            .mfrm-slider-navigation__red-cta {
                background-color: $color-button-primary-background-hover;
            }
        }
    }

    &__red-cta {
        @include btn(primary, default);
        margin-top: 15px;
        padding: 11px 30px;
        font-size: 14px;
        justify-content: center;

        @include breakpoint(lg) {
            margin-top: 10px;
            padding: 16px 62px;
            font-size: 16px;
        }

        @include breakpoint(md) {
            display: none;
        }
    }
}