//==============================================================================
// PRODUCT LANDING PAGE
//==============================================================================

.ms-search-result-container {
  position: relative;
  &__search-results {
    margin: 16px 0 16px;
    @include breakpoint(md) {
      min-height: 40px;
    }
  }

  //--------------------------------------------------------
  // *** TO-DO *** PLP - NAV SECTION
  //--------------------------------------------------------

  &__category-nav-section {
    display: flex;
    justify-content: space-between;
  }

  //--------------------------------------------------------
  // PLP - PRODUCT/REFINERS
  //--------------------------------------------------------

  &__refine-product-section {
    position: relative;
    width: 100%;
    margin: 0 0 30px;
    display: flex;
    flex-wrap: wrap;
  }
  //--------------------------------------------------------
  // PLP - REFINERS
  //--------------------------------------------------------

  $plp-refine-menu-width-tab: 226px;
  $plp-refine-menu-width-desktop: 278px;
  &__refiner-section {
    min-width: 100%;
    max-width: 100%;
    @include breakpoint(md) {
      min-width: $plp-refine-menu-width-tab;
      max-width: $plp-refine-menu-width-tab;
      margin-right: 15px;
    }
    @include breakpoint(xl) {
      min-width: $plp-refine-menu-width-desktop;
      max-width: $plp-refine-menu-width-desktop;
      margin-right: 28px;
    }
  }

  //--------------------------------------------------------
  // PLP - PRODUCT GRID
  //--------------------------------------------------------

  &__product-section {
    flex: 1;
    width: 100%;
    @include breakpoint(md) {
      width: auto;
    }
  }
  &__Products {
    .list-unstyled {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      @include breakpoint(lg) {
        grid-column-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      }
    }
    .ms-product-search-result__item {
      display: flex;
      height: 100%;
      min-height: 346px;
      @include breakpoint(md) {
        min-height: 316px
      }
      @include breakpoint(lg) {
        min-height: 575px;
      }
    }
    .msc-product {
      width: 100%;
    }
    .skeleton-financing-container {
      padding: 0;
      margin: 0 20px 0;
      @include breakpoint(md) {
        padding: 0 27px 0;
      }
      @include breakpoint(lg) {
        padding: 0 20px 0;
      }
    }
    .skeleton-dimensions {
        border-radius: 5px;
        height: 330px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 0 16px 0;
        @include breakpoint(md) {
          height: 300px;
          padding: 0;
        }
        @include breakpoint(lg) {
          border: 1px solid #e1e1e1;
          border-radius: 5px;
          height: 559px;
          padding: 0;
      }

      &__product {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 0 15px 0;
        @include breakpoint(lg){
          display: block;
          padding: 0;
        }
      }

      &__image {
        border-radius: 5px;
        margin-bottom: 0;
        height: 86px;
        width: 115px;
        @include breakpoint(md) {
          height: 108px;
          width: 145px;
        }
        @include breakpoint(lg) {
            border-radius: 5px 5px 0 0;
            height: 208px;
            width: 100%;
        }
      }

      &__eyebrow {
        height: 26px;
        margin-bottom: 5px;
        width: 75%;
        @include breakpoint(md) {
          width: 65%;
        }
        @include breakpoint(lg) {
          width: 75%;
        }
      }

      &__ratings {
        height: 24px;
        margin-bottom: 13px;
        width: 75%;
        @include breakpoint(md) {
          width: 65%;
        }
        @include breakpoint(lg) {
          width: 75%;
        }
      }

      &__title {
        height: 50px;
        margin-bottom: 0;
        @include breakpoint(md) {
          height: 22px;
        }
        @include breakpoint(lg) {
          height: 50px;
        }
      }

      &__delivery {
        height: 22px;
        margin-top: 16px;
        margin-bottom: 25px;
        width: 75%;
        @include breakpoint(md) {
          width: 65%;
        }
        @include breakpoint(lg) {
          width: 75%;
        }
      }

        &__financing {
          height: 130px;
          margin-bottom: 0;
          margin-left: 40px;
          width: 80%;
          @include breakpoint(md) {
            margin-left: 30px;
            height: 100px;
          }
          @include breakpoint(lg) {
            width: 80%;
            margin-left: 25px;
            height: 150px;
          }
        }
        &__banner {
          height: 485px;
          margin-bottom: 0;
          width: 100%;
          @include breakpoint(md) {
            height: 300px;
          }
          @include breakpoint(lg) {
            height: 261px;
          }
        }
      }
    }

  //--------------------------------------------------------
  // PLP - SORT
  //--------------------------------------------------------

  &__Sort-by-category {
    @include add-mfrm-icon($icon-chev-down, after);
    position: relative;
    max-height: 40px;
    margin: 0 15px 30px;
    display: none;
    flex: 1 0 100%;
    align-items: center;
    padding: 12px 15px;
    background-color: $color-bg-primary;
    border: 1px solid $color-input-border;
    border-radius: 5px;
    @include breakpoint(md) {
      display: flex;
      width: 250px;
      flex: 0 0 250px;
      margin: 0;
    }
    &::after {
      font-size: ($icon-size-xs - 4);
      position: absolute;
      right: 18px;
    }
    label {
      @include font-content();
      margin-bottom: 0;
      margin-right: 3px;
      color: $color-text-primary;
    }
    select {
      @include font-content($font-weight: 700);
      @extend %reset-input-style;
      flex: 1;
      color: $color-text-primary;
      background-color: $color-bg-primary;
      border: 0;
      @include breakpoint(md) {
        @include font-content($font-weight: 500);
      }
    }
  }
  // * Sort Button * //
  .msc-button {
    @include add-mfrm-icon($icon-chev-down, after);
    @include font-content($font-weight: 500);
    @extend %btn-muted;
    position: relative;
    width: 100%;
    max-height: 40px;
    margin-bottom: 16px;
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-text-primary;
    background-color: $color-bg-primary;
    border: 1px solid $color-input-border;
    border-radius: 5px;
    text-align: left;
    text-transform: capitalize;
    &::after {
      font-size: ($icon-size-xs - 4);
    }
  }
}

//--------------------------------------------------------
// *** TO-DO *** PLP - FEATURE SECTION
//--------------------------------------------------------

// .ms-feature-search-result {
//   .ms-feature-search-result__item {
//     display: flex;
//     height: auto;
//     padding-bottom: 10px;

//     .msc-feature-search-result__item__image {
//       flex-grow: 1;
//       height: auto;
//       width: 315px;
//     }
//     .msc-feature-search-result__item__detail {
//       flex-grow: 1;
//       height: auto;
//       position: relative;
//       padding-left: 8%;
//       text-align: left;
//       width: 100%;
//     }
//     .msc-feature-search-result__item__detail h1 {
//       margin: 0;
//       padding: 0;
//       position: relative;
//       top: 30%;
//       margin-bottom: 8px;
//     }
//     .msc-feature-search-result__item__detail p {
//       margin: 0;
//       margin-bottom: 8px;
//       padding: 0;
//       position: relative;
//       top: 30%;
//     }
//   }
// }
// @media (max-width: $breakpoint-lg) {
//   .ms-feature-search-result {
//     .ms-feature-search-result__item {
//       .msc-feature-search-result__item__detail {
//         p,
//         h1 {
//           top: 20%;
//         }
//       }
//     }
//   }
// }

// @media (max-width: $breakpoint-md) {
//   .ms-feature-search-result {
//     .ms-feature-search-result__item {
//       display: block;

//       .msc-feature-search-result__item__image,
//       .msc-feature-search-result__item__detail {
//         width: 100%;
//       }

//       .msc-feature-search-result__item__image {
//         margin-bottom: 10px;
//       }

//       .msc-feature-search-result__item__detail {
//         padding-left: unset;
//       }
//     }
//   }
// }
