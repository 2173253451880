.mfrm-cb-img-border {
    .ms-content-block__image {
        border: 1px solid $color-mercury;
        border-radius: 5px;
    }
}

.mfrm-single-cta {
    .ms-content-block {
        &__cta {
            text-align: center;
        }

        &__cta .msc-cta__primary {
            @include btn(primary, large);
        }
    }
}

// for droga5 homepage update
.d5-mfrm-single-cta {
    .ms-content-block {
        &__cta {
            text-align: center;

            div {
                font-weight: 700;
            }
        }

        &__cta .msc-cta__primary {
            @include btn(quinary, default);
        }
    }
}

.mfrm-dynamic-image {
    height: auto;
}

/* Generic styling class to force the font sizes to match the design library. Eventually this should go away in favor of a CSS design overhaul. */
.fs-nominal {
    >.ms-content-block__details {
        >.ms-content-block__title {
            font-size: 36px;
            line-height: 44px;
            font-weight: 700;

            @include breakpoint(lg) {
                font-size: 44px;
                line-height: 48px;
            }
        }

        >.ms-content-block__text {
            font-size: 16px;
            line-height: 22px;

            @include breakpoint(lg) {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}