//all set for min-width so it will be this breakpoint and up
$breakpoint-sm: 320px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;
$color-tealish-blue: #1B244D;
$color-east-bay: #495071;
$color-punch: #D63426;
$color-charcoal: #2D2926;
$color-grayscale: #E1E1E1;
$icon-hover: #F8F8F8;
$color-white: #FFFFFF;

@mixin icon-states {
  &:hover {
    color: $color-charcoal;
    text-decoration: none;
    background-color: $icon-hover;
    cursor: pointer;
  }

  &:focus-visible {
    background: $icon-hover;
    box-shadow: 0px 0px 0px 3px $color-white, 0px 0px 0px 5px $color-charcoal;
    border-radius: 100px;
  }

  &:active {
    background: $color-grayscale;
    border-radius: 100px;
  }
}

@mixin link-states {
  &:hover {
    color: $color-charcoal;
    text-decoration: underline !important;
    cursor: pointer;
  }

  &:active {
    color: $color-charcoal;
    text-decoration: underline;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 3px $color-white, 0px 0px 0px 5px $color-charcoal;
    color: inherit;
  }
}

//over ride MFCL drawer settings
.drawer-module_drawer-body__2cXWf {
  padding: 0 !important;
}

.inactive {
  display: none !important;
}


//navbar drawer styles
.navbar-drawer {
  &_width {
    width: 320px !important;

    @include breakpoint(lg) {
      width: 482px !important;
    }
  }

  //drawer header styles
  &_header {

    &_hide-mfcl-header {
      display: none !important;
    }

    &_container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 64px;
      padding-left: 14px;
      position: fixed;
      width: 100%;
      z-index: 1;

      @include breakpoint(lg) {
        padding-left: 0;
        height: 80px;
        width: 95%;

      }
    }

    &_close {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      width: 40px;
      height: 40px;

      &_container {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include icon-states();
      }

      @include breakpoint(lg) {
        text-align: center;
        width: 120px;
        margin-right: 0;
      }

      &_icon {
        width: 24px;
        height: 24px;
      }
    }

    &_logo {
      height: 64px;

      & .mfrm-logo {
        @include link-states();
      }

      @include breakpoint(lg) {
        height: 80px;
        padding-top: 9px;
      }
    }

  }

  //drawer body styles
  &_body {
    overflow-x: hidden;
    padding: 0 !important;

    &_login-section {
      display: flex;
      flex-direction: row;
      justify-items: space-evenly;
      align-items: space-between;
      width: 100%;
      padding: 8px 0px;
      height: 56px;

      @include breakpoint(lg) {
        height: 64px;
        padding-top: 16px;
        padding-bottom: 8px;
      }

            &_text {
              align-self: center;
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $color-charcoal;

              & [data-testid="MyAccount"] {
                  &::after {
                    content: '\007C';
                    color: black;
                    margin: 0 20px;
                  }
                }
            }

      &_icon {
        align-self: center;
        width: 16px;
        height: 16px;
        margin-right: 14px;

        &_wrapper {
          width: 24px;
          height: 24px;
          display: flex;
          align-self: center;
          margin-right: 10px;
        }
      }
    }

    &_link {
      color: $color-charcoal;
      margin-right: 10px;

      &:hover {
        color: $color-charcoal;
      }

      &_container {
        margin-top: 64px;

        @include breakpoint(lg) {
          margin-left: 120px;
          margin-top: 80px;
        }
      }

      &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        margin-left: 22px;
        margin-right: 22px;
        max-width: 320px;

        @include breakpoint(lg) {
          margin-left: 0;
          margin-right: 57px;
        }
      }

      &_main {
        &_container {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          width: 100%;
          padding: 8px 0px 0px;
        }

        &_item {
          display: flex;
          align-items: center;
          height: 52px;
          padding-top: 8px;
          padding-bottom: 8px;
          border-bottom: solid 1px $color-grayscale;
          cursor: pointer;
          width: 100%;
          color: $color-charcoal;
          white-space: nowrap;

          a {
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            margin-right: 18px;
            @include link-states();
          }
        }
      }

      &_secondary {
        &_container {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          width: 100%;
          padding-top: 24px;
          margin-bottom: 64px;
        }
      }

      &_helper {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding-top: 6px;
        padding-bottom: 6px;
        cursor: pointer;
        color: $color-charcoal;
        @include link-states();
      }

      &_prominent {
        color: $color-punch;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
        cursor: pointer;
        width: 100%;
        @include link-states();

        &:hover,
        &:focus {
          color: $color-punch !important;
        }
      }
    }
  }
}