$franchise-store-bg: #f6f6f6;
$color-alabaster: #f8f8f8;
$color-green: #006601;
$color-mercury: #e1e1e1;
$franchise-pill-color: #505050;

@mixin hideScrollBar() {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    /* Edge */
    scrollbar-width: none;
    /* Firefox */
}

@mixin focus () {
    &:focus-visible {
        box-shadow: 0px 0px 0px 2px $color-white, 0px 0px 0px 4px $color-charcoal;
    }
}

.store-selector-drawer {
    width: 100% !important;
    position: fixed;
    margin-top: 0;
    max-width: unset !important;
    right: 0 !important;
    height: 100%;
    @include breakpoint(md) {
        max-width: 430px !important;
        width: 430px !important;
    }

    &__backdrop {
        background-color: transparent !important;

        &:after {
            margin-top: 0;
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.8);
        }
    }

    &__body {
        position: relative;
        padding: 0;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            @include hideScrollBar();
        }
    }

    .header {
        position: relative;

        button {
            position: absolute;
            right: 11px;
            top: 11px;
            z-index: 1;
            border: none;
            background-color: $white;
            width: auto;
            height: 26px;
            cursor: pointer;
            @include focus();
        }
    }

    &__title {
        padding: 20px 20px 16px;
        margin: 0;
        font-size: 16px;
        line-height: 25px;

        @include breakpoint(md) {
            padding: 30px 30px 16px;
            font-size: 18px;
            line-height: 25px;

        }
    }

    &__header {
        display: none;
    }

    &__footer {
        position: sticky;
        padding: 30px;
        bottom: 0;
        width: 100%;
        border-top: 2px solid $color-mercury;
        text-align: center;
        background-color: $white;

        .see-all-btn {
            &:focus {
                color: white;
            }

            @include focus();
        }
    }
}

.search-location-wrapper {
    padding: 0 15px 30px;

    @include breakpoint(md) {
        color: $color-punch;
        padding: 0 30px 30px;
    }
}

.search-location-form {
    display: flex;
    margin-top: 20px;
}

.search-location-button {
    width: 117px;
    margin-top: 0;
    margin-left: 10px;
    font-weight: 700;
    @include focus();

}

.zipcode-input {
    width: 100%;

    &:focus {
        box-shadow: 0px 0px 0px 1px $color-white, 0px 0px 0px 3px $color-charcoal;
    }
}

.zipcode-input-wrapper {
    width: 100%;
    max-width: 243px;
    border-radius: 25px;

    & label {
        line-height: 1;
    }

    @include breakpoint(lg) {
        max-width: unset;
    }
}

.usecurrent-location-wrapper {
    cursor: pointer;
    text-decoration: underline;
    display: flex;
    align-items: center;
    width: 100%;
    @include focus();

    & .use-current-location-link {
        color: $color-punch;
    }
    svg {
        margin-right: 8px;
    }
    @include breakpoint(lg){
        width: 55%;
    }
}

// Store Line Item styling
.store-line-item-wrapper {
    padding: 25px 15px;
    border-top: 1px solid $color-mercury;
    position: relative;

    @include breakpoint(md) {
        padding: 25px 30px;
    }

    &.franchise {
        background: $franchise-store-bg;

        & .franchise-pill {
            color: white;
            background-color: $franchise-pill-color;
            padding: 5px 10px;
            border-radius: 16px;
            margin-top: -2px;
            font-size: 12px;
            float: right;
        }
    }

    .store-name {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        margin-right: 5px;
    }

    .store-distance {
        margin-bottom: 5px 0 10px;

        .store-hours-time {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.5;
            color: $color-green;
        }

        .store-hours-distance {
            font-size: 14px;
            line-height: 1.21;
            margin: 5px 0 10px;

            &::before {
                content: '\007C';
                margin: 0 3px;
            }
        }
    }


    .store-address-line {
        font-size: 14px;
        line-height: 1.43;
    }

    .store-details-link {
        text-decoration: underline;
        margin-left: auto;
        color: $color-punch;
        @include focus();
        border-radius: 2px;

    }

    .store-line-item-footer {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .my-store {
            border: 0;
            cursor: pointer;
            font-size: 12px;
            padding: 0 6px;
            height: 20px;
            font-weight: 700;
            min-width: 84px;
            border-radius: 3px;
            color: $white;
            background: $color-green;
        }

        .make-my-store {
            font-weight: 700;
            @include focus();

        }
    }

}



//No store styling
.store-list-wrapper {
    flex-grow: 1;

    & .loader-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}

.no-stores-wrapper {
    padding: 30px;
    border-top: 1px solid $color-alabaster;
}

.no-stores-details {
    padding: 50px 20px;
    margin: 0;
    background-color: $color-alabaster;
    text-align: center;
    font-size: 16px;

    a {
        text-decoration: underline;
        color: black;
        display: block;
        @include focus();
    }
}

.no-stores-link {
    margin: 0;
    text-decoration: underline;

    &:hover {
        color: black;
    }

    @include focus();
}