.selectable-card {
    display: flex;
    color: #2d2926;
    border: 1px solid transparent;
    border-radius: 5px;
    align-items: center;
    padding: 26px;
    .product-price {
      display: block;
    }
    &__image{
      margin-right: 10px;
      flex-shrink: 0;
      width: 107px;
    }
    &__content{
      text-align: left;
    }
    &__eyebrow {
      color: #d63426;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
    }
    &__product-name {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      font-family: "Rubik";
    }
    &__rating{
        line-height: 13px;
        margin: 0 0 6px 0;
    }
    &__inactive {
      opacity: .25;
    }
    img{
      height: 87.5px;
    }
}