//==============================================================================
// Financing Price Per Month
//==============================================================================
$buybox-financial-options-price-color: $color-text-secondary;
$buybox-financial-options-border: $color-input-border;

.ms-buybox {
  .msc-product {
    &__financing-per-month {
      display: flex;
      justify-content: space-between;

      &:not(.redesign) {
        align-items: center;
      }

      width: 100%;

      &.redesign {
        flex-direction: column;
      }

      @include breakpoint(lg) {
        width: 423px;

        &:not(.redesign) {
          margin: 30px auto;
        }

        &.redesign {
          width: 600px;
          max-width: 100%;
        }
      }

      &:not(.redesign) {

        .product-price-section,
        .financing-price-section {
          flex: 0 0 50%;
          text-align: center;
        }
      }

      .product-price-section {
        margin-bottom: 4px;
      }

      .product-price-label,
      .financing-price-label {
        @include font-content($font-size-s, 700, 1.25);
        margin-bottom: 3px;

        @include breakpoint(md) {
          margin: 0;
        }
      }

      .product-price-loader,
      .price-spinner {
        height: 1.83rem;
        width: 1.83rem;
      }

      .product-price,
      .financing-price {
        @include font-content($font-size-xxl, 500, 28px);

        @include breakpoint(md) {
          @include font-content(1.43rem, 500, 1.11);
        }

        color: $buybox-financial-options-price-color;
      }

      .product-price-black {
        @include font-content($font-size-xxl, 500, 28px);
        color: $color-charcoal;
        font-family: 'Rubik';
      }

      .product-cents {
        position: relative;
        line-height: 0;
        vertical-align: baseline;
        font-size: 50%;
        top: -0.8em;
      }

      .product-original {
        color: $color-text-primary;
        margin: 0px 5px;
        @include font-content($font-size-l, 400, 24px);
      }

      .product-original-price {
        text-decoration: line-through;
        line-height: 24px;
      }

      .product-offer-discount {
        margin-left: 5px;
        font-weight: 700;
        line-height: 20px;
      }

      .product-price-info,
      .financing-price-info {
        font-family: "Rubik", sans-serif;
        color: $color-charcoal;
        @include font-content($font-size-s, 400, 1.33);
      }

      &.redesign {

        .product-price-info,
        .financing-price-info {
          color: $color-charcoal;
          @include font-content($font-size-l, 400, 24px);
        }
      }

      &:not(.redesign) {
        .financing-price-info {
          margin: 5px 28px;
        }
      }

      .product-price-info {
        margin: 5px 15px;

        @include breakpoint(md) {
          margin: 5px 0;
        }
      }

      .financing-price-info.financing-price-text {
        & .cashback-message {
          display: inline;
        }
      }

      .financing-price-right {
        margin-top: 18px;

        @include breakpoint(md) {
          margin: 15px 10px 0 10px;
        }
      }

      .financing-price-modal-toggle {
        @include font-content($font-size-s, 400, 1.33);
        text-decoration: underline;
      }

      &.redesign {
        .financing-price-modal-toggle {
          @include font-content($font-size-l, 500, 20px);
          text-decoration: underline;
          margin-left: 4px;
          @include link-with-right-arrow();

          @include breakpoint(xs) {
            display: inline-block;
            margin-left: 1em;
          }

          @include breakpoint(xl) {
            display: inline;
            margin: 0 10px;
          }
        }
      }

      .partition {
        display: flex;
        align-items: center;
        border-right: 1px solid $buybox-financial-options-border;
        position: relative;
        height: 60px;

        .or_text {
          background: $color-white;
          left: -8px;
          position: absolute;
          @include font-content($font-size-s, 700, 1.33);
        }
      }

      &.redesign .financing-price-section.has-campaign {
        .financing-price {
          color: black;
        }
      }

      &__skeleton {
        margin-bottom: -2px;
        margin-top: -1px;
        height: 27px;
        width: 45%;
        border-radius: 4px;

        &-non-mattresses {
          display: none;
        }
      }
    }
  }
}