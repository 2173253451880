$product-recommendation-spacing: 30px;
$product-recommendation-content-spacing: 10px;
$product-recommendation-max-width: $breakpoint-xl;
$product-recommendation-flipper-gap: 30px;
$product-recommendation-item-width-sm: 218px;
$product-recommendation-item-width-lg: 276px;
$product-recommendation-item-height-sm: 310px;
$product-recommendation-item-height-lg: 352px;
$product-recommendation-item-spacing-sm: 15px;
$product-recommendation-item-spacing-lg: 30px;
$product-recommendation-img-height-sm: 175px;
$product-recommendation-img-height-lg: 220px;

.product-recommendation-carousel.ms-product-collection {
    @include full-width;
    margin-top: $product-recommendation-spacing;
    margin-bottom: ($product-recommendation-spacing - 15);
    @include breakpoint(lg) {
        margin: $product-recommendation-spacing 0 0;
    }

    .ms-product-collection__heading {
        margin-top: 0;
        margin-bottom: 25px;
        text-align: center;
        color: $color-text-primary;
        @include font-content($font-size-xl, bold, 1.56);
        @include breakpoint(lg) {
            @include font-content($font-size-xxl, bold, 1.17);
            margin-bottom: 30px;
        }
    }

    .msc-ss-carousel {
        max-width: $product-recommendation-max-width;
        padding: 0;

        &-strip {
            max-width: $product-recommendation-max-width;
            padding: 0 2px;
        }

        &-item {
            max-width: $product-recommendation-item-width-sm;
            height: $product-recommendation-item-height-sm;
            max-height: $product-recommendation-item-height-sm;
            padding: 0;
            margin-right: $product-recommendation-item-spacing-sm;
            margin-bottom: $product-recommendation-item-spacing-sm;
            margin-top: 5px;
            background-color: $color-bg-primary;
            border: 0;
            border-radius: 5px;
            box-shadow: 0px -1px 3px transparentize($color: $color-black, $amount: 0.85),
                0px 1px 3px transparentize($color: $color-black, $amount: 0.85);
            &:first-child {
                margin-left: $grid-gutter-width-mobile;
                @include breakpoint(md) {
                    margin-left: $grid-gutter-width-tab;
                }
                @include breakpoint(lg) {
                    margin-left: 0;
                }
            }
            &:last-child {
                margin-right: $grid-gutter-width-mobile;
                @include breakpoint(md) {
                    margin-right: $grid-gutter-width-tab;
                }
                @include breakpoint(lg) {
                    margin-right: 0;
                }
            }

            @include breakpoint(lg) {
                max-width: $product-recommendation-item-width-lg;
                height: $product-recommendation-item-height-lg;
                max-height: $product-recommendation-item-height-lg;
                margin-right: $product-recommendation-item-spacing-lg;
                margin-bottom: $product-recommendation-item-spacing-lg;
            }
        }
        &:hover .msc-flipper {
            display: block;
        }
        &__flipper {
            display: none;
            transition: background-color 0.3s linear, margin-left 0.3s linear, margin-right 0.3s linear;
            @include breakpoint(lg) {
                margin-left: (-$product-recommendation-flipper-gap);
            }
            @include breakpoint(lg) {
                &--next {
                    margin-right: (-$product-recommendation-flipper-gap);
                }
            }

            .msi-chevron-left::before {
                margin-left: -3px;
            }
            .msi-chevron-right::before {
                margin-right: -3px;
            }
        }
        &__flipper i {
            width: 60px;
            height: 60px;
            font-size: ($icon-size-m - 1);
            background-color: transparentize($color: $color-bg-indicator-active, $amount: 0.5);
            &:hover,
            &:focus {
                background-color: transparentize($color: $color-bg-indicator-active, $amount: 0.2);
            }
        }
    }

    .msc-product {
        @include no-decoration;
        width: 100%;
        height: 100%;
        display: block;
        border: 0;
        padding: 0;
        margin: 0;
        color: $color-text-primary;
        white-space: normal;
        .mfrm-product-badge {
            margin-left: 5px;
            margin-top: 5px;
        }
        &.discounted {
            color: $color-text-secondary;
            .msc-price {
                color: $color-text-secondary;
                &__strikethrough {
                    display: none;
                }
            }
        }
        &:focus {
            .msc-product__details,
            .msc-product__details * {
                /* override mixin property */
                text-decoration: underline !important;
            }
        }

        a {
            overflow: hidden;
        }

        &__image {
            height: $product-recommendation-img-height-sm;
            width: 100%;
            background-color: $color-bg-primary;
            border-radius: 5px 5px 0 0;
            @include breakpoint(lg) {
                height: $product-recommendation-img-height-lg;
            }
            img {
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
            }
        }

        > img {
            height: 100%;
            width: 100%;
            border-radius: 5px 5px 0 0;
            object-fit: cover;
        }

        .msc-empty_image {
            @include image-placeholder(100%, $product-recommendation-img-height-sm);
            background-size: 100% 100%;
            @include breakpoint(lg) {
                @include image-placeholder(100%, $product-recommendation-img-height-lg);
            }
        }

        &__price,
        .msc-price {
            @include font-content($font-size-l, 700, 1.125);
            color: $color-text-primary;
            padding: 0;
            display: inline-block;
            &--discounted {
                color: $color-text-secondary;
            }
        }
        .msc-price {
            direction: rtl;
            &__strikethrough {
                display: none;
                margin-left: 5px;
                line-height: 1.125;
            }
        }

        &__title {
            @include no-decoration;
            @include text-ellipsis;
            @include font-content($font-size-m, 500, 1.29);
            margin: $product-recommendation-content-spacing 0;
            color: $color-text-primary;
        }
        &__details {
            @include font-content($font-size-l, 700, 1.125);
            padding: 15px;
            margin: 0;
            background-color: $color-bg-primary;
            color: $color-text-primary;
        }
    }

    .msc-rating {
        &__star,
        &__count {
            border-bottom: none;
        }
    }
}
