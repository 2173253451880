;
/**
 *
 * Project dependencies
 * =================================
 * - Mixins
 * - Core variables
 * - Global styles
 * - Typography styles
 * - Components Styles
 * - Module CSS Styles
 *
 */

@import "00-abstract/bootstrap-variables";

/** Begin curated Bootstrap library */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/utilities";

@import "00-abstract/index";
@import "01-base/index";
@import "02-generic/index";
@import "03-components/index";
@import "04-modules/index";
@import "05-layout/index";
@import "06-mfcl/index";
@import "07-d5-hp/index";

/* MFRM-Compontents */
@import '../../../mfrm-components/styling/index.scss';