$mfrm-accordion-selector: accordionItem-module;
$mfrm-accordion-spacing: 15px;

[class*="#{$mfrm-accordion-selector}_innerContent"] {
    margin-bottom: 30px;
}

[class*="#{$mfrm-accordion-selector}_accordionItem"] {
    border-bottom: 1px solid $color-border-accordion;
    &:first-child {
        border-top: 1px solid $color-border-accordion;
    }
    /* caret toggle state */
    &[class*="#{$mfrm-accordion-selector}_open"] {
        [class*="#{$mfrm-accordion-selector}_openIcon"] {
            transform: rotate(180deg);
        }
    }
}

[class*="#{$mfrm-accordion-selector}_line"] {
    position: relative;
    padding: 20px $mfrm-accordion-spacing;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

[class*="#{$mfrm-accordion-selector}_center"] {
    width: 100%;
}

[class*="#{$mfrm-accordion-selector}_title"] {
    @include font-content($font-size-m, 500, 1.43);
    margin-right: $mfrm-accordion-spacing;
    color: $color-text-accordion;
    text-transform: capitalize;
}

/* Toggle State */
[class*="#{$mfrm-accordion-selector}_open"] {
    [class*="#{$mfrm-accordion-selector}_line"] {
        box-shadow: 0px 1px 0px transparentize($color: $color-border-accordion, $amount: 0.95);
    }
}

/* Icon Override */
[class*="#{$mfrm-accordion-selector}_openIcon"] {
    @include add-mfrm-icon($icon-caret-down);
    width: auto;
    height: auto;
    margin-left: auto;
    font-size: ($icon-size-xs - 4);
    line-height: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: none;
    transition: transform 0.3s ease-in-out;
    opacity: 1;
}
