//==============================================================================
// UTILITY HEADER
//==============================================================================

$primary-logo-width: 120px;
$secondary-logo-width: 67.82px;
$element-spacing: 15px;
$preferred-store-height-sm: 45px;
$checkout-mobile-font-size: 20px;

.ms-header {
    &__container-logo {
        display: flex;
        align-items: center;
        a {
            @include vfi($color-black);
            display: block;
        }
        img {
            height: auto;
        }
        @include breakpoint(sm) {
            float: left;
            justify-content: left;
        }
    }

    &__logo {
        @include box-arrow(top, 30%, 8px, $color-white);
        position: relative;
        width: 50%;
        display: flex;
        justify-content: center;
        padding: $element-spacing;
        &::after {
            z-index: 1;
        }
        @include breakpoint(sm) {
            padding-left: 0;
            width: auto;
        }

        img {
            width: $primary-logo-width;
            height: 17px;
        }
    }

    &__logo__secondary {
        width: 50%;
        display: flex;
        justify-content: center;
        padding: $element-spacing;
        border-left: 1px solid $color-border-header-primary;

        img {
            width: $secondary-logo-width;
            height: 19.99px;
        }
        @include breakpoint(sm) {
            width: auto;
        }
    }

    &__topbar {
        @include clearfix();
        position: relative;
        background-color: $color-white;
        @include breakpoint(sm) {
            padding: 0 $grid-gutter-width;
        }

        &__link {
            @include vfi($color-black);
            margin-left: $element-spacing;
            font-family: $font-family-primary;
            font-weight: 400;
            font-size: $font-size-s + 0.07;
            line-height: 1.23;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
        &__link-mobile {
            margin-left: auto;
        }
    }

    &__topbar__links {
        display: none;
        float: right;
        padding-top: $element-spacing;
        padding-bottom: $element-spacing;
        @include breakpoint(md) {
            display: flex;
        }
        &--mobile {
            display: block;
            @include breakpoint(md) {
                display: none;
            }
        }
        &--tab {
            display: none;
            @include breakpoint(md) {
                display: block;
            }
            @include breakpoint(lg) {
                display: none;
            }
            .ms-header__topbar__link:first-child {
                text-decoration: underline;
            }
        }
        &--desktop {
            display: none;
            @include breakpoint(lg) {
                display: block;
            }
        }
    }
}

//==============================================================================
// PRIMARY HEADER
//==============================================================================

$primary-header-bg-color: $color-bg-quaternary;

.ms-header {
    &__primary__container {
        position: relative;
    }
    &__primary {
        @include clearfix();
        position: relative;
        height: $header-primary-height;
        background-color: $primary-header-bg-color;
        &__wrapper {
            display: flex;
        }
    }
    &__primary__nav {
        display: none;
        @include breakpoint(lg) {
            display: inline-block;
            margin: auto 0;
            padding: 0 30px;
        }
    }
    &__primary__links {
        padding: 0 20px 0 15px;
        margin: auto 0;
        @include breakpoint(lg) {
            display: flex;
        }
        @include breakpoint(md) {
            padding: 0 30px;
        }
        &.align-signin-icon {
            margin-left: auto;
            padding-right: 5px;
        }
    }

    //=============  MOBILE NAV ICON  ==============//
    &__nav-icon {
        @extend %btn-muted;
        @include add-mfrm-icon($icon-menu);
        display: inline-block;
        min-width: 60px;
        width: 60px;
        text-align: center !important;
        padding: 25px 15px;
        color: $color-white;
        font-size: 20px;
        line-height: 1;
        cursor: pointer;
        &[aria-expanded="true"] {
            @include add-mfrm-icon($icon-close);
        }
        @include breakpoint(md) {
            min-width: 90px;
            width: 90px;
            padding: 24px 30px;
        }
        @include breakpoint(lg) {
            display: none;
        }
    }

    //=============  CART ICON  ==============//
    .ms-cart-icon {
        height: calc(62px / 2);
        display: inline-block;
        .msc-cart-icon {
            @include icon-cart();
            @include vfi();
            position: relative;
            top: 4px;
            width: 35px;
            height: 100%;
            display: block;
            color: $color-text-primary;
            &::before,
            &::after {
                font-size: $icon-size-l - 4;
            }
            &[data-no-click="true"] {
                cursor: default;
                pointer-events: none;
            }
        }
    }
    .msc-cart-icon__count {
        position: absolute;
        top: -5px;
        right: -5px;
        height: 15px;
        width: 15px;
        font-size: $font-size-xs;
        font-weight: 700;
        line-height: 1.6;
        background-color: $color-white;
        border-radius: 100%;
        text-align: center;
        z-index: 10;
    }

    //=============  SIGNIN BUTTON  ==============//
    &__account-info {
        display: none;
        height: calc(62px / 2);
        margin-right: 20px;
        @include breakpoint(md) {
            display: block;
        }
    }
    &__signin-button {
        @include icon-user();
        @include vfi();
        position: relative;
        display: none;
        width: 33px;
        height: 100%;
        @include breakpoint(md) {
            display: inline-block;
            height: 33px;
            margin-right: 25px;
            vertical-align: top;
            &.msc-btn {
                width: auto;
                &:after {
                    display: none;
                }
            }
            &-text {
                @include font-content($font-size-m, 400, 1.14);
                color: $color-text-secondary;
                display: block;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &--closed {
            display: none;
        }
        &--opened {
            @include box-arrow(top, 23%, 11px, $color-white);
            &::after {
                transform: rotate(180deg);
            }
        }
        .modal-store-locator-toggle & {
            pointer-events: none;
            touch-action: none;
            cursor: default;
        }
    }
    &__signup-button-text {
        text-transform: capitalize;
    }

    &__mobile-hamburger {
        padding-top: 15px;
    }
}

//==============================================================================
// SECONDARY HEADER
//==============================================================================

.ms-header {
    &__secondary {
        position: relative;
        height: $header-secondary-height;
        display: flex;
        align-items: center;
        background-color: $color-bg-primary;
        box-shadow: 0px 0.5px 0 $color-border-header-primary;
    }
    &__secondary__nav {
        display: inline-block;
        padding: 9px 15px;
        margin: auto;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
        @include breakpoint(md) {
            margin: auto auto auto 0;
            box-shadow: -0.5px 0 0 $color-border-header-primary;
        }
        @include breakpoint(lg) {
            box-shadow: none;
            padding: 0 10px 9px;
            white-space: normal;
            overflow-x: visible;
        }
        @include breakpoint(xl) {
            margin: auto;
        }
    }
    &__secondary__nav__link {
        @extend %link-muted;
        @include no-hover-state;
        display: inline-block;
        padding: 7px 10px;
        margin-right: 5px;
        font-family: $font-family-primary;
        font-weight: 400;
        font-size: $font-size-s + 0.07;
        line-height: 1.23;
        background-color: $color-bg-tertiary;
        color: $color-text-primary;
        border-radius: 100px;
        &:last-child {
            margin-right: 0;
        }
        @include breakpoint(lg) {
            margin-top: 9px;
        }
    }
    &__preferred-store-container {
        @include font-content($font-size-s + 0.07, 400, 1.23);
        position: relative;
        height: 45px;
        padding: 14px 15px;
        background-color: $color-bg-store-locator;
        display: flex;
        @include breakpoint(md) {
            padding: 17px 30px;
            margin-right: 30px;
            background-color: $color-white;
            white-space: nowrap;
        }
        @include breakpoint(lg) {
            margin-right: 0;
        }
        @include breakpoint(xl) {
            position: absolute;
            top: 0;
        }

        &--desktop {
            display: none;
            @include breakpoint(md) {
                min-width: 198px;
                display: flex;
            }
            @include breakpoint(md) {
                height: 50px;
            }
            @include breakpoint(md) {
                height: 50px;
            }
        }

        &--mobile {
            display: flex;
            @include breakpoint(md) {
                display: none;
            }
        }
    }
    &__preferred-store-btn {
        @include add-mfrm-icon($icon-chev-down, after);
        @include vfi($color-black);
        @extend %link-muted;
        cursor: pointer;
        border: 0;
        padding: 0;
        background: none;
        position: relative;
        color: $color-text-primary;
        @include breakpoint(md) {
            padding-right: 70px;
        }
        @include breakpoint(lg) {
            padding: 0;
        }
        &::after {
            margin-left: 5px;
            display: inline-block;
            font-size: $icon-size-xs - 6;
        }
        &--open:after {
            transform: rotate(-180deg);
        }
    }
    &__preferred-store-text {
        font-weight: normal;
        span {
            font-weight: bold;
        }
    }
}

//==============================================================================
// FIXED HEADER
//==============================================================================
$header-fixed-z-index: 2047;

.android-app-banner{
    height: 87px;
    width: 100%;
}

.ms-header {
    &__container,
    &--fixed {
        position: relative;
    }
    &--fixed {
        .ms-header__secondary{
            margin-top: 0px;
            &--lock{
                margin-top: 167px;
                @include breakpoint(md) {
                    margin-top: 122px;
                }
            }
        }
        &.header-checkout {
            padding-top: 70px;
        }
        .ms-header__container__wrapper {
            width: 100%;
            z-index: 2047;
            @include breakpoint(md) {
                top: 0px;
            }
            &--lock{
                position: fixed;
                top: 0px;
            }
        }
        .modal-store-locator-toggle & {
            z-index: 5004;
        }
    }
}

//==============================================================================
// CHECKOUT HEADER
//==============================================================================

$header-checkout-bg-color: $color-bg-quaternary;

.ms-header {
    &__checkout {
        position: relative;
        height: $header-primary-height;
        display: flex;
        background-color: $header-checkout-bg-color;
        &--fixed {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: $header-fixed-z-index;
        }
        .ms-header__logo {
            width: auto;
            margin: auto 0;
            padding-left: 15px;
            display: inline-block;
            a {
                display: block;
            }
            img {
                max-width: 120px;
                max-height: 16.5px;
            }
            &::after {
                display: none;
            }
        }
        .ms-cart-icon {
            height: calc(70px / 2);
            margin: auto 0;
            padding: 0 15px 0 18px;
            @include breakpoint(lg) {
                padding: 0 15px 0 30px;
            }
        }
        a {
            &:hover,
            &:focus {
                text-decoration: none;
                color: $color-text-alert-message;
                @include breakpoint(lg) {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
        &__phone-no {
            @include font-content($font-size-m, 700, 16px);
            @include add-mfrm-icon($icon-call);
            color: $color-text-alert-message;
            margin: auto;
            margin-right: 5px;
            &:before {
                font-size: ($icon-size-l - 2);
            }
            @include breakpoint(lg) {
                &:before {
                    display: none;
                }
            }
            span {
                display: none;
                @include breakpoint(lg) {
                    display: inline-block;
                }
            }
        }
    }
}
//Checkout header redesign
.mfrm-checkout-header {
    display: block;
    background-color: $color-bg-primary !important;
    &__container {
        display: flex;
        justify-content: center;
        width: 94%;
        max-width: 1200px;
        position: relative;
        margin: 0 auto;
        height: 100%;
        border-bottom: 1px solid $color-border-checkout-secondary;
    }
    &__logo-section{
        margin: auto 0;
        display: flex;
        img {
            width: 36px;
            height: 33px;
        }
    }
    &__mfrm-logo {
        @include vfi($color-black);
    }
    &__page-title {
        margin-left: 15px;
        margin-top: 4px;
        @include vfi($color-black);
        @include breakpoint(lg) {
            margin-top: 2px;
        }
        h2 {
            @include font-content($checkout-mobile-font-size, 700, 1.2);
            @include breakpoint(lg) {
                @include font-content($font-size-xxl, 700, 1.1667);
            }
        }
    }
    &__phone-number-mobile {
        margin: auto 0 auto auto;
        @include vfi($color-black);
        @include breakpoint(lg) {
            display: none;
        }
        img {
            top: 10px;
            left: 10px;
            position: relative;
        }
    }
    &__phone-number-desktop {
        display: none;
        margin: auto 0 auto auto;
        @include font-content($font-size-s, 700, 0.987);
        @include vfi($color-black);
        @include breakpoint(lg) {
            display: block;
        }
        &:hover,
        &:focus{
            color: $color-text-secondary !important;
            span {
                text-decoration: none !important;
            }
        }
    }
    &__cart-icon {
        margin: auto 0 auto 20px;
        img {
            top: 6px;
            left: 8px;
            position: relative;
        }
        @include vfi($color-black);
        @include breakpoint(lg) {
            margin: auto 0 auto 25px;
        }
        &:hover,
        &:focus{
            text-decoration: none !important;
        }
    }
    &__cart-icon-count {
        color: $color-text-alert-message;
        background-color: $color-bg-error-page;
        height: 18px;
        width: 18px;
        border-radius: 100%;
        position: absolute;
        top: 16px;
        right: -1px;
        text-align: center;     
        p {
            @include font-content($font-size-xs, 700, 1);
            position: relative;
            top: 4px;
        }
    }
    .icon-utility {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        transition: none;
        &:hover,
        &:focus{
            background-color: $color-bg-header-primary;
        }
    }
}