$buybox-modal-dimension-color: $color-text-tertiary;

.modal-buybox-desktop,
.modal-buybox-mobile {
  transition: none;
  @include breakpoint(md) {
    left: calc(50% - 474px / 2);
  }
  .msc-modal {
    &__dialog {
      height: 100%;
      width: 100%;
      @include breakpoint(md) {
        width: 474px;
      }
    }
    &__body {
      padding: 60px 15px 30px;
      @include breakpoint(md) {
        padding: 60px 65px 30px;
      }
      height: 100%;
    }
    &__content {
      box-shadow: -4px 0px 4px rgba(45, 41, 38, 0.1);
      border-radius: 0;
      height: 100%;
      display: inline-table;
    }
    &__close-button {
      top: 30px;
      right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      @include breakpoint(md) {
        right: 30px;
      }
      border: 0.5px solid $color-modal-border-close-button;
      border-radius: 100%;
      &:before {
        font-size: $font-size-m;
        font-weight: bold;
      }
    }
  }
  .msc-title {
    @include font-content($font-size-xxl, bold, 1.17);
    margin-bottom: 30px;
  }
  .msc-variation {
    @include font-content($font-size-m, 500, 1.14);
    @extend %field-control-input;
    width: 100%;
    min-height: 84px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus {
      border-color: $color-input-border-hover;
    }
    &:not(:disabled):not(.disabled):focus,
    &.selected {
      box-shadow: 0 0 0 1px $color-input-border-hover;
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin: 0;
    }
    
    &-size__dimension-label {
      @include font-content($font-size-s, 400, 1.167);
      color: $buybox-modal-dimension-color;
    }
    &-total-price,
    &-total-price-no-discount {
      text-align: right;
      color: $color-text-secondary;
      @include font-content($font-size-m, 500, 1.14);
    }
    &-total-price-no-discount {
      color: $color-text-primary;
    }
    &-price:not(:empty) {
      margin-top: 10px;
    }
    &-price-discount {
      @include font-content($font-size-s, 700, 1.17);
      padding-right: 5px;
      color: $color-text-primary;
    }
    &-actual-price {
      @include font-content($font-size-s, 400, 1.17);
      color: $color-midgray;
      text-decoration: line-through;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &[data-no-click="true"] {
      cursor: default;
      pointer-events: none;
    }
    .out-of-stock-variant{
      .msc-variation-size {
        &__size-label {
          color: $color-bombay;
        }
        &__dimension-label {
          @include font-content($font-size-s, 400, 1.34);
          color: $color-silverchain;
        }
      }
    }
  }
  .msc-primary {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    button {
      margin: 0 0 30px;
      &.disabled,
      &:disabled {
        color: $color-text-tertiary !important;
      }
    }
  }
}
