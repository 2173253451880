.mfrm-ribbon__banner-bleed, .mfrm-ribbon__banner-no-bleed {
  height: 319px;
}

.mfrm-ribbon__banner-subtext-only, .mfrm-ribbon__banner-subtext-only-bleed {
  height: 120px;
}

.mfrm-mobile-image-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mfrm-mobile-image-container-subtext-only {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mfrm-ribbon {
  color: $color-white;
  display: flex;
  justify-content: center;
  
  &__bkg-blue {
    background: $color-tealish-blue;
  }

  &__bkg-light-blue {
    background: $color-light-blue;
    color: $color-tealish-blue;
  }

  &__bkg-red {
    background: $color-bg-quaternary;
  }

  &__blue-cta {
    @include btn(quaternary, default);
    padding: 16px 60px;
    justify-content: center;
  }

  &__red-cta {
    @include btn(primary, default);
    padding: 16px 60px;
    justify-content: center;
  }

  &__inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 13.5px;
    position: relative;
    text-align: center;
    font-style: normal;
    margin-top: 5px;
  }

  &__inner-content-subtext-only {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 13.5px;
    position: relative;
    text-align: left;
    font-style: normal;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }

  &__img-border {
    flex-shrink: 0;
    margin-bottom: 5px;
    
    img {
      border-radius: 100%;
      width: 90px;
      height: 90px;
      border: 3px solid #FFFFFF;
      box-sizing: border-box;
    }
  }

  &__img-no-border {
    flex-shrink: 0;

    img {
      border-radius: 100%;
      width: 90px;
      height: 90px;
      border: 0px solid #FFFFFF;
      box-sizing: border-box;
    }
  }

  &__text-container {
    margin-top: 5px;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  &__text-container-subtext-only {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  &__header-text {
    font-family: rubik;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 8px;
  }
  
  &__sub-text {
    font-family: rubik;
    font-weight: normal;
    font-size: $font-size-m;
    line-height: 20px;
    padding-left: 8px;
  }

  &__button-text {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 12px;
    text-align: center;
  }
}

@include breakpoint(md) {
  .mfrm-ribbon__banner-bleed, .mfrm-ribbon__banner-no-bleed {
    height: 145px;
  }
  .mfrm-mobile-image-container, .mfrm-mobile-image-container-subtext-only {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 13.45px;
  }
  
  .mfrm-ribbon {

    &__inner-content {
      flex-direction: row;
      text-align: left;
      padding: 0px 5px;
      margin-top: 0px;
    }

    &__inner-content-subtext-only {
      padding: 8px 5px;
    }

    &__text-container, &__text-container-subtext-only {
      margin-top: 0px;
      margin-left: 28.45px;
      margin-bottom: 0px;
      padding-right: 23px;
    }

    &__container {
      width: 430px;
      margin-top: 0px;
    }

    &__header-text {
      padding-bottom: 8px;
    }
    
    &__sub-text {
      padding-bottom: 0px;
      padding-left: 0px;
    }
    
  }
}

@include breakpoint(lg) {
  .mfrm-ribbon__banner-bleed, .mfrm-ribbon__banner-subtext-only-bleed {
    height: 90px;
  }
  .mfrm-ribbon__banner-no-bleed, .mfrm-ribbon__banner-subtext-only {
    height: 145px;
  }
  
  .mfrm-ribbon {
    align-content: center;

    &__inner-content {
      align-items: center;
      width: 1200px;
    }

    &__text-container, &__text-container-subtext-only {
      width: 900px;
      margin-left: 25px;
    }

    &__header-text {
      font-size: $font-size-xxl;
    }

    &__sub-text {
      font-size: $font-size-l;
    }

    &__container {
      width: 300px;
      align-items: flex-end;
      margin-right: 50px;
    }

    &__inner-content-subtext-only {
      width: 1200px;
      padding: 0px 0px;
    }

    &__img-border {
      img {
        width: 115px;
        height: 115px;
      }
    }
  
    &__img-no-border {
      img {
        border-radius: 100%;
        width: 115px;
        height: 115px;
      }
    }
  }
}

@include breakpoint(xl) {
  .mfrm-ribbon {
    &__inner-content {
      padding: 0 0;
    }
  }
}