[class*="panel-module_panel-item"] > [class*="mfrm-tab-accordion"] {
    @include clearfix;
}

[class*="panel-module_panel-item"] {
    padding: 0;
    &[class*="panel-module_active"] {
        @include animation-transition(fadeIn, $duration: 0.5s);
        display: block;
    }
    &[class*="panel-module_hidden"] {
        display: none;
    }
}

//==============================================================================
// MFRM Tabs/Accordion - No Content
//==============================================================================

.mfrm-tab-accordion {
    margin: 30px 0;
    &__no-content {
        @include font-content($font-size: $font-size-xl);
        margin: 60px auto;
        color: $color-text-primary;
        text-align: center;
    }
}

//==============================================================================
// MFRM Tabs/Accordion - General Content
//==============================================================================

$mfrm-tab-accordion-spacing: 15px;

.mfrm-tab-accordion {
    .col-left,
    .col-right {
        width: 100%;
    }
    @include breakpoint(lg) {
        .col-left,
        .col-right {
            width: 48.75%;
            display: inline-block;
        }
        .col-left {
            float: left;
        }
        .col-right {
            float: right;
        }
    }

    .title {
        @include font-content($font-size-xxxl, bold, 1.14);
        margin-bottom: 30px;
        text-align: center;
    }

    .heading,
    h4 {
        @include font-content($font-size-l, bold, 1.25);
        padding-bottom: $mfrm-tab-accordion-spacing;
        padding-left: $mfrm-tab-accordion-spacing;
        padding-right: $mfrm-tab-accordion-spacing;
        color: $color-text-primary;
        &.bordered {
            border-bottom: 1px solid $color-border-tab-accordion;
            margin-bottom: $mfrm-tab-accordion-spacing;
        }
    }
    .sub-heading,
    h6 {
        @include font-content($font-size-l, 500, 1.5);
        margin-bottom: $mfrm-tab-accordion-spacing;
        padding-left: $mfrm-tab-accordion-spacing;
        padding-right: $mfrm-tab-accordion-spacing;
        color: $color-text-primary;
    }

    .text,
    p {
        @include font-content($font-size-l, normal, 1.5);
        margin-bottom: ($mfrm-tab-accordion-spacing + 15);
        padding-left: $mfrm-tab-accordion-spacing;
        padding-right: $mfrm-tab-accordion-spacing;
        color: $color-text-primary;
        p,
        div {
            display: inline;
        }
    }
    &__delivery-tab {
        p {
            margin: 0;
            padding: 0;
        }
    }

    &__content,
    &__text,
    div:not(.product-details-tabs-wrapper) {
        ul {
            @include font-content($font-size-l, normal, 1.5);
            margin-bottom: ($mfrm-tab-accordion-spacing + 15);
            padding-left: $mfrm-tab-accordion-spacing;
            color: $color-text-primary;
            li {
                margin-bottom: 21px;
            }
        }
        > div[contenteditable] {
            display: inline;
        }
    }

    &__about-text,
    &__about-tab {
        @include font-content($font-size-l, 400, 1.5);
        margin: 0 15px;
        @include breakpoint(lg) {
            @include font-content($font-size-l, 400, 1.37);
            margin: 25px 0;
        }
    }

    .pdp-tab {
        display: none;

        &.active {
            display: block;
        }
    }
}

//==============================================================================
// MFRM Tabs/Accordion - Enhanced Content
//==============================================================================

.mfrm-tab-accordion {
    &__enhanced-content {
        display: block;
        @include breakpoint(md) {
            margin-bottom: 30px;
            display: flex;
        }
        @include breakpoint(lg) {
            padding: 0 15px;
        }
        > div[contenteditable] {
            display: inline;
        }
    }
    &__media {
        display: block;
        text-align: center;
        margin-bottom: 15px;
        @include breakpoint(md) {
            margin-right: 15px;
            margin-bottom: 0;
        }
        @include breakpoint(lg) {
            margin-right: 30px;
        }
        > iframe {
            max-width: 100%;
            border-radius: 5px;
            @include breakpoint(md) {
                height: 192px;
                max-width: 346px;
            }
            @include breakpoint(lg) {
                height: 272px;
                max-width: 483px;
            }
        }
        img {
            max-width: 100%;
            border-radius: 5px;
            @include breakpoint(md) {
                max-width: 346px;
            }
            @include breakpoint(lg) {
                max-width: 483px;
            }
        }
    }
    &__text {
        /* Specific override for description tab */
        .mfrm-tab-accordion__description-tab & {
            padding: 0;
            line-height: 1.37;
        }
    }
}

//==============================================================================
// MFRM Tabs/Accordion - Warranty
//==============================================================================

.mfrm-tab-accordion {
    &__title,
    h2 {
        .mfrm-tab-accordion__warranty-tab & {
            display: none;
            @include breakpoint(lg) {
                display: block;
                text-align: center;
                margin-bottom: 30px;
                padding-top: 30px;
            }
        }
    }
    &__heading,
    h4 {
        .mfrm-tab-accordion__warranty-tab & {
            padding-bottom: 20px;
            line-height: 1.37;
        }
        .mfrm-tab-accordion__content.collapsed & {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__text,
    p {
        .mfrm-tab-accordion__warranty-tab & {
            margin-bottom: 20px;
            line-height: 1.37;
        }
        .mfrm-tab-accordion__content.collapsed & {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__text p {
        .mfrm-tab-accordion__content.collapsed & {
            padding: 0;
            margin: 0;
            display: inline;
        }
    }
    &__more-cta {
        transition: none;
        .content-expanded & {
            margin: 0 15px;
        }
    }
    .mfrm-tab-accordion__warranty-tab {
        ul {
            // override for warranty list style
            margin-bottom: 20px !important;
            padding-left: 30px !important;
            list-style: disc;
            li {
                margin-bottom: 20px !important;
                line-height: 1.37 !important;
            }
        }
    }
    /* if content collapsed */
    &__content-collapsed {
        display: inline;
        .mfrm-tab-accordion__text {
            display: inline;
        }
    }
    &__content.collapsed {
        padding-left: 15px;
        padding-right: 15px;
        .mfrm-tab-accordion__warranty-tab & {
            margin-bottom: 20px;
        }
    }
}

//==============================================================================
// MFRM Tabs/Accordion - Table
//==============================================================================

.mfrm-tab-accordion {
    table,
    tbody,
    tr,
    th,
    td {
        display: block;
        color: $color-text-primary;
    }
    table {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 30px;
        padding: 0 15px;
        text-align: center;
        @include breakpoint(md) {
            margin-top: 30px;
        }
        @include breakpoint(lg) {
            margin-top: 45px;
        }
    }
    tbody {
        display: flex;
        flex-flow: column wrap;
        @include breakpoint(md) {
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
    tr {
        min-width: 100%;
        max-width: 100%;
        margin-top: 15px;
        @include breakpoint(md) {
            min-width: 31.86%;
            max-width: 31.86%;
        }
        @include breakpoint(lg) {
            min-width: 32.2%;
            max-width: 32.2%;
        }
    }
    th {
        @include font-content($font-size-l, bold, 1.25);
        padding: 15px;
        background-color: $color-bg-primary;
        border-bottom: 1px solid $color-border-tab-accordion;
    }
    td {
        @include font-content($font-size-l, normal, 2.4);
        background-color: $color-bg-primary;
        &:nth-child(even) {
            background-color: $color-bg-accordion-table;
        }
    }
}

//==============================================================================
// MFRM Tabs/Accordion - Delivery/Shipping
//==============================================================================

.mfrm-tab-accordion {
    &__delivery-tab {
        padding: 0 15px;
    }
}

//==============================================================================
// MFRM Tabs/Accordion - See More CTA
//==============================================================================

.mfrm-tab-accordion {
    &__more-cta {
        @include font-content($font-size-l, 400, 1.37);
        margin-left: 0;
        display: inline-block;
        text-decoration: underline;
        /* override hover/focus */
        color: $color-text-primary !important;
    }
}

//==============================================================================
// MFRM Tabs/Accordion - Description - Adjustable Base FAQ
//==============================================================================
$faq-accordion-header-font-size: 2.286rem;
$mfrm-accordion-selector: accordionItem-module;
.mfrm-accordion {
    &__faq {
        h2 {
            @include font-content($font-size-xxl, 700, 1.17);
            text-align: center;
            margin-bottom: 30px;
            @include breakpoint(md) {
                @include font-content($faq-accordion-header-font-size, 700, 1.17);
                margin-bottom: 60px;
            }
        }
        .mfrm-accordion {
            [class*="#{$mfrm-accordion-selector}_line"] {
                padding: 25px 30px 15px;
            }
            [class*="#{$mfrm-accordion-selector}_title"] {
                @include font-content($font-size-m, 500, 1.425);
                @include breakpoint(md) {
                    @include font-content($font-size-l, 500, 1.5);
                }
            }
            [class*="#{$mfrm-accordion-selector}_innerContent"] {
                margin: -5px 0 30px 30px;
                @include breakpoint(md) {
                    margin: -5px 0 25px 30px;
                }
                width: 88%;
                @include breakpoint(lg) {
                    width: 58%;
                }
                @include font-content($font-size-l, 400, 1.375);
                @include breakpoint(md) {
                    @include font-content($font-size-l, 400, 1.5);
                }
            }
        }
    }
}
