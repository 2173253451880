$cart-space: 15px;
$cart-heading-font-size: 32px;
$color-text-promo-code: $color-green;
.cart {
  //Hide store selector and move up the secondary nav behind the primary nav based on cart classname
  .ms-header__secondary {
    margin-top: 72px !important;
    opacity: 0;
  }

  .ms-header__preferred-store-container--mobile {
    display: none;
  }
}

.msc-cart {
  &__container {
    background-color: $color-bg-cart-primary;
    @include breakpoint(lg) {
      background-color: $color-bg-primary;
    }
    .d5-mfrm-sleep-expert-picks {
      margin-top: 60px;
      padding-bottom: 50px;
      margin-bottom: 40px;
      width: 100%;

      @include breakpoint(lg) {
          margin-bottom: 0;
      }

      &__img {
          display: none;
        }

      &__wrapper {
        background: $color-bg-sleep-expert !important;
        padding: 30px 0;
        margin-top: 0;

        @include breakpoint(lg) {
          padding: 30px 30px 30px;
        }
      }

      &__title {
        padding: 0;
        margin-bottom: 5px;
        @include font-content(20px, 700, 24px);

        @include breakpoint(lg) {
          margin-bottom: 8px;
          @include font-content($font-size-xxl, 700, 1.18);
        }
      }

      &__content {
        padding: 0px 15px 0 15px;
        margin-bottom: 30px;
        @include font-content($font-size-m, 400, 1.4);

        @include breakpoint(lg) {
          margin-bottom: 30px;
          max-width: 800px;
          padding: 0;
        }
      }

      &__button {
        margin: 0;

        .msc-btn {
          background: $color-bg-error-page;
          border-radius: 100px;
          color: $color-white;
          padding: 13px 32px;
          width: 200px;
          height: 40px;
          margin-top: 0;
        }
        .d5-mfrm-single-cta {
          @include font-content($font-size-m, 700, 0.85);
          border: none;
          &:hover,
          &:focus,
          &:active {
            border: none !important;
            box-shadow: none !important;
          }
        }
      }
    }
    //RAP section on cart page 
    .mfrm-cart-rap-container {
      background-color: $color-bg-primary;
      padding: 15px 15px;

      @include breakpoint(lg) {
        padding: 0;
        margin-top: 60px;
      }
    }

    .mfrm-rap-items {
      border: none;

      .ms-content-block__text {
        text-align: center;

        @include breakpoint(lg) {
          text-align: initial;
        }
      }
    }

    .ms-content-block__link {
      flex-direction: column;

      @include breakpoint(lg) {
        flex-direction: row;
      }
    }

    .ms-content-block__text {
      &::after {
        display: none !important;
      }
    }

    .mfrm-rap-item {
      .row {
        grid-column-gap: 15px;
        grid-row-gap: 0px;
        grid-template-columns: repeat(3, 1fr) !important;

        @include breakpoint(lg) {
          grid-column-gap: 30px;
        }
      }
    }
  }

  &__ship-message {
    background-color: $color-bg-primary;
    display: block;
    padding: $cart-space;
    text-align: left;
    &:empty {
      padding: 0;
    }
    @include breakpoint(lg) {
      display: none;
      background-color: transparent;
      padding: 0;
    }
  }
  &__deliver-to {
    @include font-content($font-size-m, bold, 1.42);
  }
  &__delivery-error-message {
    @include font-content($font-size-m, normal, 1.42);
    color: $color-text-secondary;
    margin: $cart-space 0;
  }

  &__find-store {
    @include font-content($font-size-l, 500, 1.125);
    color: $color-text-secondary;
    @include add-mfrm-icon($icon-chev-right, after);
    @extend %link-muted;
    display: flex;
    align-items: center;
    margin-top: 15px;
    &:hover {
      color: $color-button-secondary-background-hover;
    }
    &::after {
      font-size: 9px;
      padding-left: 5px;
      font-weight: bold;
    }
    @include breakpoint(lg) {
      cursor: pointer;
    }
  }
  //==============================================================================
  // Buybox Content - Delivery by Message
  //==============================================================================
  $delivery-message-input-height: 44px;
  $delivery-message-input-width: 345px;
  &__delivery-message {
    margin-top: 0;
    margin-bottom: 0;
    flex-flow: column wrap;
    justify-content: left;
    width: 100%;
    padding: 15px;
    background-color: $color-bg-primary;
    @include breakpoint(md) {
      padding: 15px 30px;
    }
    @include breakpoint(lg) {
      margin-bottom: 15px;
      background-color: transparent;
      padding: 0;
    }
    &-text {
      @include vfi-tab($color-black);
      @include font-content($font-size-m, 400, 1.43);
      @include add-mfrm-icon($icon-time-delivery-truck);
      color: $color-text-primary;
      padding-left: 0;
      position: relative;
      .sr-only {
        width: auto !important;
        height: 100% !important;
      }
      &::before {
        display: none;
        top: 50%;
        left: 0;
        position: absolute;
        font-size: ($icon-size-m + 6);
        color: $color-delivery-truck;
        transform: translateY(-50%);
      }
      &:not(:only-child) {
        margin-bottom: 0;
      }
    }
    &-heading {
      @include font-content($font-size-m, 700, 1.43);
      @include vfi($color-black);
      cursor: pointer;
      text-decoration: underline;
      padding: 0;
      border: none;
      background: transparent;
      margin-left: 5px;
      color: $color-text-primary !important;
    }
    &-text-error {
      @include font-content($font-size-m, 400, 1.43);
      display: block;
      color: $color-error;
      padding-left: 25px;
      margin-top: 5px;
      @include breakpoint(md) {
        @include font-content($font-size-m, 400, 1.43);
      }
    }
    &-link {
      @include font-content($font-size-m, 400, 1.43);
      @include vfi($color-black);
      @extend %btn-muted;
      padding: 0;
      display: inline-block;
      color: $color-text-primary;
      text-decoration: underline;
      outline-offset: 1px;
      cursor: pointer;
      width: 115px;
      text-align: left;
      &:hover {
        color: $color-text-primary;
        text-decoration: none;
      }
      @include breakpoint(md) {
        @include font-content($font-size-m, 400, 1.43);
      }
      + .msc-cart__delivery-message-text-error {
        padding-left: 0;
        margin-top: 15px;
      }
    }
    &-input {
      width: 100%;
      margin-bottom: 0;
      @include breakpoint(sm) {
        width: $delivery-message-input-width;
      }
      @include breakpoint(lg) {
        width: 100%;
      }
      .field-control {
        @extend %field-control-input-rounded;
        min-height: $delivery-message-input-height;
        height: $delivery-message-input-height;
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        speak: digits;
        @include breakpoint(sm) {
          min-width: $delivery-message-input-width;
          width: $delivery-message-input-width;
          max-width: $delivery-message-input-width;
        }
        @include breakpoint(lg) {
          min-width: 100%;
          width: 100%;
          max-width: 100%;
        }
        &:focus {
          ~ .ms-buybox__delivery-message-button {
            box-shadow: 0 0 0 1px $color-button-primary-background;
          }
        }
        + .field-label {
          top: 15px;
        }
      }
      &:not(:only-child) {
        margin-bottom: 0;
        margin-top: 21px;
      }
      + .ms-buybox__delivery-message-text-error {
        margin-left: 25px;
      }
    }
    &-button {
      @include btn(primary, large);
      position: absolute;
      top: 0;
      right: 0;
      height: $delivery-message-input-height;
      padding: 16px 30px;
    }
    &--input-active {
      margin-top: 0;
      margin-bottom: 0;
      @include breakpoint(lg) {
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
  }
    &__btn-img-paypal {
      width: 68px;
      height: auto;
      filter: grayscale(100%);
    }
    &__btn-img-applePay {
      width: 50px;
      height: auto;
    }
    &__btn-applepay{
      margin-top: 0 !important;
      margin-right: 15px !important;
      cursor: default;

      &:only-child {
        margin-right: 0 !important;
      }
    }
    &__applepay-only {
       margin-right: 0 !important;
    }
    &__btn-paypal {
      padding-top: 13px !important;
      padding-bottom: 13px !important;
    }
    // when rap section is not shown and only sleeps expert section is shown
    &__sleep-expert-section {
      width: 100%;
      @include breakpoint(lg) {
        margin-top: 60px;
      }
      .d5-mfrm-sleep-expert-picks {
        margin-top: 0;
      }
    }
    &__promotion-msg-section {
      @include vfi($color-black);
      background-color: $color-bg-promotion-msg;
      border-radius: 10px;
      margin-top: 15px;
      padding: 8px;
    }
    &__promotion-msg-text {
      @include font-content($font-size-s, 400, 1.33);
      color: $color-promotion-message;
    }
    &__RA-mobile-container {
      display: block;
      @include breakpoint(lg) {
        display: none;
      }
    }
    &__RA-desktop-container {
      display: none;
      @include breakpoint(lg) {
        display: block;
      }
    }
    &__SE-desktop-container {
      display: none;
      @include breakpoint(lg) {
        display: block;
      }
    }
}

.ms-cart {
  @include breakpoint(lg) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .msc-cart__find-store-text {
    @include add-mfrm-icon($icon-chev-right, after);
    margin-top: 15px;
    a {
      @include vfi($color-black);
      @include font-content($font-size-l, 500, 1.125);
      color: $color-link-secondary;
    }
    &::after {
      margin-left: 5px;
      display: inline-block;
      font-size: 9px;
      color: $color-link-secondary;
      position: absolute;
      margin-top: 6px;
    }
  }

  //==============================================================================
  // Cart Line Container
  //==============================================================================

  .msc-cartline-wraper {
    @include full-width;
    max-width: $breakpoint-lg;
    margin-top: 0;
    margin-bottom: 30px;
    flex: 1;
    margin-left: -15px;
    width: calc(100% + 30px);
    @include breakpoint(md) {
      margin-left: -30px;
      width: calc(100% + 60px);
    }
    @include breakpoint(lg) {
      max-width: 66%;
      margin: 0 30px 0 0;
    }
    //Loader
    .msc-waiting {
      display: block;
      margin: 50px auto;
    }
    .msc-cart__delivery-message {
      display: flex;
      @include breakpoint(lg) {
        display: none;
      }
    }
  }
  .msc-cart {
    //==============================================================================
    // Cart Conatainer
    //==============================================================================

    &__container {
      margin-top: $cart-space * 2;
      @include breakpoint(md) {
        margin-top: 90px;
      }
    }
    &-line__container {
      display: flex;
      flex-grow: 1;
      position: relative;
    }

    &__head {
      width: 100%;
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      @include breakpoint(lg) {
        min-height: 117px;
        padding: 30px 0;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }
      &.empty {
        @include breakpoint(lg) {
          padding-top: 15px;
          padding-bottom: 20px;
          align-items: center;
          justify-content: center;
          min-height: auto;
        }
      }
      &-wrapper {
        display: flex;
        flex-flow: column wrap;
        text-align: center;
        @include breakpoint(lg) {
          text-align: left;
        }
        > p {
          @include font-content($line-height: 1.43);
          color: $color-text-primary;
        }
      }
    }

    //==============================================================================
    // Cart Title
    //==============================================================================

    &__heading {
      @include vfi-tab($color-black);
      @include font-content($font-size-xxxl, 700, 1.14);
      margin: 0 15px 5px;
      color: $color-text-primary;
      text-align: center;
      @include breakpoint(lg) {
        margin: 0 0 5px;
        display: inline-block;
        text-align: left;
      }
    }

    //==============================================================================
    // Empty Cart
    //==============================================================================

    &__empty-cart {
      @include vfi-tab($color-black);
      text-align: center;
      .msc-cart__btn-backtoshopping {
        display: inline-block;
      }
      @include breakpoint(lg) {
        margin-bottom: 60px;
        .msc-cart__btn-backtoshopping {
          @include btn(secondary, large);
          &:focus:hover,
          &:focus:not(:focus-visible):hover {
            color: $color-link-hover;
            text-decoration: none;
          }
          margin: 0;
          &::after {
            display: none;
          }
        }
      }
      .msc-cart-line {
        margin-bottom: $cart-space;
        display: block;
        color: $color-text-primary;
        @include breakpoint(lg) {
          margin-bottom: 50px;
        }
      }
    }

    //==============================================================================
    // Continue shopping Button
    //==============================================================================

    &__btn-backtoshopping {
      @include btn(secondary, small);
      @include font-content($font-size-m, bold, 0.86);
      margin-bottom: ($cart-space + 15);
      display: none;
      @include breakpoint(lg) {
        @include add-mfrm-icon($icon-chev-right, after);
        @include font-content($font-size-l, 500, 1.12);
        margin: 5px 0 10px;
        padding: 0;
        display: block;
        color: $color-link-secondary;
        background: transparent;
        border: 0;
        &:hover,
        &:focus,
        &:focus:hover,
        &:active,
        &:focus:not(:focus-visible):hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          color: $color-link-hover;
          background-color: transparent;
          text-decoration: none;
        }
        &::after {
          margin-left: 5px;
          display: inline-block;
          font-size: ($icon-size-xs - 4);
          font-weight: bold;
          vertical-align: middle;
        }
      }
    }

    //==============================================================================
    // Guest Checkout Button
    //==============================================================================

    &__btn-guestcheckout {
      visibility: hidden;
    }
  }

  //==============================================================================
  // Empty Cart Title
  //==============================================================================

  &-empty {
    margin-bottom: -20px;
    .msc-cartline-wraper {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }
    @include breakpoint(lg) {
      margin-top: 60px;
      margin-bottom: 0;
    }
    .msc-cart__heading {
      text-align: center;
    }
  }

  //==============================================================================
  // Checkout and Promo Button
  //==============================================================================

  .msc-cart__btn-checkout,
  .msc-promo-code__apply-btn {
    @include btn(primary, large);
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 346px;
    padding: 16px;
  }
    //TODO: Button checkout spacing for Paypal and Amazon Pay
    .msc-express-payment-container-in-cart-page {
      margin: 15px auto 0;
      width: 100%;
      max-width: 345px;

      @include breakpoint(lg) {
        margin-top: 15px;
      }
  
      iframe {
        width: 100%;
      }
    }
  
    .msc-order-summary-wrapper {
      .msc-express-payment-container-in-cart-page {
        display: none;
  
        @include breakpoint(lg) {
          display: block;
        }
      }
    }
  .msc-cart__btn-checkout {
    margin: 0 auto;
    display: none;
    @include breakpoint(lg) {
      margin: 30px auto 0;
      display: flex;
    }
    &.disabled,
    &:disabled {
      cursor: default !important;
      pointer-events: none !important;
      touch-action: none !important;
    }
    &-wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 15px;
      background-color: $color-white;
      border-top: 1px solid $color-border-checkout-secondary;
      z-index: 10;
      @include breakpoint(lg) {
        display: none;
      }
      .msc-cart__btn-checkout,
      .ms-checkout-express-paypal__payment-wrapper {
        display: flex;
        @include breakpoint(lg) {
          display: none;
        }
      }
    }
  }
  .msc-promo-code__apply-btn {
    margin: 0 auto 15px;
  }

  .msc-order-summary {
    &-wrapper {
      padding: 0 15px 30px;
      margin-bottom: 0;
      @include breakpoint(md) {
        padding: 0 30px 30px;
      }
      @include breakpoint(lg) {
        padding: 15px 17.5px;
        border-radius: 8px;
      }
      .msc-cart__ship-message {
        margin: 0 0 30px;
        align-self: flex-start;
        display: none;
        &:empty {
          margin: 0;
        }
        @include breakpoint(lg) {
          display: block;
        }
      }
      .msc-cart__delivery-message {
        display: none;
        @include breakpoint(lg) {
          display: flex;
        }
      }
      .msc-express-payment-container-in-cart-page {
        .msc-cart__btn-checkout {
          display: flex;
          margin: 0;
          padding: 13px 0;
          width: 100%;
          max-width: 100%;
          @include breakpoint(lg) {
            display: flex;
          }
        }
      }
    }
    &__items {
      margin-bottom: 0;
    }
  }
}

.msc-cart-line {
  &__promo-codes {
    @include font-content($font-size-s, 500, 1.35);
    color: $color-text-discount-code;
  }
  &-item-product-discount-container {
    line-height: 0;
    margin-top: 5px;
  }
}

.msc-add-gift {
  display: table-footer-group;
  clear: both;
  width: 100%;
  position: relative;
  text-align: center;
  &__button {
    margin-top: 10px;
    @include btn(secondary, small);
    @include font-content($font-size-m, bold, 0.86);
    @include breakpoint(lg) {
      @include font-content($font-size-l, 500, 1.12);
    }
  }
}
/////////////////////   Sell from cart - quantity component   ///////////////////// 
.msc-cart-line {
  &__quantity {
    &-container {
      position: relative;
      display: flex;
      align-items: start;

      @include breakpoint(lg) {
        position: absolute;
        align-items: center;
        right: 0;
        bottom: 0;
      }
    }

    &-button {
      border: none;
      background: transparent;
      padding: 0;

      @include breakpoint(lg) {
        &:focus {
          outline: 1px dotted $color-black !important;
          outline-offset: -1px;
          border-radius: 100%;
        }
      }
    }

    &-disabled {
      opacity: 0.7;
    }

    &-text {
      @include font-content($font-size-l, 400, 1.12);
      color: $color-text-primary;
      margin: 0 3px;
      width: 32px;
      text-align: center;

      @include breakpoint(lg) {
        &:focus {
          outline: 1px dotted $color-black !important;
        }
      }
    }
    &-icon {
      width: 16px;
      height: 16px;
    } 
  }
  
  &__quantity-section-desktop {
    display: none;

    @include breakpoint(lg) {
      display: block;
      flex: 1 0 26%;
    }
  }
}