// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
$button-type: (primary: (border: 1px solid transparent,
        color: $color-white,
        background-color: $color-button-primary-background,
    ),
    secondary: (border: 1px solid $color-button-border,
        color: $color-button-secondary-text,
        background-color: $color-button-secondary-background,
    ),
    tertiary: (border: 1px solid $color-button-border-tertiary,
        color: $color-text-primary,
        background-color: $color-white,
    ),
    quaternary: (border: 1px solid transparent,
        color: $color-button-quaternary-text,
        background-color: $color-button-quaternary-background,
    ),
    quinary: (border: 1.5px solid $color-button-border-quinary,
        color: $color-text-primary,
        background-color: $color-white,
    ),
);

$button-size: (
    default: (padding: 10px 20px,
        font-size: $font-size-m,
        line-height: 0.71,
    ),
    small: (padding: 11px 30px,
        font-size: $font-size-m,
        line-height: 0.86,
    ),
    large: (padding: 16px 60px,
        font-size: $font-size-l,
        line-height: 0.75,
    ),
    extraLarge: (padding: 16px 160px,
        font-size: $font-size-l,
        line-height: 0.75,
    )
);

//function to get deep level mapped keys
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }

    @return $map;
}

@mixin btn($btn-type, $btn-size) {
    display: inline-block;
    padding: map-deep-get($button-size, $btn-size, padding);
    color: map-deep-get($button-type, $btn-type, color);
    background-color: map-deep-get($button-type, $btn-type, background-color);
    border: map-deep-get($button-type, $btn-type, border);
    font-size: map-deep-get($button-size, $btn-size, font-size);
    line-height: map-deep-get($button-size, $btn-size, line-height);
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    outline: 0;
    outline-offset: 0;
    border-radius: 100px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    
    @if($btn-type==quinary){
        width: 200px;
        font-size: 16px;
        padding: 14.825px 20px;
    }

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @if ($btn-type==primary) {
            background-color: $color-button-primary-background-hover;
            color: $color-button-primary-text;
        }

        @else if ($btn-type==secondary) {
            background-color: $color-button-secondary-background-hover;
            color: $color-button-secondary-text-hover;
            border-color: $color-button-secondary-background-hover;
        }

        @else if ($btn-type==tertiary) {
            background-color: $color-white;
            color: $color-text-primary;
            border-color: $color-text-primary;
        }

        @else if ($btn-type==quaternary) {
            background-color: $color-button-quaternary-background-hover;
            color: $color-button-quaternary-text;
        }

        // for droga5 homepage
        @else if ($btn-type==quinary) {
            border: 1.5px solid $color-charcoal;
        }
    }

    &:focus,
    &:focus:not(:focus-visible) {

        @if ($btn-type!=quinary) {
            @include breakpoint(lg) {
                outline: 1px dotted lighten($color-black, 50%);
            }
        }

        @if ($btn-type==primary) {
            color: $color-button-primary-text;
        }

        @else if ($btn-type==secondary) {
            color: $color-button-secondary-background-hover;
            border-color: $color-button-secondary-background-hover;

            &:hover {
                color: $color-button-secondary-text-hover;
            }
        }

        @else if ($btn-type==tertiary) {
            color: $color-text-primary;
            border-color: $color-text-primary;
        }

        @else if ($btn-type==quaternary) {
            color: $color-button-quaternary-text;
            border-color: $color-button-quaternary-background-hover;
        }

        // for droga5 homepage
        @else if ($btn-type==quinary) {
            box-shadow: 0px 0px 0px 3px #FFFFFF, 0px 0px 0px 5px #2D2926;
            border: 1px solid $color-button-border-quinary;
        }
    }
    &:disabled,
    &.disabled {
        @if ($btn-type==quinary) {
            background: $color-mercury !important;
            color: $color-midgray !important;
            border: 1.5px solid $color-mercury !important;
        }
    }
}

button,
a {
    transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s, border-color ease-in-out 0.3s;

    &:disabled,
    &.disabled {
        //used important to override hover effects
        color: $color-button-disabled-text !important;
        background-color: $color-button-disabled-background !important;
        border-color: $color-button-border-disabled !important;
        cursor: default;
    }
}

.btn-primary {
    @include btn(primary, default);
}

.btn-secondary {
    @include btn(secondary, default);
}

.btn-tertiary {
    @include btn(tertiary, default);
}

.btn-quaternary {
    @include btn(quaternary, default);
}

.btn-quinary {
    @include btn(quinary, default);
}

.btn-link {
    color: $color-link-secondary;
    font-size: $font-size-m;
    text-decoration-line: underline;
}

.text-link {
    color: $color-link-secondary;
}

[class*="button-module_btn"] {
    text-transform: none;
    [class*="button-module_lg"] {
        padding: 0 20px;
    }
}
