//==============================================================================
// SHOP BY DEPARTMENT
//==============================================================================
$grid-gap: 30px;
$grid-desktop-column: 6;
$grid-tablet-column: 3;
$grid-mobile-column: 2;
$sbd-item-image-max: 165px;
$sbd-item-image-max-lg: 277px;
$sbd-item-image-width: 100%;
$sbd-item-image-height: 100px;
$sbd-sale-item-top: 18%;

.mfrm-nsrp-sbd {
  padding: $grid-gap / 2 0 0 0;

  .heading {
    width: 100%;
    font-size: $font-size-xl;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    @include breakpoint(lg) {
      font-size: $font-size-xxl;
      line-height: 1.5;
    }
  }

  .row {
    align-items: flex-end;
    margin-left: -$grid-gap / 4;
    margin-right: -$grid-gap / 4;
    row-gap: $grid-gap / 2;

    [class^="col-"] {
      @extend .col-6;
    }

    @include breakpoint(md) {
      [class^="col-"] {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .col-12,
      .col-md-2 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }

    @include breakpoint(lg) {
      margin-left: -$grid-gap / 2;
      margin-right: -$grid-gap / 2;

      .col-12,
      .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
      }
    }

    .ms-content-block {
      margin-left: $grid-gap / 4;
      margin-right: $grid-gap / 4;

      @include breakpoint(lg) {
        margin-left: $grid-gap / 2;
        margin-right: $grid-gap / 2;
      }

      &__image {
        height: auto;
        width: $sbd-item-image-width;
        min-height: $sbd-item-image-height;
        max-width: $sbd-item-image-max;

        @include breakpoint(lg) {
          max-width: $sbd-item-image-max-lg;
        }

        .msc-main_image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__details {
        justify-content: center;
        text-align: center;
        font-weight: 500;
        margin-top: $grid-gap / 3;
      }

      &__title {
        margin: 0;
        line-height: 1.45;
        font-weight: 500;
      }

      &__eyebrow-text {
        color: $color-text-secondary;
        font-family: $font-family-primary;
        font-weight: 700;
        font-size: $font-size-s;
        margin: $grid-gap*2/3 0 $grid-gap/6 0;
      }

      &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        position: relative;
        padding: 0 $grid-gap / 3 $grid-gap / 2;
        color: $color-black;
        text-decoration: none;
        border-radius: 5px;
        border: 2px solid transparent;
        box-shadow: 0 1px 8px 0 rgba($black, 0.1);
        @include breakpoint(md) {
          box-shadow: none;
        }

        @include breakpoint(lg) {
          padding: 0 $grid-gap / 2 $grid-gap / 2;
        }

        &:hover {
          box-shadow: 0 1px 8px 0 rgba($black, 0.1);
          .ms-content-block__details {
            text-decoration: underline;
          }
        }

        &:active {
          box-shadow: 0 1px 4px 0 rgba($black, 0.1);
        }

        &:focus {
          box-shadow: none;
          border: 2px solid $color-midgray;
        }
      }
    }
  }
}