//TODO
.refine-submenu-item__rating {
    @include font-content($font-size-s, normal, 1.35);
    @extend %link-muted;
    color: $color-rating-primary;
}
.msc-rating {
    position: relative;
    display: block;

    &__range {
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        overflow: visible;
        color: transparent;
        background: transparent;
        border: 0 none;
        opacity: 0;
        z-index: -1;

        &:focus {
            + .msc-rating__group {
                border: 1px dotted $color-white;
                outline: 1px dotted $color-black;
            }
        }
    }

    &__star {
        @include add-mfrm-icon($icon-star);
        color: $color-rating-primary;
        font-size: ($icon-size-s - 2);
    }

    &__half-star {
        @include add-mfrm-icon($icon-star);
        @include add-mfrm-icon($icon-star-half, after);
        color: $color-rating-secondary;
        position: relative;
        font-size: ($icon-size-s - 2);

        &:after {
            color: $color-rating-primary;
            position: absolute;
            left: -3px;
            top: 0;
        }
    }

    &__empty-star {
        @include add-mfrm-icon($icon-star);
        color: $color-rating-secondary;
        font-size: ($icon-size-s - 2);
    }

    &__star,
    &__half-star,
    &__empty-star {
        margin-right: 3px;
    }

    &__count {
        @include font-content($font-size-l, normal, 24px);
        color: $color-rating-primary;
        text-align: right;
        margin-left: 2px;
    }
    &__group {
        display: inline-block;
        vertical-align: middle;
    }
}
