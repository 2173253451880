//==============================================================================
// SEARCH RESULTS
//==============================================================================
.searchpage {
  .ms-search-result-container {
    &__title {
      @include font-content($font-size-l, 400, 1.38);
      color: $color-text-primary;
    }
    &__refine-product-section {
      margin: 10px 0 30px;
      @include breakpoint(lg) {
        margin: 0 0 30px;
      }
    }
    .msc-choice-summary {
      &__selection {
        margin: -10px 0 20px;
        @include breakpoint(lg) {
          margin: -20px 0 30px;
        }
      }
    }
  }
  .ms-search-result {
    &__search-page-title {
      display: inline-block;
      @include font-content($font-size-xxl, 700, 1.14);
      color: $color-text-primary;
      @include breakpoint(md) {
        @include font-content($font-size-xxxl, 700, 1.14);
      }
    }
    &__collection-title-text {
      @include font-content($font-size-l, 400, normal);
      @include breakpoint(lg) {
        @include font-content($font-size-xl, 400, 1.42);
      }
    }
    &__result-text {
      @include font-content($font-size-l, 400, normal);
    }
    &__collection-title-count {
      text-transform: capitalize;
      @include font-content($font-size-s, 400, normal);
      @include breakpoint(md) {
        @include font-content($font-size-m, 400, 1.42);
      }
      @include breakpoint(lg) {
        @include font-content($font-size-xl, 400, 1.37);
      }
    }
  }
}
.ms-search-result-container {
  &__search-results {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }
  &__pagination {
    margin: 0 auto;
  }
  &__title {
    width: fit-content;
    min-height: 31px;
    @include breakpoint(lg) {
      min-height: 43px;
    }
  }
}

.ms-search-result {
  &__collection-title-prefix {
    display: inline-block;
    vertical-align: bottom;
    padding-top: 7px;
    @include breakpoint(lg) {
      @include font-content($font-size-xl, 400, 1.37);
    }
  }
  &__collection-title-text {
    @include font-content($font-size-xxxl, bold, 1.14);
    display: inline;
    color: $color-text-primary;
    @include breakpoint(lg) {
      @include font-content($font-size-xxxxl, bold, 1.22);
    }
  }
 &__incorrect{
     margin-bottom: 8px;
     @include breakpoint(lg) {
      margin-bottom: 5px;
     @include font-content($font-size-xl,normal, 1.22);
    }
    &-result{
      @include font-content($font-size-l, 700, 1.37);
      @include breakpoint(lg) {
        @include font-content($font-size-xl, 700, 1.22);
      }
     }
 }
 &__correct{
  @include font-content($font-size-l, normal, 1.42);
   &-result{
      text-transform: capitalize;
    }
 }
 
 &__result-text {
    @include font-content($font-size-m, normal, 1.43);
    margin-left: 10px;
    display: inline-block;
    color: $color-text-tertiary;
    @include breakpoint(lg) {
      @include font-content($font-size-l, normal, 1.37);
    }
  }
}

//==============================================================================
// NO SEARCH RESULTS BLOCK
//==============================================================================
//TODO for Search Tips

// .ms-search-result-container {
//
//   &__no-results-message {
//     font-family: $font-family-primary;
//     font-weight: 400;
//     font-size: $font-size-m;
//     h1,
//     h2,
//     h3,
//     h4,
//     h5,
//     h6 {
//       color: $color-text-primary;
//       margin-bottom: 0;
//       font-size: $font-size-m;
//       font-weight: 500;
//       line-height: 1.4;
//     }

//     ul {
//       padding: 0;
//       margin: 0;
//       li {
//         line-height: 1.4;
//       }
//     }
//   }
// }

//==============================================================================
// NO SEARCH RESULTS - BY SEARCH FIELD **TODO Search tips
//==============================================================================
.ms-search-result-container {
  &__nsrp-error {
    @include full-width;
    padding-left: ($grid-gutter-width-mobile + 10);
    padding-right: ($grid-gutter-width-mobile + 10);
    border-bottom: 10px solid $color-border-plp-secondary;
    @include breakpoint(md) {
      padding-left: ($grid-gutter-width-tab + 10);
      padding-right: ($grid-gutter-width-tab + 10);
    }
    @include breakpoint(xl) {
      padding-left: $grid-gutter-width-desktop;
      padding-right: $grid-gutter-width-desktop;
    }
    &-container {
      max-width: $page-width-max;
      margin: 0 auto;
      display: flex;
      flex: 1;
      flex-flow: column wrap;
      justify-content: space-between;
      @include breakpoint(md) {
        flex-flow: row wrap;
      }
    }
    //Error Message Block
    .ms-search-result-container {
      &__error {
        margin-top: 30px;
        flex: 1;
        align-self: center;
        @include breakpoint(md) {
          margin-top: 0;
          margin-right: 95px;
        }
      }
      &__error-message {
        @include font-content($font-size-xl, normal, 1.3);
        margin-bottom: 25px;
        color: $color-text-primary;
        @include breakpoint(md) {
          margin-bottom: 15px;
        }
        span {
          font-weight: bold;
        }
      }
      &__no-results-message {
        @include font-content($line-height: 1.43);
        color: $color-text-primary;
      }
    }
  }
}

// ------------------------------------------
// NO SEARCH RESULTS - NEED ASSISTANCE BLOCK
// ------------------------------------------

.ms-search-result-container__help-block {
  max-width: 300px;
  padding: 0;
  margin: 0 auto;
  @include breakpoint(md) {
    margin: auto 0;
    padding: 30px 0;
  }
  .ms-content-block {
    &__image {
      display: none;
    }
    &__details {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      padding: 30px 30px 30px;
      @include breakpoint(md) {
        padding: 30px;
        border: 1px solid $color-border-plp-primary;
        border-radius: 5px;
      }
      .ms-content-block__details {
        padding: 0;
        display: block;
        border: 0;
      }
    }
    &__title {
      @include font-content($font-size-xl, bold, 1.2);
      margin-bottom: 15px;
      text-align: center;
      color: $color-text-primary;
    }
    &__text {
      order: 1;
      text-align: center;
    }
    &__sub-title {
      @include font-content($font-size-m, 500, 1.43);
      margin-bottom: 5px;
      color: $color-black;
    }
    &__time {
      @include font-content($font-size-m, normal, 1.71);
      color: $color-text-primary;
    }
    &__cta {
      display: flex;
      a {
        @include btn(primary, large);
        @include font-content($font-size-l, bold, 0.75);
        min-width: 174px;
        margin-bottom: 15px;
        padding: 15px;
      }
    }
  }
}

//==============================================================================
// NO SEARCH RESULTS - BY REFINERS
//==============================================================================
.ms-search-result-container {
  &__category-error {
    @include full-width;
    padding-left: $grid-gutter-width-mobile;
    padding-right: $grid-gutter-width-mobile;
    margin-bottom: 30px;
    border-bottom: 10px solid $color-border-plp-secondary;
    flex: 1;
    align-self: flex-start;
    @include breakpoint(md) {
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;
    }
    @include breakpoint(xl) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
    .ms-search-result-container {
      &__error {
        margin-bottom: 30px;
      }
      &__error-message {
        @include font-content($font-size-xl, 400, 1.3);
        margin-bottom: 5px;
        color: $color-text-primary;
      }
      &__no-results-message {
        @include font-content($font-size-m, 400, 1.43);
        @include breakpoint(lg) {
          @include font-content($font-size-l, 400, 1.375);
        }
      }
      &__clear-all button {
        @include btn(primary, large);
      }
    }
  }
  //Product Recommendation Carousel override
  &__no-search-result-container {
    .product-recommendation-carousel.ms-product-collection {
      margin-top: 0;
      @include breakpoint(md) {
        max-width: 60.5vw;
        margin-left: auto;
        margin-right: auto;
      }
      @include breakpoint(lg) {
        max-width: 70.5vw;
      }
      @include breakpoint(xl) {
        max-width: 830px;
        margin-top: 30px;
      }
      @include breakpoint(xxl) {
        max-width: 890px;
      }
      .ms-product-collection__item {
        margin-right: 21px;
      }
    }
    //Mattress Matcher Banner override
    .mfrm-mm-banner img {
      @include breakpoint(lg) {
        height: 100%;
      }
    }
  }
}

// END - NO SEARCH RESULTS
//==============================================================================

//==============================================================================
// Search Result Refiner Loader
//==============================================================================

.ms-search-result-container {
  &__screen-loader {
    @include clearfix();
    @include animation-transition(fadedInMore, $duration: 0.75s);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2045;
    background-color: $color-white;
    img{
      height: 300px;
      margin: auto;
    }
    @include breakpoint(md) {
      top: -80px;
      padding-top: 80px;
    }
    .msc-waiting-circular {
      position: sticky;
      top: 50%;
      bottom: 50%;
      width: 100px;
      height: 100px;
      margin: auto;
      display: flex;
      border-width: 5px;
    }
  }
}
