.mfrm-helps-for-sleep-disruptors-container{
  margin-left: 40px;
  @include breakpoint(lg){
    padding-left: 32px;
  }
   
    .helps-with{
      width: 244.5px;
       &__heading{
        @include font-content($font-size-l, 700, 2.44);
        margin-bottom: 7px;
      }
      &__display-text{
        @include font-content($font-size-l, 400, 24px);
        display: flex;
        align-items: center;
        div{
          flex-basis: 24px;
          flex-grow: 0;
          flex-shrink: 0;
          margin-right: 5px;
          display: flex;
          justify-content: center;
        }
        margin-bottom: 18px;
      }
      &__display-about{
        margin-bottom: 25px;
      }
      &__display-about-link{
        display: flex;
        align-items: center;
        .helps-with__link-text{
          vertical-align: bottom;
          white-space: nowrap;
          @include font-content($font-size-l, 500, $font-size-l);
        }
        .helps-with__link-caret{
          margin-left: 10px;
        }
      }
    }
}