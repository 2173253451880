.mfrm-product-details {
    .mfrm-accordion {
      @media (hover: hover) {
        [class^="accordionItem-module_accordionItem"]:hover,
        [class*=" accordionItem-module_accordionItem"]:hover {
          /*or line*/
          background-color: $color-alabaster;
        }
      }
      [class^="collapse-module"],
      [class*=" collapse-module"] {
        padding: 0;
      }
      [class^="accordionItem-module_title"],
      [class*=" accordionItem-module_title"] {
        font-size: 16px;
        @media (min-width: $breakpoint-xl) {
          font-size: 28px;
        }
      }
    }
  }

  .mfrm-product-details__specifications {
    @media (min-width: 1200px) {
      display: flex;
    }
    .features {
      flex-basis: 657px;
      margin-right: 32px;
    }
    .breakdown {
      flex-basis: 511px;
      flex-shrink: 0;
    }
    h1 {
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      margin: 32px 0;
    }
    h2 {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      margin-bottom: 32px;
    }
    li {
      display: inline-flex;
      width: 84px;
      text-align: center;
      text-transform: uppercase;
      flex-direction: column;
      align-items: center;
      margin-bottom: 32px;
      margin-right: 32px;
    }
    dl {
      border-radius: 4px;
      border: 1px solid #e1e1e1;
    }
    dl > :not(:last-of-type) {
      border-bottom: 1px solid #e1e1e1;
    }
    div.row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
    }
    dt {
      background: #f8f8f8;
      flex: 0 0 124px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (min-width: 576px) {
      dt {
        flex-basis: 189px;
      }
    }
    dt,
    div.dd {
      padding: 18px;
    }
    div.dd {
      flex: 1 1;
      overflow: hidden;
    }
    dd:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
