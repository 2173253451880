$wmap-notify-bg-color: #fff8df;
$wmap-order-total-border-color: #dde3e8;
.mfrm-wmap__thankyou {
    &__header.print-only {
        display: none;
    }
    // Notification
    &__notify {
        margin-bottom: 30px;
        padding: 10px;
        display: flex;
        align-items: center;
        background-color: $wmap-notify-bg-color;
        overflow: hidden;
        color: $color-text-primary;
        &__icon {
            @include add-mfrm-icon($icon-time-delivery-truck, before);
            margin-right: 10px;
            &::before {
                color: $color-text-primary;
                font-size: ($icon-size-l + 8);
            }
        }
        &__outstanding-bal,
        &__info-text {
            @include font-content($font-size-l, normal, 1.19);
        }
        &__outstanding-bal {
            span {
                font-weight: bold;
            }
        }
        &__info-text {
            a {
                @include font-content();
                @include vfi-visible($color-black);
                color: $color-text-secondary;
                text-decoration: underline;
            }
        }
    }
    // Payment Details
    &__payment {
        &__info,
        &__address {
            &__heading {
                @include font-content($font-size-s, 600, 3.08);
                margin-top: 20px;
                color: $color-text-tertiary;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
            &__text {
                @include font-content($font-size-l, normal, 1.19);
                margin-top: 5px;
                margin-bottom: 5px;
                color: $color-text-primary;
            }
        }
    }
    // Order Summary
    &__order-summary {
        margin-top: 40px;
        margin-bottom: 30px;
    }
    &__order-total,
    &__prev-bal,
    &__amt-paid,
    &__out-bal {
        display: flex;
        justify-content: space-between;
        &__label,
        &__price {
            @include font-content($font-size-l, normal, 1.63);
        }
    }
    &__order-total,
    &__prev-bal,
    &__amt-paid {
        &__label,
        &__price {
            color: $color-text-primary;
        }
    }
    &__out-bal {
        &__label,
        &__price {
            color: $color-text-secondary;
        }
    }
    &__order-total {
        margin-bottom: 10px;
        padding: 10px 0;
        border-bottom: 1px solid $wmap-order-total-border-color;
        &__label {
            text-transform: uppercase;
        }
    }
    &__order-number {
        @include font-content($font-size-l, normal, 1.63);
        color: $color-text-primary;
        margin-bottom: 20px;
    }
    // Bottom Section
    &__b-section {
        &__cta {
            margin-top: 20px;
            text-align: center;
        }
        &__cta-button {
            @include btn(primary, large);
            text-transform: uppercase;
            &.disabled,
            &:disabled {
                touch-action: none !important;
                user-select: none !important;
                pointer-events: none !important;
                cursor: default !important;
            }
        }
        &__disclaimer {
            @include font-content($font-size-l, normal, 1.63);
            margin-top: 15px;
            margin-bottom: 50px;
            color: $color-text-primary;
            text-align: center;
            font-style: italic;
        }
    }
}

.mfrm-wmap__head-title.thankyou {
    max-width: 83%;
}

.mfrm-wmap__head-title.sub-text {
    margin-top: 10px;
    display: inline-block;
    @include breakpoint(lg) {
        margin: 0;
    }
}
