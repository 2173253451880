$msv-order-summary-items-border-radius: 4px;
$msv-order-summary-label-line-height: 20px;
$msv-order-summary-line-padding: 5px 0;
$order-summary-stepper-pos: 70px;

.msc-order-summary {
  &-wrapper {
    min-width: 100%;
    align-items: center;
    display: flex;
    padding: 75px 15px 45px;
    margin: 0 -15px;
    flex-direction: column;
    align-self: flex-start;
    background: $color-bg-order-summary;
    @include breakpoint(md) {
      margin: 0 -30px;
      padding: 30px;
      max-width: inherit;
    }
    @include breakpoint(lg) {
      margin: 48px 0 30px;
      padding: 30px 17.5px;
      background-color: $color-bg-cart-primary;
      // Checkout Override
      .ms-checkout & {
        padding: 15px 17.5px 30px;
      }
    }
    .msc-cart__btn-backtoshopping {
      margin-top: 30px;
    }
    &.__payment {
      padding: 30px 15px 80px;
      @include breakpoint(lg) {
        padding: 15px 17.5px 30px;
        margin: 30px 0 30px;
      }
      .msc-order-summary__items {
        margin-bottom: 60px;
        &.ms-checkout-express-paypal__order-summary-section {
            margin-bottom: 0;
            @include breakpoint(lg) {
              margin-bottom: 15px;
            }
          }
        @include breakpoint(lg) {
          margin-bottom: 15px;
        }
      }
      &.ms-checkout-express-paypal__order-summary-wrapper {
        padding-top: 15px;
      }
    }
    &.__cinfo,
    &.__delivery {
      @include breakpoint(lg) {
        margin: 30px 0 30px;
      }
      .msc-order-summary__heading,
      .msc-order-summary__items {
        display: none;
        @include breakpoint(lg) {
          display: block;
        }
      }
      &.ms-checkout-express-paypal__order-summary-wrapper {
        padding-top: 15px;

        .msc-order-summary__heading {
          display: block;
          }
        .msc-order-summary__items {
          display: block;
          margin-bottom: 0;

        @include breakpoint(lg) {
          margin-bottom: 15px;
          }
        }
      }
    }
    &-spacer {
      display: none;
      @include breakpoint(lg) {
        padding-top: $order-summary-stepper-pos;
        display: block;
      }
    }
    &-parent {
      max-width: $breakpoint-md;
      @include breakpoint(md) {
        max-width: inherit;
      }
      @include breakpoint(lg) {
        position: sticky;
        top: calc(var(--initialTopbarSize) + 70px);
        margin-bottom: -$order-summary-stepper-pos;
        flex: 0 0 31.667%;
        max-width: 31.667%;
        align-self: flex-start;
        margin-bottom: 0px;
        .mfrm-stepper {
          margin-top: 30px;
        }
        .mfrm-stepper + .msc-order-summary-wrapper-spacer {
          padding-top: 0;
        }
      }
    }
  }
  &__line-item {
    @include vfi-tab($color-black);
  }

  @include breakpoint(max-width, xs) {
    margin: unset;
  }

  &__heading {
    display: none;
    @include breakpoint(lg) {
      @include font-content($font-size-l, bold, 1.25);
      display: block;
      margin-bottom: 15px;
      width: 100%;
      color: $color-text-primary;
    }
    &-wrapper {
      @include vfi-tab($color-black);
      align-self: flex-start;
    }
  }

  &__items {
    border-radius: $msv-order-summary-items-border-radius;
    width: 100%;
    margin-bottom: 15px;
    background: $color-white;
    padding: 15px;
    @include breakpoint(md) {
      padding: 15px 30px;
    }
    @include breakpoint(xl) {
      padding: 15px;
    }
    .msc-promo-code {
      &__group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 10px;
        @include breakpoint(sm) {
          flex-direction: row;
        }
      }

      &__input-box {
        flex: auto;
        margin-right: 20px;
        padding-left: 8px;
        width: 100px;
        @include breakpoint(md) {
          width: auto;
        }
      }
    }
    &.progressive {
      padding-bottom: 25px !important;
    }
  }

  &__label {
    @include vfi-tab($color-black);
    text-transform: capitalize;
    &-zipcode {
      @include vfi-tab($color-black);
    }
  }

  &__line {
    &-net-price,
    &-tax-amount,
    &-shipping,
    &-loyalty,
    &-gift-card,
    &-total-discounts,
    &-sub-total,
    &-recycling-fee {
      @include font-content($font-size-m, normal, 1.43);
      position: relative;
      padding-bottom: 15px;
      clear: both;
      .sr-only {
        @include vfi-sronly();
        width: 100% !important;
        height: auto !important;
      }
    }

    &-tax-amount-zipcode {
      position: relative;
      text-decoration: underline;
      cursor: pointer;
      .sr-only {
        @include vfi-sronly();
        width: 100% !important;
        height: auto !important;
      }
      &:hover {
        color: $color-text-secondary;
      }
    }

    &-total {
      @include font-content($font-size-l, bold, 1.25);
      position: relative;
      padding: 15px 0 0;
      border-top: 1px solid $color-border-choice-summary-primary;
      .msc-order-summary__value .msc-price__actual {
        @include font-content($font-size-l, bold, 1.25);
        color: $color-text-primary;
      }
      .sr-only {
        @include vfi-sronly();
        width: 100% !important;
        height: auto !important;
        bottom: 0;
      }
    }
  }

  &__value {
    float: right;
    text-align: right;
    .msc-price__actual {
      @include font-content($font-size-m, normal, 1.43);
      color: $color-text-primary;
    }
  }
  &__discounted-amount {
    position: relative;
    color: $color-text-secondary;
    .sr-only {
      @include vfi($color-black);
      top: 10px;
      left: 5px;
    }
  }
  &__progressive-heading {
    @include font-content($font-size-m, bold, 1.43);
    margin: 11px 0 16px;
    @include vfi-visible($color-black);
  }
  &__lease-total {
    @include font-content($font-size-m, bold, 1.43);
    display: flex;
    justify-content: space-between;
  }
  &__lease-to-own {
    @include font-content($font-size-m, bold, 1.43);
    display: flex;
    justify-content: space-between;
  }
  &__lease-description {
    @include font-content($font-size-s, 400, 1.33);
    @include vfi-visible($color-black);
  }
  &__progressive-item {
    border-top: 1px solid $color-border-checkout-secondary;
    padding-top: 15px;
    position: relative;
    .sr-only {
      @include vfi-sronly();
      width: 100% !important;
      height: auto !important;
    }
  }
  &__lease-to-own-wrapper {
    padding: 15px 0;
    position: relative;
    border-top: 1px solid $color-border-checkout-secondary;
    .sr-only {
      @include vfi-sronly();
      width: 100% !important;
      height: auto !important;
    }
  }
}
