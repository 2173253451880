//all set for min-width so it will be this breakpoint and up
$breakpoint-sm: 320px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$five-product-breakpoint: 1340px;

$icon-hover: #F8F8F8;
$color-grayscale: #E1E1E1;
$color-charcoal: #2D2926;
$color-sleep-blue: #0B1232;
$color-light-blue: #F2F5FF;
$color-punch: #D63426;
$color-white: #FFFFFF;

@mixin product-menu-bar-text {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

@mixin ribbon-text {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $color-sleep-blue;
}

@mixin border-botttom {
    border-bottom: 1px solid $color-grayscale;
}

@mixin icon-wrapper {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
}

@mixin icon {
    width: 30px;
    height: 30px;
    flex: auto;
    text-align: center;
}

@mixin icon-states {
    &:hover {
        color: $color-charcoal;
        text-decoration: none;
        background-color: $icon-hover;
        cursor: pointer;
    }

    &:focus-visible {
        background: $icon-hover;
        box-shadow: 0px 0px 0px 3px $color-white, 0px 0px 0px 5px $color-charcoal;
        border-radius: 100px;
    }

    &:active {
        background: $color-grayscale;
        border-radius: 100px;
    }
}

@mixin link-states {
    &:hover {
        color: $color-charcoal;
        text-decoration: underline;
        cursor: pointer;
    }

    &:active {
        color: $color-charcoal;
        text-decoration: underline;
    }

    &:focus-visible {
        box-shadow: 0px 0px 0px 3px $color-white, 0px 0px 0px 5px $color-charcoal;
    }
}

.ms-promo-banner__link {
    padding-right: 5px;

    &:focus {
        border: 1px solid black;
        box-shadow: none;
    }
}

.search-modal__close-button {
    line-height: 0;

    &:hover {
        text-decoration: none !important;
    }
}


.logo-icon-img {
    width: 210px;
    height: 28.8px;
}

.mfrm-header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1440px;
    margin: auto;
    margin-bottom: 0;
    &-ribbon-wrapper {
        width: 100%;
        height: 40px;
        background: $color-light-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        @include ribbon-text;
    }

    &__wrapper {
        width: 100%;
        height: 124px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        margin-right: 10px;

        @include breakpoint(md) {
            height: 148px
        }

        @include breakpoint(lg) {
            height: 88px;
            flex-wrap: nowrap;
            padding: 0;
            margin-right: 22px;
            margin-left: 22px;
        }

        @include breakpoint(xl) {
            padding: 0;
            margin-right: 110px;
            margin-left: 35px;
            height: 88px;
        }

        & .hamburger-menu {
            &__wrapper {
                display: flex;
                align-items: center;
                @include icon-wrapper;
                order: 1;

                &:hover {
                    color: $color-charcoal;
                    text-decoration: none;
                    background-color: $icon-hover;
                    cursor: pointer;
                }

                &:active {
                    background: $color-grayscale;
                    border-radius: 100px;
                }

                @include breakpoint(lg) {
                    margin-right: 38px;
                    margin-left: 0;
                    margin-top: -5px;
                    border-radius: 100%;
                }
            }

            &__btn {
                @include icon;
                cursor: pointer;
                color: $color-charcoal;

                &:focus-visible {
                    background: $icon-hover;
                    box-shadow: 0px 0px 0px 3px $color-white, 0px 0px 0px 5px $color-charcoal;
                }

                & svg {
                    vertical-align: middle;

                    @include breakpoint(lg) {
                        vertical-align: bottom;
                    }
                }

                &-text {
                    display: none;

                    @include breakpoint(xl) {
                        display: block;
                        line-height: 16px;
                        margin-top: 14px;
                        font-size: 12px;
                        color: $color-charcoal;
                    }
                }
            }
        }

        & .mfrm-logo {
            height: 64px;
            order: 3;
            @include link-states();

            @include breakpoint(sm) {
                width: 210px;
            }

            img {
                width: 160px;
                height: 24.5px;

                @include breakpoint(sm) {
                    width: 175px;
                }
            }

            @include breakpoint(md) {
                margin-left: 20%;
                margin-right: 20%;

            }

            @include breakpoint(lg) {
                margin: 0;
                order: 2;

                img {
                    height: 29px;
                    width: 210px;
                }
            }
        }

        & .mfrm-search__wrapper {
            width: 80%;
            height: 60px;
            flex: auto;
            text-align: center;
            margin: 0 12px 16px 12px;
            text-align: center;
            order: 5;
            @include border-botttom();
            @include breakpoint(md){
              margin: 0 12px;
            }
            @include breakpoint(lg) {
                width: 30%;
                margin: 24px 79px auto 36px;
                border-bottom: none;
                order: 3;
            }

            @include breakpoint(xl) {
                margin-right: 58px;
            }

            .search-modal__close-button {
                @include link-states();
            }

            .search-result-item.autosuggest-link {
                &:hover {
                    color: inherit;
                }

                p {
                    @include link-states();
                }
            }

            .card-link {
                @include link-states();

                &:focus {
                    border-radius: 5px;
                }
            }
        }

        .ms-search.mfrm-search {
            width: 99%;
            margin-left: 0;
            height: 48px;
        }

        .utility-icons {
            display: flex;
            width: 90px;
            justify-content: space-between;
            align-items: center;
            order: 4;

            @include breakpoint(md) {
                width: 115px;
                justify-content: space-evenly;
            }

            @include breakpoint (lg) {
                margin-top: 5px;
                width: 265px;
                justify-content: space-between;
            }

            & [data-type="STORES"] {
                display: none;

                @include breakpoint(lg) {
                    display: block;
                }
            }
        }

        @media  (max-width:$breakpoint-lg) {
            .mobile-hide {
                display: none;
            }
            .mobile-show {
                display: block;
            }
        }
        
        @media (min-width:1025px)  {
            .desktop-hide {
                display: none;
            }
            .desktop-show {
                display: block;
            }
        }
    
        .icon-desktop-hide {
            display: block;

            @include breakpoint(lg) {
                display: none;
            }
        }

        .icon-mobile-hide {
            display: none;

            @include breakpoint(lg) {
                display: flex;
                font-family: "Rubik" !important;
                align-self: center;
                align-items: center;
                justify-content: center;
                padding-bottom: 1rem;
                width: auto ;
                font-weight: 700;
                white-space: pre;

            }
        }

        .icon-track-hide {
            display: none;

            @include breakpoint(lg) {
                display: block;
            }
        }

        & .utility-icons--before-logo {
            display: block;
            margin-top: -5px;
            margin-left: -5px;
            order: 2;

            @include breakpoint(lg) {
                display: none;
            }

            & [data-type="STORES"] {
                display: block;
            }
        }

        .utility-icon {
            @include icon-states();
            height: 40px;
            width: 40px;
            border-radius: 100%;

            &:hover {
                background: #F8F8F8;
            }

            &__link {
                display: inline-block;
                text-decoration: none;
                &.store{
                    height: 40px;
                    width: 40px;
                    background: transparent;
                    border:none;
                    padding:0;
                }
            }

            &.utility-icon--mobile-hide {
                display: none;

                @include breakpoint(sm) {
                    display: block;
                }
            }

            &__icon {
                position: relative;
                left: 8px;
                top: 8px;
                &.store-icon{
                    top: 0;
                }
                &.phone-no{
                  left: 0;
              }
            }

            &.utility-icon--mobile-hide {
                display: none;
            }

            &__store-locator {
                @include icon-wrapper;
                margin-right: 0;
                border-radius: 100%;
                color: $color-charcoal;
                @include icon-states();

                &-icon {
                    position: relative;
                    left: 8px;
                }

                @include breakpoint(sm) {
                    margin-right: 5px;
                }

                @include breakpoint(lg) {

                    margin-right: 16px;
                }
            }

            &__track-my-order {
                @include icon-wrapper;
                display: none;
                @include icon-states();

                @include breakpoint(lg) {
                    display: flex;
                    border-radius: 100%;
                    margin-right: 16px;
                }

                &__icon {
                    position: relative;
                    top: 0;
                    left: 9px;
                    text-decoration: none;

                    &.disabled {
                        pointer-events: none
                    }
                }
            }

            &__user-profile {
                @include icon-wrapper;
                margin-right: 0;
                border-radius: 100%;
                @include icon-states();

                @include breakpoint(sm) {
                    margin-right: 5px;
                }

                @include breakpoint(lg) {
                    margin-right: 16px;
                }

                &__icon {
                    position: relative;
                    top: 0;
                    left: 9px;
                    text-decoration: none;

                    &.disabled {
                        pointer-events: none
                    }
                }

            }

            &__cart-icon {
                @include icon-wrapper;
                margin-right: 0;
                border-radius: 100%;
                color: $color-charcoal;
                @include icon-states();

                @include breakpoint(sm) {
                    margin-right: 5px;
                }

                @include breakpoint(lg) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    &__menu-bar-wrapper {
        display: none;

        @include breakpoint(lg) {
            width: 100%;
            height: 46px;
            padding: 0;
            margin-right: 35px;
            margin-left: 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include border-botttom();

            & .menu-bar {
                width: 80%;
                display: flex;
                justify-content: start;
                align-items: center;
                padding-top: 10px;
                padding-bottom: 16px;

                & .menu-bar-link {
                    @include product-menu-bar-text;
                    color: $color-charcoal;
                    margin-right: 30px;
                    @include link-states();

                    &:last-child {
                        margin-right: 0;
                    }

                    @include breakpoint(lg) {
                        white-space: nowrap;
                    }

                    @media (min-width: $breakpoint-lg) and (max-width: 1199px) {
                        &:nth-child(n+6) {
                            display: none;
                        }
                    }
                }
            }

            & .menu-bar__prominent-links-wrapper {
                display: flex;
                width: 20%;
                justify-content: flex-end;
                padding-top: 10px;
                padding-bottom: 17px;

                & .menu-bar__prominent-links {
                    & .menu-bar__prominent-link {
                        @include product-menu-bar-text;
                        line-height: 17px;
                        @include link-states();
                        color: $color-punch;

                        &:hover {
                            color: $color-punch !important;
                        }
                    }
                }
            }
        }

        @include breakpoint(xl) {
            margin-right: 100px;
            margin-left: 100px;
        }

        @include breakpoint(xxl) {
            margin-right: 120px;
            margin-left: 114px;
        }

        @include breakpoint($five-product-breakpoint) {
            & .menu-bar {
                width: 85%;
            }

            & .menu-bar__prominent-links-wrapper {
                width: 15%;
            }
        }
    }
}