/**
 * Typography
 * =================================
 * - Base
 * - Paragraphs
 * - Headings
 * - Type sizes
 * - Misc
 * - Utilities
 *
 */

a,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
span,
sup {
  -webkit-font-smoothing: antialiased;
}

h1,
.h1 {
  font-size: $font-size-xxxxl;

  @include breakpoint(lg) {
    font-size: $h1-font-size;
  }
}

h2,
.h2 {
  font-size: $font-size-xxxl;
  line-height: 32px;
  @include breakpoint(lg) {
    // no variable used bc it doesnt exist and lives in between xxxl and xxxxl.
    font-size: 32px;
    line-height: 36px;
  }
}

.d5-h2 {

  // no variable used bc it doesnt exist and lives in between l and xl.
  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0px;
    font-style: normal;
  }

  @include breakpoint(lg) {

    // no variable used bc it doesnt exist and lives in between xxxl and xxxxl.
    h2 {
      font-size: 32px;
      line-height: 36px;
    }
  }

}