.msc-order-view-summary {
    border-bottom: 1px solid $color-border-cart-secondary;
    &__item {
        position: relative;
        .sr-only {
            @include vfi-sronly();
            width: 100% !important;
            height: 60% !important;
        }
    }
    &__item-wrapper {
        &-tax {
            position: relative;
        }
    }
    &__value {
        float: right;
        text-align: right;
    }
    &__label {
        @include font-content($font-size-m, 400, 1.375);
        color: $color-text-primary;
        padding-bottom: 15px;
    }
    &__discounted-amount {
        color: $color-text-secondary;
        .sr-only {
            top: 10px;
            left: 5px;
        }
    }
    &__tax-button {
        @include vfi-tab($color-black);
        text-decoration: underline;
    }
}

.msc-order-view-total {
    position: relative;
    margin-top: 15px;
    .sr-only {
        @include vfi-sronly();
        width: 100% !important;
        height: 100% !important;
    }
    &__value {
    @include vfi-tab($color-black);
        float: right;
        text-align: right;
    }
    &__label {
        @include font-content($font-size-l, 700, 1.375);
        position: relative;
        color: $color-text-primary;
    }
}
