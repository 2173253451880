// ------------------ ONLY PRINT MEDIA ------------------ //
// Set Page size to A4
@page {
    size: A4;
    //margin: 30px auto;
}
@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
    }
    // using '.mfrm-wmap-page' body tag class name. Must be added in site builder!
    .mfrm-wmap-page {
        .ms-header__primary__container,
        .ms-header__secondary,
        .ms-header__logo__secondary,
        .rotate-promo-banner,
        .mfrm-online-pay__customer-service,
        .mfrm-wmap__head,
        .embeddedServiceHelpButton,
        .product-recommendations-container,
        footer {
            display: none !important;
            width: 0;
            height: 0;
        }
        .mfrm-online-pay > .row {
            display: block;
        }
        .ms-header__logo {
            display: block;
            img {
                width: 300px;
                max-height: none;
                max-width: none;
                filter: brightness(0) grayscale(1);
            }
        }
    }
    .mfrm-wmap__thankyou {
        &__header {
            display: none;
            &.print-only,
            &.order-summary {
                display: block;
            }
        }
        &__heading {
            font-size: 20px;
        }
        &__subheading {
            margin-top: 5px;
        }
        &__payment__info__heading,
        &__payment__address__heading {
            color: #444;
        }
        &__notify,
        &__b-section {
            display: none;
        }
    }
    .mfrm-online-pay {
        &.container {
            display: inline !important;
        }
    }
}
