  //==============================================================================
  // Buybox Product Overview
  //==============================================================================

  .product-overview{
      &__container{
          width: 100%;
          margin: 30px 0;
          text-align: left;
      }
      &__heading{
        color: $color-black;
        @include font-content($font-size-xxxl,700,32px);
        margin-bottom: 8px;
        @include breakpoint(lg){
            @include font-content(32px, 700, 36px);
        }
      }
      &__description{
         color: $color-black;
        @include font-content($font-size-l,400,24px);
        @include breakpoint(lg){
            @include font-content(22px, 400, 32px);
        }
        a{
          text-decoration: underline;
          @include font-content($font-size-l,400,24px);
          @include breakpoint(lg){
              @include font-content(22px, 400, 32px);
          }
        }
      }
  }