$badge-spacing: 5px;

.mfrm-product-badge {
    @include badge-placement(top);
    position: absolute;
    left: 0;
    max-height: 310px;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    z-index: 1;
    &__image {
        max-height: 40px;
        margin-bottom: $badge-spacing;
        margin-right: $badge-spacing;
    }
}
