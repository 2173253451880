$max-field-height: 46px;
$store-selector-input-height: 44px;
$store-selector-button-border-radius: 50px;
$store-selector-input-border-radius: 50px;
$store-selector-input-padding: 15px 25px;
$store-selector-button-height: 44px;
$store-selector-button-width: 117px;
$store-selector-set-as-preferred-store-height: 36px;
$store-selector-set-as-preferred-store-width: 162px;
$store-selector-set-as-preferred-store-padding: 10px 30px;
$store-selector-set-as-preferred-store-radius: 50px;
$store-selector-preferred-store-height: 20px;
$store-selector-preferred-store-width: 84px;
$store-selector-preferred-store-radius: 3px;
$store-selector-msc-modal-header-padding: 0 15px;
$store-selector-msc-modal-header-padding-md: 0 30px;
$store-selector-msc-modal-height: 100vh;
$store-selector-msc-modal-width: 375px;
$store-selector-msc-modal-width-md: 430px;
$store-selector-msc-modal-padding: 30px 0;
$store-selector-msc-modal-heading-margin: 0 0 18px;
$store-selector-search-padding: 21px 15px 30px;
$store-selector-search-padding-md: 21px 30px 30px;
$store-selector-location-padding: 25px 15px;
$store-selector-location-padding-md: 25px 30px;
$store-selector-covid-message-padding: 17.5px 15px;
$store-selector-covid-message-padding-md: 17.5px 30px;
$store-selector-see-all-stores-width: 231px;
$store-selector-see-all-stores-height: 106px;
$store-selector-see-all-stores-height-button: 46px;
$store-selector-see-all-stores-padding: 16px 20px;
$store-selector-see-all-stores-radius: 50px;

$store-header-gap: 45px;
$store-header-gap-md: calc(var(--initialTopbarSize) + 70px + 50px);
$store-modal-height: calc(var(--windowSize) - 45px);
$store-modal-height-md: calc((-70px - 50px) + var(--windowSize) - var(--initialTopbarSize));
$store-locator-loading-div: 250px;
$franchise-card-bg-color:#f6f6f6;
$franchise-pill-color:#505050;

.ms-store-select-dialog {
  overflow: auto;
  transform: translateZ(0);
  @include breakpoint(lg) {
    overflow-y: scroll;
  }

  &::before {
    display: none;
  }
}

.ms-store-select {
  &__locations {
    min-height: calc(var(--windowSize) - 390px);
    &.franchise{
      .ms-store-select__location {
        background-color: $franchise-card-bg-color;
      }
    }
  }

  &__location {
    &.ms-store-select__location--franchise {
      background-color: $franchise-card-bg-color
    }
  }

  &.msc-modal__dialog {
    display: block;
    white-space: normal;
    background-color: $color-bg-primary;
    margin-bottom: 0;
    margin-left: 0;
    height: 100%;
    width: 100%;
    max-width: $store-selector-msc-modal-width-md;
    min-height: $store-modal-height;
    height: $store-modal-height;
    margin-top: $store-header-gap;
    @include breakpoint(md) {
      min-height: $store-modal-height-md;
      height: $store-modal-height-md;
      width: $store-selector-msc-modal-width-md;
      margin-top: $store-header-gap-md;
    }
  }
  .msc-modal {
    &__header {
      padding: $store-selector-msc-modal-header-padding;
      text-align: left;
      @include breakpoint(md) {
        padding: $store-selector-msc-modal-header-padding-md;
      }
    }
    &__title {
      @include font-content($font-size-xl, bold, 1);
      margin: $store-selector-msc-modal-heading-margin;
    }

    &__close-button {
      top: 10.58px;
      right: 13.72px;
      font-size: 16px;
      @include breakpoint(md) {
        right: 11.72px;
      }
    }

    &__current-location {
      @include font-content($font-size-m, 400, 1);
      @include add-mfrm-icon($icon-location);
      border: 0;
      padding: 0 15px;
      position: relative;
      display: inline-block;
      text-decoration: underline;
      color: $color-text-secondary;
      cursor: pointer;
      @include breakpoint(md) {
        padding: 0 30px;
      }
      &:before {
        @include font-content($icon-size-s, 400, 1);
        margin-right: 10px;
        display: inline-block;
        vertical-align: bottom;
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
      }
    }

    &__content {
      box-shadow: none;
      border-radius: 0;
      padding-top: 30px;
    }

    &__body {
      padding: 0;
    }

    &__modal-footer {
      padding: 30px 15px;
    }
  }

  &__search {
    display: block;
    padding: $store-selector-search-padding;
    @include breakpoint(md) {
      padding: $store-selector-search-padding-md;
    }
    &-form {
      display: flex;
      flex: auto;
      flex-wrap: wrap;
      position: relative;
      .field-control {
        @extend %field-control-input-rounded;
        max-height: ($max-field-height - 2);
        width: 100%;
        margin-bottom: 10px;
        // override inline style
        box-sizing: border-box !important;
        @include breakpoint(xs) {
          max-width: 54.5%;
          margin-bottom: 0;
        }
        @include breakpoint(md) {
          max-width: 243px;
        }
      }
      .field-label {
        /* Override default extended top */
        top: calc(#{$max-field-height} / 3) !important;
        left: 5px;
      }
    }

    &-button {
      @include font-content($font-size-l, 700, 1.25);
      order: 2;
      display: flex;
      height: $store-selector-button-height;
      width: 100%;
      cursor: pointer;
      border-radius: $store-selector-button-border-radius;
      align-items: center;
      justify-content: center;
      color: $color-button-primary-text;
      background: $color-bg-quaternary;
      border: 1px solid $color-bg-quaternary;
      &:hover,
      &:active,
      &:focus-visible {
        cursor: pointer;
        background: $color-button-primary-background-hover;
      }
      @include breakpoint(xs) {
        min-width: $store-selector-button-width;
        width: $store-selector-button-width;
        max-width: $store-selector-button-width;
        margin-left: 10px;
      }
    }

    .search-btn-disabled {
      color: $color-button-disabled-text;
      border-color: $color-button-border-disabled;
      background-color: $color-button-disabled-background;
    }
  }
 &__no-locations-wrapper {
   min-height: calc(var(--windowSize) - 535px);
 }
  &__toggle-view {
    display: none;
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__covid-message {
    @include add-mfrm-icon($icon-warning);
    @include font-content($font-size-m, 400, 1.43);
    display: flex;
    padding: $store-selector-covid-message-padding;
    color: $color-text-tertiary;
    border-top: 1px solid $color-border-store-select-primary;
    align-items: center;
    &:before {
      font-size: 24px;
      margin-right: 12px;
      vertical-align: middle;
    }
    @include breakpoint(md) {
      padding: $store-selector-covid-message-padding-md;
    }
  }

  &__search-see-all-stores {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 0;
    height: $store-selector-see-all-stores-height;
    width: 100%;
    max-width: $store-selector-msc-modal-width-md;
    padding: 30px 15px;
    background: $color-bg-primary;
    border-top: 1px solid $color-border-store-select-primary;
    z-index: 1;
    @include breakpoint(md) {
      padding: 30px;
    }
  }

  &__store-hours-details-group.has-location {
    border-right: 1px solid $color-text-primary;
    margin-right: 10px;
    padding-right: 10px;
  }

  &__search-see-all-stores-button {
    @include font-content($font-size-l, 700, 1);
    @include no-decoration();
    height: $store-selector-see-all-stores-height-button;
    width: 100%;
    margin: 0 auto;
    padding: $store-selector-see-all-stores-padding;
    display: block;
    border-radius: $store-selector-see-all-stores-radius;
    color: $color-button-primary-text;
    background: $color-button-primary-background;
    text-align: center;
    text-transform: capitalize;
    transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
    outline-offset: 0;
    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      cursor: pointer;
      color: $color-button-primary-text;
      background: $color-button-primary-background-hover;
    }
    &:focus {
      color: $color-button-primary-text;
      @include breakpoint(lg){
        outline: 1px dotted $color-black;
      }
    }
    @media (min-width: $breakpoint-xxs) {
      width: $store-selector-see-all-stores-width;
    }
  }

  &__store-hours-details {
    @include font-content($font-size-m, 500, 1.21);
    &.open {
      color: $color-text-store-timing;
    }
  }

  &__found-locations {
    display: none;
    padding: 0 30px;
  }

  &__no-locations {
    padding: 30px 15px;
    border-top: 1px solid $color-border-store-select-primary;
    @include breakpoint(md) {
      padding: 30px;
    }
    > div:not(.ms-store-select__empty-placeholder) {
      display: flex;
      height: 148px;
      padding: 20px;
      border-radius: 5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $color-bg-store-locator;
    }
    p strong {
      @include font-content($font-size-xl, 700, 1.22);
      display: block;
      margin: 0 0 10px;
      text-align: center;
    }
  }

  &__empty-placeholder {
    @include empty-placeholder-animation($color-bg-store-locator);
    height: 148px;
    border-radius: 5px;
  }

  &__location {
    padding: $store-selector-location-padding;
    border-top: 1px solid $color-border-store-select-primary;
    @include breakpoint(md) {
      padding: $store-selector-location-padding-md;
    }

    &-line-item {
      color: $color-text-primary;

      &-set-as-preferred-store {
        @include font-content($font-size-m, 700, 1);
        height: $store-selector-set-as-preferred-store-height;
        min-width: $store-selector-set-as-preferred-store-width;
        padding: $store-selector-set-as-preferred-store-padding;
        border-radius: $store-selector-set-as-preferred-store-radius;
        color: $color-bg-quaternary;
        background: $color-bg-primary;
        border: 1px solid $color-bg-quaternary;
        &:hover {
          color: $color-button-primary-text;
          background: $color-button-primary-background;
        }
      }

      &-preferred-store {
        @include font-content($font-size-s, 700, 1);
        border: 0;
        height: $store-selector-preferred-store-height;
        min-width: $store-selector-preferred-store-width;
        border-radius: $store-selector-preferred-store-radius;
        color: $color-button-primary-text;
        background: $color-text-store-timing;
        &:hover {
          text-decoration: none;
        }
      }

      &-store-details {
        @include font-content($font-size-m, 400, 1.28);
        color: $color-text-secondary;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }

      &-store-hours {
        display: flex;
        margin: 0 0 10px;
        align-items: center;
        &-time {
          @include font-content($font-size-m, 500, 17px);
          color: $color-green;
          &::after{
              content: '\007C';
              @include font-content($font-size-m, 400, 16px);
              color: $color-black ;
              margin: 0 10px;
          }
        }
        &-distance {
            @include font-content($font-size-m, 400, 17px);
            color: $color-black;
        }
      }

      &-store-distance {
        @include font-content($line-height: 1.21);
        color: $color-text-primary;
      }
    }

    &-line-item-header,
    &-line-header,
    &-line-stock-status {
      margin-bottom: 10px;
      line-height: 1;
    }

    &-line-item-header{
      & .franchise-pill{
        color: white;
        background-color: $franchise-pill-color;
        padding: 5px 10px;
        border-radius: 12px;
        margin-top: -2px;
        font-size: 12px;
        float: right;
      }
    }
    &-line-item-store-name {
      @include font-content($font-size-xl, 500, 1);
    }

    &-line-item-store-index {
      display: none;
    }

    &-line-header {
      display: none;
    }

    &-line-shop-address {
      &-section {
        display: flex;
        margin: 0 0 20px;
      }
      &-text {
        @include font-content($line-height: 1.43);
        color: $color-text-primary;
        display: block;
        max-width: 222px;
      }
    }

    &-line-shop-phone {
      &-section {
        display: none;
        margin-bottom: 20px;
      }
    }

    &-line-item-btn-holder {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-line-item-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-line-item-footer--franchise {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
  &__search-in-progress {
    min-height: calc(var(--windowSize) - 287px);
    height: 140px;
    padding: 30px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(lg) {
      min-height: $store-locator-loading-div - 10px;
    }
    @include breakpoint(xxl) {
      min-height: $store-locator-loading-div + 45px;
    }
  }
}

/* Backdrop overrides */
.ms-store-select-dialog + .msc-modal__backdrop.show {
  visibility: hidden;
  @media (min-width: $store-selector-msc-modal-width-md) {
    margin-top: $store-header-gap;
    visibility: visible;
  }
  @include breakpoint(md) {
    margin-top: $store-header-gap-md;
  }
}
/* Z-Index Overrides */
.modal-store-locator-toggle .ms-header {
  &__preferred-store-container {
    z-index: 10013;
    pointer-events: all;
  }
  @include breakpoint(md) {
    &__primary,
    &__secondary {
      z-index: ($modal-z-index + 1);
      pointer-events: none;
    }
    &__topbar {
      z-index: ($modal-z-index + 2);
      pointer-events: none;
    }
  }
}
.modal-store-locator-toggle-pdp {
  .ms-store-select-dialog {
    ~ .msc-modal__backdrop,
    .msc-modal__dialog {
      min-height: var(--windowSize);
      height: var(--windowSize);
      margin-top: 0;
    }
  }
}
