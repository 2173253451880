.d5-shop-mattress-by-dropdown {
    &__btn {
        &.msc-btn {
            font-weight: 700;
        }

        padding: 16px 60px;
        @include btn(primary, large);

        @include breakpoint(lg) {
            @include btn(primary, large);
        }
    }

    &__buttoncontent {
        @include breakpoint(lg) {
            padding-left: 64px;
        }
    }

    &__heading {
        width: 226px;
        margin: 0 auto;
        color: $color-charcoal;
        text-align: center;
        padding-bottom: 32px;
        @include font-content(20px, 400, 1.5);

        @include breakpoint(lg) {
            width: 1200px;
            @include font-content(32px, 400, 2.25);
        }
    }

    &__content {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(md) {
            flex-direction: column;
        }

        @include breakpoint(lg) {
            flex-direction: row;
            align-items: normal;
        }
    }

    &__selectcontent {

        @include breakpoint(md) {
            display: flex;
            padding-bottom: 12px;
        }
        
        @include breakpoint(lg) {
            display: flex;
        }
    }

    &__label {
        color: #6a6a6a;
        @include font-content($font-size-xl, 400, 1.75);
        margin-right: 25px;
        width: 66px;
        letter-spacing: 0.2px;

        @include breakpoint(md) {
            margin-right: 20px;
            width: auto;
            padding-left: 32px;
        }

        @include breakpoint(lg) {
            @include font-content($font-size-xxl, 400, 1.75);
            padding-left: 32px;
        }
    }

    &__dropdowncontent {
        padding-bottom: 20px;
        display: flex;

        @include breakpoint(md) {
            margin-right: 15px;
        }

        @include breakpoint(lg) {
            margin-right: 25px;
        }
    }

    &__brandcontent {
        padding-bottom: 37px;

        @include breakpoint(lg) {
            padding-right: 20px;
        }
    }

    &__buttoncontent {
        padding-top: 17px;

        @include breakpoint(md) {
            padding-top: 0;
        }
    }

    &__select-container {
        @include add-mfrm-icon($icon-chev-down-slim, after);
        position: relative;
        display: flex;
        align-items: center;

        &::after {
            position: absolute;
            right: 10px;
            font-weight: bold;
            font-size: $icon-size-s;
            pointer-events: none;
        }
    }

    &__select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-bottom: 4px;
        color: $color-charcoal;
        border-width: 0 0 2px 0;
        background-color: $color-white;
        border-color: $color-border-store-select-primary;
        letter-spacing: 0.2px;
        @include font-content($font-size-xl, 400, 1.75);
        width: 225px;

        @include breakpoint(md) {
            width: 218px;
        }

        @include breakpoint(lg) {
            width: 288px;
            @include font-content($font-size-xxl, 400, 1.75);
            padding-bottom: 5px;
        }
    }
}