//==============================================================================
// MFRM 2.0 ICONS (ICOMOON CONVERTED)
//==============================================================================

$mfrm-font-path: "../../../mfrm";

@font-face {
  font-family: "icomoon";
  src: url("#{$mfrm-font-path}/icomoon.woff?rgdrdj") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e928";
}

$icon-phone: "\e928";

.icon-chat:before {
  content: "\e931";
}

$icon-chat: "\e931";

.icon-arrow-top-left:before {
  content: "\e932";
}

$icon-arrow-top-left: "\e932";

.icon-warning:before {
  content: "\e902";
}

$icon-warning: "\e902";

.icon-arrow-right:before {
  content: "\e946";
}

$icon-arrow-right: "\e946";

.icon-chev-down:before {
  content: "\e947";
}

$icon-chev-down: "\e947";

.icon-chev-up:before {
  content: "\e94e";
}

$icon-chev-up: "\e94e";

.icon-chev-left:before {
  content: "\e94f";
}

$icon-chev-left: "\e94f";

.icon-chev-right:before {
  content: "\e950";
}

$icon-chev-right: "\e950";

.icon-close:before {
  content: "\e951";
}

$icon-close: "\e951";

.icon-info:before {
  content: "\e952";
}

$icon-info: "\e952";

.icon-menu:before {
  content: "\e953";
}

$icon-menu: "\e953";

.icon-pause:before {
  content: "\e954";
}

$icon-pause: "\e954";

.icon-search:before {
  content: "\e904";
}

$icon-search: "\e904";

.icon-mattress-motion:before {
  content: "\e906";
}

$icon-mattress-motion: "\e906";

.icon-mattress-press-relief:before {
  content: "\e907";
}

$icon-mattress-press-relief: "\e907";

.icon-mattress-support:before {
  content: "\e908";
}

$icon-mattress-support: "\e908";

.icon-plant:before {
  content: "\e909";
}

$icon-plant: "\e909";

.icon-snowflake:before {
  content: "\e927";
}

$icon-snowflake: "\e927";

.icon-time-delivery-truck:before {
  content: "\e949";
}

$icon-time-delivery-truck: "\e949";

.icon-location:before {
  content: "\e903";
}

$icon-location: "\e903";

.icon-chev-left-slim:before {
  content: "\e956";
}

$icon-chev-left-slim: "\e956";

.icon-chev-down-slim:before {
  content: "\e957";
}

$icon-chev-down-slim: "\e957";

.icon-checkmark:before {
  content: "\e958";
}

$icon-checkmark: "\e958";

.icon-trash:before {
  content: "\e959";
}

$icon-trash: "\e959";

.icon-close-slim:before {
  content: "\e95a";
}

$icon-close-slim: "\e95a";

.icon-hypoallergenic:before {
  content: "\e95b";
}

$icon-hypoallergenic: "\e95b";

.icon-breathability:before {
  content: "\e95c";
}

$icon-breathability: "\e95c";

.icon-caret-down:before {
  content: "\e95d";
}

$icon-caret-down: "\e95d";

.icon-close-bold:before {
  content: "\e95f";
}

$icon-close-bold: "\e95f";

.icon-star:before {
  content: "\e960";
}

$icon-star: "\e960";

.icon-star-half:before {
  content: "\e961";
}

$icon-star-half: "\e961";

.icon-check-fill:before {
  content: "\e962";
}

$icon-check-fill: "\e962";

.icon-times-fill:before {
  content: "\e963";
}

$icon-times-fill: "\e963";

.icon-calendar:before {
  content: "\e964";
}

$icon-calendar: "\e964";

.icon-circle-step:before {
  content: "\e965";
}

$icon-circle-step: "\e965";

.icon-check-step:before {
  content: "\e966";
}

$icon-check-step: "\e966";

.icon-checkmark-outline:before {
  content: "\e967";
}

$icon-checkmark-outline: "\e967";

.icon-chat-outline:before {
  content: "\e968";
}

$icon-chat-outline: "\e968";

.icon-warning-fill:before {
  content: "\e905";
}

$icon-warning-fill: "\e905";

.icon-print:before {
  content: "\e90a";
}

$icon-print: "\e90a";

.icon-call:before {
  content: "\e90b";
}

$icon-call: "\e90b";

$icon-track-my-order: "\e96d";

$icon-location-v2: "\e96c";

$icon-search-v2: "\e904";


@mixin icon-user($color: #fff, $size: 32px, $position: right) {

  @include header-icon-styles($color, $size, $position);
  &::before {
    content: "\e94a";
    opacity: 0.3;
  }

  &::after {
    content: "\e94b";
    margin-left: -1em;
  }
}

@mixin icon-user-v2($color: #fff, $size: 25px, $position: right) {

  &::before,
  &::after {
    position: relative;
    left: 8px;
    top: 1px;
    #{$position}: 0;
    font-size: $size;
    color: $color;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }

  &::before {
    content: "\e96a";
    opacity: 1;
  }

  &::after {
    content: "\e96a";
    margin-left: -1em;
  }
}

@mixin icon-cart($color: #fff, $size: 32px, $position: right) {
  
  @include header-icon-styles($color, $size, $position);
  &::before {
    content: "\e94c";
    opacity: 0.3;
  }

  &::after {
    content: "\e94d";
  }
}

@mixin icon-cart-v2($color: #fff, $size: 25px, $position: left) {

  &::before,
  &::after {
    position: absolute;
    #{$position}: 5px;
    top: -2px;
    font-size: $size;
    color: $color;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }

  &::before {
    content: "\e96b";
    opacity: 1;
  }

  &::after {
    content: "\e96b";
  }
}

@mixin icon-paypal($size: 30px) {

  &::before,
  &::after {
    font-size: $size;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0.5;
  }

  &::before {
    content: "\e900";
    position: relative;
    top: 5px;
    font-size: ($size + 8);
    color: #113984;
  }

  &::after {
    content: "\e901";
    color: #009ee3;
  }
}

@mixin icon-location($color: #fff, $size: 32px, $position: left) {

  &::before,
  &::after {
    position: relative;
    #{$position}: 8px;
    top: 3px;
    font-size: $size;
    color: $color;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }

  &::before {
    content: "\e96c";
    opacity: 1;
  }
}

@mixin add-mfrm-icon($icon, $selector: before) {
  &:#{$selector} {
    font-family: "icomoon" !important;
    content: $icon;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }
}

@mixin track-my-order($icon, $selector: before) {
  &:#{$selector} {
    font-family: "icomoon" !important;
    content: $icon;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: relative;
    left: 8px;
    top: 3px;
  }
}

@mixin search-icon($icon, $selector: before) {
  &:#{$selector} {
    font-family: "icomoon" !important;
    content: $icon;
    speak: none;
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }
}
//==============================================================================
// HEADER ICON COMMON STYLES
//==============================================================================
@mixin header-icon-styles($color: #fff, $size: 32px, $position: right) {
  &::before,
  &::after {
    position: absolute;
    #{$position}: 0;
    font-size: $size;
    color: $color;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }
}