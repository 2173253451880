//==============================================================================
// SHIPPING METHOD
//==============================================================================
$space15: 15px;
$product-image-width-sm: 105px;
$product-image-width-lg: 160px;

.ms-checkout-delivery {
    &_head {
        margin: 30px 0 $space15;
        display: flex;
        justify-content: space-between;
        h4 {
            @include font-content($font-size-l, 700, 1.25);
            @include vfi-tab($color-black);
            margin: 0;
            text-align: left;
            @include breakpoint(lg) {
                @include font-content($font-size-xl, 700, 1.22);
            }
        }
        &.paypal-express {
            margin: $space15;
            
            @include breakpoint(md) {
                margin: $space15 0 $space15 $space15 + $space15 ;
            }
            @include breakpoint(lg) {
                margin: 30px 0 $space15;
            }
        }
        &.paypal-heading {
            margin-top: 15px;
            
            @include breakpoint(lg) {
                margin-top: 0;
            }
        }
    }
    &-container {
        position: relative;
    }
    &_content {
        border: 1px solid $color-input-border;
        border-radius: 4px;
        margin-bottom: ($space15 + 15);
        padding: $space15 $space15 0 $space15;
        &__wrapper {
            position: relative;
            &-element {
                outline-offset: -1px;
                overflow: hidden;
            }
            .sr-only {
                @include vfi-sronly();
                width: 100% !important;
                height: 100% !important;
            }
        }
        &__editLink {
            @include font-content($font-size-m, 400, 1.4);
            @include vfi-tab($color-black);
            position: absolute;
            top: 0;
            right: 0;
            text-decoration: underline;
            &.paypal-express {
                top: 35px;
                @include breakpoint(lg) {
                    top: 6px
                }
            }
        }
        &__edit-payment{
            &:hover{
                cursor: pointer;
                a {
                    color: $color-text-secondary;
                }
            }
        }
        h3 {
            @include vfi-tab($color-black);
            @include font-content($font-size-s, 700, 0.8);
            background-color: $color-bg-delivery-tag;
            border-radius: 100px;
            display: inline-block;
            margin-bottom: $space15;
            padding: 5px 10px;
            color: $color-text-primary;
        }
        h2 {
            @include vfi-tab($color-black);
            margin: 5px 0 15px;
            text-align: left;
            @include font-content($font-size-l, 700, 1.25);
            @include breakpoint(lg) {
                @include font-content($font-size-xl, 700, 1.22);
            }
        }
        &__date {
            @include vfi-tab($color-black);
            @include font-content($font-size-l, 700, 1.25);
            color: $color-text-primary;
            @include breakpoint(lg) {
                @include font-content($font-size-xl, 700, 1.22);
            }
        }
        &__delivery-date {
            @include font-content($font-size-l, 700, 1.25);
            margin: $space15 0;
            color: $color-text-primary;
            @include breakpoint(lg) {
                @include font-content($font-size-xl, 700, 1.22);
            }
        }
        &__item,
        &__main-heading-vfi {
            @include vfi-tab($color-black);
        }
        &__main-div {
            position: relative;
            .sr-only {
                @include vfi-sronly();
                width: 100% !important;
                height: 100% !important;
            }
        }
        &__message {
            @include font-content($font-size-l, 400, 1.37);
            margin-bottom: $space15;
            p {
                display: inline
            }
        }
        &__product-image {
            @include vfi($color-black);
            outline-offset: -1px;
            width: $product-image-width-sm;
            float: left;
            margin-right: 15px;
            .msc-empty_image {
                @include image-placeholder($product-image-width-sm, 94px);
                @include breakpoint(lg) {
                    @include image-placeholder($product-image-width-lg, 144px);
                }
            }
            @include breakpoint(lg) {
                width: $product-image-width-lg;
                margin-right: 30px;
            }
        }
        &__main-div-one {
            overflow: hidden;
            outline-offset: -2px;
        }
        &__list {
            margin: 0;
            padding: 0;
            > div {
                margin-bottom: 15px;
                overflow: hidden;
                @media only print {
                    break-inside: avoid;
                    padding-top: 4rem;
                }
            }
        }
        &__specs {
            padding: 0;
            overflow: hidden;
            position: relative;
            @include breakpoint(lg) {
                padding-right: 100px;
            }
        }
        &__name {
            @include font-content($font-size-s, 700, 1.25);
            margin-bottom: $space15 - 10px;
            color: $color-text-primary;
            @include vfi-tab($color-black);
            @include breakpoint(md) {
                @include font-content($font-size-m, 700, 1.42);
                max-width: 400px;
            }
            @include breakpoint(lg) {
                @include font-content($font-size-l, 700, 1.37);
                max-width: 278px;
            }
        }
        &__type {
            display: inline-block;
        }
        &__type,
        &__quantity {
            color: $color-text-tertiary;
            margin-bottom: $space15 - 10px;
            @include font-content($font-size-s, 400, 1.67);
        }
        &__quantity {
            position: relative;
            .sr-only {
                width: auto !important;
                height: auto !important;
            }
        }
        &__price {
            position: static;
            text-align: left;
            @include font-content($font-size-l, 700, 1.25);
            @include breakpoint(md) {
                @include font-content($font-size-xl, 700, 1.22);
                top: 0;
                right: 0;
                position: absolute;
                text-align: right;
            }
            &.discount {
                display: flex;
                color: $color-text-secondary;
                @include breakpoint(md) {
                    display: inline-block;
                }
                p {
                    color: $color-text-primary;
                    @include font-content($font-size-m, 700, 1.42);
                    display: inline-block;
                    span {
                        color: $color-text-tertiary;
                        margin-left: 5px;
                        text-decoration: line-through;
                        @include font-content($font-size-s, 400, 1.66);
                    }
                }
                @include breakpoint(md) {
                    @include font-content($font-size-xl, 700, 1.25);
                    p {
                        display: block;
                        label {
                            display: inline;
                        }
                        span {
                            @include font-content($font-size-m, 400, 1.42);
                        }
                    }
                }
                .msc-cart-line__product-price {
                    display: block;
                    text-align: left;
                    @include breakpoint(md) {
                        text-align: right;
                    }
                    label {
                        display: none;
                        @media print {
                            display: inline-block;
                            margin-left: 10px;
                        }
                        @include breakpoint(md) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .deliveryMsg {
            color: $color-text-primary;
            margin-top: 15px;
        }
        @include breakpoint(md) {
            margin-bottom: $space15 + 15px;
        }
        @include breakpoint(lg) {
            margin-bottom: $space15;
        }
    
        &__delivery-date-section {
            margin-bottom: 15px;
        }
    
        &__delivery-date-error {
            @include font-content($font-size-m, 400, 1.38);
            color: $color-text-secondary;
            @include breakpoint(lg) {
                @include font-content($font-size-l, 400, 1.38);
            }
        }
        
    }
    &_delivery-content {
        margin-bottom: $space15;
        h3 {
            @include font-content($font-size-s, 700, 0.8);
            background-color: $color-bg-delivery-tag;
            border-radius: 100px;
            display: inline-block;
            margin-bottom: $space15;
            padding: 5px 10px;
            color: $color-text-primary;
        }
        h2 {
            margin: 5px 0 15px;
            text-align: left;
            @include font-content($font-size-l, 700, 1.25);
            @include breakpoint(lg) {
                @include font-content($font-size-xl, 700, 1.22);
            }
        }
        &__date {
            @include font-content($font-size-l, 700, 1.25);
            margin-bottom: $space15;
            color: $color-text-primary;
            @include breakpoint(lg) {
                @include font-content($font-size-xl, 700, 1.22);
            }
        }
        &__delivery-date {
            @include font-content($font-size-l, 700, 1.25);
            @include vfi-tab($color-black);
            margin: $space15 0;
            color: $color-text-primary;
            @include breakpoint(lg) {
                @include font-content($font-size-xl, 700, 1.22);
            }
        }
        &__message {
            @include font-content($font-size-l, 400, 1.37);
            margin-bottom: $space15;
        }
        &__list {
            list-style-type: none;
            margin: 0;
            padding: 0;
            vertical-align: top;
            li {
                display: flex;
                flex-direction: row;
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            img {
                display: inline-block;
                width: 105px;
                height: 90px;
                margin-right: $space15;
                @include breakpoint(lg) {
                    width: 160px;
                    height: 135px;
                    margin-right: ($space15 + 15);
                }
            }
        }
        &__specs {
            position: relative;
            display: inline-block;
            width: 53%;
            li {
                display: block;
                margin-bottom: 0 !important;
            }
            @include breakpoint(md) {
                width: 82%;
                li {
                    display: inline-block;
                    vertical-align: top;
                    width: 75%;
                    &:last-child {
                        width: 25%;
                        text-align: right;
                    }
                }
            }
            @include breakpoint(lg) {
                width: 84%;
            }
        }
        &__name {
            @include font-content($font-size-s, 700, 1.25);
            margin-bottom: $space15 - 10px;
            color: $color-text-primary;
            @include breakpoint(md) {
                @include font-content($font-size-m, 700, 1.42);
            }
            @include breakpoint(lg) {
                @include font-content($font-size-l, 700, 1.37);
            }
        }
        &__type {
            display: inline-block;
        }
        &__type,
        &__quantity {
            color: $color-text-tertiary;
            margin-bottom: $space15 - 10px;
            @include font-content($font-size-s, 400, 1.67);
        }
        &__quantity {
            position: relative;
            .sr-only {
                width: auto !important;
                height: auto !important;
            }
        }
        &__price {
            @include font-content($font-size-l, 700, 1.25);
            @include breakpoint(md) {
                @include font-content($font-size-xl, 700, 1.22);
            }
            &.discount {
                display: flex;
                color: $color-text-secondary;
                @include breakpoint(md) {
                    display: inline-block;
                }
                p {
                    color: $color-text-primary;
                    @include font-content($font-size-m, 700, 1.42);
                    display: inline-block;
                    span {
                        color: $color-text-tertiary;
                        margin-left: 5px;
                        text-decoration: line-through;
                        @include font-content($font-size-s, 400, 1.66);
                    }
                }
                @include breakpoint(md) {
                    @include font-content($font-size-xl, 700, 1.25);
                    p {
                        display: block;
                        label {
                            display: inline;
                        }
                        span {
                            @include font-content($font-size-m, 400, 1.42);
                        }
                    }
                }
                .msc-cart-line__product-price {
                    display: block;
                    text-align: right;
                    label {
                        display: none;
                        @media print {
                            display: inline-block;
                            margin-left: 10px;
                        }
                        @include breakpoint(md) {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .deliveryMsg {
            color: $color-text-primary;
            margin-top: 15px;
        }
        @include breakpoint(md) {
            margin-bottom: $space15 + 15px;
        }
        @include breakpoint(lg) {
            margin-bottom: $space15;
        }
    }
    .ms-checkout-delivery__heading {
        @include font-content($font-size-xxl, 700, 1.17);
        color: $color-text-primary;
        margin: 0 0 15px;
        text-align: center;
        @include breakpoint(lg) {
            text-align: left;
        }
    }
        &-modal {
            &.msc-modal__dialog {
                width: 345px;
    
                @include breakpoint(md) {
                    width: 768px;
                }
            }
    
            .msc-modal {
                &__header {
                    position: relative;
                    background-color: $color-bg-primary;
                    border-radius: 5px;
    
                    .msc-modal__close-button {
                        top: 20px;
                        right: 15px;
                        width: 30px;
                        height: 30px;
                        @include add-mfrm-icon($icon-close);
                        border: 0.5px solid $color-modal-border-close-button;
                        border-radius: 100%;
                        background-color: $color-bg-primary;
                        padding-left: 6px;
    
                        &:before {
                            font-size: $font-size-s;
                        }
    
                        @include breakpoint(md) {
                            top: 15px;
                            right: 30px;
                        }
                    }
                }
    
                &__body {
                    padding: 0;
                }
            }
            &__iframe{
                width: 100%;
                height: 80vh;
            }
            &__iframe-body {
                #_im_feedbackTab__466 {
                    display: none !important;
                }
            }
        }
        &__paypal-express {
            &-heading {
                @include font-content(22px, 700, 0.99);
                @include breakpoint(lg) {
                    @include font-content($font-size-xxl, 700, 1.2);
                }
            }
            &-service {
                @include font-content($font-size-l, 700, 1.28);
            }
    
            &-service-richtext {
                @include font-content($font-size-m, 400, 1.28);
            }
    
            &-willcall {
                margin: 15px 0;
                @include font-content($font-size-l, 400, 1.39);
                @include breakpoint(lg) {
                    margin: 15px 0 0 0;
                }
            }
        }
        &__initial-heading {
            margin: 15px 0 !important;
        }
        &__express-delivery{
            .sr-only{
                @include vfi-sronly();
                width: 100% !important;
                height: 65% !important;
            }
        }
}
// Shipping Method core/delivery product
.ms-checkout-delivery-shipping {
    padding: 30px 0 0;
    @include breakpoint(lg) {
        padding: 0;
    }
    &__separator {
        @include full-width;
        height: 15px;
        margin-top: 30px;
        display: block;
        background-color: $color-bg-cart-primary;
        @include breakpoint(md) {
            height: 30px;
        }
        @include breakpoint(lg) {
            display: none;
        }
    }

    &__subheading {
        @include font-content($font-size-m, normal, 1.43);
        margin-bottom: 15px;
        color: $color-text-primary;
        text-align: center;
        @include breakpoint(md) {
            text-align: left;
        }
        &__wrapper {
            position: relative;
            .sr-only {
                @include vfi-sronly();
                width: 100% !important;
                height: 100% !important;
            }
            p {
                @include vfi-tab();
            }
        }
    }
}

.ms-checkout-delivery-options {
    @include breakpoint(lg) {
        margin-bottom: 60px;
    }
    &__head {
        overflow: hidden;
        margin-bottom: 15px;
    }
    &__product-image {
        @include vfi-tab($color-black);
        outline-offset: -1px;
        .msc-empty_image {
            @include image-placeholder($product-image-width-sm, 94px);
            @include breakpoint(lg) {
                @include image-placeholder($product-image-width-lg, 144px);
            }
        }
    }
    &__heading {
        @include font-content($font-size-l, bold, 1.25);
        @include vfi-tab($color-black);
        margin: 15px 0;
        text-align: center;
        width: 100%;
        @include breakpoint(lg) {
            @include font-content($font-size-xl, bold, 1.22);
            text-align: left;
        }
    }
    &__heading-span {
        overflow: hidden;
        outline-offset: -1px;
    }
    &__link {
        @include font-content($font-size-m, normal, 1.42);
        @include vfi($color-black);
        text-align: center;
        display: block;
        text-decoration: underline;
        cursor: pointer;
        text-align: center;
        outline-offset: -1px;
        @include breakpoint(lg) {
            text-align: left;
            display: inline-block;
        }
        &-wrapper {
            @include vfi-tab();
        }
    }
    &__input-radio {
        position: relative;
        top: 1px;
        margin-right: 5px;
    }
    &__price {
        margin-left: 5px;
        .msc-price__actual {
            @include font-content();
        }
    }
    &__important-message {
        background: $color-bg-delivery-important-message;
        color: $color-text-primary;
        padding: 15px;
        margin: 15px 0;
        h4 {
            @include font-content($font-size-l, 700, 1.125);
            margin-bottom: 5px;
        }
        p {
            @include font-content($font-size-m, normal, 1.45);
        }
    }
    &__info-notification {
        @include vfi-tab($color-black);
        @include font-content($font-size-m, normal, 1.43);
        width: 100%;
        margin: 15px 0;
        padding: 15px;
        background-color: $color-bg-delivery-notification;
        border-radius: 2px;
        &-wrapper {
            @include vfi-tab($color-black);
        }
        h4 {
            @include font-content($font-size-m, bold, 1.43);
            margin-bottom: 5px;
            color: $color-text-primary;
        }
        p {
            color: $color-text-primary;
            @include font-content($font-size-m, normal, 1.43);
        }
    }
    &__backordered-wrapper {
        @include vfi-tab($color-black);
        background-color: $color-bg-delivery-notification;
        padding: 15px;
        margin: 15px 0;
        border-radius: 2px;
        width: 100%;
    }
    &__backordered-message {
        p {
            color: $color-text-primary;
            @include font-content($font-size-m, normal, 1.43);
        }
    }
    &__backordered-title {
        h4 {
            @include font-content($font-size-m, bold, 1.43);
            margin-bottom: 5px;
            color: $color-text-primary;
        }
    }
    &__tag {
        @include vfi-tab($color-black);
        @include font-content($font-size-s, bold, 0.83);
        background: $color-bg-delivery-tag;
        padding: 5px 10px;
        width: 70px;
        height: 20px;
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        border-radius: 50px;
    }
    &__product-container {
        padding: 15px 15px 0;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid $color-border-accordion;
    }
    &__btn-save-atp {
        @include font-content($font-size-l, 700, 0.8);
        display: block;
        min-height: 44px;
        width: 100%;
        padding: 16px 0 !important;
        border-radius: 50px;
        z-index: 0;
        position: relative;
        background-color: transparent;
        border: 2px solid $color-bg-quaternary;
        color: $color-bg-quaternary;
        margin: 30px auto 15px auto;
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active:focus,
        &:focus,
        &:hover {
            background-color: $color-button-primary-background-hover;
            border-color: $color-button-primary-background-hover;
            box-shadow: none !important;
            color: $color-text-alert-message;
        }
        @include breakpoint(lg) {
            width: 345px;
            margin: 30px 0;
        }
        [class*="button-module_btn-flex"] {
            [class*="loading-module_loading-ring"] {
                svg {
                    circle {
                        &:last-child {
                            stroke: $color-button-primary-background;
                        }
                    }
                }
            }
        }
    }
}

//==============================================================================
// DELIVERY METHOD
//==============================================================================
.ms-checkout__guided-form.__payment {
    .ms-checkout-delivery-shipping {
        padding: 0;
        &__separator {
            display: none;
        }
    }
}
.ms-checkout-delivery-options__service .ms-checkout-delivery-options__info-notification {
    display: none;
    margin: 5px 0 0;
}
.ms-checkout-delivery-options__service.warning .ms-checkout-delivery-options__info-notification {
    @include vfi-tab($color-black);
    display: block;
}
.ms-checkout-delivery-options__service {
    @include font-content($font-size-l, normal, 1.37);
    @include vfi($color-black);
    padding: 13px;
    display: block;
    border-radius: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    @include breakpoint(md) {
        padding: 15px;
    }
    &-wrapper {
        @include vfi-tab($color-black);
    }
    border: 1px solid $color-border-payment-method;
    &.selected {
        border: 2px solid $color-border-payment-method-active;
    }
    &-price,
    &-title {
        @include font-content($font-size-l, bold, 1.25);
        display: inline-block;
        color: $color-text-primary;
        margin-bottom: 5px;
        max-width: 240px;
        @include breakpoint(md) {
            @include font-content($font-size-xl, bold, 1.22);
            max-width: 100%;
        }
    }
    &-price {
        float: right;
    }
    &-richtext p {
        display: inline;
    }
    &__subtitle {
        margin-left: 3px;
        @include font-content(11px, normal, 1.45);
        color: $color-text-primary;
        @include breakpoint(md) {
            @include font-content($font-size-s, normal, 1.45);
        }
    }
}
.ms-checkout-delivery-options__service-disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
.ms-checkout-delivery-options__product {
    margin-bottom: 15px;
    overflow: hidden;
    &-image {
        width: 105px;
        float: left;
        margin-right: 15px;
        @include breakpoint(lg) {
            width: 160px;
            margin-right: 30px;
        }
    }
    &-holder {
        padding: 0;
        overflow: hidden;
        position: relative;
        @include breakpoint(lg) {
            padding-right: 100px;
        }
    }
    &-name {
        @include font-content($font-size-s, bold, 1.25);
        margin-bottom: 5px;
        text-transform: capitalize;
        @include breakpoint(md) {
            @include font-content($font-size-m, bold, 1.42);
            max-width: 400px;
        }
        @include breakpoint(lg) {
            @include font-content($font-size-l, bold, 1.37);
            max-width: 278px;
        }
        &-link {
            @include vfi-tab($color-black);
            @include no-decoration;
            display: inline-block;
        }
    }
    &__specs {
        display: inline-block;
        width: 53%;
        position: relative;
        li {
            display: block;
        }
        @include breakpoint(md) {
            width: 85%;
            li {
                display: inline-block;
                vertical-align: top;
                width: 75%;
                &:last-child {
                    width: 25%;
                    text-align: right;
                }
            }
        }
        @include breakpoint(lg) {
            width: 84%;
        }
    }
    &-qty {
        position: relative;
        .sr-only {
            width: auto !important;
            height: auto !important;
        }
    }
    &-qty,
    &-color,
    &__type {
        @include font-content($font-size-s, normal, 1.66);
        color: $color-text-tertiary;
        margin-bottom: 5px;
    }
    &-color {
        span:first-child {
            padding: 0;
            &:before {
                display: none;
            }
        }
        span:before {
            margin: 0 3px;
            display: inline-block;
            content: ",";
        }
    }
    &-price {
        position: static;
        @include breakpoint(md) {
            top: 0;
            right: 0;
            position: absolute;
        }
    }
    .msc-cart-line__product-price .msc-discount-price {
        @include font-content($font-size-m, bold, 1.42);
        margin-right: 5px;
        display: none;
        @media print {
            display: inline-block;
            margin-left: 10px;
        }
        @include breakpoint(md) {
            display: inline;
        }
    }
    .msc-cart-line__product-price .msc-price__strikethrough {
        margin: 0;
        color: $color-text-tertiary;
        @include font-content($font-size-m, normal, 1.42);
    }
    .msc-cart-line__product-price .discounted,
    .msc-cart-line__product-price__mobile .discounted {
        color: $color-text-primary;
    }
    .msc-cart-line__product-price.discount .discounted,
    .msc-cart-line__product-price__mobile.discount .discounted {
        color: $color-text-secondary;
    }
    .msc-cart-line__product-price.discount span.msc-price__strikethrough[aria-hidden="true"],
    .msc-cart-line__product-price__mobile.discount span.msc-price__strikethrough[aria-hidden="true"] {
        color: $color-text-primary;
        margin-left: 5px;
        @include breakpoint(md) {
            margin: 0;
        }
    }
    .msc-cart-line__product-price,
    .msc-cart-line__product-price__mobile {
        display: flex;
        text-align: left;
        @include font-content($font-size-l, bold, 1.25);
        @include breakpoint(md) {
            display: block;
            text-align: right;
            @include font-content($font-size-xl, bold, 1.22);
        }
    }
}

//==============================================================================
// DELIVERY SCHEDULE
//==============================================================================
.ms-checkout-delivery-options__schedule {
    &-title {
        @include font-content($font-size-l, bold, 1.25);
        @include vfi-tab($color-black);
        margin-top: 30px;
        margin-bottom: 20px;
        color: $color-text-primary;
        text-align: center;
        @include breakpoint(lg) {
            @include font-content($font-size-xl, bold, 1.22);
            text-align: left;
        }
    }
    &-date {
        position: relative;
        margin-bottom: 20px !important;
        &-button {
            @include add-mfrm-icon($icon-calendar, after);
            @include font-content($font-size-l, normal, 1.37);
            @include vfi($color-text-primary);
            @extend %btn-muted;
            width: 100%;
            max-height: 50px;
            padding: 9px 13px;
            text-align: left;
            display: flex;
            justify-content: space-between;
            appearance: none;
            cursor: pointer;
            color: $color-text-primary;
            background-color: $color-white;
            border: 1px solid $color-input-border;
            border-radius: 5px;
            box-sizing: border-box;
            transition: border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
            &:not(:disabled):not(.disabled):hover,
            &:not(:disabled):not(.disabled):focus {
                border-color: $color-input-border-hover;
            }
            &:not(:disabled):not(.disabled):focus {
                box-shadow: inset 0 0 0 1px $color-input-border-hover;
            }
            &:disabled,
            &.disabled {
                color: $color-input-disabled;
                user-select: none;
                touch-action: none;
                cursor: default;
                pointer-events: none;
            }
            &::before,
            &::after {
                font-size: ($icon-size-m + 2);
                width: 18px;
                font-weight: bold;
            }
        }
    }
    &-time {
        @include add-mfrm-icon($icon-chev-down-slim, after);
        position: relative;
        margin-bottom: 0;
        &::after {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            font-size: ($icon-size-xs - 1);
        }
        select {
            @include font-content($font-size-l, normal, 1.37);
            width: 100%;
            padding: 9px;
            appearance: none;
            -webkit-appearance: none;
        }
    }
}

.ms-checkout-delivery-options__accordion {
    [class*="accordionItem-module_line"] {
        padding: 15px;
        @include vfi($color-black);
    }
    [class*="accordionItem-module_title"] {
        font-weight: normal;
        text-transform: none;
    }
    [class*="accordionItem-module_innerContent"] {
        margin-bottom: 15px;
    }
}

.selected-date-notification {
    height: 1px !important;
    width: 1px !important;
    margin: -1px;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
    white-space: nowrap !important;
}
