$wmap-cc-image-width: 30px;
$wmap-cc-image-height: 20px;

.mfrm-wmap {
    &__payment-instrument {
        position: relative;
        &__cc-images {
            display: none;
            @media (min-width: $breakpoint-xs) {
                max-width: 80%;
                margin-right: 0;
                margin-left: auto;
                margin-bottom: -24px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
            }
        }
        &__cc-image {
            min-width: $wmap-cc-image-width;
            width: $wmap-cc-image-width;
            max-width: $wmap-cc-image-width;
            min-height: $wmap-cc-image-height;
            height: $wmap-cc-image-height;
            max-height: $wmap-cc-image-height;
            margin-left: 5px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .checkout-payment-instrument {
        &__iframe {
            width: 100%;
            margin-top: 15px;
            margin-bottom: -20px;
            @include breakpoint(sm) {
                margin-bottom: -40px;
            }
        }
    }
    .ms-checkout-payment-instrument {
        min-height: 191px;
    }
    .ms-checkout-payment-instrument__btn-save {
        display: none !important;
    }
    .ms-checkout-payment-instrument__loading-background {
        padding-top: 30px;
        + .ms-checkout-payment-instrument__add {
            visibility: hidden;
            height: 0;
        }
    }
}
