
.ms-breadcrumb {
    padding: $spacer 8px 10px;
    @include breakpoint(md) {
        padding: $spacer 0 10px;
    }
    &_skeleton-dimensions {
        margin: 15px 8px;
        width: 25% !important;
        @include breakpoint(md) {
            margin-left: -5px;
            width: 20% !important;
        }
        @include breakpoint(lg) {
            width: 10% !important;
        }
    }
   ol{
    &.ms-breadcrumb_list {
        @include text-ellipsis;
        line-height: 0.8;
        padding-bottom: 5px;
        @include breakpoint(md) {
            margin-left: -5px;
        }
    }
   }
    &_item {
        @include font-content($font-size: $font-size-s);
        color: $color-text-breadcrumb;
        display: inline;
        opacity: 0.85;
        a[data-no-click="true"] {
            color: $color-text-primary;
            cursor: default;
            pointer-events: none;
        }
        &.breadcrumb_item-current {
            color: $color-text-primary;
            opacity: 1;
            width: 100%;
            @include breakpoint(md) {
                width: auto;
            }
        }
        &.breadcrumb_item-current a {
            color: $color-text-primary;
            opacity: 1;
        }
    }
    &_link {
        color: $color-text-breadcrumb;
        span {
            margin: 0;
        }
    }
}
.breadcrumb_item-query {
    text-transform: capitalize;
}