.mfrm-suggestion-cards {
    &__click-banner{
        display: contents;
        cursor: pointer;
        &:hover{
            color: black;
        }
    }
    &__heading {
        margin: 25px auto;
        margin-top: 0;
        @include font-content($font-size-xxl, 400, 1.22);
        text-align: center;

        @include breakpoint(lg) {
            @include font-content($font-size-xxl, 400, 1.22);
        }

        @include breakpoint(lg) {
            font-size: $font-size-xxxxxl;
            line-height: 1.5;
        }
    }

    &__subheading {
        margin: 25px auto;
        margin-top: 0;
        text-align: center;
        color: $color-text-primary;
        @include font-content($font-size-m, 400, 1.6);

        @include breakpoint(lg) {
            @include font-content(20px, 400, 1.22);
            line-height: 1.5;
        }
    }

    &__container {
        overflow-x: scroll;
        @include hide-scrollbar-webkit;
        scrollbar-width: none;

        @include breakpoint(lg) {
            overflow-x: hidden;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;

        @include breakpoint(md) {
            justify-content: center;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $color-border-product-card-primary;
        margin: 0px 7.5px;
        width: 299px;
        border-radius: 8px;
        background: $color-white;
        flex: none;

        @include breakpoint(md) {
            width: 311px;
            flex: inherit;
        }

        @include breakpoint(lg) {
            margin: 0px 15px;
            width: 380px;
        }

        &.horizontal {
            align-items: flex-start;

            @include breakpoint(md) {
                width: 384px;
            }

            @include breakpoint(lg) {
                flex-direction: row;
                width: 100%;
                height: 266px;
            }
        }
    }

    &__text-container {
        margin: 30px 25px;

        &.horizontal {
            margin: 0;
            padding: 20px;

            @include breakpoint(lg) {
                flex: 1;
            }
        }
    }

    &__text-container-content {
        text-align: center;
        color: $color-text-primary;
    }

    &__image-container {
        width: 100%;

        img {
            border-radius: 8px 8px 0 0;
            width: 100%;
        }

        &.horizontal {
            @include breakpoint(lg) {
                flex: 1;
            }

            img {
                border-radius: 8px 8px 0 0;

                @include breakpoint(lg) {
                    border-radius: 8px 0 0 8px;
                    height: 264px;
                    object-fit: fill;
                }
            }
        }

    }

    &__card-heading {
        @include font-content($font-size-xl, normal, 1.22);
        letter-spacing: 0.2px;
        white-space: nowrap;
    }

    &__card-content {
        @include font-content($font-size-m, normal, 1.6);

        @include breakpoint(lg) {
            @include font-content($font-size-l, normal, 1.625);
            min-height: 104px;
        }

        letter-spacing: 0.2px;
        margin: 20px 0;
        min-height: 60px;

        &.horizontal {
            min-height: 90px;
            @include breakpoint(md) {
                min-height: 65px;
            }
            @include breakpoint(lg) {
                min-height: 104px;
                @include font-content($font-size-m, normal, 1.625);
            }
        }
    }

    &__buttoncontainer {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(lg) {
            margin: 0 35px;
        }

        &.horizontal {
            margin: 0;
            padding: 0;
        }
    }

    .d5-mfrm-single-cta {
        @include btn(quinary, default);
        margin-bottom: 10px;
        &.horizontal {
            margin: 0;
        } 
    }
}