//==============================================================================
// Zoom Outside Image (Popup)
//==============================================================================
$carousel-flipper-size-sm: 30px;
$carousel-flipper-size-lg: 60px;
$carousel-height: 310px;
$carousel-height-md: 640px;
$carousel-height-lg: 513px;

.ms-containerZoom {
  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: zoom-in;
  }

  &__result {
    overflow: hidden;
    position: fixed;
    background-color: $color-white;
    background-repeat: no-repeat;
    z-index: 1140;
    box-shadow: 0px 6px 8px 2px transparentize($color: $color-black, $amount: 0.85);
    border: 1px solid $color-modal-border;
    width: 580px;
    height: 772px;
    right: 10px;

    @include breakpoint(md, max-width) {
      display: none;
    }

    @include breakpoint(lg, max-width) {
      width: 390px;
      height: 400px;
    }
  }

  &__image {
    border: none;
    left: 0;
    max-width: none;
    max-height: none;
    position: absolute;
    top: 0;
  }

  &__zoom-lens {
    position: absolute;
    cursor: zoom-out;
    width: 200px;
    height: 180px;
    background-position: 0px 0px;
    top: -200px;
    right: -200px;
    float: right;
    overflow: hidden;
    zoom: 1;
    background-color: $color-white;
    border: 1px solid $color-black;
    opacity: 0;
    &__opacity {
      opacity: 0.4;
    }
    @include breakpoint(md, max-width) {
      display: none;
    }
  }
}

//==============================================================================
// Zoom Inside Image
//==============================================================================

.ms-inline-zoom {
  display: block;
  overflow: hidden;
  cursor: zoom-in;
  cursor: -webkit-zoom-in;
  .msc-empty_image {
    @include image-placeholder(215px, 215px);
    @include breakpoint(xs) {
      @include image-placeholder(250px, 250px);
    }
    @media only screen and (min-width: $breakpoint-xs) {
      @include image-placeholder($media-gallery-img-height-mobile, $media-gallery-img-height-mobile);
    }
    @include breakpoint(md) {
      @include image-placeholder(100%, $media-gallery-img-height-mobile);
    }
  }

  &.zoomed {
    cursor: zoom-out;
    cursor: -webkit-zoom-out;
  }

  &__zoomedImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    max-width: none;
    max-height: none;
    display: none;
    opacity: 0;
    border: none;
    @include breakpoint(md) {
      display: block;
    }
  }
}

.ms-fullscreen-section {
  position: relative;

  .product-video {
    width: 100% !important;
    height: 310px !important;
    min-width: 100%;
    @include breakpoint(md) {
      height: 641px !important;
      min-width: 686px;
    }
    @include breakpoint(lg) {
      height: 621px !important;
    }
  }

  .btn-paly {
    @include add-mfrm-icon($icon-pause);
    top: 50%;
    left: 50%;
    width: 74px;
    height: 74px;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    margin: -39px 0 0 -37px;
    background: $color-bg-primary;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @include breakpoint(md) {
      margin: -36px 0 0 -36px;
    }
    &:before {
      font-size: 22px;
      line-height: 74px;
      color: $color-text-secondary;
    }
  }

  img {
    vertical-align: top;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    /* for dark overlay on top of the image */
    background: transparent;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    opacity: 1;
  }

  &__magnifying-glass-icon {
    color: $color-white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px;
    line-height: 1;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .ms-fullscreen-section {
    display: block;
  }
}

.msc-modal .msc-fullview-modal {
  padding: 15px;
  vertical-align: middle;
  .msc-modal__content {
    padding: 15px;
    max-width: 738px;
    border-radius: 10px;
    box-shadow: none;
    @include breakpoint(md) {
      min-width: 720px;
    }
    @media only screen and (min-width: 800px) {
      min-width: 738px;
    }
  }
  .msc-modal__header {
    clear: both;
    margin-bottom: 30px;
  }
  .msc-modal__close-button {
    padding: 0;
    width: 30px;
    height: 30px;
    float: right;
    position: static;
    border-radius: 50%;
    border: 1px solid $color-modal-border-close-button;
    &:before {
      font-size: $icon-size-xs;
      font-weight: 700;
      line-height: 30px;
    }
  }
  .msc-fullview-modal-body.msc-modal__body {
    margin: 0;
    padding: 0;
    border-radius: 10px;
  }
  .msc-carousel {
    align-items: unset;
    position: relative;
    width: 100%;
    &__item {
      .ms-inline-zoom {
        float: unset;
        position: unset;
      }
      img {
        min-height: $carousel-height;
        object-fit: fill;
        border-radius: 10px;
        @include breakpoint(md) {
          min-height: $carousel-height-lg;
          height: auto;
        }
      }
    }
    &__control__prev,
    &__control__next {
      left: 2px;
      display: block;
      &__icon:before {
        line-height: $carousel-flipper-size-sm;
      }
    }
    &__control__next {
      left: auto;
      right: 2px;
    }
    // override inline style
    .msc-tooltip {
      display: none !important;
    }
  }

  @include breakpoint(md) {
    padding: 0;
    .msc-modal__content {
      padding: 30px;
    }
    .msc-carousel {
      &__control__prev,
      &__control__next {
        left: -15px;
        &__icon:before {
          line-height: $carousel-flipper-size-lg;
        }
      }
      &__control__next {
        left: auto;
        right: -15px;
      }
    }
  }
}

//==============================================================================
// Showing First image when lightbox open in PDP page
//==============================================================================
.media-gallery-modal-open {
  .ms-media-gallery {
    .msc-carousel__item {
      margin: 0;
      transform: none;
      display: block;
      height: $carousel-height;
      @include breakpoint(md) {
        height: auto;
      }
    }
    &__thumbnails-container {
      opacity: 0;
      transition: ease 1s opacity;
    }
  }
  .msc-carousel__inner {
    max-height: $carousel-height;
    height: $carousel-height;
    @include breakpoint(md) {
      max-height: $carousel-height-md;
      height: auto;
    }
    @include breakpoint(lg) {
      max-height: $carousel-height-lg;
    }
  }
  .msc-carousel__indicators {
    opacity: 0;
    transition: ease 1s opacity;
  }
}
