.choose-your-foundation-modal {

  .tabs {
    display:flex;
    height: 38px;
    .tab{
      user-select:none;
      cursor: pointer;
      flex-basis: 33.3333%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.selected{
        border-bottom: 2px solid #d63426;
        color: #d63426;
      }
    }
  }

  .modal {
    font-family: sans-serif;
    text-align: center;
    width: 383px;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    background: #eee;
  }

  .msc-modal__body {
    padding-left: 0px;
    padding-right: 0px;
    > :not(.tabs):not(.adjustable-base-modal-content) {
      margin-left: 15px;
      margin-right: 15px;
      @media (min-width: $breakpoint-md) {
        margin-left:20px;
        margin-right:20px;
      }
    }
    .tabs{
      border-bottom: 1px solid #e5e5e5;
    }
  }

  .selectable-card-wrapper {
    border: 1px solid #fff;
    //Not part of item component
    border-radius: 5px;
    &:hover:not(.disable-hover), &.selected {
      border: 1px solid #d63426;
    }
  }


  button {
    background: white;
    width: 95px;
    height: 40px;
    border: 1px solid #b3b5b7;
    border-radius: 4px;
  }

  //price

  .product-price {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

  .current-price {
    margin-right: 8px;
  }

  .standard-price,
  .current-price {
    display: inline-block;
    position: relative;
  }

  .standard-price .strike {
    position: absolute;
    border-bottom: 1px solid black;
    top: calc(50%);
    width: calc(100%);
  }

  sup {
    font-size: 10px;
  }

  .standard-price.crossed-out {
    font-weight: 400;
  }

  .adj-base-subtitle {
    margin-bottom: 12px;
    margin-top: -27px;
  }
}

.choose-your-foundation-modal .current-price,
.choose-your-foundation-button .msc-adjustable-base-modal-label .discount{
  color: #d63426;
}