.ms-checkout-payment-skeleton {
    @include breakpoint(lg) {
        margin-top: 35px;
    }

    &__heading {
        width: 125px;
        height: 35px;
        margin-bottom: 5px !important;
        margin: 0 auto;

        @include breakpoint(lg) {
            margin: 0;
        }
    }

    &__bullet {
        width: 25px;
        height: 30px;
        border-radius: 100% !important;
        margin-right: 15px;
    }

    &__payment-method {
        width: 170px;
        height: 30px;
    }

    &__payment-card {
        width: 29px;
        height: 20px;
        margin-right: 5px;
    }

    &__payment-wrapper {
        display: flex;
        margin-top: 30px;
        margin-bottom: 20px !important;
    }

    &__title-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__card-wrapper {
        display: flex;
    }

    &__billing-wrapper {
        display: flex;
    }

    &__billing-checkbox {
        height: 22px;
        width: 22px;
    }

    &__billing-text {
        width: 252px;
        height: 22px;
        margin-left: 22px;
    }

    &__order-title {
        width: 185px;
        height: 35px;
        margin: 30px auto !important;

        @include breakpoint(lg) {
            margin: 30px 0 !important;
        }
    }

    &__order-sub-title {
        width: 130px;
        height: 22px;
        margin-bottom: 30px !important;
    }

    &__customer {
        &-info {
            display: block;

            @include breakpoint(lg) {
                display: flex;
            }
        }

        &-details-wrapper {
            width: 100%;

            @include breakpoint(lg) {
                width: 50%;
            }
        }

        &-details {
            width: 180px;
            height: 25px;

            @include breakpoint(lg) {
                width: 130px;
            }
        }
    }

    &__delivery {
        &-heading {
            height: 22px;
            width: 165px;
            margin: 30px 0 15px !important;
        }

        &-wrapper {
            width: 100%;
            border: 1px solid $color-border-checkout-primary;
            padding: 15px 15px 0 15px;
            border-radius: 4px;
        }
    }

    .msc-cart-line__container {
        display: flex;
        flex-grow: 1;
    }

    .msc-cart-lines-item {
        border: none;
        padding: 0;
    }

    .ms-checkout-shipping-skeleton__container {
        display: flex;
    }

    .ms-checkout-shipping-skeleton__container-input {
        width: 48%;
    }
}

.ms-checkout {
    &__order-sumary-desktop {
        display: none;

        @include breakpoint(lg) {
            display: block;
        }
    }
}