.d5-mfrm-csr-vote-module{
    color: #2D2926;
    display: flex;
    justify-content: center;


    &__module-container {
        background-color: #F2F5FF;
        max-width: 1200px;
        border-radius: 10px;
        width: 100%;
      }
    
    &__img{
      width: 168px;
      height: 71px;
      margin: auto;
      aspect-ratio: 21/9;
      margin: 40px auto 16px auto;

    }

      
    &__header-text {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 8px;
      }
      
      &__content-text {
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          margin: 0 32px 22px 32px;
      }
      
      &__btn-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 40px;
          &:hover{
            text-decoration: none;
          }
        }

      &__blue-cta {
          @include btn(quaternary, default);
          padding: 16px 60px;
          justify-content: center;
      }
      &__button-text {
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 12px;
          text-align: center;
          color: #FFFFFF;
        }
}

@include breakpoint(md) {

  .d5-mfrm-csr-vote-module{
    &__content-text {
        margin: 0 64px 22px 64px;
    }
  }
}

@include breakpoint(lg) {

  .d5-mfrm-csr-vote-module{
    &__content-text {
        margin: 0 203px 22px 203px;
    }
  }
}