.ms-refine-submenu {
  &__input-range-refiner {
    display: flex;
  }
  &__input-ranges {
    padding-right: 15px;
    &:last-child {
      padding-right: 0;
    }
  }
  &__input-range {
    height: 40px;
    width: 100%;
    padding: 7px;
    @include font-content($font-size-l, 500);
    
    @include breakpoint(md) {
        width: 98.96%;
      }
    @include breakpoint(lg) {
      width: 100%;
    }
    @include breakpoint(xl) {
      width: 94%;
    }
    @extend %field-control-input;
    border-radius: 2px;
  }
  &__input-range-label {
    @include font-content($font-size-s, 400, 1.83);
    top: 18px;
    position: relative;
    padding: 0 5px;
    background: $color-white;
    left: 5px;
  }
  &__range-refiner {
    .slider__labels {
      display: none;
    }
    .slider__track {
      width: 232px;
    }
  }
}
