//==============================================================================
// REST ASSURED PROMISE
//==============================================================================
$rap-container-space: 30px;
$rap-items-gap: 5px;
$rap-items-icon-dimension: 35px;
$rap-item-icon-dimension: 105px;
$rap-item-icon-background-color: $color-icon-quatenary;
.mfrm {
  &-rap-title-container {
    img {
      width: $rap-item-icon-dimension;
      height: $rap-item-icon-dimension;
      display: inline;
      margin-bottom: $rap-container-space / 2;
      min-height: $rap-item-icon-dimension;
    }
    h2 {
      font-size: $font-size-xl;
      line-height: 1.2;
      @include breakpoint(lg) {
        font-size: $font-size-xxl;
        line-height: 1.5;
      }
    }
  }
  &-rap-item {
    margin-top: 36px;
    padding: 0;
    .row {
      margin: 0;
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-row-gap: 10px;
      @include breakpoint(md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: $rap-items-gap * 3;
      }
      @include breakpoint(lg) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: $rap-items-gap * 6;
      }
    }
  }
  &-rap-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.45;
    border: 1px solid $color-border-rap-primary;
    border-radius: 5px;

    &:focus-within {
      border: 1px solid $color-button-border;
    }

    img {
      width: $rap-items-icon-dimension;
      height: $rap-items-icon-dimension;
      margin: 0 14px 0 15px;
    }
    .ms-content-block {
      &__details {
        width: 100%;
      }

      &__link {
        display: flex;
        align-items: center;
        width: 100%;

        &:hover {
          color: $color-charcoal;
          text-decoration: underline;
        }
      }
    }
    .ms-content-block__text {
      @include no-hover-state;
      @include add-mfrm-icon($icon-arrow-right, after);
      font-weight: 500;
      line-height: 1.45;
      display: block;
      padding: 19px 0 19px 5px;
      &:after {
        right: 10px;
        position: absolute;
        top: 12px;
        background-color: $rap-item-icon-background-color;
        border-radius: 50%;
        width: $rap-items-icon-dimension;
        height: $rap-items-icon-dimension;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &-rap-btn {
    margin: $rap-container-space * 2 0 $rap-container-space * 3;
    a {
      @include btn(primary, large);
    }
  }
}
