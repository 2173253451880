.mfrm-shop-by-slider-container {
  .heading {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    font-size: $font-size-xl;
    line-height: 1.2;
    @include breakpoint(lg) {
      font-size: $font-size-xxl;
      line-height: 1.5;
    }
  }

  .cb-shop-by-slider__withText {
    .ms-content-block__text {
      margin-left: 15px;
    }
    .ms-content-block__text-alt {
      @include breakpoint(lg){
        margin-left: 3px;
      }
    }
  }

  .cb-shop-by-slider__textOnly {
    .ms-content-block__text {
      margin-left: 0px;
    }
  }

  .cb-shop-by-slider__withoutText {
    .cb-shop-by-slider {
      padding: 6px 30px;
    }
  }

  .cb-shop-by-slider__link {
    border: 1px solid $color-button-border-tertiary;
    border-radius: 25px;
    margin: 0 5px;
    height: 44px;
    padding: 5px 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:focus,
    &:hover {
      border-color: $color-button-border-tertiary-hover;
      color: $color-button-border-tertiary-hover;
    }
  }

  .cb-shop-by-slider__link-alt {
    @include breakpoint(lg) {
      padding: 5px 15px;
    }
  }

  .shop-mattress-by-size .row,
  .mfrm-why-matrressfirm .row {
    @include breakpoint(xl) {
      justify-content: center;
    }
  }

  .mfrm-shop-by-slider__content .row,
  .mfrm-why-matrressfirm .row {
    // Hide scrollbar on FF
    scrollbar-width: none;
    flex-wrap: nowrap !important;
    overflow-x: scroll;

    .col-12 {
      width: auto;
      flex: none;
      padding: 0;

      &:first-child {
        .cb-shop-by-slider__link {
          margin-left: 0;
        }
      }

      &:last-child {
        .cb-shop-by-slider__link-alt {
          @include breakpoint(lg) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .row::-webkit-scrollbar {
    display: none;
  }

  img {
    max-height: 32px;
    width: auto;
  }
  .mfrm-why-matrressfirm {
    .cb-shop-by-slider__link {
      padding: 5px 15px;
      border: none;
      &:focus,
      &:active,
      &:focus-visible {
        text-decoration: underline;
      }
    }
    .cb-shop-by-slider__withText {
      .ms-content-block__text {
        margin-left: 3px;
      }
    }
  }
}
