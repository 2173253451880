// ** = Includes Theme Specific Variables //

//==============================================================================
// FONT FAMILY
//==============================================================================

$font-family-primary: "Rubik",
sans-serif;
$font-family-system: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
Oxygen,
Ubuntu,
Cantarell,
"Open Sans",
"Helvetica Neue",
sans-serif;
$font-family-times: Times,
"Times New Roman",
serif;

//==============================================================================
// FONT SIZES -- 1rem = 14px
//==============================================================================

$font-size-xs: 0.72rem; //10px
$font-size-s: 0.86rem; //12px
$font-size-m: 1rem; //14px
$font-size-l: 1.143rem; //16px
$font-size-xl: 1.29rem; //18px
$font-size-xxl: 1.72rem; //24px
$font-size-xxxl: 2rem; //28px
$font-size-xxxxl: 2.571rem; //36px
$font-size-xxxxxl: 3rem; //48px

//==============================================================================
// ICON SIZES
//==============================================================================

$icon-size-xs: 12px;
$icon-size-s: 16px;
$icon-size-m: 18px;
$icon-size-l: 32px;
$icon-size-xl: 64px;

//==============================================================================
// MODAL GLOBAL VARIABLES
//==============================================================================

$modal-z-index: 5003;

//==============================================================================
// HEADER GLOBAL VARIABLES**
//==============================================================================

$header-primary-height: 70px;
$header-secondary-height: 50px;
$header-store-selector-mobile-height: (var(--initialTopbarSize) - 52px);

//==============================================================================
// PAGE WIDTH/HEIGHT**
//==============================================================================

$page-width-medium: 1025px;
$page-width-larger: 1440px;
$page-width-max: 1200px;

//==============================================================================
// GRID SPACING AND COLUMNS
//==============================================================================
//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;

$grid-gutter-width-mobile: 15px;
$grid-gutter-width-tab: 30px;
$grid-gutter-width-desktop: 120px;

$content-block-spacer-y: 30px 0;

/*Input Field Global Height*/
$max-field-height: 40px;
$separator-height: 30px;

//==============================================================================
// BASE COLOR VARIABLES
//==============================================================================

$color-white: #ffffff;
$color-alabaster: #f8f8f8;
$color-concrete: #f2f2f2;
$color-athensgray: #f1f1f2;
$color-gallery: #f0f0f0;
$color-alto: #e4e4e4;
$color-gainsboro: #dcdcdc;
$color-mercury: #e1e1e1;
$color-silver: #c4c4c4;
$color-silverchain: #cccccc;
$color-silversand: #c6c8ca;
$color-bombay: #b3b5b7;
$color-dustygray: #9b9b9b;
$color-naturalgray: #969492;
$color-darkgray: #979797;
$color-boulder: #7c7c7c;
$color-midgray: #626366;
$color-tundora: #444444;
$color-mineshaft: #323030;
$color-charcoal: #2d2926;
$color-codgray: #1e1e1e;
$color-black: #000000;
$color-green: #006601;
$color-light-green: #E0FAF2;
$color-brightsun: #fdd835;
$color-camaron: #20b323;
$color-catalinablue: #04356f;
$color-east-bay: #495071;
$color-punch: #d63426;
$color-tabasco: #9d1c11;
$color-tealish-blue: #1b244d;
$color-light-blue: #F2F5FF;
$color-dark-cerulean: #005290;
$color-nero: #222222;
$color-night-rider: #333333;
$color-supernova : #fccc0b;
$color-ghost-white : #f2f5ff;
$color-yellow-alert: #fccc0b;
$color-deepgray: #4a4a4a;
$color-charleston-green: #272727;
$color-dark-green: #397F72;
$color-btn-paypal: #ffc439;
$color-rose: #FDE5E6;
$color-cyan: #ABBDED;
$color-green-secondary: #439F93;
$color-purple: #543F6E;

//==============================================================================
// THEME COLOR VARIABLES**
//==============================================================================

//--------------------
// Background Color
//--------------------

$color-bg-primary: $color-white;
$color-bg-secondary: $color-gallery;
$color-bg-tertiary: $color-athensgray;
$color-bg-quaternary: $color-punch;
$color-bg-quinary: $color-charcoal;
$color-bg-senary: $color-naturalgray;
$color-bg-footer-primary: $color-alabaster;
$color-bg-footer-secondary: $color-alto;
$color-bg-cart-primary: $color-alabaster;
$color-bg-store-locator: $color-alabaster;
$color-bg-accordion-table: $color-alabaster;
$color-bg-feature-spotlight: $color-alabaster;
$color-bg-order-summary: $color-alabaster;
$color-bg-plp-banner: $color-alabaster;
$color-bg-error-page: $color-tealish-blue;
$color-bg-chat: $color-dark-cerulean;
$color-bg-chat-invitation: $color-punch;
$color-bg-promotion-msg: $color-light-green;
$color-bg-delivery-notification: $color-alabaster;
$color-bg-delivery-important-message: $color-alabaster;
$color-bg-delivery-tag: $color-mercury;
$color-bg-alert-message: $color-green;
$color-bg-error: $color-alabaster;
$color-bg-sleep-expert: $color-light-blue;
$color-bg-header-primary: $color-alabaster;
$color-bg-progressive-close-btn: $color-alabaster;
$color-bg-progressive-success-section: $color-light-green;
$color-bg-progressive-payment-overview: $color-alabaster;

//--------------------
// Input Field Colors
//--------------------

$color-input-border: $color-bombay;
$color-input-placeholder: $color-dustygray;
$color-input-border-hover: $color-charcoal;
$color-input-border-focus: $color-tundora;
$color-input-text: $color-charcoal;
$color-input-disabled: $color-bombay;

//--------------------
// Text Color
//--------------------

$color-text-primary: $color-charcoal;
$color-text-secondary: $color-punch;
$color-text-tertiary: $color-midgray;
$color-text-store-timing: $color-green;
$color-text-chat: $color-night-rider;
$color-text-alert-message: $color-white;
$color-text-handy: $color-tealish-blue;
$color-text-discount-code: $color-dark-green;

//--------------------
// Button Color
//--------------------

$color-button-primary-text: $color-white;
$color-button-primary-background: $color-punch;
$color-button-primary-background-hover: $color-tabasco;

$color-button-secondary-text: $color-punch;
$color-button-secondary-text-hover: $color-white;
$color-button-secondary-background: transparent;
$color-button-secondary-background-hover: $color-tabasco;

$color-button-quaternary-text: $color-white;
$color-button-quaternary-text-hover: $color-white;
$color-button-quaternary-background: $color-tealish-blue;
$color-button-quaternary-background-hover: $color-east-bay;

//--------------------
// Button Borders
//--------------------

$color-button-border: $color-punch;
$color-button-border-tertiary: $color-alto;
$color-button-border-tertiary-hover: $color-charcoal;
$color-button-border-quinary: $color-bombay;

//--------------------
// Button Disabled
//--------------------

$color-button-border-disabled: $color-silverchain;
$color-button-disabled-text: $color-bombay;
$color-button-disabled-background: $color-white;

//---------------
// Link Color
//---------------

$color-link-primary: $color-charcoal;
$color-link-secondary: $color-punch;
$color-link-hover: $color-punch;

//----------------------------------------------------------
// Border Colors
//----------------------------------------------------------
//Modal Colors
$color-modal-border: $color-dustygray;
$color-modal-border-close-button: $color-darkgray;

//Choice Summary Colors
$color-border-choice-summary-primary: $color-mercury;
$color-border-choice-summary-primary-hover: $color-dustygray;

//Header Colors
$color-border-header-primary: $color-alto;

//Footer Colors
$color-border-footer-primary: $color-gallery;
$color-border-footer-secondary: $color-alto;

//Navigation Colors
$color-border-nav-item: $color-athensgray;
$color-border-nav-item-separator: $color-tundora;

//Cart-Mini Colors
$color-border-cart-mini-primary: $color-gainsboro;
$color-border-cart-mini-secondary: $color-gallery;
$color-border-cart-mini-tertiary: $color-alto;

//ChatBot Colors
$color-border-chatbot-primary: $color-gainsboro;

//PDP Media Gallery Colors
$color-border-media-gallery-primary: $color-silver;

//PLP Colors
$color-border-plp-primary: $color-mercury;
$color-border-plp-secondary: $color-alabaster;

//Product Card Colors
$color-border-product-card-primary: $color-mercury;
$color-border-product-card-secondary: $color-alabaster;

//Refiners Colors
$color-border-refiner-primary: $color-alabaster;
$color-border-refiner-secondary: $color-mercury;
$color-border-refiner-tertiary: $color-dustygray;

//Mattress Matcher Colors
$color-border-mm-primary: $color-mercury;
$color-border-mm-secondary: $color-alabaster;
$color-border-mm-tertiary: $color-alto;

//Store Select Colors
$color-border-store-select-primary: $color-mercury;

//Product Collection Colors
$color-border-product-collection-primary: $color-mercury;

//Financing Modal Colors
$color-border-financing-modal-primary: $color-mercury;
$color-border-financing-modal-secondary: $color-bombay;

//Bonus Modal Colors
$color-border-bonus-modal-primary: $color-bombay;
$color-border-bonus-modal-secondary: $color-charcoal;

//Auto-Suggest Colors
$color-border-auto-suggest-primary: $color-alto;

//Cart Colors
$color-border-cart-primary: $color-alto;
$color-discount-price: $color-white;
$color-border-cart-line-Item: $color-alabaster;
$color-border-cart-secondary: $color-alabaster;
$color-promotion-message: $color-dark-green;

//Checkout Colors
$color-border-checkout-primary: $color-alto;
$color-border-checkout-secondary: $color-mercury;
$color-border-progressive-benefits: $color-cyan;
$color-border-progressive-success: $color-green-secondary;
$color-bg-checkout-error: $color-rose;
$color-bg-disabled-button: $color-mercury;
$color-bg-checkout-pill: $color-purple;
$color-btn-checkout-background: $color-mercury;

//RAP Colors
$color-border-rap-primary: $color-alto;

//Image Placeholder Colors
$color-border-image-placeholder: $color-silver;

//Stepper Colors
$color-stepper-active: $color-punch;
$color-stepper-inactive: $color-bombay;
$color-stepper-text: $color-midgray;

//Delivery Option Colors
$color-bg-delivery-notification: $color-alabaster;

//Promotional Colors
$color-bg-promotion-msg: $color-light-green;

//---------------
// Rating Colors
//---------------

$color-rating-primary: $color-charcoal;
$color-rating-secondary: $color-mercury;

//---------------
// Icon Color
//---------------

$color-icon-primary: $color-punch;
$color-icon-secondary: $color-white;
$color-icon-tertiary: $color-mineshaft;
$color-icon-quatenary: $color-concrete;
$color-icon-hover: $color-punch;

//---------------
// Error Color
//---------------

$color-error: $color-punch;

//---------------
// Modal Color
//---------------

$color-modal-background: $color-codgray;

//-----------------------
// Carousel Indicators
//-----------------------

$color-bg-indicator: $color-bombay;
$color-bg-indicator-active: $color-charcoal;



//---------------------------
// Badge Color
//---------------------------
$color-badge: $color-supernova;

//-----------------
// Direction
//-----------------

$rdirection: right !default;
$ldirection: left !default;

//-----------------
// Breadcrumbs
//-----------------

$color-text-breadcrumb: $color-midgray;

//-----------------
// Scrollbar
//-----------------

$color-bg-scrollbar: $color-dustygray;
$color-bg-scrollbar-hover: $color-boulder;
$color-border-scrollbar: $color-boulder;
$color-bg-scrollbar-track: $color-concrete;
$color-border-scrollbar-track: $color-concrete;

//-----------------
// Tooltip
//-----------------

$color-bg-tooltip: $color-white;
$color-arrow-tooltip: $color-white;
$color-text-tooltip: $color-charcoal;
$color-border-tooltip: $color-dustygray;

//--------------------
// MFRM Tab/Accordion
//--------------------

$color-border-tab-accordion: $color-bombay;

//--------------------
// MFRM Store Select
//--------------------

$color-border-store-select: $color-bombay;

//-----------------
// MFRM Accordion**
//-----------------

$color-border-accordion: $color-bombay;
$color-text-accordion: $color-charcoal;

//-----------------
// MFRM Payment Method**
//-----------------

$color-border-payment-method: $color-bombay;
$color-border-payment-method-active: $color-charcoal;

//-----------------
// MFRM Tabs**
//-----------------

$color-border-tabs-wrapper: $color-gallery;
$color-border-tab-item: $color-punch;

//-----------------
// MFRM Action Card**
//-----------------

$color-border-action-card-primary: $color-mercury;
$color-border-action-card-secondary: $color-punch;

//-----------------
// MFRM Icon Colors**
//-----------------

$color-delivery-truck: $color-green;