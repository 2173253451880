$modal-max-width: 375px;

.modal-email-subscription {
    z-index: ($modal-z-index + 10);
    .modal-open & {
        overflow: hidden;
        @media only screen and (max-device-width: $breakpoint-lg) and (orientation: landscape) {
            overflow-y: auto;
        }
    }
    &:before {
        vertical-align: bottom;
        @include breakpoint(md) {
            vertical-align: middle;
        }
    }
    .msc-modal__dialog {
        width: 100%;
        vertical-align: bottom;
        @include breakpoint(sm) {
            width: auto;
            max-width: $modal-max-width;
        }
        @include breakpoint(md) {
            vertical-align: middle;
        }
        .msc-modal__close-button {
            @include add-mfrm-icon($icon-close);
        }
    }
    .msc-modal__content {
        @include breakpoint(md) {
            justify-content: center;
        }
    }
    &__subtitle {
        @include font-content($font-weight: normal, $line-height: 1.43);
        margin-bottom: 8px;
        color: $color-text-primary;
        text-align: center;
    }

    &__body {
        text-align: initial;
        padding: 32px 45px 16px;
    }

    &__title {
        @include font-content($font-size-xxxl, bold, 1.14);
        margin-bottom: 10px;
        color: $color-text-secondary;
        text-align: center;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        flex-flow: wrap;
        justify-content: space-between;
    }

    &__button {
        @include btn(primary, large);
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 16px;
        text-align: center;
    }

    &__text {
        @include font-content($font-size-xs, normal, 1.6);
        margin-top: 22px;
        color: $color-text-primary;
        a {
            text-decoration: underline;
        }
    }
    .field-group {
        display: inline-block;
        margin: 6px 0;
        /* fields after first two */
        &:not(:last-of-type) {
            width: 48%;
            .field-control {
                width: 100%;
            }
        }

        /* Name */
        &:nth-of-type(1) {
            width: 100%;
        }

        &:last-of-type {
            width: 100%;
        }

        @include breakpoint(sm) {
            .name {
                width: 165px;
            }
            .zip {
                width: 105px;
            }
        }
        .email {
            width: 100%;
        }
    }
    &__alert-message {
        @include animation-transition(fadeIn, $duration: 0.45s);
        @include font-content($font-size: $font-size-s, $line-height: 1.6);
        height: 0;
        &--error {
            color: $color-error;
        }
    }
    &__success .modal-email-subscription,
    &__error .modal-email-subscription {
        &__title {
            margin: 15px;
            color: $color-text-primary;
        }
        &__text {
            @include font-content($line-height: 1.43);
            margin-top: 0;
            margin-bottom: 15px;
            padding: 0;
            color: $color-text-primary;
            text-align: center;
        }
        &__button {
            margin: 15px 0;
        }
    }
}
