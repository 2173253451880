.cart-product-alert {
    border: 2px solid $color-yellow-alert;
    &__wrapper {
        text-align: center;
        background-color: $color-yellow-alert;
        padding: 15px;
    }
    &__title {
        @include font-content($font-size-l, 700, 1.25);
    }
    &__message {
        padding: 5px 0;
        p {
            @include font-content($font-size-s, 400, 1.66);
            color: $color-text-primary;
        }
        &__chat-link {
            @include vfi-visible($color-black);
            color: $color-text-primary;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    &__link {
        text-decoration: underline;
        @include font-content($font-size-m, 400, 1.42);
        cursor: pointer;
    }
    &__item-quantity {
        @include font-content($font-size-s, 400, 1.66);
        text-align: center;
        padding: 13px 15px;
        color: $color-text-alert-message;
        background: $color-bg-alert-message;
    }
}
