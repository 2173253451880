$product-collection-item-width: 360px;
$product-collection-carousel-padding-top: 15px;
$product-collection-margin-top-mobile: 30px;
$product-collection-margin-bottom-mobile: 60px;
$product-collection-heading-padding-right: 100px;
$product-collection-heading-margin-top: 20px;
$product-collection-heading-margin-bottom: 15px;
$product-collection-item-padding: 5px 20px 24px 1px;
$product-collection-price-strikethrough-line-height: 20px;
$product-collection-tooltip-left: 0px;
$product-collection-tooltip-top: 0px;
$product-collection-tooltip-inner-margin-top: -36px;
$product-collection-tooltip-inner-max-width: 200px;
$product-collection-tooltip-inner-padding: 4px 8px;
$product-collection-transparent: transparent;
$simple-product-collection-image-desktop-width: 95px;
$simple-product-collection-space: 15px;
$simple-product-collection-item-space: 15px 10px 14px;
$simple-product-collection-title-height: 48px;

.ms-product-collection {
  margin-top: $product-collection-margin-top-mobile;
  margin-bottom: $product-collection-margin-bottom-mobile;
  @include breakpoint(sm) {
    margin-top: 30px;
  }

  &__heading {
    margin-top: $product-collection-heading-margin-top;
    margin-bottom: $product-collection-heading-margin-bottom;
    text-transform: none;
    text-align: center;
    @include font-content($font-size-xxxl, 700, 1.14);
  }

  &__item {
    margin: 0;
    display: inline-block;
    max-width: none;
    padding: $product-collection-item-padding;
    overflow: hidden;
    text-align: left;
    vertical-align: top;
    width: 100%;
    @include breakpoint(sm) {
      width: $product-collection-item-width;
    }
  }

  .msc-ss-carousel {
    padding-top: $product-collection-carousel-padding-top;
    max-width: 576px;
    margin: 0 auto;
    @include breakpoint(md) {
      max-width: 1440px;
    }

    &-strip {
      margin: 0 auto;
      max-width: 1440px;
    }
  }

  .msc-product {
    margin: 0;
    overflow: hidden;
    display: block;
    &__image {
      img {
        width: 224px;
        height: 187px;
        @include breakpoint(lg) {
          width: 278px;
          height: 232px;
        }
      }
    }

    .msc-product__title {
      text-decoration: none;
      @include font-content($font-size-s, 400, 1.34);
      @include breakpoint(lg) {
        @include font-content($font-size-m, 400, 1.43);
      }
    }
  }

  .msc-price {
    display: inline-block;
    text-transform: none;

    &__strikethrough {
      font-size: $font-size-m;
    }

    &__actual {
      font-weight: bold;
      color: inherit;
    }
  }

  .msc-rating {
    &__star,
    &__count {
      border-bottom: none;
    }
  }

  .msc-tooltip {
    display: block;
    left: $product-collection-tooltip-left;
    position: absolute;
    top: $product-collection-tooltip-top;

    .msc-tooltip-inner {
      margin-top: $product-collection-tooltip-inner-margin-top;
      max-width: $product-collection-tooltip-inner-max-width;
      padding: $product-collection-tooltip-inner-padding;
      text-align: center;
    }

    &.msc-bs-tooltip-bottom {
      padding: $tooltip-arrow-height 0;

      .msc-tooltip-inner {
        margin-top: 0px;
      }
    }
  }
  &.product-recommendation-carousel .msc-ss-carousel-item {
    &.msc-product__with-cta {
      height: 341px;
      max-height: 341px;

      @include breakpoint(lg) {
        height: 384px;
        max-height: 384px;
      }
      .msc-product__title {
        margin: 0px !important;
      }
      .msc-price {
        margin: 5px 0;
      }
      .msc-btn {
        @include font-content($font-size-l, 700, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 44px;
        width: 144px;
        margin: 25px auto 30px;
      }
      .msc-product__image {
        height: 145px !important;
        @include breakpoint(lg) {
          height: 185px !important;
        }
        img {
          height: 145px;
          @include breakpoint(lg) {
            height: 185px;
          }
        }
      }
    }
  }
}
//==============================================================================
// Simple Recommended Products
//==============================================================================

.msc-simple-product-collection {
  .msc-ss-carousel {
    max-width: 435px;
    margin: 0 auto;
    text-align: center;
    @include breakpoint(md) {
      max-width: none;
    }
    &-slide {
      @include breakpoint(md) {
        display: grid;
        justify-content: space-between;
        grid-auto-flow: column;
        grid-column-gap: $simple-product-collection-space;
        grid-row-gap: $simple-product-collection-space;
      }
      @include breakpoint(lg) {
        grid-column-gap: $simple-product-collection-space * 2;
        grid-row-gap: $simple-product-collection-space * 2;
      }
    }
  }
  .ms-product-collection {
    &__heading {
      @include font-content($font-size-xxl, 700, 1.17);
      margin-bottom: $simple-product-collection-space;
    }
    &__item {
      border: 1px solid $color-border-product-collection-primary;
      border-radius: 5px;
      width: 135px;
      padding: 0;
      margin-right: $simple-product-collection-space;
      @include breakpoint(md) {
        width: 100%;
        max-width: 349px;
        margin-right: 0;
        display: none;
        padding: 11px 10px;
        &:nth-child(-n + 3) {
          display: flex;
        }
      }
      @include breakpoint(xxl) {
        width: 349px;
      }

      @media (min-width: $container-max-width) {
        width: 380px;
      }
    }
  }
  .msc-product {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    border: none;
    &:hover {
      text-decoration: none;
      color: $color-text-primary;
    }
    @include breakpoint(md) {
      flex-direction: row;
    }
    &__image {
      width: 135px;
      height: 108px;
      display: block;
      overflow: hidden;
      align-items: center;
      border-radius: 5px;
      @include breakpoint(md) {
        margin-right: 15px;
        width: $simple-product-collection-image-desktop-width;
        height: $simple-product-collection-image-desktop-width - 19;
        min-width: $simple-product-collection-image-desktop-width;
      }
      img {
        border-radius: 5px 5px 0 0;
        object-fit: cover;
        height: $simple-product-collection-image-desktop-width + 18;
        width: 100%;
        @include breakpoint(md) {
          border-radius: 5px;
          height: 100%;
        }
      }
    }
    &__details {
      margin: 0;
      white-space: pre-wrap;
      padding: $simple-product-collection-item-space;
      font-size: 0;
      @include breakpoint(md) {
        padding: 0;
      }
    }
    &__title {
      margin: 0;
      padding: 0;
      min-height: $simple-product-collection-title-height;
      @include text-ellipsis;
      height: $simple-product-collection-title-height;
      @include breakpoint(md) {
        min-height: auto;
        padding-right: $simple-product-collection-space - 5;
        height: auto;
      }
    }
    .msc-empty_image {
      background-position: 50%;
      height: 108px;
      width: 100%;
      @include breakpoint(md) {
        height: 76px;
        width: 95px;
      }
    }
    .msc-price {
      display: none;
    }
    .msc-rating {
      display: none;
    }
  }
  .msc-ss-carousel__flipper,
  .msc-ss-carousel-vert__flipper {
    display: none;
  }
}
