.mfrm-recommendation-module {
    &__avatarheadingcontainer {
        height: 280px;
        display: flex;
        flex-direction: row;

        @include breakpoint(lg) {
            border-radius: 5px;
        }

        @include breakpoint(md) {
            height: 164px;
        }

        width: 100%;
        background: #242c5d;
    }

    &__avatarcontentcontainer {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint(md) {
            padding-top: 32px;
            flex-direction: row;
        }
    }

    &__avatarimagecontainer {
        padding: 0 32px 0 32px;
        flex-direction: row;
        @include breakpoint(md) {
            padding: 0 23px 0 30px;
        }
    }

    &__avatarimage {
        height: auto;
        margin: auto;
        max-width: 100px;
    }

    &__avatartextcontainer {
        flex-direction: row;
        text-align: center;
        width: 100%;
        padding-top: 10px;

        @include breakpoint(md) {
            width: 531px;
            text-align: inherit;
        }

        @include breakpoint(lg) {
            width: 625px;
        }
    }

    &__avatarheading {
        color: #abbded;
        @include font-content(22px, 700, 1);
        width: 345px;
        padding-left: 15px;
        padding-bottom: 8px;

        @include breakpoint(lg) {
            @include font-content($font-size-xxl, 700, 1.75);
        }

        @include breakpoint(md) {
            @include font-content(22px, 700, 1.25);
            width: 100%;
            padding-left: 0px;
        }
    }

    &__avatarsubheading {
        @include font-content($font-size-m, 400, 1.5);
        color: $color-white;
        width: 345px;
        padding-top: 8px;
        padding-left: 15px;

        @include breakpoint(md) {
            width: 100%;
            padding-left: 0px;
            padding-top: 0px;
            display: inline;
        }
    }

    &__avatarsubheadinglink {
        color: $color-white;
        text-decoration: underline;
        flex-direction: row;
        padding-left: 3px;
    }

    max-height: 354px;
    overflow-y: hidden;

    &__parent {
        padding-right: 15px;

        @include breakpoint(md) {
            padding-right: 15px;
        }
    }

    &__heading {
        margin: 25px auto;
        margin-top: 0;
        font-size: $font-size-xl;
        line-height: 1.2;

        @include breakpoint(lg) {
            font-size: $font-size-xxl;
            line-height: 1.5;
        }
    }

    &__text-white {
        color: $color-white;
    }

    &__text-red {
        color: $color-punch;
    }

    &__text-black {
        color: $color-black;
    }

    &__sale {
        border: 1px solid $color-mercury;
        border-radius: 5px;
        width: 288px;
        height: 410px;
        padding-right: 15px;
        position: relative;
        color: $color-mercury;
    }

    &__availability-text-container {
        color: $color-black;
        text-align: left;
        margin-top: 10px;
        white-space: initial;
    }

    &__availabile-indicator {
        height: 10px;
        width: 10px;
        background-color: #006601;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
        margin-right: 8px;
    }

    &__not-availabile-indicator {
        background-color: grey;
    }

    &__promobadge {
        .mfrm-product-badge__image {
            margin-bottom: 0px;
            margin-right: 0px;
            border-radius: 0;
            max-height: 30px;
        }
    }

    &__cardBkgColor-white {
        background-color: $color-white;
    }

    &__sales-container {
        max-height: 335px;
        overflow-y: hidden;
    }

    &__badge-container {
        border-radius: 2px;
        position: absolute;
        top: 6px;
    }

    &__promo {
        min-width: fit-content;
        padding: 5px 10px;
        left: 6px;
    }

    &__rating-container {
        text-align: left;
        padding-left: 15px;

        strong {
            display: none;
        }
    }

    &__title {
        white-space: nowrap;
        padding-bottom: 10px;
        font-size: $font-size-l;
        font-weight: bold;
        color: $color-punch;
        text-align: left;
    }

    &__imagecontainer {
        height: 221px;
        width: 287px;
        position: relative;
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        .msc-main_image {
            border-radius: 5px 5px 0px 0px;
            height: 100%;
            width: 100%;
        }
    }

    &__description {
        white-space: pre-wrap;
        padding-bottom: 10px;
        font-size: $font-size-m;
        font-weight: medium;
        color: $color-charcoal;
        text-align: left;
        width: 193px;

        @include breakpoint(lg) {
            width: 248px;
        }
    }

    &__text-container {
        color: $color-charcoal;
        height: 90px;
        padding-top: 14px;
        max-width: 220px;
        padding-left: 15px;
        line-height: 18px;
    }

    a {
        text-decoration: none;
    }

    &__link {
        padding-right: 15px;

        @include breakpoint(lg) {
            padding: 0;
        }

        &:focus {
            padding: 0;
            border: 2px solid $color-bombay;
            border-radius: 5px;
        }

        &:focus,
        &:hover {
            color: inherit;

            .mfrm-content-reel__title,
            .mfrm-content-reel__description-container .mfrm-content-reel__red-cta {
                background-color: $color-button-primary-background-hover;
            }
        }
    }

    &__description-container {
        white-space: normal;

        &-no-image {
            display: none;

            @include breakpoint(lg) {
                display: block;
                padding-top: 10px;
                line-height: 20px;
                white-space: normal;
            }
        }
    }

    &__red-cta {
        @include btn(primary, default);
        margin-top: 15px;
        padding: 11px 30px;
        font-size: $font-size-m;
        justify-content: center;

        @include breakpoint(lg) {
            margin-top: 10px;
            padding: 16px 62px;
            font-size: $font-size-l;
        }
    }
}

