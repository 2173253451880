// ------------------ ORDER SUMMARY ------------------ //

.mfrm-wmap {
    &__order-summary {
        margin-top: 40px;
        &__order-number {
            @include font-content($font-size-l, normal, 1.63);
            color: $color-text-primary;
            margin-top: 15px;
            margin-bottom: 25px;
        }

        // - ORDER SUMMARY TABLE - //
        &__table {
            &__head {
                margin-bottom: 5px;
                display: flex;
                border-top: 1px solid $wmap-table-border-color;
                border-bottom: 1px solid $wmap-table-border-color;
            }
            &__body {
                .mfrm-wmap__order-summary__table__ext-price,
                .mfrm-wmap__order-summary__table__list-price,
                .mfrm-wmap__order-summary__table__qty {
                    font-weight: normal;
                }
                .mfrm-wmap__order-summary__table__qty {
                    width: 27px;
                    font-size: $font-size-s;
                    text-align: center;
                    @include breakpoint(md) {
                        width: 36px;
                        font-size: $font-size-l;
                    }
                }
                .mfrm-wmap__order-summary__table__ext-price {
                    display: inline-block;
                }
                .mfrm-wmap__order-summary__table__ext-price,
                .mfrm-wmap__order-summary__table__list-price {
                    font-size: $font-size-s;
                    @include breakpoint(md) {
                        font-size: $font-size-l;
                    }
                }
            }
            &__body__content,
            &__body__product-content {
                margin-bottom: 10px;
                display: flex;
                color: $color-text-primary;
            }
            &__body__content {
                justify-content: space-between;
                align-items: center;
            }
            &__body__product-content {
                align-items: flex-start;
                @include breakpoint(md) {
                    align-items: center;
                }
            }
            &__qty,
            &__desc,
            &__ext-price,
            &__list-price {
                @include font-content($font-size-m, 500, 1.63);
                color: $color-text-primary;
                @include breakpoint(md) {
                    @include font-content($font-size-l, 500, 1.63);
                }
            }
            &__qty {
                width: 22px;
                @include breakpoint(md) {
                    margin-left: 5px;
                    width: 112px;
                }
            }
            &__desc {
                width: 58.33333333%;
                margin-left: 17px;
                @include breakpoint(md) {
                    margin-left: 18px;
                }
            }
            &__ext-price {
                display: inline;
                @include breakpoint(lg) {
                    display: none;
                }
                &.desktop {
                    display: none;
                    @include breakpoint(lg) {
                        display: inline;
                    }
                }
            }
            &__ext-price,
            &__list-price {
                width: 30%;
                margin-right: 3px;
                text-align: right;
                @include breakpoint(md) {
                    width: 20%;
                }
                @include breakpoint(lg) {
                    width: 16.66666667%;
                }
            }
            &__img {
                width: 38px;
                height: 36px;
                margin: 0 23px;
                display: none;
                @include breakpoint(md) {
                    display: inline-block;
                }
                img {
                    width: 100%;
                    height: 100%;
                    vertical-align: top;
                    font-size: 7px;
                    overflow: hidden;
                }
                .msc-empty_image {
                    @include image-placeholder(38px, 36px);
                }
            }
            &__title {
                width: 58.33333333%;
                margin-left: 14px;
                font-size: $font-size-s;
                text-transform: capitalize;
                @include breakpoint(md) {
                    margin-left: 7px;
                    font-size: $font-size-l;
                }
            }
            &__label {
                @include font-content($font-size-s, normal, 1.63);
                width: 58.33333333%;
                margin-left: 34px;
                display: block;
                text-transform: capitalize;
                @include breakpoint(md) {
                    @include font-content($font-size-l, normal, 1.63);
                    margin-left: 122px;
                }
                @include breakpoint(lg) {
                    margin-left: 128px;
                }
            }
            &__footer {
                &__total {
                    margin-top: 20px;
                    margin-bottom: 10px;
                    padding: 10px 0;
                    border-bottom: 1px solid $wmap-table-border-color;
                    &-label {
                        text-transform: uppercase;
                    }
                }
                &__total,
                &__paid,
                &__due {
                    display: flex;
                    justify-content: space-between;
                    &-label,
                    &-price {
                        @include font-content($font-size-m, 400, 1.63);
                        @include breakpoint(md) {
                            @include font-content($font-size-l, 400, 1.63);
                        }
                    }
                }
            }
        }
    }
}
