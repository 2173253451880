//==============================================================================
// FLOAT INPUT
//==============================================================================
//TODO
//INPUT ERROR

.field-group {
    position: relative;
    margin-bottom: 10px;
    &.required label::after {
        content: ' *';
    }
}
.field-control {
    @extend %field-control-input;
    @extend %float-label;
    &--error {
        //used important to override placeholder
        border-color: $color-error;
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            border-color: $color-error !important;
        }
        &:not(:disabled):not(.disabled):focus {
            box-shadow: 0 0 0 1px $color-error !important;
        }
        + .field-label {
            color: $color-error;
        }
    }
}
.field-label {
    @extend %field-control-label;
}
