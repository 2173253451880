//==============================================================================
// MFRM Social Icons
//==============================================================================
$social-icon-background: $color-midgray;
$social-icon-background-hover: $color-bg-quinary;
$social-icon-vertical-line-background: #e5e5e5;
$focus-visible-background: $color-midgray;
$toast-background: $color-black;
$toast-text-background: $color-bg-primary;
$vertical-line-height: 55px;
$social-icon-width: 35px;
.mfrm-social {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 3px 10px 3px;
    overflow-y: hidden;

    &__link {
        display: flex;
        align-items: center;
        width: 100%;

        &:hover {
            text-decoration: underline;
            color: $social-icon-background-hover;
            .mfrm-social__image {
                background: $social-icon-background-hover;
            }
            .mfrm-social__text {
                color: $social-icon-background-hover;
            }
        }
    }

    &__text {
        cursor: pointer;
        font-weight: lighter;
        line-height: 1.45;
        display: block;
        padding-left: 5px;
        color: $social-icon-background;

        &:after {
            right: 10px;
            position: absolute;
            top: 12px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: 1025px) {
        &__text {
            display: none;
        }
    }

    .row {
        // Hide scrollbar on FF
        scrollbar-width: none;
        flex-wrap: nowrap !important;
        overflow-x: scroll;

        .col-12 {
            width: auto;
            flex: none;
            padding: 0;
        }
    }

    .row::-webkit-scrollbar {
        display: none;
    }

    &__image {
        cursor: pointer;
        display: flex !important;
        background: $social-icon-background;
        width: $social-icon-width;
        height: $social-icon-width;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 7.5px;
        img {
            object-fit: contain;
        }

        &:hover {
            background: $social-icon-background-hover;
        }
    }

    &__link:focus-visible {
        outline: 2px solid $focus-visible-background;
        text-decoration: underline;
        color: $focus-visible-background;
    }

    span {
        display: none;
    }

    .social-media-icon-facebook img {
        width: 11px;
    }

    &__vl1 {
        border-right: 1.5px solid $social-icon-vertical-line-background;
        height: $vertical-line-height;
        margin-left: 20px;
        margin-right: 14px;
    }

    &__vl2 {
        height: $vertical-line-height;
    }

    &__toast-style {
        width: 303px;
        height: 54px;
        max-width: 100%;
        margin: 0 auto;
        position: fixed;
        left: 50%;
        top: 20%;
        transform: translate(-50%, -50%);
        z-index: 999;
        background-color: $toast-background;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        animation: fadeOut 2.5s;
        animation-delay: 5s;
        @keyframes fadeOut {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }

        p {
            color: $toast-text-background;
            position: absolute;
            left: 20px;
        }
    }

    &__toast-close-button {
        @include add-mfrm-icon($icon-close);
        background-color: transparent;
        border: 0;
        position: absolute;
        font-size: 12px;
        right: 22px;
        color: $toast-text-background;

        &:hover {
            color: $toast-text-background;
            text-decoration: underline;
        }
    }
}
