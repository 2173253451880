//==============================================================================
// Footer Menu
//==============================================================================
$footer-background-color: $color-bg-footer-primary;
$footer-space: 60px;
$footer-menu-width: 75%;
$footer-text-line-height: 22px;
$footer-disclosure-background: $color-bg-footer-secondary;
$footer-text-color: $color-text-primary;

.ms-footer-container {
  background-color: $footer-background-color;
  padding: 0 $footer-space / 4 $footer-space / 2;
  @include breakpoint(md) {
    padding: $footer-space $footer-space / 2;
  }
  @include breakpoint(lg) {
    padding: $footer-space $footer-space * 2;
  }
  .row {
    margin: 0;
  }
  .col-12 {
    flex: none;
    @include breakpoint(md) {
      display: contents;
    }
  }
  .default-container {
    padding: 0;
  }
  .footer-menu {
    width: 100%;
    @include breakpoint(lg) {
      // override for fluid class width
      width: $footer-menu-width !important;
    }
    .col-12 {
      display: inline-block;
      width: 100%;
      flex: auto;
      @include breakpoint(md) {
        margin-right: $footer-space / 4;
        width: (100% - $footer-menu-width) !important;
      }
      @include breakpoint(lg) {
        margin-right: $footer-space / 2;
      }
      &:last-child {
        margin: 0;
      }
      /*First child of footer accordion */
      &:first-child [class*="accordionItem-module_line"] {
        border-top: 1px solid $color-border-footer-primary;
      }
    }
    h2 {
      margin-bottom: $footer-space / 4;
      padding-top: 15px;
      @include breakpoint(md) {
        padding: 0;
      }
    }
    .ms-footer__list-item {
      line-height: 2.75;
      padding: 0 15px;
      &:last-child {
        margin-bottom: 15px;
        @include breakpoint(md) {
          margin-bottom: 0;
        }
      }
      @include breakpoint(md) {
        line-height: 1.5;
        padding: 0;
      }
    }
    a {
      display: inline-block;
      line-height: 1.5;
      font-size: $font-size-l;
      @include breakpoint(md) {
        font-size: $font-size-m;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  //==============================================================================
  // Footer Accordion
  //==============================================================================
  .footer-accordion {
    [class*="accordionItem-module_accordionItem"] {
      border: 0;
    }
    [class*="accordionItem-module_line"] {
      position: relative;
      padding: 15px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color-border-footer-primary;
      cursor: pointer;
      overflow: hidden;
    }

    [class*="accordionItem-module_center"] {
      width: 100%;
    }

    [class*="accordionItem-module_title"] {
      font-size: $font-size-xl;
      line-height: 1.55;
      font-weight: 500;
      margin: 0;
    }

    [class*="accordionItem-module_openIcon"] {
      @include add-mfrm-icon($icon-chev-down-slim);
      margin-left: auto;
      margin-right: 10px;
      width: auto;
      height: auto;
      font-size: ($icon-size-s - 2);
      transition: transform 0.3s ease-in-out;
      background: none;
      opacity: 1;
    }
    [class*="accordionItem-module_openIcon"] [class*="accordionItem-module_openIcon"] {
      transform: rotate(180deg);
    }
    [class*="collapse-module_collapse"]:not([class*="collapse-module_show"]) {
      display: none;
      li.footer-accordion {
        display: none;
      }
    }
    [class*="accordionItem-module_innerContent"] {
      padding: 15px 0;
      margin-bottom: 0;
      font-size: $font-size-l;
      line-height: 1.5;
    }
    [class*="collapse-module_show"] {
      border-bottom: 1px solid $color-border-footer-primary;
    }
  }
  ul.footer-accordion {
    margin: 0 -15px;
    @include breakpoint(md) {
      margin: 0;
    }
  }

  //==============================================================================
  // Footer Social Media and Customer Hours
  //==============================================================================

  h2 {
    font-size: $font-size-xl;
    line-height: $footer-text-line-height;
  }
  .container-social-media {
    // override fluid class width
    width: 100% !important;
    text-align: $ldirection;
    margin-top: $footer-space / 2;
    @include breakpoint(lg) {
      margin-top: 0;
      text-align: $rdirection;
      width: (100% - $footer-menu-width) !important;
    }
    .col-12 {
      display: block;
    }
    .phone-number {
      display: block;
      font-size: $font-size-l;
      font-weight: 500;
      line-height: 1.4;
      margin-top: $footer-space / 4;
      margin-bottom: $footer-space / 4;
    }
    .hours-title {
      font-weight: 500;
      line-height: 1.43;
    }
    p {
      line-height: 1.75;
    }
  }
}

//==============================================================================
// Footer Alert
//==============================================================================

.ms-footer-container {
  .footer-alert-mobile {
    margin: 0 -15px;
    box-shadow: 0px -1px 0px $color-border-footer-primary, 0px 1px 0px $color-border-footer-primary;
    @include breakpoint(md) {
      display: none;
    }
    div.footer-alert {
      display: inline-block;
      padding: $footer-space / 2 $footer-space / 4;
    }
    .col-12 {
      padding: 0;
    }
    .mfrm-footer-alert {
      &_link {
        display: inline-block;
        margin: 0;
      }
    }
  }
}

.ms-footer {
  &__sr-alert-container {
    width: 100%;
    margin: $footer-space / 4 0 $footer-space / 2;
    @include breakpoint(md) {
      margin: $footer-space / 2 0;
    }
    .row {
      justify-content: space-between;
      align-items: center;
    }

    .col-12 {
      display: flex;
      flex-basis: 100%;
      @include breakpoint(lg) {
        flex-basis: 0;
      }
      &:first-child {
        flex-grow: 1;
      }
    }

    .col-12:last-child {
      justify-content: flex-start;
      @include breakpoint(lg) {
        justify-content: flex-end;
      }
    }
  }
}

.mfrm-footer {
  &-alert_link {
    padding: 13px $footer-space / 4;
    @include breakpoint(sm) {
      padding: 14px $footer-space / 4;
    }
    border: 1px solid $footer-disclosure-background;
    background: $color-white;
    border-radius: 5px;
    margin-bottom: $footer-space / 2;
    cursor: pointer;
    display: none;
    @include breakpoint(md) {
      display: block;
    }
    @include breakpoint(lg) {
      margin-bottom: 0;
    }
    &:hover {
      text-decoration: none;
      text-decoration-color: transparent;
      color: $footer-text-color;
    }
  }
  &-alert_text {
    @include add-mfrm-icon($icon-info, after);
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 16px;
    &::after {
      font-size: 30px;
      margin-left: $footer-space / 2;
      vertical-align: middle;
    }
  }
}
//==============================================================================
// Footer Accessibility
//==============================================================================
.ms-footer {
  &__accessibility {
    padding: 5px 10px;
    background-color: $color-white;
    border: 1px solid $color-border-footer-secondary;
    border-radius: 22.5px;
    @include breakpoint(lg) {
      margin-left: $footer-space / 4;
    }
    a {
      @include vfi($color-black);
      display: block;
    }
    img {
      max-width: 56px;
      height: auto;
      max-height: 22px;
    }
  }
}
//==============================================================================
// Footer Disclosures
//==============================================================================

.ms-footer {
  &__disclosures {
    font-family: $font-family-primary;
    font-weight: normal;
    font-size: $font-size-s;
    color: $color-text-primary;
    p {
      margin-bottom: 25px;
      line-height: 1.8;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__show-more-text {
    background: none;
    border: 0em;
    text-decoration: underline;
  }
  &__show-more-text-connect {
    white-space: nowrap;
  }
}
//==============================================================================
// Footer Related Pages
//==============================================================================
.be-ix-link-block {
  margin-top: 30px !important;
  .be-related-link-container {
    display: block !important;
    text-align: left !important;
    .be-label {
      color: $color-text-primary !important;
      font-size: $font-size-xl !important;
      line-height: 1.2 !important;
      font-weight: 700 !important;
      font-family: $font-family-primary !important;
    }
    .be-list {
      li {
        font-family: $font-family-primary !important;
      }
      .be-list-item a {
        color: $color-text-primary !important;
        font-size: $font-size-m !important;
        line-height: 1.5 !important;
        &:hover {
          text-decoration: none !important;
          color: $color-text-secondary !important;
        }
      }
    }
  }
}