.mfrm-spotlight {
    &__container {
        margin-top: -15px;
        margin-bottom: 15px;
        padding: 15px;
        border-radius: 5px;
        background-color: $color-bg-feature-spotlight;
        @include breakpoint(md) {
            margin-top: 0;
            padding: 0;
            display: inline-block;
            background-color: transparent;
        }
        @include breakpoint(lg) {
            margin-bottom: 30px;
            padding: 0 15px;
        }
        /* override for tabs accordion */
        .mfrm-tab-accordion & {
            width: auto;
        }
    }
    &__title {
        @include font-content($font-size-l, bold, 1.22);
        margin-bottom: 20px;
        color: $color-text-primary;
        @include breakpoint(md) {
            @include font-content($font-size-xl, bold, 1.22);
        }
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        @include breakpoint(md) {
            padding: 20px 15px 0;
            flex-direction: row;
            align-items: center;
            background-color: $color-bg-feature-spotlight;
            border-radius: 10px;
        }
        /* override for tabs accordion */
        .mfrm-tab-accordion & {
            margin-bottom: 0;
            padding: 0;
            @include breakpoint(md) {
                padding: 20px 15px 0;
            }
        }
        li {
            @include font-content($font-size-l, normal, 1.37);
            margin-bottom: 20px;
            color: $color-text-primary;
            display: flex;
            align-items: center;

            &:last-child {
                margin-bottom: 0;
            }

            .mfrm-spotlight__icon {
                max-height: 24px;
                margin-right: 15px;
            }

            @include breakpoint(md) {
                @include font-content($font-size-m, normal, 1.43);
                margin-right: 30px;
                &:last-child {
                    margin-bottom: 20px;
                    margin-right: 0;
                }
            }
            @include breakpoint(lg) {
                @include font-content($font-size-l, normal, 1.37);
            }
            /* Feature icons */
            &.motion_separation {
                @include add-mfrm-icon($icon-mattress-motion);
            }
            &.cooling_technology {
                @include add-mfrm-icon($icon-snowflake);
            }
            &.breathable {
                @include add-mfrm-icon($icon-breathability);
            }
            &.eco_friendly {
                @include add-mfrm-icon($icon-plant);
            }
            &.hypo_allergenic,
            &.hypoallergenic {
                @include add-mfrm-icon($icon-hypoallergenic);
            }
            &.support {
                @include add-mfrm-icon($icon-mattress-support);
            }
            &.pressure_relief {
                @include add-mfrm-icon($icon-mattress-press-relief);
            }
            &::before {
                margin-right: 15px;
                display: inline-block;
                font-size: ($icon-size-m + 6);
                vertical-align: middle;
                display: none;
            }
            /* override for tabs accordion */
            .mfrm-tab-accordion & {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                    @include breakpoint(md) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
