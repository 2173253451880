.mfrm-best-for-sleep-styles-container{
  margin-left: 40px;
    @include breakpoint(lg){
      padding-left: 50px;
    }
    .best-for{
        width: 244.5px;
   &__heading{
    @include font-content($font-size-l, 700, 2.44);
  }
  &__display-name{
    @include font-content($font-size-l, 400, 2.72);
  }
  &__display-about-link{
      @include font-content($font-size-l, 500, 2.44);
  }
 }
}