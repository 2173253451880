//==============================================================================
// Buybox Color Variations
//==============================================================================
$color-item-size: 48px;
$color-item-size-xs: 60px;
.msc-buybox__custom-swatch {
    order: 1;
    margin: 15px 0 5px;
    &__label {
        color: $color-text-tertiary;
        margin-bottom: 15px;
        margin-left: 9px;
        @include font-content($font-size-l, 400, 1.37);
        span {
            color: $color-text-primary;
            font-weight: 700;
        }
        @include breakpoint(lg) {
            @include font-content($font-size-m, 400, 1.57);
        }
    }
    &__dropdown-redesign {
        margin-top: 25px;
    }
    .swatches{
        overflow-x: auto;
        white-space: nowrap;
        @include custom-scrollbar-webkit();
    }
    &__label-redesign {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-bottom: 15px;
    }
    &__color-label{
        color: $color-charcoal;
        text-align: left;
        @include font-content($font-size-l, 500, 20px);
        span{
            @include font-content($font-size-l, 400, 20px);
            text-transform: none;
        }
    }
    &__number-of-colors{
        color: $color-charcoal;
        text-align: left;
        @include font-content($font-size-s, 400, 16px);
    }
    .color-swatch {
       display: inline-flex;
        li {
            width: $color-item-size;
            height: $color-item-size;
            padding: 4px 0px 0px 0px;
            text-align: center;
            cursor: pointer;
            position: relative;
            margin: 0 8px 10px 0;
            background-repeat: no-repeat;
            &:before {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                position: absolute;
                border: 2px solid transparent;
                transition: border ease 0.3s;
            }
            &:hover {
                &:before {
                    border-color: transparent;
                    border-radius: 50%;
                }
            }
            &:focus {
                &:before {
                    border-color: $color-button-border-tertiary;
                    border-radius: 50%;
                }
            }
            &:active,
            &.selected {
                outline: 0;
                &:before {
                    border-color: $color-button-border;
                    border-radius: 50%;
                }
            }
            div {
                background-position: 50%;
                border-radius: 50%;
                height: 42px;
                position: relative;
                &:hover {
                    border: 1px solid $color-button-border;
                }
                &.active-color {
                    &:hover {
                        border: none;
                    }
                }
                &.oos-color {
                    &:hover {
                        border: none;
                    }
                }
            }
            img {
                width: 100%;
                height: auto;
                overflow: hidden;
                border-radius: 50%;
            }
            button {
                opacity: 0;
                display: none;
                visibility: hidden;
            }
            span {
                margin-top: 12px;
                display: inline-block;
                @include font-content($font-size-l, 700, 1.37);
                color: $color-text-primary;
            }
            .gg-format-slash {
                display: block;
                width: 2px;
                height: 61px;
                background: $color-text-tertiary;
                transform: rotate(46deg);
                position: absolute;
                margin: -5px 0px 0px 24px;
            }
            .gg-format-slash-redesign {
                box-sizing: border-box;
                position: relative;
                display: block;
                transform: rotate(45deg) scale(var(--ggs, 1));
                width: 4px;
                height: 58px;
                background: currentColor;
                border-radius: 3px;
                left: 23px;
                top: -4px;
            }
            &.slash {
                border: none;
                cursor: default;
                &:hover,
                &:focus,
                &:active,
                &.selected {
                    &:before {
                        border-color: transparent;
                    }
                }
            }
        }
    }
    @media (min-width: $breakpoint-xs) {
        .color-swatch {
            li {
                width: $color-item-size-xs;
                height: $color-item-size-xs;
                padding: 5px 5px 0px 5px;
                div {
                    height: 49px;
                }
            }
        }
    }
    @include breakpoint(lg) {
        margin: 30px 0 11px;
        &__title {
            font-size: $font-size-m;
        }
    }
}