//The !importants are needed to override the third-party bv styling
div[data-bv-show="rating_summary"] {
    display: none;
    div.bv_main_container {
        padding: 0 !important;
        > div.bv_main_container_row_flex {
            display: none !important;
        }
        .bv_stars_component_container {
            padding-right: 0 !important;
          }
        .bv_avgRating_component_container {
            display: none !important;
        }
        .bv_numReviews_text  {
            font-weight: bold !important;
            margin-left: 2px !important;
        }
    }
}

.mfrm-complete-your-bed {
  & div[data-bv-show="rating_summary"] {
    pointer-events: none;
  }
}