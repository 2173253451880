/**
 * Hide element while making it readable for screen readers
 */
@mixin visually-hidden {
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  margin: -1px;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.sr-only,
.screen-reader-only {
  @include visually-hidden;
}
