$max-field-height: 45px;
$success-message-timeout: calc(var(--ftr-msg-timeout-duration) * 0.001s);

.ms-footer__email-subscription {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-bg-primary;
    &__title {
        @include font-content($font-size-xl, 700, 1.1);
        max-width: 225px;
        margin-bottom: 20px;
        text-align: center;
        color: $color-text-primary;
        @include breakpoint(lg) {
            @include font-content($font-size-xxl, 700, 1.17);
            max-width: 300px;
        }
    }
    &__form {
        width: 100%;
        margin-bottom: 15px;
        @include breakpoint(sm) {
            width: auto;
            display: flex;
        }
        .field-group {
            @include breakpoint(sm) {
                margin-bottom: 0;
            }
        }
        .field-control {
            @extend %field-control-input-rounded;
            max-height: $max-field-height;
            min-width: 100%;
            @include breakpoint(sm) {
                min-width: 300px;
            }
        }
        .field-label {
            /* Override default extended top */
            top: calc(#{$max-field-height} / 3) !important;
        }
    }
    &__button {
        @include btn(primary, large);
        width: 100%;
        @include breakpoint(sm) {
            width: auto;
            margin-left: 10px;
        }
    }
    &__alert-message {
        @include font-content($line-height: 1.43);
        height: 0;
        &--success {
            @include animation-transition(fadeInOut, $duration: $success-message-timeout);
        }
        &--error {
            @include animation-transition(fadeIn, $duration: 0.45s);
            color: $color-error;
        }
    }
}
