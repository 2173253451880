// ------------------ BILLING ADDRESS ------------------ //
$email-info-text-color: #332E2A;

.mfrm-wmap {
    input[type="text"] {
        -webkit-appearance: none;
        box-shadow: none !important;
    }
    .ms-checkout-billing {
        &-address__shipping-address-label,
        &-buttons {
            display: none !important;
        }
    }
    .ms-checkout-billing-address {
        margin-left: 0;
        margin-right: 0;
        display: block;
        &__heading {
            margin-top: 30px;
            margin-bottom: 15px;
            text-transform: capitalize;
        }
        &__subheading {
            @include font-content($font-size-l, normal, 1.63);
            margin-bottom: 15px;
            display: block !important;
            color: $color-text-primary;
        }
        &__subheadingBold {
            font-weight: 500;
        }
    }
    .ms-checkout-billing-address .msc-address-form {
        &__item {
            flex-direction: column;
            &-city {
                .msc-address-form__input-text {
                    margin-bottom: 5px;
                }
            }
            &-state {
                flex-wrap: nowrap !important;
                .state_field {
                    margin-bottom: 5px;
                }
            }
            &-phone {
                .msc-address-form__input {
                    margin-bottom: 5px;
                    width: 100%;
                    &:placeholder-shown + .msc-address-form__label {
                        top: calc(40px / 4);
                        padding-right: 40%;
                        padding-top: 4px;
                        padding-bottom: 5px;
                    }
                }
                .msc-address-form__input:focus,
                .msc-address-form__input.field-control--value {
                    + .msc-address-form__label {
                        top: calc(40px / 3);
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-right: 5px;
                    }
                }
            }
            &-address {
                margin-top: 5px;
            }

            &-email {
                flex-direction: column;
                align-items: flex-start;

                & .wmap-email-info {
                    order: 2;
                    color: $email-info-text-color;
                    @include font-content($font-size-l, 400, 22px);
                    margin-top: 10px;
                    margin-bottom: 25px;
                }

                & .msc-address-form__field {
                    &.wmap-email-style {
                        position: relative;

                        & .wmap-email-icon {
                            height: 30px;
                            width: 30px;
                            position: absolute;
                            top: 5px;
                            left: 10px;
                            display: inline;
                            z-index: 10;
                        }

                        & .field-control {
                            width: 100%;
                            margin-bottom: 5px;
                            padding-left: 50px;

                            &:focus {
                                & ~ .field-label {
                                    margin-left: 10px;
                                    top: calc(42px / 3);
                                }
                            }

                            & ~ .field-label {
                                margin-left: 45px;
                                top: calc(42px / 3);
                            }
                        }
                    }
                }
            }
        }
        &__field {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
        }
        &__label {
            transition: transform ease 0.2s, font-size ease 0.2s;
            visibility: visible !important;
        }
    }
}
