$category-banner-height-default: 145px;
.mfrm-category-banner {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  .ms-content-block__details,
  &.ms-content-block .ms-content-block__details {
    //hide banner unused content
    display: none;
  }
  .ms-content-block__image {
    margin-top: 16px;
    @include breakpoint(md) {
      margin-top: 0px;
      margin-right: -1px;
    }
  }
  &__clickable-banner {
    cursor: pointer;
    border: 3px solid transparent !important;
    &:focus {
      border: 3px solid black !important;
      border-radius: 5px;
    }
  }
  &__cta-button {
    @include btn(primary, small);
    display: flex;
    flex-direction: row;
    padding: 10px 36px;
    &__sleep-disruptor{
      padding: 10px 51px;
    }
    &__text {
      line-height: 21px;
      margin-right: 5px;
    }
    &__logo {
      height: 50%;
    }
  }
}

.single-category-banner-image {
  display: none;
}

.single-category-banner-skeleton {
  width: 100%;
  height: 142px;
  margin-bottom: -10px  !important;
  margin-top: -12px;
  margin-left: 0;
  @include breakpoint(xs) {
    width: 100%;
    height: 159px;
    margin-left: 0;
    margin-top: -17px;
    margin-bottom: -14px  !important;
  }


  @include breakpoint(sm) {
    width: 100%;
    height: 385px;
    margin-top: -42px;
    margin-bottom: -35px  !important;
    margin-left: 0;
  }

  
  @include breakpoint(md) {
    width: 100%;
    height: 427px;
    margin-top: -46px;
    margin-bottom: -39px  !important;
    margin-left: 0;
  }
  @include breakpoint(lg) {
    margin-top: -27px;
    width: 100%;
    height: 264px;
    margin-bottom: -23px !important;
  }
  @include breakpoint(xl) {
    margin-top: -34px;
    width: 100%;
    height: 310.5px;
    margin-bottom: -27px !important;
  }
}


.category-banner__dynamic-image {
  display: flex;
  justify-content: center;
}

// Image height/width on desktop
.category-banner__dynamic-image img {
  width: 1200px;
  height: auto;
}

// Image height/width on tablet
@media only screen and (max-width: 768px) {
  .category-banner__dynamic-image img {
    width: 708px;
  }
}

// Image height/width on mobile
@media only screen and (max-width: 576px) {
  .category-banner__dynamic-image img {
    width: 345px;
  }
}
