$wmap-cs-container-width: 220px;
$wmap-border-color-nav: $color-athensgray;
$wmap-default-padding: 30px;
$wmap-table-border-color: #dde3e8;
$wmap-radio-bg-color: #f1f1f1;
$wmap-radio-border-color: $color-bombay;
$wmap-radio-spacing: 20px;

.mfrm-online-pay {
    // ------------------ BASE LAYOUT ------------------ //
    > .row {
        flex-wrap: nowrap;
        > .col-12:first-child {
            flex-basis: $wmap-cs-container-width;
            max-width: $wmap-cs-container-width;
            display: none;
            @include breakpoint(md) {
                flex-basis: ($wmap-cs-container-width + 20);
                max-width: ($wmap-cs-container-width + 20);
                display: block;
            }
        }
        > .col-12:not(:first-child) {
            flex-shrink: 1;
            display: block !important;
        }
    }
    &-container {
        position: relative;
        padding-top: ($wmap-default-padding - 15);
        @include breakpoint(md) {
            padding-top: ($wmap-default-padding + 5);
            padding-left: $wmap-default-padding;
            padding-right: 0;
        }
    }
}

// ## PAGE CONTENT ## //
.mfrm-wmap {
    // ------------------ PARENT HEADING ------------------ //
    &__head {
        &-title {
            @include font-content(($font-size-xxxl + 0.143), 600, 0.76);
            margin-bottom: 20px;
            color: $color-text-secondary;
        }
        &-text {
            @include font-content($font-size-l, normal, 1.63);
            margin: 15px 0;
            color: $color-text-primary;
        }
    }

    // - HEADINGS - //
    &__order-summary,
    &__amount-pay,
    &__confirmation,
    &__thankyou {
        &__heading {
            @include font-content($font-size-xxl, 400, 1.04);
            padding-bottom: 10px;
            color: $color-text-primary;
            border-bottom: 1px solid $color-text-primary;
        }
        &__subheading {
            @include font-content($font-size-l, normal, 1.63);
            margin: 15px 0;
            color: $color-text-primary;
        }
    }
    .mfrm-online-pay__payment-heading h2,
    .ms-checkout-billing-address__heading {
        @include font-content($font-size-xxl, 400, 1.04);
        padding-bottom: 10px;
        color: $color-text-primary;
        border-bottom: 1px solid $color-text-primary;
    }
    &__print-cta {
        @include add-mfrm-icon($icon-print, before);
        @include vfi-visible($color-black);
        @extend %btn-muted;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        &:hover:not(.disabled):not(:disabled) {
            span {
                color: $color-text-secondary;
            }
        }
        &::before {
            font-size: ($icon-size-l + 8);
            color: $color-text-primary;
        }
        span {
            @include font-content($font-size-m, 600, 1.43);
            color: $color-text-primary;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: color 0.2s ease-in-out;
        }
    }
    &-page.modal-open {
        .mfrm-online-pay {
            filter:blur(5px);
        }
    }
    &-page {
        .embeddedServiceHelpButton {
            @include breakpoint(sm, max-width) {
                display: none;
            }
        }
    }
}