//==============================================================================
// Buybox Content - Eyebrow Message
//==============================================================================
$eyebrow-tooltip-padding: 15px;
$eyebrow-tooltip-width: 239px;

.mfrm-eyebrow {
  &__wrapper {
    position: relative;
    max-width: 67%;
    float: right;
    margin-left: 15px;
  }
  &__button {
    @extend %btn-muted;
    @include font-content($font-size-s, 500, 1.2);
    width: auto;
    height: auto;
    padding: 5px 10px;
    border: 1px solid transparentize($color: $color-button-border, $amount: 0.8);
    border-radius: 100px;
    text-transform: uppercase;
    word-break: break-word;
    color: $color-button-secondary-text;
    @include breakpoint(lg) {
      @include font-content($font-size-m, 700, 1.2);
    }
    sup {
      font-size: ($font-size-s - 0.36);
      @include breakpoint(lg) {
        font-size: ($font-size-m - 0.43);
      }
    }
  }
  &--empty {
    display: none;
  }
}
/* TOOLTIP */
.mfrm-eyebrow__content {
  min-width: $eyebrow-tooltip-width;
  padding: $eyebrow-tooltip-padding;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0px -1px 3px transparentize($color: $color-black, $amount: 0.85),
    0px 1px 3px transparentize($color: $color-black, $amount: 0.85);
  z-index: 1;
  div {
    @include font-content($line-height: 1.29);
    color: $color-text-primary;
  }
}
