.ms-checkout .mfrm-stepper {
  margin-bottom: 20px;
  margin-top: 0;
  @include breakpoint(lg) {
    margin-bottom: 30px;
    margin-right: 0;
    margin-left: 0;
  }
  .mfrm-stepper__step--checked [class*="stepper-module_circle"],
  .mfrm-stepper__step--active [class*="stepper-module_circle"] {
    cursor: pointer;
  }
}
