//==============================================================================
// IMPORTS
//==============================================================================
$mfrm-font-path: '../../../mfrm/';

@font-face {
  font-family: 'Rubik';
  src: local(''), url('#{$mfrm-font-path}Rubik-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: local(''), url('#{$mfrm-font-path}Rubik-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: local(''), url('#{$mfrm-font-path}Rubik-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: local(''), url('#{$mfrm-font-path}Rubik-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
