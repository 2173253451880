/**
 * Global app/site styles
 * =================================
 */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: -webkit-fill-available;
  width: 100%;
  /* Override brightspot styles */
  font-size: 0.875rem !important;
}

header .row {
  margin: 0;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin: 0 auto !important;
  font-family: $font-family-primary;
  /* Override brightspot styles */
  font-size: 1rem !important;
  background-color: $color-white !important;
  background: 0 0;
}
iframe {
  border: 0;
}
* {
  outline: 0;
}

body .container {
  > .row {
    > div[class^="col"] {
      padding: 0;
    }
  }
  @include breakpoint(md) {
    width: 100%;
    &:first-child{
      margin: 0 auto !important;
    }
    margin: 0 auto 80px !important;
  }
  @include breakpoint(lg) {
    width: 100%;
    margin: 0 auto !important;
  }
}

/**
 * Default layout container
 */
.default-container {
  .row {
    margin: 0;
  }
  &.fluid {
    > .row {
      > div[class^="col"] {
        padding: 0;
      }
    }
  }
}

a {
  text-decoration: none;
  transition: color ease 0.3s;
  &:hover,
  &:focus {
    color: $color-link-hover;
  }
}

/* Override Bootstrap Container */
.container {
  width: 100% !important;
  max-width: $page-width-max !important;
  padding: 0 $grid-gutter-width-mobile;
  @include breakpoint(md) {
    padding: 0 $grid-gutter-width-tab;
  }
}

.container,
.fluid {
  @media (min-width: ($breakpoint-xl + 60)) {
    margin: 0 auto;
    padding: 0;
  }
}
.fluid {
  width: 100% !important;
  max-width: 100% !important;
}

.no-click {
  pointer-events: none;
  user-select: none;
  cursor: default;
}

[data-m-image-position="left"] img {
  object-position: left;
}
[data-m-image-position="center"] img {
  object-position: center;
}
[data-m-image-position="right"] img {
  object-position: right;
}

.image-align-right img {
  object-position: right;
}
.image-align-center img {
  object-position: center;
}
.image-align-left img {
  object-position: left;
}

// .ms-cart,
.msc-cart__container,
.ms-checkout {
  margin-bottom: 80px;
}

.ms-cart {
  @include breakpoint(lg) {
    margin-bottom: 40px;
  }
}

.mattressfirm .skip-to-main {
  height: 1px;
  left: -999px;
  position: absolute;
  width: 1px;
  z-index: 300002;
  &:focus,
  &:active {
    display: block;
    height: auto;
    left: 0;
    padding: 15px;
    position: fixed;
    right: 0;
    text-align: center;
    width: auto;
    z-index: 9999999;
    background: $color-white;
    outline: 1px dotted $color-black;
    outline-offset: -14px;
  }
}
