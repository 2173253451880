.ms-search-result__seo {
    margin-bottom: 30px;
    width: 100%;

    h1 {
        @include font-content($font-size-xxl - 0.148, 700, 1);
        color: $color-text-primary;
        margin-bottom: 15px;
    }

    &-text {
        @include font-content($font-size-l, 400, 1.37);
        color: $color-text-primary;
        word-break: break-word;

        a {
            color: $color-text-secondary;
        }

        &-description {
            a {
                color: $color-text-primary;
                text-decoration: underline;

                &:hover {
                    color: $color-text-secondary;
                    text-decoration: underline;
                }
            }
        }
    }

    @include breakpoint(md) {
        margin-left: 241px;
        width: auto;
    }

    @include breakpoint(lg) {
        margin-left: 306px;
    }
}