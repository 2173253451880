$product-recommendation-content-spacing: 8px;
$product-recommendation-img-height-sm: 175px;
$product-recommendation-img-height-lg: 232px;
$product-recommendation-oc-img-height-sm: 185px;
$mfcl-carousel-selector: carousel-module;

.product-recommendations {
    &-container {
        text-align: center;
        h2 {
            @include font-content($font-size-xxl, 700, 28px);
            margin-bottom: 32px;
        }

        .mfcl-carousel__button {
            font-size: 1.72rem !important;
            background: none;
            display: none;
            &:first-of-type {
                left: -27px;
            }
            &:last-of-type {
                right: -27px;
            }
            @include breakpoint(md) {
                top: 50%;
                opacity: 1 !important;
                display: block;
                &:first-of-type {
                    left: -16px;
                }
                &:last-of-type {
                    right: -16px;
                }
            }
            @include breakpoint(lg) {
                &:first-of-type {
                    left: -27px;
                }
                &:last-of-type {
                    right: -27px;
                }
            }
        }
        [class*="#{$mfcl-carousel-selector}_carousel-wrapper-slides"] {
            top: 5px;
            margin-bottom: 30px;
        }
        .msc-product {
            @include no-decoration;
            width: 218px;
            height: 100%;
            display: block;
            border: 1px solid #e1e1e1;
            border-radius: 5px;
            padding: 0;
            color: $color-text-primary;
            white-space: normal;
            transition: 0.5s;
            @include breakpoint(md) {
                width: 212px;
            }
            @include breakpoint(lg) {
                width: 270px;
                &:hover {
                    text-decoration: none;
                    transform: translate(0, -5px);
                }
            }
            .mfrm-product-badge {
                margin-left: 12px;
                margin-top: 10px;
                border-radius: 5px 0px;
            }

            &:focus {
                .msc-product__details,
                .msc-product__details * {
                    /* override mixin property */
                    text-decoration: underline !important;
                }
            }

            .product-price-section {
                margin-bottom: 4px;
                .product-price {
                    @include font-content($font-size-l, 700, 24px);
                    color: $color-text-secondary;
                    .product-price-label {
                        @include font-content($font-size-s, 400, 1.3);
                        color: $color-text-primary;
                        @include breakpoint(md) {
                            @include font-content($font-size-s, 400, 1.17);
                        }
                        @include breakpoint(lg) {
                            @include font-content($font-size-s, 400, 1.4);
                        }
                    }
                }
                .product-original {
                    color: $color-text-tertiary;
                    margin: 0px 5px;
                    @include font-content($font-size-sm, 400, 24px);
                    &.price-text {
                        @include font-content($font-size-l, 700, 24px);
                        color: $color-text-primary;
                        margin: 0;
                    }
                }
                .product-original-price {
                    text-decoration: line-through;
                }
            }

            &__image {
                height: $product-recommendation-img-height-sm;
                width: 100%;
                background-color: $color-bg-primary;
                border-radius: 5px 5px 0 0;

                @include breakpoint(lg) {
                    height: $product-recommendation-img-height-lg;
                }
                img {
                    width: 224px;
                    height: 187px;
                    @include breakpoint(lg) {
                        width: 278px;
                        height: 232px;
                    }
                }
            }

            > img {
                height: 100%;
                width: 100%;
                border-radius: 5px 5px 0 0;
                object-fit: cover;
            }

            &__title {
                @include no-decoration;
                @include text-ellipsis;
                @include font-content($font-size-m, 500, 20px);
                margin: $product-recommendation-content-spacing 0;
                color: $color-text-primary;
                &:hover {
                    text-decoration: underline !important;
                }
            }

            &__details {
                @include font-content($font-size-l, 700, 1.125);
                padding: 16px;
                margin: 0;
                background-color: $color-bg-primary;
                color: $color-text-primary;
                border-radius: 5px;
                text-align: left;
            }
            &__rating-holder {
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                height: 24px;
                .msc-rating {
                    width: 100%;
                    margin-bottom: 8px;
                    @include breakpoint(md) {
                        max-width: inherit;
                    }
                    &__count {
                        @include font-content($font-size-sm, normal, 24px);
                        text-align: left;
                    }
                }
            }
        }

        .shop-ATC-buttons {
            .shop-now-btn {
                min-height: 44px;
                @include btn(primary, large);
                width: 70%;
                display: block;
                margin: 16px auto 0;
                padding: 14px 0;
                @include breakpoint(lg) {
                    margin: 22px auto 0;
                }
            }
        }
        // this is product recommendation caraousel css on Cart & OC page
        .ms-cart &,
        .order-confirmation & {
            .msc-product__image {
                //code added to make room for fallback image
                height: $product-recommendation-oc-img-height-sm;
                @include breakpoint(lg) {
                    height: $product-recommendation-img-height-lg;
                }
            }
        }
    }
}
