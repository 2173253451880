$mattress-matcher-image-height-mobile: 200px;
$mattress-matcher-image-height-desktop: 260px;
$mattress-matcher-image-width-tab: 48.183%;

.mfrm-nsrp-mm {
    padding: $content-block-spacer-y;

    .ms-content-block {
        max-height: 348px;
        display: flex;
        border: 1px solid $color-border-mm-tertiary;
        border-radius: 5px;

        &__image {
            @include breakpoint(lg) {
                order: 2;
                max-width: 500px;
            }

            @include breakpoint(xl) {
                max-width: 600px;
            }

            img {
                height: 100%;
                border-radius: 0 5px 5px 0;
            }
        }

        &__details {
            margin: 0 auto !important;
            padding: 30px 15px;

            @include breakpoint(md) {
                padding: 60px 15px;
            }

            @include breakpoint(lg) {
                order: 1;
                padding: 70px 15px;
            }

            .ms-content-block__details {
                display: block;
                padding: 0;
            }
        }

        &__title {
            margin-bottom: 0;
            font-family: $font-family-primary;
            font-weight: 700;
            font-size: $font-size-s;
            color: $color-text-secondary;
            text-align: center;
            line-height: 0.83;
        }

        &__text {
            font-family: $font-family-primary;
            text-align: center;

            h2 {
                margin-bottom: 10px;
                color: $color-text-primary;
                font-weight: 700;
                font-size: $font-size-xxl;
                line-height: 1.17;
                max-width: inherit;

                @include breakpoint(lg) {
                    font-size: $font-size-xxxl;
                    line-height: 1.14;
                }
            }

            p {
                max-width: 300px;
                margin: 0 auto;
                color: $color-text-primary;
                font-weight: 400;
                font-size: $font-size-m;
                line-height: 1.43;

                @include breakpoint(lg) {
                    font-size: $font-size-l;
                    line-height: 1.375;
                }
            }
        }

        &__cta {
            margin-top: $spacer * 2;
        }

        &__cta .msc-cta__primary {
            @include btn(primary, large);
        }
    }
}

.mfrm-nsrp-mm .ms-content-block {
    &__image {
        display: none;

        @include breakpoint(lg) {
            display: block;
        }
    }
}

//==============================================================================
// PLP Match Banner
//==============================================================================
.mfrm-mm-banner,
.mfrm-mm-banner-global {
    &.ms-content-block {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        border-bottom: 10px solid $color-border-plp-secondary;

        @include breakpoint(md) {
            padding: 0 0 110px;
            flex-direction: row;
            //min-height will boost the CLS desktop by 0.06 but responsiveness won't be as good
            // min-height: 340.35px;
        }

        @include breakpoint(lg) {
            border: 1px solid $color-border-mm-primary;
            border-radius: 5px;
            padding: 0;
            //min-height will boost the CLS desktop by 0.06 but responsiveness won't be as good
            // min-height: 348.91px;
        }
    }

    .ms-content-block {
        &__image {
            display: block;
            height: auto;

            @include breakpoint(sm) {
                max-height: 100%;
            }

            @include breakpoint(md) {
                padding: 0;
                flex-basis: 50%;
                order: 0;
            }

            @include breakpoint(lg) {
                flex-basis: 50%;
                max-width: 50%;
                order: 2;
            }

            img {
                width: 100%;
                border-radius: 5px 5px 0 0;
                object-fit: cover;

                @include breakpoint(md) {
                    border-radius: 5px;
                    max-height: 100%;
                    height: 100%;
                }

            }
        }

        @mixin details-center {
            margin: 0 auto;
            padding: 30px 0;
            display: block !important;

            @include breakpoint(md) {
                padding: 0 15px;
                align-self: center;
                flex-basis: auto;
            }

            @include breakpoint(lg) {
                order: 1;
                padding: 30px 30px 19px 30px;
                margin: 0 auto;
            }

            .ms-content-block__details {
                display: block;
                padding: 0;
            }
        }

        &__details {
            @include details-center;
        }

        &__details-left {
            @include details-center;
            margin: 0 auto;
            padding: 30px 0;
            display: block !important;

            @include breakpoint(md) {
                padding: 0 15px;
                align-self: center;
                flex-basis: 50%;
            }

            @include breakpoint(lg) {
                order: 1;
                padding: 60px;
                margin: 0 0;
            }

            .ms-content-block__details {
                display: block;
                padding: 0;
            }
        }

        &__title {
            @include font-content($font-size-s, bold, 0.83);
            margin-bottom: 15px;
            color: $color-text-secondary;
            text-align: center;

            @include breakpoint(md) {
                margin-bottom: 10px;
            }

            @include breakpoint(lg) {
                margin-bottom: 15px;
            }
        }

        @mixin text-center {
            h2 {
                @include font-content($font-size-xxl, bold, 1.17);
                margin-bottom: 10px;
                color: $color-text-primary;
                text-align: center;

                @include breakpoint(lg) {
                    @include font-content($font-size-xxxl, bold, 1.14);
                }
            }

            p {
                @include font-content($font-size-m, normal, 1.43);
                margin: 0 30px;
                color: $color-text-primary;
                text-align: center;

                @include breakpoint(md) {
                    margin: 0;
                }

                @include breakpoint(lg) {
                    @include font-content($font-size-l, 400, 1.37);
                    max-width: 300px;
                    margin: 0 auto;
                }
            }
        }

        &__text {
            @include text-center;
        }

        &__text-left {
            @include text-center;

            h2 {
                text-align: left;
                margin: 0px 0px 5px 30px;
                @include breakpoint(md) {
                    margin: 0px 0px 5px 15px;
                }
                @include breakpoint(lg) {
                    margin: 0px 0px 5px 0px;
                }
            }

            p {
                margin: 0 30px;
                text-align: left;
                @include font-content($font-size-xl, 400, 1.75);

                @include breakpoint(md) {
                    margin: 0 15px;
                }

                @include breakpoint(lg) {
                    max-width: 400px;
                    margin: 0px;
                }
            }
        }

        &__cta {
            margin-top: $spacer * 2;
            text-align: center;

            @include breakpoint(md) {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                margin-bottom: $spacer * 2;
            }

            @include breakpoint(lg) {
                position: static;
                left: 0;
                transform: none;
                margin-top: $spacer * 2;
                margin-bottom: 0;
            }
        }

        &__cta-left {
            text-align: center;
            margin: $spacer 0px 0px 30px;

            @include breakpoint(md){
                margin: $spacer 0px 0px 15px;
            }
            @include breakpoint(lg) {
                position: static;
                left: 0;
                transform: none;
                margin: $spacer 0px 0px 0px;
            }
        }

        &__cta .msc-cta__primary {
            @include btn(primary, large);
        }
    }
}

.mfrm-mm-banner-nonPLP.ms-content-block.mfrm-mm-banner {
    border-bottom: none;
    border: 1px solid $color-border-mm-primary;
    border-radius: 5px;
    padding: 0;
    min-height: 504px;

    @include breakpoint(md) {
        min-height: 354px;
    }

    .ms-content-block__details {
        flex-basis: 50%;
    }

    .ms-content-block__image {

        @include breakpoint(md) {
            order: 2;
        }

        img {
            @include breakpoint(md) {
                border-radius: 0 5px 5px 0;
            }
        }
    }


    .ms-content-block__cta {
        margin-top: $spacer * 2;
        text-align: center;

        @include breakpoint(md) {
            position: relative;
            left: 0;
            bottom: 0;
            transform: translateX(0%);
            margin-bottom: 0;
        }

    }
}

.mfrm-mm-banner-global.ms-content-block {
    $self: ms-content-block;
    border-radius: 5px;
    flex-direction: column;
    margin-top: $spacer;
    margin-bottom: $spacer * 2;

    @include breakpoint(md) {
        margin-top: $spacer;
        flex-direction: row;
    }

    @include breakpoint(lg) {
        border: 1px solid $color-border-mm-primary;
        height: 261px;
    }

    .#{$self}__image {
        margin-top: 0;
        height: 205px;

        @include breakpoint(md) {
            height: 100%;
        }

        @include breakpoint(lg) {
            order: 0;
            border-radius: 5px 5px 0 0;
        }
    }

    .#{$self}__details {
        flex-basis: 100%;
        margin: 0 auto;
        text-align: center;

        @include breakpoint(md) {
            flex-basis: 50%;
            padding-top: 0;
        }

        @include breakpoint(lg) {
            padding-top: $spacer * 2;
        }
    }

    .#{$self}__title,
    .#{$self}__text>h2 {
        text-align: center;

        @include breakpoint(md) {
            text-align: center;
        }

        @include breakpoint(lg) {
            text-align: center;
        }
    }

    .#{$self}__text {
        @include breakpoint(lg) {
            padding-top: 0;
        }
    }
}