.mfrm-adj-base {
  padding: 30px 0;

  @media (max-width: $breakpoint-lg) {
    padding: 0;
  }

  h2 {
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: $breakpoint-lg) {
      margin-bottom: 25px;
      font-size: 24px;
    }
  }
  
    &__dropdown {
      order: 2;
      padding: 0 1px;

      @media (max-width: $breakpoint-lg) {
        padding: 0px 30px;
      }

      @media (max-width: $breakpoint-sm) {
        padding: 0px 15px;
      }

      &-label {
        @extend %field-control-label;
        @extend %float-label;
        position: relative;
        top: 10px;
        background: $color-white;
        width: auto !important;
        display: inline-block;
        padding: 0 5px;
        margin: 0 10px;
      }
    }


    &__dropdown {
      @include add-mfrm-icon($icon-caret-down, after);
      position: relative;
      order: 2;
      margin-top: 25px;
      &::after {
        position: absolute;
        top: 57%;
        right: 45px;
        bottom: 50%;
        font-size: ($icon-size-xs - 4);

        @media (max-width: $breakpoint-sm) {
          right: 30px;
        }
      }
      select {
        @extend %reset-input-style;
      }
    }


  .msc-dropdown__select{
    @extend %field-control-input;
    width: 100%;
    min-height: 72px;
    @include font-content($font-size-m, 500, 1.14);
  }

  &__sizeButtons-container {
    margin-bottom: 50px;
    text-align: center;
    @include breakpoint(lg){
      display: flex;
      justify-content: center;
    }
  }

  &__size-button {
    border: 1px solid $color-mercury;
    box-sizing: border-box;
    border-radius: 5px;
    background: none;
    padding: 5px 20px;
    margin:5px;
    min-width: 150px;

    &-dimensions {
      font-size: $font-size-s;
    }

    &:focus {
      box-shadow: 0 0 0 2px $color-charcoal;
      text-shadow: 0 0 .65px $color-charcoal, 0 0 .65px $color-charcoal;
    }
  }

  .selected-size {
    box-shadow: 0 0 0 2px $color-charcoal;
    text-shadow: 0 0 .65px $color-charcoal, 0 0 .65px $color-charcoal;
  }

  .selected-view {
    text-decoration: none;
    background-color: $color-tabasco;
    color: $color-white !important;
    border-color: $color-tabasco;
  }

  &__content-container {
    @media (max-width: $breakpoint-lg) {
      flex-direction: column-reverse;
    }
  }

  &__content-left {
    width: 75%;
    @media (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }

  &__selected-size-dimensions, 
  &__selected-view-dimensions {
    @include font-content($font-size-l);
    display: flex;
    justify-content: center;
    width: 100%;

    @media (max-width: $breakpoint-sm) {
      justify-content: flex-start;
    }

    span {
      @include font-content($font-size-xl, bold);
    }
  }

  &__selected-size-dimensions {
    margin-bottom: 40px;
    margin-top: 20px;

    @media (max-width: $breakpoint-sm) {
      display: block;
      line-height: 1.5;
      margin-left: 20px;
      margin-top: 10px;
     }
   

    :first-child {
      margin-right: 20px;

      @media (max-width: $breakpoint-sm) {
        margin-right: 0;
       }
    }
  }


  .mfrm-adj-base__selected-view-dimensions {
    margin-top: 40px;

    .mfrm-adj-base__selected-view-data { 
       margin-right: 20px;
       @media (max-width: $breakpoint-sm) {
        margin-right: 0;
        width: 170px;
        line-height: 2;
       }
    }
  }

  &__image-container {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-md) {
      height: 300px;
    }

    @media (max-width: $breakpoint-sm) {
      height: 150px;
    }
  }
  
  picture, &__image {
    width: 100%;
    height: auto;
  }

  &__adjust {
    @include font-content($font-size-xl, 700);
    text-align: center;
    margin-left: 75px;
    margin-top: 95px;

    @media (max-width: $breakpoint-lg) {
      margin-top: 0px;
      margin-left: 0;
      display: flex;
      justify-content: center;
    }

    @media (max-width: $breakpoint-sm) {
      margin-top: 10px;
      margin-bottom: 15px;
    }


    .mfrm-adj-base__view-wrapper {
      list-style: none;
      // TODO - update accordion css to not use ul
      padding-left: 0 !important;

      @media (max-width: $breakpoint-lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 0 !important;
      }

      @media (max-width: $breakpoint-sm) {
        margin-top: 5px;
      }
    }
  }
  
  &__adjust-header {
    margin-bottom: 10px;
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__view-item {
    margin-bottom: 0px !important;

    @media (max-width: $breakpoint-lg) {
      padding: 0 8px;
    }
  }

  &__view-button {
    @include btn(secondary, large);
    width: 182px;
    margin-top: 10px;
    padding: 16px;
    margin-bottom: 5px;

    @media (max-width: $breakpoint-md) {
      width: 150px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-top: 15px;
    }

  }
}