.ms-serch-result-sorting {
    list-style: none;
    position: absolute;
    z-index: 9;
    background-color: white;
    border: $color-bombay 1px solid;
    width: 100%;
    margin-top: 21px !important;
    border-radius: 5px;
    right: 0;
    top: 28px;
    @include breakpoint(md) {
        width: 345px;
    }
    &__each-list-item {
        font-family: $font-family-primary;
        font-size: 14px;
        line-height: 16px;
        margin: 0 15px;
        padding: 18px 12px;
        border-bottom: #eeeeee 1px solid;
        cursor: pointer;
        &:last-child {
            border-bottom: none;
        }
        @include breakpoint(md) {
            font-size: 16px;
        }
    }
    &__bold {
        font-weight: bold;
    }
    &__arrow {
        position: absolute;
        top: -6px;
        width: 30px;
        right: 0;
        bottom: 0;
        @include box-arrow-border();
        @include breakpoint(md) {
            top: -7px;
        }
    }
}

.ms-search-result-arrow-rotate::after {
    transform: rotate(180deg);
    top: 15px;
}

.ms-search-result-container__Sort-by-category {
    cursor: pointer;
    label {
        cursor: pointer;
        font-size: 14px;
        span {
            font-weight: bold;
        }
        @include breakpoint(md) {
            font-size: 16px;
        }
    }
}
